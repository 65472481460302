import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Modal, Tooltip } from '@mantine/core';
import { Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
// Import files
import * as api from '../../redux/api';
import LoadingPage from '../../components/general/LoadingPage';
import ModalPerusahaanDetail from '../../components/perusahaan/ModalPerusahaanDetail';
import Table from '../../components/general/Table';
import { distanceFormat } from '../../utils/distanceFormat';
import QRCode from 'qrcode.react';
import ModalQrDetails from '../../components/general/ModalQrDetails.jsx';

export default function PerusahaanData() {
  const [showImage, setShowImage] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [imageData, setImageData] = useState(null);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [perusahaanDetailKode, setPerusahaanDetailKode] = useState('');
  const [openModalQR, setOpenModalQR] = useState(false);
  const [qrDetail, setQrDetail] = useState({});

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getAllPerusahaan = async () => {
    await api.getAllPerusahaan().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getAllPerusahaan();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(({ satpamperusahaannama, satpamperusahaankode, satpamperusahaanlatitude, satpamperusahaanlongitude, satpamperusahaanradius, satpamperusahaanalamat }) => {
        if (
          debouncedQuery !== '' &&
          !`${satpamperusahaannama} ${satpamperusahaankode} ${satpamperusahaanlatitude} ${satpamperusahaanlongitude} ${satpamperusahaanradius} ${satpamperusahaanalamat}`
            .toLowerCase()
            .includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  const handleImageData = ({ satpamperusahaanfoto }) => {
    setImageData(satpamperusahaanfoto?.split('/data-perusahaan/')[1] ? process.env.REACT_APP_API_URL_PHOTO + satpamperusahaanfoto : require('../../images/logo.png'));
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (kegiatanKode) => {
    setPerusahaanDetailKode(kegiatanKode);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleOpenModalQR = ({ kode, nama }) => {
    setQrDetail({ kode, nama });
    setOpenModalQR(true);
  };

  const handleDelete = (data) => {
    setShowPopover('');
    Swal.fire({
      text: `Apakah anda yakin ingin menghapus "${data.satpamperusahaannama}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .deletePerusahaan(data.satpamperusahaankode)
            .then((res) => {
              if (res.data.status === false) Swal.fire({ icon: 'error', title: 'Oops...', text: res.data.message });
              else Swal.fire('Good job!', res.data.message, 'success');
              getAllPerusahaan();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  if (data && data[0] === '') return <LoadingPage />;

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamperusahaankode',
      render: (data) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover
            opened={showPopover === data.satpamperusahaankode}
            onClick={() => setShowPopover(showPopover ? '' : data.satpamperusahaankode)}
            width={110}
            position="right"
            withArrow
            shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(data.satpamperusahaankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/desa-adat/update/${data.satpamperusahaankode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Edit
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleDelete(data)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto Desa Adat',
      accessor: 'satpamperusahaanfoto',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamperusahaanfoto }) => (
        <div className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80" onClick={() => handleImageData({ satpamperusahaanfoto })}>
          {satpamperusahaanfoto?.split('/data-perusahaan/')[1] ? (
            <img src={process.env.REACT_APP_API_URL_PHOTO + satpamperusahaanfoto} alt="" className="h-full object-contain rounded" />
          ) : (
            <img src={require('../../images/logo.png')} alt="" className="h-full object-contain rounded" />
          )}
        </div>
      )
    },
    {
      title: 'Kode dan Status',
      accessor: 'satpamperusahaanstatus',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200,
      render: ({ satpamperusahaankode, satpamperusahaannama, satpamperusahaanstatus }) => (
        <div>
          <div
            className="flex justify-center text-center h-auto w-full py-0 my-0 mb-1 cursor-pointer"
            onClick={() =>
              handleOpenModalQR({
                kode: satpamperusahaankode,
                nama: satpamperusahaannama
              })
            }>
            <QRCode
              className=" max-w-max  "
              value={satpamperusahaankode}
              level="L"
              lengthAdjust={'l'}
              imageSettings={{ src: '/logo.png', x: undefined, y: undefined, height: 25, width: 25, excavate: true }}
            />
          </div>
          {satpamperusahaanstatus === 'Aktif' ? (
            <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">Aktif</div>
          ) : (
            <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">Tidak Aktif</div>
          )}
        </div>
      )
    },
    {
      title: 'Nama Desa Adat',
      accessor: 'satpamperusahaannama',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Latitude',
      accessor: 'satpamperusahaanlatitude',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'Longitude',
      accessor: 'satpamperusahaanlongitude',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'Radius',
      accessor: 'satpamperusahaanradius',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamperusahaanradius }) => (
        <div>
          <div className="py-0 my-0">
            <div className="py-0 my-0">{satpamperusahaanradius !== null && satpamperusahaanradius !== null && distanceFormat(satpamperusahaanradius)}</div>
          </div>
        </div>
      )
    },
    {
      title: 'Alamat',
      accessor: 'satpamperusahaanalamat',
      width: 300,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamperusahaanalamat }) => (
        <div>
          <div className="py-0 my-0">
            <div className="py-0 my-0">{satpamperusahaanalamat}</div>
          </div>
        </div>
      )
    }
  ];

  return (
    <div
      className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300"
      onClick={() => showPopover && setShowPopover('')}>
      <div className="w-full min-h-screen mb-5">
        <div className="w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full"></div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <Tooltip label="Tambah Desa Adat">
                  <Link to="/desa-adat/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
          {/* Table Component */}
          {data?.length < 0 ? (
            <div className="text-center">
              <div className="mt-10">Tidak ada data desa adat.</div>
            </div>
          ) : (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          )}
        </div>
        {/* Modal Detail Perusahaan */}
        <ModalPerusahaanDetail kode={perusahaanDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

        {/* Modal Detail QR */}
        <ModalQrDetails qrDetail={qrDetail} name={'QR_code'} openModalQR={openModalQR} setOpenModalQR={setOpenModalQR} />

        {/* Modal Image */}
        <Modal centered size="xl" opened={showImage} onClose={() => setShowImage(false)} className="overflow-auto bg-transparent -mt-10">
          {/* Content image */}
          {imageData && <img src={imageData} alt="" className="max-h-[80vh] object-contain rounded-t-lg flex items-center justify-center" />}
        </Modal>
      </div>
    </div>
  );
}
