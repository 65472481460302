import moment from 'moment';
import React from 'react';

export default function TableRiwayatPatroliMail({ rutePatroli, ruteDetail }) {
  return (
    <>
      {rutePatroli?.map((titik) => (
        <table key={titik.satpampatrolidetailkode} style={{ width: '100%' }}>
          <thead style={{ fontWeight: 700 }}>
            <tr style={{ color: 'rgb(255, 255, 255)' }}>
              <th
                colSpan={4}
                style={{
                  padding: '1rem',
                  backgroundColor: 'rgb(5, 45, 90)',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem'
                }}>
                <p>{titik.satpamtitiknama}</p>
              </th>
              <th
                style={{
                  padding: '1rem',
                  backgroundColor: 'rgb(5, 45, 90)',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem'
                }}>
                <p>{titik.satpampatrolidetailtarget}X</p>
              </th>
            </tr>
            <tr style={{ backgroundColor: 'rgb(209, 213, 219)' }}>
              <th
                style={{
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem'
                }}>
                <p style={{ textAlign: 'center' }}>TANGGAL MASUK</p>
              </th>
              <th
                style={{
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem'
                }}>
                <p style={{ textAlign: 'center' }}>TANGGAL KELUAR</p>
              </th>
              <th
                style={{
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem'
                }}>
                <p style={{ textAlign: 'center' }}>LAMA PATROLI</p>
              </th>
              <th
                style={{
                  width: '25%',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem'
                }}>
                <p style={{ textAlign: 'center' }}>FOTO</p>
              </th>
              <th
                style={{
                  width: '25%',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem'
                }}>
                <p style={{ textAlign: 'center' }}>KETERANGAN</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {ruteDetail?.map((rute) =>
              rute
                ?.filter(
                  (r) =>
                    r.satpampatrolidetailtitiksatpampatrolidetailkode ===
                    titik.satpampatrolidetailkode
                )
                ?.map((titik) => (
                  <tr key={titik.satpampatrolidetailtitikkode}>
                    <td style={{ padding: '1.5rem 0.5rem' }}>
                      <p style={{ textAlign: 'center' }}>
                        {titik.satpampatrolidetailtitikmasuktanggal !== null
                          ? moment(titik.satpampatrolidetailtitikmasuktanggal).format(
                              'DD MMM YYYY HH:mm'
                            )
                          : '-'}
                      </p>
                    </td>
                    <td style={{ padding: '1.5rem 0.5rem' }}>
                      <p style={{ textAlign: 'center' }}>
                        {titik.satpampatrolidetailtitikkeluartanggal !== null
                          ? moment(titik.satpampatrolidetailtitikkeluartanggal).format(
                              'DD MMM YYYY HH:mm'
                            )
                          : '-'}
                      </p>
                    </td>
                    <td style={{ padding: '1.5rem 0.5rem' }}>
                      <p style={{ textAlign: 'center' }}>
                        {titik.satpampatrolidetailtitikdurasirute}
                      </p>
                    </td>
                    <td
                      style={{
                        width: '25%',
                        padding: '1.5rem 0.5rem'
                      }}>
                      <div style={{ display: 'flex' }}>
                        {titik.satpampatrolidetailtitikfotosatu?.split('/data-patroli/')[1] && (
                          <div style={{ width: '50%' }}>
                            <div
                              style={{
                                backgroundColor: '#ffffff',
                                wordBreak: 'break-all',
                                maxWidth: '24rem',
                                height: '6rem',
                                margin: '0 0.5rem',
                                borderRadius: '0.375rem',
                                boxShadow:
                                  '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
                              }}>
                              <img
                                style={{
                                  objectFit: 'contain',
                                  width: '100%',
                                  height: '6rem',
                                  borderRadius: '0.25rem'
                                }}
                                src={titik.satpampatrolidetailtitikfotosatu}
                                alt=""
                              />
                            </div>
                          </div>
                        )}
                        {titik.satpampatrolidetailtitikfotodua?.split('/data-patroli/')[1] && (
                          <div style={{ width: '50%' }}>
                            <div
                              style={{
                                backgroundColor: '#ffffff',
                                wordBreak: 'break-all',
                                maxWidth: '24rem',
                                height: '6rem',
                                margin: '0 0.5rem',
                                borderRadius: '0.375rem',
                                boxShadow:
                                  '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
                              }}>
                              <img
                                style={{
                                  objectFit: 'contain',
                                  width: '100%',
                                  height: '6rem',
                                  borderRadius: '0.25rem'
                                }}
                                src={titik.satpampatrolidetailtitikfotodua}
                                alt=""
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    <td
                      style={{
                        paddingLeft: '0.25rem',
                        paddingRight: '0.25rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        textAlign: 'left',
                        width: '25%'
                      }}>
                      <p>{titik.satpampatrolidetailtitikketerangan}</p>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      ))}
    </>
  );
}
