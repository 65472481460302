// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal, Popover } from '@mantine/core';
import Table from '../../components/general/Table';
// import Swal from 'sweetalert2';
// import moment from 'moment/moment';
import ModalKeluhanDetail from '../../components/keluhan/ModalKeluhanDetail';
import { useDebouncedValue } from '@mantine/hooks';
import ModalAssignKeluhan from '../../components/keluhan/ModalAssignKeluhan';

export default function KeluhanData() {
  const [data, setData] = useState([]);
  // const [dataKategori, setDataKategori] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [modalAssign, setModalAssign] = useState(false);
  const [keluhanDetailKode, setKeluhanDetailKode] = useState('');
  const [selectId, setSelectId] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedPhoto, setSelectedPhoto] = useState('');
  const [openModalPhoto, setOpenModalPhoto] = useState(false);
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.keluhanData().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(({ satpamkeluhantanggal, satpamkeluhankategorinama, satpamkeluhanadminnama, satpamkeluhanisi, satpamkeluhankode }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamkeluhantanggal} ${satpamkeluhankategorinama} ${satpamkeluhanadminnama} ${satpamkeluhanisi} ${satpamkeluhankode} `
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        })
      );
    }
  }, [data, debouncedQuery]);

  const handleOpenModalDetail = (kode) => {
    setKeluhanDetailKode(kode);
    setOpenModalDetail(true);
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'index',
      width: 100,
      render: (data, index) => {
        return (
          <div key={index} className="flex justify-center items-center gap-1">
            {/* Button Aksi */}
            <Popover width={150} position="right" withArrow shadow="xl">
              <Popover.Target>
                <div className="px-2 py-1 my-1 btnAksi">Action</div>
              </Popover.Target>
              <Popover.Dropdown className="popOverDropdown">
                <div
                  size="sm"
                  className="popOverItem"
                  onClick={() => {
                    handleOpenModalDetail(data.satpamkeluhankode);
                    setSelectId(data.satpamkeluhanid);
                  }}>
                  <i className="fa-solid fa-circle-info"></i> Detail
                </div>

                {data.satpamkeluhanstatus === 'Open' && (
                  <div
                    size="sm"
                    className="popOverItem"
                    onClick={() => {
                      setKeluhanDetailKode(data.satpamkeluhanid);
                      setModalAssign(true);
                    }}>
                    <i className="fa-solid fa-users"></i> Assign Petugas
                  </div>
                )}
              </Popover.Dropdown>
            </Popover>
          </div>
        );
      }
    },
    {
      title: 'Kode Keluhan',
      accessor: 'satpamkeluhankode',
      width: 150,
      render: ({ satpamkeluhankode }) => <p className="text-center text-wrap">{satpamkeluhankode ? satpamkeluhankode : '-'}</p>
    },
    {
      title: 'Status',
      accessor: 'satpamkeluhantanggal',
      width: 150,
      render: ({ satpamkeluhanstatus }) => (
        <div>
          {satpamkeluhanstatus === '' ? (
            <div className="block w-full mb-2 text-sm text-center border-2 rounded-full border-primary text-primary btnStatus">Belum Di Assign</div>
          ) : satpamkeluhanstatus === 'Progress' ? (
            <div className="block w-full mb-2 text-sm text-center border-2 rounded-full border-secondary text-secondary btnStatus">On Progress</div>
          ) : (
            <div className="block w-full mb-2 text-sm text-center border-2 rounded-full border-secondary text-secondary btnStatus">{satpamkeluhanstatus}</div>
          )}
        </div>
      )
    },
    {
      title: 'Alamat',
      accessor: 'satpamkeluhanalamat',
      width: 150,
      render: ({ satpamkeluhanalamat }) => <p className="truncate">{satpamkeluhanalamat}</p>
    },
    {
      title: 'Isi',
      accessor: 'satpamkeluhanisi',
      width: 150,
      render: ({ satpamkeluhanisi }) => <p className="truncate">{satpamkeluhanisi}</p>
    },
    {
      title: 'Gambar',
      accessor: 'satpamkeluhangambar',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      textAlignment: 'center',
      render: ({ satpamkeluhangambar }) => (
        <div
          className="flex items-start justify-center h-32 mt-1 mb-2 cursor-pointer hover:opacity-80"
          onClick={() => {
            setOpenModalPhoto(true);
            setSelectedPhoto(satpamkeluhangambar);
          }}>
          <img
            src={satpamkeluhangambar ?? 'https://placehold.co/600x400?text=Cannot+Show\nImage'}
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = 'https://placehold.co/600x400?text=Cannot+Show\nImage';
            }}
            className="object-contain h-32 rounded"
          />
        </div>
      )
    }
  ];

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 overflow-x-hidden md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      {/* <div className="mb-5">
        <Cards
          title="Statistik Keluhan"
          desc="Berikut ini adalah jumlah data laporan keluhan"
          card1={{ title: 'Progress', value: data?.filter((value) => value.satpamkeluhanstatus === 'Progress')?.length ?? 0 }}
          card2={{
            title: 'Belum Di Assign',
            value: data?.filter((value) => value.satpamkeluhanstatus === '')?.length ?? 0
          }}
          card3={{ title: 'Total Data', value: data?.length ?? 0 }}
          loading={false}
        />
      </div> */}

      <div className="w-full min-h-screen">
        <div className="justify-center w-full pb-8 rounded-lg bg-gray-50 shadowAll">
          <div className="flex flex-wrap items-center lg:flex-nowrap justify-beetween">
            <div className="flex flex-col flex-wrap items-center justify-start w-full md:flex-row"></div>
            <div className="flex justify-end px-5 my-3">
              {/* <div className="flex w-full gap-4">
                <Tooltip label="Tambah Keluhan">
                  <div className="p-2 rounded-md cursor-pointer bg-customGray hover:opacity-80" onClick={() => setOpenModalKeluhanCreate(true)}>
                    <AiOutlineFileAdd size={24} />
                  </div>
                </Tooltip>
              </div> */}
            </div>
          </div>
          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data Keluhan.</div>
            </div>
          )}
        </div>
        {/* Modal Detail Keluhan */}
        <ModalKeluhanDetail kode={keluhanDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} selectedData={selectId} fetchData={getData} />
        <ModalAssignKeluhan keluhanID={keluhanDetailKode} isOpen={modalAssign} setModal={setModalAssign} refetch={getData} />
        <Modal opened={openModalPhoto} onClose={() => setOpenModalPhoto(false)} centered size="xl">
          <img src={selectedPhoto} alt="" />
        </Modal>

        {/* Modal Create Keluhan */}
        {/* <ModalKeluhanCreate getData={getData} data={dataKategori} openModalKeluhanCreate={openModalKeluhanCreate} setOpenModalKeluhanCreate={setOpenModalKeluhanCreate} /> */}
        {/* Modal Update Keluhan */}
        {/* <ModalKeluhanUpdate
          kode={keluhanDetailKode}
          getData={getData}
          data={dataKategori}
          openModalKeluhanUpdate={openModalKeluhanUpdate}
          setOpenModalKeluhanUpdate={setOpenModalKeluhanUpdate}
        /> */}
      </div>
    </div>
  );
}
