import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link, useParams } from 'react-router-dom';
import { useDebouncedValue } from '@mantine/hooks';
// Import files
import * as api from '../../redux/api';
import BtnAction from '../../components/general/BtnAction';
import BtnAdd from '../../components/general/BtnAdd';
import LoadingPage from '../../components/general/LoadingPage';
import ModalPendudukDetail from '../../components/penduduk/ModalPendudukDetail';
import Table from '../../components/general/Table';

export default function Penduduk({ satpamData, satpamJumlah }) {
  const [satpamDetailId, setSatpamDetailId] = useState('');
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [data, setData] = useState(['']);
  const [datas, setDatas] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const { id } = useParams();

  const getData = async () => {
    await api.getPendudukByBanjarId(id).then((res) => {
      setData(res.data.data);
      setDatas(res.data);
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(({ satpamkode, satpamstatus, satpamnamalengkap, satpamtipe, satpamjabatan, satpamnohp }) => {
        if (
          debouncedQuery !== '' &&
          !`${satpamkode} ${satpamstatus} ${satpamnamalengkap} ${satpamtipe} ${satpamjabatan} ${satpamnohp}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  const handleDelete = (data) => {
    Swal.fire({
      text: `Apakah anda ingin menghapus "${data.nama}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .deletePenduduk(data.id)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  const handleOpenModalDetail = (id) => {
    setSatpamDetailId(id);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const columnData = [
    {
      title: 'Action',
      accessor: 'id',
      width: 100,
      render: (data) => (
        <BtnAction
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          handleOpenModalDetail={handleOpenModalDetail}
          handleDelete={handleDelete}
          data={data.id}
          id={data}
          page="/banjar-penduduk"
          noPrint={true}
        />
      )
    },
    {
      title: 'Kewarganegaraan',
      accessor: 'jenis_kewarganegaraan',
      textAlignment: 'center',
      width: 150
    },
    {
      title: 'Foto Selfie',
      accessor: 'foto_selfie',
      width: 150,
      textAlignment: 'center',
      render: ({ foto_selfie }) => (
        <>
          {foto_selfie?.split('/data-penduduk/')[1] ? (
            <img src={process.env.REACT_APP_API_URL_PHOTO + foto_selfie} alt="" className="h-[125px] w-[125px] object-cover my-2 rounded-lg" />
          ) : (
            '-'
          )}
        </>
      )
    },
    {
      title: 'Nama Lengkap',
      accessor: 'nama',
      width: 200
    },
    {
      title: 'Status Kependudukan',
      accessor: 'tipe',
      width: 150,
      textAlignment: 'center',
      render: ({ tipe }) => (tipe === 'KT' ? 'Krama Tamiu' : tipe === 'T' ? 'Tamiu' : 'Krama Adat')
    },
    {
      title: 'Pekerjaan',
      accessor: 'pekerjaan',
      width: 150
    },
    { title: 'No. Hp', accessor: 'no_hp', width: 150 },
    {
      title: 'Awal Tinggal',
      accessor: 'periode_start',
      width: 125,
      textAlignment: 'center',
      render: ({ periode_start }) => (periode_start !== null ? moment(periode_start).format('LL') : '-')
    },
    {
      title: 'Akhir tinggal',
      accessor: 'periode_end',
      width: 125,
      textAlignment: 'center',
      render: ({ periode_end }) => (periode_end !== null ? moment(periode_end).format('LL') : 'Menetap')
    },
    {
      title: 'Jenis Kelamin',
      accessor: 'jenis_kelamin',
      width: 125,
      textAlignment: 'center',
      render: ({ jenis_kelamin }) => (jenis_kelamin === 'L' ? 'Laki-laki' : 'Perempuan')
    },
    { title: 'Alamat Asal', accessor: 'alamat_ktp', width: 250 },
    {
      title: 'Nomor Kartu',
      accessor: 'nomor_kartu',
      width: 150,
      textAlignment: 'center',
      render: ({ nomor_kartu }) => (nomor_kartu !== null ? nomor_kartu : '-')
    },
    {
      title: 'Tgl Expired Identitas',
      accessor: 'date_expired_kartu_id',
      width: 150,
      textAlignment: 'center',
      render: ({ date_expired_kartu_id }) => (date_expired_kartu_id !== null ? moment(date_expired_kartu_id).format('LL') : 'Seumur hidup')
    },
    {
      title: 'Foto Identitas',
      accessor: 'foto_kartu_id',
      width: 200,
      textAlignment: 'center',
      render: ({ foto_kartu_id }) => (
        <>
          {foto_kartu_id?.split('/data-penduduk/')[1] ? (
            <img src={process.env.REACT_APP_API_URL_PHOTO + foto_kartu_id} alt="" className="h-[125px] w-full object-cover my-2 rounded-lg" />
          ) : (
            '-'
          )}
        </>
      )
    },
    {
      title: 'Nomor Paspor',
      accessor: 'paspor',
      width: 150,
      textAlignment: 'center',
      render: ({ paspor }) => (paspor !== null ? paspor : '-')
    },
    {
      title: 'Tgl Expired Paspor',
      accessor: 'date_expired_paspor',
      width: 150,
      textAlignment: 'center',
      render: ({ date_expired_paspor }) => (date_expired_paspor !== null ? moment(date_expired_paspor).format('LL') : '-')
    },
    {
      title: 'Foto Paspor',
      accessor: 'foto_paspor',
      width: 200,
      textAlignment: 'center',
      render: ({ foto_paspor }) => (
        <>
          {foto_paspor?.split('/data-penduduk/')[1] ? (
            <img src={process.env.REACT_APP_API_URL_PHOTO + foto_paspor} alt="" className="h-[125px] w-full object-cover my-2 rounded-lg" />
          ) : (
            '-'
          )}
        </>
      )
    }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-16" onClick={() => showPopover && setShowPopover('')}>
      <div className="w-full">
        {/* Table Component */}
        <div className="my-10 w-full bg-light p-5 rounded-lg shadowAll">
          <div className="flex justify-between ml-5">
            <div className="font-bold text-lg">Banjar Detail</div>
            <div className="flex gap-4 w-fit">
              <BtnAdd path={`/banjar-penduduk/registrasi/${id}`} />
            </div>
          </div>
          {datas.nama_banjar && (
            <div className="px-5">
              <b>Nama Banjar:</b> {datas.nama_banjar}
            </div>
          )}
          {data.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data penduduk.</div>
            </div>
          )}
        </div>
        <ModalPendudukDetail id={satpamDetailId} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />
      </div>

      <div className="flex justify-center mt-5">
        <Link to="/banjar">
          <button className="btnAksi bg-primary py-2 px-5">Kembali ke banjar</button>
        </Link>
      </div>
    </div>
  );
}
