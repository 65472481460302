import moment from 'moment/moment';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { BsGearFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
// Import files
import * as api from '../../redux/api';
import BtnAdd from '../../components/general/BtnAdd';
import LoadingPage from '../../components/general/LoadingPage';
import Table from '../../components/general/Table';

export default function Banjar() {
  const [data, setData] = useState(['']);
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const getData = async () => {
    await api.getBanjarAll().then((response) => {
      setData(response.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(({ name }) => {
        if (debouncedQuery !== '' && !`${name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  const handleDelete = (data) => {
    Swal.fire({
      text: `Apakah anda ingin menghapus "${data.name}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .deleteBanjar(data.id)
            .then((res) => {
              if (res.data.status === true) {
                Swal.fire('Good job!', res.data.message, 'success');
                getData();
              } else {
                Swal.fire({ icon: 'error', title: 'Oops...', text: res.data.message });
              }
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  const columnData = [
    {
      title: 'Action',
      accessor: 'id',
      width: 110,
      render: (data) => (
        <div className="flex justify-center">
          <Popover width={110} position="right" withArrow shadow="lg">
            <Popover.Target>
              <div className="px-2 py-1 my-1 btnAksi">
                <BsGearFill className="mb-[2px]" /> &nbsp; Aksi
              </div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              {adminType === 'Admin Project' && (
                <>
                  <Link to={`/banjar/update/${data.id}?nama_banjar=${data.name}`}>
                    <div className="popOverItem">
                      <i className="fa-solid fa-pen-to-square"></i> Ubah
                    </div>
                  </Link>
                  <div className="popOverItem" onClick={() => handleDelete(data)}>
                    <i className="fa-solid fa-trash mr-[2px]"></i> Hapus
                  </div>
                </>
              )}
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Code Banjar',
      accessor: 'code',
      width: 50
    },
    {
      title: 'Nama Banjar',
      accessor: 'name',
      width: 50
    },
    {
      title: 'Dibuat pada',
      accessor: 'created_at',
      width: 50
    }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 md:px-10">
      <div className="w-full">
        {/* Table Component */}
        <div className="w-full p-5 my-10 rounded-lg bg-light shadowAll">
          <div className="flex justify-end mb-3">
            <div className="flex gap-4 w-fit">
              <BtnAdd path="/banjar/registrasi" />
            </div>
          </div>
          {data.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data banjar!</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
