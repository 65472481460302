import moment from 'moment';
import React from 'react';
import { parseToNewLine } from '../../utils/parseToNewLine';
import Headerlaporan from '../general/HeaderLaporan';
import TimelineSatpamProses from '../general/TimelineSatpamProses';
import PhotoCetak from '../general/PhotoCetak';

const TableCetakKegiatan = ({ data, date, kode }) => {
  return (
    <div className="w-full">
      {data.map((item) => (
        <div key={item.id}>
          {!kode && typeof date !== 'undefined' && (
            <h4 className="text-md p-2">
              <span className="font-bold">Periode : </span>
              {moment(date.startDate).format('DD MMM YYYY')} s.d {moment(date.endDate).format('DD MMM YYYY')}
            </h4>
          )}
          <div className="flex w-full">
            <Headerlaporan />
            <div className="w-full px-5 bg-dark flex flex-col items-end justify-center text-white">
              <div className="text-gray-100 text-[18px] font-bold">LAPORAN LALU LINTAS</div>
              <div className="text-gray-100 text-[15px] font-bold">{item.satpamperusahaannama}</div>
            </div>
          </div>
          <div className="m-5">
            <table className="border-gray-50" id="kegiatanHarianPrint">
              <thead className="bg-primary font-bold text-white text-[12px]">
                <tr>
                  <th colSpan="4" className="border border-gray-300 px-2 py-2 text-left">
                    <p className="font-semibold text-[18px]">#{item?.kode}</p>
                  </th>
                </tr>
              </thead>
              <tbody className="text-black">
                <tr>
                  <td className="w-[30%] border border-gray-300 px-2 py-2 text-left font-semibold">{moment(item?.tanggalmelaporkan).format('DD MMM YYYY HH:mm')}</td>
                  <td className="border border-gray-300 px-2 py-2 text-left">
                    <span className="font-bold">KATEGORI</span> : {item?.kategorinama}
                  </td>
                  <td rowSpan="3" className="w-[20%] border border-gray-300 px-2 py-2 text-left align-top">
                    <h4 className="font-semibold mb-2">DETAIL PROSES</h4>
                    <TimelineSatpamProses data={item.satpam_proses ? item.satpam_proses : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-2 py-2 text-left align-top">
                    PATOKAN LOKASI : {item?.alamatpatokan}
                    <br /> <br />
                    <span className="font-semibold">ALAMAT LENGKAP</span> : {item?.alamat}
                  </td>
                  <td rowSpan="2" className="border border-gray-300 px-2 py-2 text-left align-top">
                    <h4 className="font-semibold mb-2">URAIAN KEGIATAN</h4>
                    <p className="mb-3">{parseToNewLine(item?.uraian)}</p>
                    <h4 className="font-semibold mb-2">KETERANGAN</h4>
                    {parseToNewLine(item?.keterangan)}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-2 py-2 text-left">
                    <h4 className="text-md font-bold">
                      PELAPOR <br /> {item?.satpamnamalengkap}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" rowSpan="2" className="border border-gray-300 p-5">
                    <div className="mx-auto">
                      <div className="flex flex-wrap -m-1 md:-m-2">
                        <PhotoCetak data="/data-lalulintas/" photo={process.env.REACT_APP_API_URL_PHOTO + item?.fotosatu} keterangan={item?.keteranganfotosatu} />
                        <PhotoCetak data="/data-lalulintas/" photo={process.env.REACT_APP_API_URL_PHOTO + item?.fotodua} keterangan={item?.keteranganfotodua} />
                        <PhotoCetak data="/data-lalulintas/" photo={process.env.REACT_APP_API_URL_PHOTO + item?.fototiga} keterangan={item?.keteranganfototiga} />
                        <PhotoCetak data="/data-lalulintas/" photo={process.env.REACT_APP_API_URL_PHOTO + item?.fotoempat} keterangan={item?.keteranganfotoempat} />
                        <PhotoCetak data="/data-lalulintas/" photo={process.env.REACT_APP_API_URL_PHOTO + item?.fotolima} keterangan={item?.keteranganfotolima} />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="w-[20%] border border-gray-300 px-2 py-2 text-center align-middle">
                    <div>Tanda Tangan</div>
                    <div className="border mt-28 mb-10 mx-5" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-center w-full my-2 text-black">
            <b>Copyright&copy;{process.env.REACT_APP_YEAR}</b>
            <br />
            <b>{process.env.REACT_APP_COMPANY}</b>
          </div>
          <div className="page-break" />
        </div>
      ))}
    </div>
  );
};

export default TableCetakKegiatan;
