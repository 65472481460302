import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Popover } from '@mantine/core';
import { Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useState, useEffect } from 'react';
// Import files
import * as api from '../../redux/api';
import { MdVerified } from 'react-icons/md';
import { parseToNewLine } from '../../utils/parseToNewLine';
import LoadingPage from '../../components/general/LoadingPage';
import ModalKeadaanCreate from '../../components/keadaan/ModalKeadaanCreate';
import ModalKeadaanDetail from '../../components/keadaan/ModalKeadaanDetail';
import Table from '../../components/general/Table';
import { Modal } from '@mantine/core';

export default function KeadaanData({ personilType }) {
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const [data, setData] = useState(['']);
  const [dataSatpam, setDataSatpam] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [keadaanDetailKode, setKeadaanDetailKode] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalKeadaanCreate, setOpenModalKeadaanCreate] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [status, setStatus] = useState('all');
  const [imageData, setImageData] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.keadaanData().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    const getPersonil = async () => {
      await api.personilListByType('pecalang').then((res) => {
        setDataSatpam(res.data.data);
      });
    };
    getPersonil();
    getData();
  }, [personilType]);

  useEffect(() => {
    data?.length > 0
      ? setFilterData(
          data?.filter(({ satpamkeadaantanggal, satpamkeadaanpesan, satpamkeadaanalamat, satpamkeadaanlatitude, satpamkeadaanlongitude }) => {
            if (
              debouncedQuery !== '' &&
              !`${satpamkeadaantanggal} ${satpamkeadaanpesan} ${satpamkeadaanalamat} ${satpamkeadaanlatitude} ${satpamkeadaanlongitude}`
                .toLowerCase()
                .includes(debouncedQuery.trim().toLowerCase())
            ) {
              return false;
            }
            return true;
          })
        )
      : setFilterData([]);
  }, [data, debouncedQuery]);

  useEffect(() => {
    if (status === 'all') setFilterData(data);
    else if (status === 'verify') setFilterData(data.filter((item) => item.is_verified !== 0));
    else setFilterData(data.filter((item) => item.is_verified !== 1));
  }, [data, status]);

  const handleOpenModalDetail = (kode) => {
    setKeadaanDetailKode(kode);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleImageData = ({ file_image }) => {
    setImageData(file_image?.split('/data-keadaan-darurat/')[1] ? file_image : 'https://demofree.sirv.com/nope-not-here.jpg');
    setShowImage(!showImage);
  };

  const handleVerification = (data) => {
    Swal.fire({
      text: `Apakah anda yakin ingin memverifikasi pesan darurat ini?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        const verifikasiData = async () => {
          const res = await api.keadaanVerify({ satpamkeadaankode: data.satpamkeadaankode });
          if (res.data.status) {
            Swal.fire('Good job!', res.data.message, 'success');
            setLoading(false);
            getData();
            setOpenModalDetail(false);
          } else {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.data.message
            });
          }
        };
        verifikasiData();
      }
    });
  };

  const handleDelete = (kode) => {
    setShowPopover('');
    Swal.fire({
      text: 'Do you want to delete this message?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .keadaanDelete(kode)
            .then((res) => {
              if (res.data) {
                Swal.fire('Good job!', res.data.message, 'success');
                getData();
              } else Swal.fire({ icon: 'error', title: 'Oops...', text: 'Failed to delete!' });
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamkeadaankode',
      width: 100,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: (data) => (
        <div className="flex justify-center gap-1">
          {/* Button Aksi */}
          <Popover
            opened={showPopover === data.satpamkeadaankode}
            onClick={() => setShowPopover(showPopover ? '' : data.satpamkeadaankode)}
            width={110}
            position="right"
            withArrow
            shadow="xl">
            <Popover.Target>
              <div className="px-2 py-1 my-1 btnAksi">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(data.satpamkeadaankode)}>
                <i className="fa-solid fa-circle-info"></i> {data.is_verified === 1 ? 'Detail' : 'Verifikasi'}
              </div>
              <div className="popOverItem" onClick={() => handleDelete(data.satpamkeadaankode)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto Keadaan',
      accessor: 'file_image',
      width: 150,
      textAlignment: 'center',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ file_image }) => (
        <div className="flex justify-center" onClick={() => handleImageData({ file_image })}>
          {file_image && file_image.split('/data-keadaan-darurat/')[1] ? (
            <img src={`${process.env.REACT_APP_API_URL_PHOTO}${file_image}`} alt="" className="h-[125px] w-[125px] object-cover my-2 rounded-lg" />
          ) : (
            <img src={'https://demofree.sirv.com/nope-not-here.jpg'} alt="" className="h-[125px] w-[125px] object-cover my-2 rounded-lg" />
          )}
        </div>
      )
    },
    {
      title: 'Tanggal & Status',
      accessor: 'satpamkeadaantanggal, is_verified',
      width: 150,
      textAlignment: 'center',
      cellsStyle: {
        verticalAlign: 'bottom'
      },
      render: ({ satpamkeadaantanggal, is_verified }) => (
        <div className="flex flex-col gap-2 justify-center items-center my-0 py-0">
          <div className="flex justify-center w-full h-auto py-0 my-0  text-center cursor-pointer">{moment(satpamkeadaantanggal).format('DD MMM YYYY HH:mm')}</div>
          <div>
            {is_verified === 1 ? (
              <span className="flex items-center justify-center gap-1 py-2 px-3 border rounded-full border-primary">
                <MdVerified size={20} className="text-primary" /> Terverifikasi
              </span>
            ) : (
              <span className="py-2 px-3 border rounded-full border-primary">Belum verifikasi</span>
            )}
          </div>
        </div>
      )
    },
    {
      title: 'Nama Pengirim',
      accessor: 'satpamnamalengkap',
      width: 150,
      textAlignment: 'center',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamnamalengkap }) => (
        <div>
          <div className="flex justify-center w-full h-auto py-0 my-0 mb-1 text-center cursor-pointer">{satpamnamalengkap}</div>
        </div>
      )
    },
    {
      title: 'Pesan',
      width: 250,
      accessor: 'satpamkeadaanpesan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkeadaanpesan }) => <>{satpamkeadaanpesan !== null && satpamkeadaanpesan !== '' && parseToNewLine(satpamkeadaanpesan)}</>
    },
    {
      title: 'Alamat',
      accessor: 'satpamkeadaanalamat',
      width: 300,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: (data) =>
        data?.link_maps ? (
          <a href={data.link_maps} target="_blank" rel="noreferrer" className="w-full underline text-primary">
            {data?.satpamkeadaanalamat || '-'}
          </a>
        ) : (
          <span className="w-full">{data?.satpamkeadaanalamat || '-'}</span>
        )
    }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div
      className="relative w-full px-4 mx-auto -mt-8 overflow-x-hidden md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300"
      onClick={() => showPopover && setShowPopover('')}>
      <div className="w-full min-h-screen">
        <div className="justify-center w-full pb-8 rounded-lg bg-gray-50 shadowAll">
          <div className="flex flex-wrap items-center lg:flex-nowrap justify-beetween">
            <div className="flex flex-col flex-wrap items-center justify-start w-full md:flex-row"></div>
            <div className="flex justify-end px-5 my-3">
              <div className="flex w-full gap-4">
                {adminType === 'Admin Project' && (
                  <Tooltip label="Tambah laporan">
                    <div className="p-2 rounded-md cursor-pointer bg-customGray hover:opacity-80" onClick={() => setOpenModalKeadaanCreate(true)}>
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} id="satpamkeadaankode" verify={true} setStatus={setStatus} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data Keadaan.</div>
            </div>
          )}
        </div>

        <div className="flex justify-center mt-5">
          <Link to="/">
            <button className="px-20 py-2 btnAksi bg-primary">Dashboard</button>
          </Link>
        </div>

        {/* Modal Detail Keadaan */}
        <ModalKeadaanDetail
          kode={keadaanDetailKode}
          openModalDetail={openModalDetail}
          setOpenModalDetail={setOpenModalDetail}
          handleVerification={handleVerification}
          loading={loading}
          data={data.filter((item) => item.satpamkeadaankode === keadaanDetailKode)[0]}
        />
        {/* Modal Detail Keadaan */}
        <ModalKeadaanCreate getData={getData} dataSatpam={dataSatpam} openModalKeadaanCreate={openModalKeadaanCreate} setOpenModalKeadaanCreate={setOpenModalKeadaanCreate} />

        {/* modal image */}
        <Modal centered size="xl" opened={showImage} onClose={() => setShowImage(false)} className="overflow-auto bg-transparent -mt-10">
          {/* Content image */}
          {imageData && <img src={imageData} alt="" className="w-full object-contain rounded-t-lg flex items-center justify-center" />}
        </Modal>
      </div>
    </div>
  );
}
