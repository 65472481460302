import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as api from '../../redux/api';
import { UsahaSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';
import SectionDataDiriUsaha from '../../components/personil/SectionDataDiriUsaha';
import SectionInputDataUsaha from '../../components/personil/SectionInputDataUsaha';
import SectionInputFotoUsaha from '../../components/personil/SectionInputFotoUsaha';

const UsahaCreateUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [banjarOptions, setBanjarOptions] = useState([]);
  const [posOptions, setPosOptions] = useState([]);
  const [paketOptions, setPaketOptions] = useState([]);
  const [detailUsaha, setDetailUsaha] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(UsahaSchema)
  });

  const [fotoUsahaPreview] = useImagePreview(watch('foto_usaha'));
  const [fotoSelfiePreview] = useImagePreview(watch('foto_selfie'));

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const getBanjarList = async () => {
    await api.personilBanjarData().then((res) => {
      setBanjarOptions(res.data.data);
    });
  };

  const getPosList = async () => {
    await api.patroliGetPOS().then((res) => {
      setPosOptions(res.data.data);
    });
  };

  const getPaketList = async () => {
    await api.getPaketKTU().then((res) => {
      setPaketOptions(res.data.data);
    });
  };

  const getDetailUsaha = async () => {
    await api.getDetailKTU(id).then((res) => {
      const value = res.data.data;
      setDetailUsaha(value);
      setValue('banjar_code', value.banjar_code);
      setValue('satpamnamalengkap', value.satpamnamalengkap);
      setValue('satpamnohp', value.satpamnohp);
      setValue('satpamemail', value.satpamemail);
      setValue('satpamjeniskelamin', value.satpamjeniskelamin);
      setValue('tempat_lahir', value.satpamtempatlahir);
      setValue('tanggal_lahir', value?.satpamtanggallahir?.split(' ')[0]);
      setValue('nama_usaha', value.nama);
      setValue('jenis_kewarganegaraan', value.jenis_kewarganegaraan);
      setValue('jenis_usaha', value.jenis_usaha);
      setValue('izin_nomor', value.izin_nomor);
      setValue('nomor_identitas', value.nomor_identitas);
      setValue('izin_usaha', value.izin_usaha);
      setValue('alamat', value.alamat);
      setValue('lat', value.lat);
      setValue('long', value.long);
      setValue('status_kepemilikan', value.status_kepemilikan);
      setValue('paket', String(value.paket_id));
      setValue('kode_pos', value.kode_pos);
      setValue('foto_selfie', value.foto_selfie);
      setValue('foto_usaha', value.foto_usaha);

      if (value.jenis_kewarganegaraan === 'WNA') {
        setValue('paspor', value.paspor);
        setValue('date_expired_paspor', value.date_expired_paspor);
      }
    });
  };

  const createUsaha = async (data) => {
    const formData = new FormData();

    // Compress foto
    if (data?.foto_selfie?.length > 0 && typeof data.foto_selfie !== 'string') {
      const compressedFile = await imageCompression(data.foto_selfie[0], compressOptions);
      formData.append('foto_selfie', compressedFile, compressedFile.name);
    }

    if (data?.foto_usaha?.length > 0 && typeof data.foto_usaha !== 'string') {
      const compressedFile = await imageCompression(data.foto_usaha[0], compressOptions);
      formData.append('foto_usaha', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    if (data?.foto_selfie?.length === 0) {
      formData.delete('foto_selfie');
    }

    if (data?.foto_usaha?.length === 0) {
      formData.delete('foto_usaha');
    }

    if (data?.izin_nomor?.length === 0) {
      formData.delete('izin_nomor');
    }

    if (data?.paspor?.length === 0) {
      formData.delete('paspor');
    }

    if (data?.date_expired_paspor?.length === 0) {
      formData.delete('date_expired_paspor');
    }

    await api
      .createKTU(formData)
      .then((res) => {
        Swal.fire('Good job!', res.data.message, 'success').then((isConfirm) => {
          if (isConfirm) {
            navigate(-1);
          }
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        let errMessage = [];
        if (error?.response?.data?.errors) {
          for (const key in error.response.data.errors) {
            errMessage.push(error.response.data.errors[key].toString());
          }
        }
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errMessage.length > 0 ? errMessage.join(', ') : 'Something went wrong!'
        });
      });
  };

  const updateUsaha = async (data) => {
    const formData = new FormData();

    // Compress foto
    if (data?.foto_selfie?.length > 0 && typeof data.foto_selfie !== 'string') {
      const compressedFile = await imageCompression(data.foto_selfie[0], compressOptions);
      formData.append('foto_selfie', compressedFile, compressedFile.name);
    }

    if (data?.foto_usaha?.length > 0 && typeof data.foto_usaha !== 'string') {
      const compressedFile = await imageCompression(data.foto_usaha[0], compressOptions);
      formData.append('foto_usaha', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    formData.append('satpamkode', detailUsaha.satpam_code);

    if (typeof data?.foto_usaha === 'string') {
      formData.delete('foto_usaha');
    }

    if (typeof data?.foto_selfie === 'string') {
      formData.delete('foto_selfie');
    }

    if (data?.paket === '1' || data?.paket === 1 || data?.paket === '2' || data?.paket === 2) {
      formData.delete('kode_pos');
      formData.delete('lat');
      formData.delete('long');
    }

    if (data?.izin_nomor === null || data?.izin_nomor === 'null' || data?.izin_usaha === 'Tidak Ada') {
      formData.delete('izin_nomor');
    }

    if (data?.kode_pos === 'null' || data?.kode_pos === null) {
      formData.delete('kode_pos');
    }

    if (data?.lat === 'null' || data?.lat === null) {
      formData.delete('lat');
    }

    if (data?.long === 'null' || data?.long === null) {
      formData.delete('long');
    }

    await api
      .updateKTU(formData)
      .then((res) => {
        Swal.fire('Good job!', res.data.message, 'success').then((isConfirm) => {
          if (isConfirm) {
            navigate(-1);
          }
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        let errMessage = [];
        if (error?.response?.data?.errors) {
          for (const key in error.response.data.errors) {
            errMessage.push(error.response.data.errors[key].toString());
          }
        }
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errMessage.length > 0 ? errMessage.join(', ') : 'Something went wrong!'
        });
      });
  };

  const onSubmit = async (data) => {
    setLoading(true);

    if (id === undefined) {
      createUsaha(data);
    }

    if (id !== undefined) {
      updateUsaha(data);
    }
  };

  useEffect(() => {
    getBanjarList();
    getPosList();
    getPaketList();
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      getDetailUsaha();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="relative w-full h-full px-4 mx-auto -mt-8 md:px-10 bg-gray-50">
      <div className="w-full">
        <div className="flex items-center justify-center w-full py-5 mt-5 mb-5 text-lg font-semibold text-center rounded-lg bg-light shadowAll">
          <h1>{id ? 'Update Usaha' : 'Registrasi Usaha'}</h1>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full">
          <div className="flex-wrap gap-5 text-sm lg:flex md:flex-nowrap">
            <div className="flex-col gap-5 lg:w-1/2 lg:flex">
              <SectionDataDiriUsaha register={register} errors={errors} watch={watch} banjarOptions={banjarOptions} />
              <SectionInputDataUsaha register={register} watch={watch} errors={errors} posOptions={posOptions} paketOptions={paketOptions} />
            </div>
            <SectionInputFotoUsaha register={register} errors={errors} fotoSelfiePreview={fotoSelfiePreview} fotoUsahaPreview={fotoUsahaPreview} />
          </div>

          <div className="flex items-center justify-between w-full px-10 py-5 my-8 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-light bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <button type="button" onClick={() => navigate(-1)} className="px-5 py-2 text-sm rounded-md text-light bg-dark hover:opacity-80">
              Kembali
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UsahaCreateUpdate;
