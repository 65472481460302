import React from 'react';
import { Modal } from '@mantine/core';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import CurrencyInput from 'react-currency-input-field';

const dataSelect = [
  {
    nama: 'Belum Bayar',
    value: 'UNPAID'
  },
  {
    nama: 'Sudah Bayar',
    value: 'PAID'
  }
];

export default function ModalKontrbisuiUpdate({ id, setModalUpdate, openModalUpdate, refreshData }) {
  const [loading, setIsLoading] = useState(false);
  const [DaftarKode, setDaftarKode] = useState([]);
  const [wargaCode, setWargaCode] = useState('');
  const [namaWarga, setNamaWarga] = useState('');
  const [listStatus, setListStatus] = useState('');
  const [updateStatus, setUpdateStatus] = useState('');
  const [price, setPrice] = useState('');
  const [year, setYear] = useState(moment().format('YYYY'));
  const [month, setMonth] = useState(moment().format('MM'));
  const [dataKontribusi, setDataKontribusi] = useState({});

  useEffect(() => {
    let active = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.getDetailKontribusi(id.id);

        setDataKontribusi(response.data.data);
      } catch (error) {
        // Handle error if needed
        console.error('Error fetching detail:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // Get list of warga
    if (active) {
      fetchData();
      listWarga();
    }
  }, [id]);

  const listWarga = async () => {
    try {
      const response = await api.listWarga();
      const data = response.data.data.map((item) => ({
        value: item.user_code,
        label: item.nama
      }));
      setDaftarKode(data);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const listStatusPaid = () => {
    const data = dataSelect;
    const dataStatus = data.map((item) => ({
      value: item.value,
      label: item.nama
    }));
    return dataStatus;
  };

  const CheckStatus = () => {
    const data = dataSelect;
    const valeuData = data.filter((value) => value.value === listStatus);
    return valeuData[0];
  };

  console.log(CheckStatus());

  // Populate form fields when dataKontribusi changes
  useEffect(() => {
    if (dataKontribusi && dataKontribusi.date) {
      setWargaCode(dataKontribusi.user_code || '');

      // Extract the numeric value from the "price" field
      const numericPrice = parseFloat(dataKontribusi.price.replace(/[^0-9.-]+/g, ''));
      setPrice(numericPrice);

      // Mapping of month names from your API data to numeric format
      const monthNameMapping = {
        Januari: '01',
        Februari: '02',
        Maret: '03',
        April: '04',
        Mei: '05',
        Juni: '06',
        Juli: '07',
        Agustus: '08',
        September: '09',
        Oktober: '10',
        November: '11',
        Desember: '12'
      };

      // Split the date from your API into month and year
      const parts = dataKontribusi.date.split(' ');
      if (parts.length === 2) {
        const monthName = parts[0];
        const year = parts[1];

        // Map the month name to numeric format
        const monthNumeric = monthNameMapping[monthName];

        if (monthNumeric && year) {
          setYear(year);
          setMonth(monthNumeric);
        } else {
          // Handle invalid date format
          console.error('Invalid date format:', dataKontribusi.date);
        }
      } else {
        // Handle invalid date format
        console.error('Invalid date format:', dataKontribusi.date);
      }
    }
  }, [dataKontribusi]);

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (!wargaCode || !price || !year || !month) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill in all required fields'
      });
    }

    const formData = new FormData();
    formData.append('user_code', wargaCode);
    formData.append('price', price);

    // Create a moment object for the selected year and month
    const selectedDate = moment(`${year}-${month}`, 'YYYY-MM');
    const selectedMonthValue = selectedDate.month() + 1;
    const selectedYearValue = selectedDate.year();
    formData.append('bulan', selectedMonthValue);
    formData.append('tahun', selectedYearValue);
    formData.append('status', updateStatus);

    try {
      setIsLoading(true);

      // Pass the correct id as a simple value (string or number) here
      const response = await api.updateKontribusi(id.id, formData);

      if (response.status === 200) {
        setModalUpdate(false);

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Data submitted successfully'
        });
        // Clear form fields and reset year and month
        setWargaCode('');
        setPrice('');
        setYear(moment().format(''));
        setMonth(moment().format(''));
        refreshData();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to submit data'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while submitting data'
      });
      console.error('API Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal opened={openModalUpdate} size="lg" overflow="inside" centered closeOnClickOutside onClose={() => setModalUpdate(false)}>
      <div className="mb-6 text-center ">
        <h1>Modal Update Kontribusi</h1>
      </div>
      <form className="flex flex-col w-full gap-5" onSubmit={onSubmitForm}>
        <div className="flex flex-col w-full gap-5">
          <div className="flex flex-col justify-around p-2 ">
            <div className="md:flex">
              <div className="md:w-1/3">
                <label className="block mt-2 font-semibold md:mb-0">Nama Warga</label>
              </div>
              <div className="md:w-2/3">
                <Select
                  options={DaftarKode}
                  defaultInputValue={namaWarga}
                  className="w-full text-black"
                  placeholder="Pilih Warga"
                  isSearchable={true}
                  value={wargaCode ? DaftarKode.find((option) => option.value === wargaCode) : null}
                  name="wargakode"
                  required
                  onChange={(e) => {
                    // Handle the selected option here
                    setWargaCode(e.value);
                  }}
                />
              </div>
            </div>
            <div className="my-5 md:flex">
              <div className="md:w-1/3">
                <label className="block mt-2 font-semibold md:mb-0">Masukan Harga</label>
              </div>
              <div className="md:w-2/3 ">
                <CurrencyInput
                  id="price-input"
                  name="price"
                  placeholder="Harga"
                  prefix="Rp "
                  allowNegativeValue={false}
                  decimalsLimit={0}
                  decimalSeparator=","
                  groupSeparator="."
                  value={price}
                  onValueChange={(value) => setPrice(value)}
                  className="w-full p-2 outline-none border-[1.5px] focus:border-blue-600 hover:border-blue-500 rounded-md"
                  required
                />
              </div>
            </div>
            <div className="mb-5 md:flex">
              <div className="md:w-1/3">
                <label className="block mt-2 font-semibold md:mb-0">Pilih Bulan dan Tahun</label>
              </div>
              <div className="md:w-2/3 ">
                <input
                  type="month"
                  value={`${year}-${month}`}
                  onChange={(e) => {
                    const selectedDate = moment(e.target.value, 'YYYY-MM');
                    const selectedYear = selectedDate.format('YYYY');
                    const selectedMonth = selectedDate.format('MM');
                    setYear(selectedYear);
                    setMonth(selectedMonth);
                  }}
                  className="w-full p-2 outline-none border-[1.5px] focus:border-blue-600 hover:border-blue-500 rounded-md"
                  required
                />
              </div>
            </div>
            <div className="mb-5 md:flex">
              <div className="md:w-1/3">
                <label className="block mt-2 font-semibold md:mb-0">Status Pembayaran</label>
              </div>
              <div className="md:w-2/3 ">
                <Select
                  options={listStatusPaid()}
                  defaultInputValue={listStatus === 'PAID' ? 'Sudah Bayar' : 'Belum Bayar'}
                  className="w-full text-black"
                  placeholder="Pilih Status Pembayaran"
                  isSearchable={true}
                  name="status"
                  required
                  onChange={(e) => {
                    setUpdateStatus(e.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full px-10 py-5 border-t">
            <Link to="/kontribusi">
              <button className="px-5 py-2 text-sm rounded-md btn text-gray-50 bg-dark hover:opacity-80" onClick={() => setModalUpdate(false)}>
                Kembali
              </button>
            </Link>
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}
