import moment from 'moment';
import React from 'react';
import HeaderLaporanMail from '../general/HeaderLaporanMail';
import TableRiwayatPatroliMail from './TableRiwayatPatroliMail';

export default function TableCetakPatroliMail({ dataPatroli, ruteDetail }) {
  return (
    <div style={{ width: '100%' }}>
      {dataPatroli.map((data, i) => (
        <div key={i}>
          <div style={{ display: 'flex', width: '100%' }}>
            <HeaderLaporanMail />
            <div
              style={{
                width: ' 100%',
                padding: '0.7rem',
                textAlign: 'right',
                backgroundColor: '#0f172a',
                textColor: 'white'
              }}>
              <div
                style={{
                  display: 'block',
                  fontSize: '18px',
                  fontWeight: '700',
                  color: 'rgb(243, 244, 246)'
                }}>
                LAPORAN PATROLI
              </div>
              <div
                style={{
                  display: 'block',
                  fontSize: '15px',
                  fontWeight: '700',
                  color: 'rgb(243, 244, 246)'
                }}>
                {data.satpamperusahaannama}
              </div>
            </div>
          </div>
          <div style={{ margin: '1.25rem' }}>
            <table style={{ width: '100%', borderColor: 'rgb(249, 250, 251)' }} id="kegiatanHarianPrint">
              <thead
                style={{
                  backgroundColor: 'rgb(5, 45, 90)',
                  fontSize: '12px',
                  fontWeight: '700',
                  color: 'rgb(255, 255, 255)'
                }}>
                <tr>
                  <th
                    colSpan="2"
                    style={{
                      padding: '0 0.5rem',
                      textAlign: 'left'
                    }}>
                    <p style={{ padding: '0.5rem', fontSize: '18px', fontWeight: '600' }}>#{data?.detail?.satpampatrolikode}</p>
                  </th>
                  <th
                    colSpan="2"
                    style={{
                      padding: '0 0.5rem',
                      textAlign: 'right'
                    }}>
                    <p style={{ fontSize: '18px', fontWeight: '600' }}>{data?.detail?.satpampatrolijenis}</p>
                  </th>
                </tr>
              </thead>
              <tbody style={{ color: 'rgb(0, 0, 0)' }}>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      height: '10px !important',
                      padding: '0.5rem',
                      textAlign: 'left',
                      fontWeight: '600'
                    }}>
                    <div
                      style={{
                        justifyContent: 'space-between',
                        gap: '0.5rem',
                        textTransform: 'uppercase'
                      }}>
                      <div style={{ float: 'left' }}>NAMA PERSONIL</div>
                      <div style={{ float: 'right' }}>{data?.detail?.satpamnamalengkap}</div>
                    </div>
                  </td>
                  <td
                    colSpan="2"
                    rowSpan={data?.detail?.satpampatrolijenis === 'Patroli Berkendara' ? 6 : 2}
                    style={{
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left'
                    }}>
                    <h4
                      style={{
                        marginBottom: '0.5rem',
                        fontWeight: '600',
                        textTransform: 'uppercase',
                        textAlign: 'center'
                      }}>
                      Rute Patroli
                    </h4>
                    <div style={{ width: '100%', overflowX: 'auto' }}>
                      <table style={{ width: '100%', tableLayout: 'auto', lineHeight: '1.5' }}>
                        <thead>
                          <tr>
                            <th
                              colSpan={3}
                              style={{
                                backgroundColor: 'rgb(5, 45, 90)',
                                paddingLeft: '0.5rem',
                                paddingRight: '0.5rem',
                                paddingTop: '0.25rem',
                                paddingBottom: '0.25rem',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                fontSize: '0.875rem',
                                lineHeight: '1.25rem',
                                fontWeight: 600,
                                letterSpacing: '0.05em',
                                color: 'rgb(255, 255, 255)'
                              }}>
                              {data?.detail?.satpamposnama}
                            </th>
                          </tr>
                          <tr>
                            <th
                              style={{
                                paddingLeft: '0.5rem',
                                paddingRight: '0.5rem',
                                paddingTop: '0.25rem',
                                paddingBottom: '0.25rem',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                fontSize: '0.875rem',
                                lineHeight: '1.25rem',
                                fontWeight: 600,
                                letterSpacing: '0.05em'
                              }}>
                              CHECKPOINT
                            </th>
                            <th
                              style={{
                                paddingLeft: '0.5rem',
                                paddingRight: '0.5rem',
                                paddingTop: '0.25rem',
                                paddingBottom: '0.25rem',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                fontSize: '0.875rem',
                                lineHeight: '1.25rem',
                                fontWeight: 600,
                                letterSpacing: '0.05em'
                              }}>
                              TARGET
                            </th>
                            <th
                              style={{
                                paddingLeft: '0.5rem',
                                paddingRight: '0.5rem',
                                paddingTop: '0.25rem',
                                paddingBottom: '0.25rem',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                fontSize: '0.875rem',
                                lineHeight: '1.25rem',
                                fontWeight: 600,
                                letterSpacing: '0.05em'
                              }}>
                              KEMAJUAN
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.detail?.Detail?.length > 0 &&
                            data?.detail?.Detail?.map((rute_patroli) => (
                              <tr key={rute_patroli.satpampatrolidetailsatpamtitikkode}>
                                <td
                                  style={{
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    paddingLeft: '0.5rem',
                                    paddingRight: '0.5rem',
                                    paddingTop: '0.25rem',
                                    paddingBottom: '0.25rem',
                                    fontSize: '0.875rem',
                                    lineHeight: '1.25rem'
                                  }}>
                                  <p style={{ textAlign: 'center' }}>{rute_patroli.satpamtitiknama}</p>
                                </td>
                                <td
                                  style={{
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    paddingLeft: '0.5rem',
                                    paddingRight: '0.5rem',
                                    paddingTop: '0.25rem',
                                    paddingBottom: '0.25rem',
                                    fontSize: '0.875rem',
                                    lineHeight: '1.25rem'
                                  }}>
                                  <p style={{ textAlign: 'center' }}>{rute_patroli.satpampatrolidetailtarget}</p>
                                </td>
                                <td
                                  style={{
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    paddingLeft: '0.5rem',
                                    paddingRight: '0.5rem',
                                    paddingTop: '0.25rem',
                                    paddingBottom: '0.25rem',
                                    fontSize: '0.875rem',
                                    lineHeight: '1.25rem'
                                  }}>
                                  <p style={{ textAlign: 'center' }}>{rute_patroli.presentase.toFixed()}%</p>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '25%',
                      whiteSpace: 'nowrap',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top',
                      fontWeight: 600
                    }}>
                    <div style={{ gap: '2rem' }}>
                      <div style={{ float: 'left' }}>WAKTU MULAI</div>
                      <div style={{ float: 'right' }}>
                        {data?.detail?.satpampatroliawalwaktu !== null ? moment(data?.detail?.satpampatroliawalwaktu).format('DD MMM YYYY HH:mm') : '-'}
                      </div>
                    </div>
                    <div>
                      <div style={{ float: 'left' }}>WAKTU AKHIR</div>
                      <div style={{ float: 'right' }}>
                        {data?.detail?.satpampatroliakhirwaktu !== null ? moment(data?.detail?.satpampatroliakhirwaktu).format('DD MMM YYYY HH:mm') : '-'}
                      </div>
                    </div>
                    <div>
                      <div style={{ float: 'left' }}>WAKTU PATROLI</div>
                      <div style={{ float: 'right' }}>{data?.detail?.durasi_kumulatif}</div>
                    </div>
                  </td>
                  <td
                    style={{
                      padding: '0.5rem',
                      fontWeight: '700',
                      verticalAlign: 'top'
                    }}
                    height="5px">
                    <div>
                      <div style={{ float: 'left' }}>TARGET</div>
                      <div style={{ float: 'right' }}>{data?.detail?.target_kumulatif}X</div>
                    </div>
                    <div style={{ clear: 'both' }}>
                      <div style={{ float: 'left' }}>KEMAJUAN</div>
                      <div style={{ float: 'right' }}>{data?.detail?.persentase_kumulatif?.toFixed()}%</div>
                    </div>
                  </td>
                </tr>
                {data?.detail?.satpampatrolijenis === 'Patroli Berkendara' && (
                  <>
                    <tr>
                      <td
                        colSpan="2"
                        style={{
                          height: '20%',
                          padding: '1.5rem',
                          fontWeight: '700',
                          verticalAlign: 'top'
                        }}
                        height="5px">
                        <div>
                          <div style={{ float: 'left' }}>JENIS KENDARAAN</div>
                          <div style={{ float: 'right' }}>{data?.detail?.satpampatrolikendaraanjenis}</div>
                        </div>
                        <div style={{ clear: 'both' }}>
                          <div style={{ float: 'left' }}>NAMA KENDARAAN</div>
                          <div style={{ float: 'right' }}>{data?.detail?.satpampatrolikendaraannama}</div>
                        </div>
                        <div style={{ clear: 'both' }}>
                          <div style={{ float: 'left' }}>NOMOR KENDARAAN</div>
                          <div style={{ float: 'right' }}>{data?.detail?.satpampatrolikendaraannomor}</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: '25%',
                          padding: '2rem',
                          fontWeight: '700',
                          verticalAlign: 'left'
                        }}
                        height="5px">
                        <p style={{ textAlign: 'center' }}>KILOMETER AWAL</p>
                      </td>
                      <td
                        style={{
                          height: '20%',
                          padding: '1.5rem',
                          fontWeight: '700',
                          verticalAlign: 'left'
                        }}
                        height="5px">
                        <p style={{ textAlign: 'center' }}>KILOMETER AKHIR</p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: '25%',
                          padding: '2rem',
                          fontWeight: '700',
                          verticalAlign: 'left'
                        }}
                        height="5px">
                        <div
                          style={{
                            display: 'flex',
                            gap: '2px',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            padding: '0 2rem'
                          }}>
                          <div style={{ width: '50%' }}>{data?.detail?.satpampatrolikilometerawalangka}</div>
                          {data?.detail?.satpampatrolikilometerawalfoto.split('/data-patroli/')[1] && (
                            <div style={{ display: 'flex', width: '50%' }}>
                              <div style={{ width: '100%' }}>
                                <div
                                  style={{
                                    maxWidth: '24rem',
                                    wordBreak: 'break-all',
                                    borderRadius: '0.375rem',
                                    borderTopLeftRadius: '0.375rem',
                                    borderTopRightRadius: '0.375rem',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    boxShadow: '0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)'
                                  }}>
                                  <img style={{ height: '9rem', width: '100%', objectFit: 'cover' }} src={data?.detail?.satpampatrolikilometerawalfoto} alt="" />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td
                        style={{
                          width: '25%',
                          padding: '2rem',
                          fontWeight: '700',
                          verticalAlign: 'left'
                        }}
                        height="5px">
                        <div
                          style={{
                            display: 'flex',
                            gap: '2px',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            padding: '0 2rem'
                          }}>
                          <div style={{ width: '50%' }}>{data?.detail?.satpampatrolikilometerakhirangka}</div>
                          {data?.detail?.satpampatrolikilometerakhirfoto.split('/data-patroli/')[1] && (
                            <div style={{ display: 'flex', width: '50%' }}>
                              <div style={{ width: '50%' }}>
                                <div
                                  style={{
                                    maxWidth: '24rem',
                                    wordBreak: 'break-all',
                                    borderRadius: '0.375rem',
                                    borderTopLeftRadius: '0.375rem',
                                    borderTopRightRadius: '0.375rem',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    boxShadow: '0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)'
                                  }}>
                                  <img style={{ height: '9rem', width: '100%', objectFit: 'cover' }} src={data?.detail?.satpampatrolikilometerakhirfoto} alt="" />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="2"
                        style={{
                          width: '20%',
                          padding: '0.5rem',
                          textAlign: 'left',
                          fontWeight: 600
                        }}
                        height="5px">
                        <div style={{ display: 'flex', gap: '2px', flexDirection: 'space-between' }}>
                          <p>JARAK (KM AWAL - KM AKHIR)</p>
                          <p>{data?.detail?.satpampatrolikilometerakhirangka - data?.detail?.satpampatrolikilometerawalangka} KM</p>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      minWidth: '100%',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left'
                    }}
                    height="5px">
                    <h4
                      style={{
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        textAlign: 'center',
                        fontWeight: 600,
                        color: 'black'
                      }}>
                      RIWAYAT PATROLI
                    </h4>
                    <div style={{ width: '100%', overflowX: 'auto' }}>
                      <TableRiwayatPatroliMail rutePatroli={data?.detail?.Detail} ruteDetail={ruteDetail} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'center',
                      verticalAlign: 'top'
                    }}
                  />
                  <td
                    style={{
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'center',
                      verticalAlign: 'middle'
                    }}>
                    <div>Tanda Tangan</div>
                    <div
                      style={{
                        marginLeft: '1.25rem',
                        marginRight: '1.25rem',
                        marginTop: '4rem',
                        marginBottom: '1.25rem',
                        border: '1px solid #c0c0c0'
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                width: '100%',
                textAlign: 'center',
                color: 'rgb(0, 0, 0)'
              }}>
              <b>Copyright&copy;{process.env.REACT_APP_YEAR}</b>
              <br />
              <b>{process.env.REACT_APP_COMPANY}</b>
            </div>
          </div>
          <div className="page-break" />
        </div>
      ))}
    </div>
  );
}
