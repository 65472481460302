import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './features/authSlice.js';
import MenuReducer from './features/menuSlice.js';
import PerusahaanReducer from './features/perusahaanSlice.js';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    menu: MenuReducer,
    perusahaan: PerusahaanReducer
  }
});
