import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
// Import files
import * as api from '../../redux/api';
import { satpamSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';

export default function AdminPecalangCreate() {
  const [loading, setLoading] = useState(false);
  const [satpamRoles, setSatpamRoles] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(satpamSchema)
  });

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const [fotoSatpamPreview] = useImagePreview(watch('satpamfoto'));

  useEffect(() => {
    const getSatpamRoles = async () => {
      await api.satpamRoles().then((res) => {
        setSatpamRoles(res.data.data);
      });
    };
    getSatpamRoles();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    if (data.satpamfoto.length > 0) {
      const compressedFile = await imageCompression(data.satpamfoto[0], compressOptions);
      formData.append('satpamfoto', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    await api
      .createSatpam(formData)
      .then((res) => {
        Swal.fire('Good job!', res.data.message, 'success');
        setLoading(false);
        reset();
        reset({ satpamfoto: '', satpamjeniskelamin: '' });
      })
      .catch((error) => {
        setLoading(false);
        let errMessage = [];
        if (error?.response?.data?.errors) {
          for (const key in error.response.data.errors) {
            errMessage.push(error.response.data.errors[key].toString());
          }
        }
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errMessage.length > 0 ? errMessage.join(', ') : 'Something went wrong!'
        });
      });
  };

  return (
    <div className="relative w-full h-full px-4 mx-auto -mt-8 md:px-10 bg-gray-50">
      <div className="w-full">
        <div className="flex items-center justify-center w-full py-5 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-light shadow-black/20">
          Registrasi Personil
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full">
          <div className="flex-wrap gap-5 text-sm lg:flex md:flex-nowrap">
            <div className="flex flex-col w-full p-5 mb-5 rounded-lg lg:w-1/2 bg-light lg:mb-0 shadowAll">
              <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                <div className="md:w-1/3">
                  <label htmlFor="role" className="block mb-1 font-semibold md:mb-0">
                    Role
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select defaultValue="" className={`w-full text-sm cursor-pointer ${errors.role ? 'invalidForm' : 'inputForm'}`} {...register('role')}>
                    <option value="" disabled>
                      Pilih role personil
                    </option>
                    {satpamRoles?.map((role, i) => (
                      <option key={i} value={role.value} selected={role.name === watch('role')}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                  <p className="text-xs text-primary">{errors.role?.message}</p>
                </div>
              </div>
              {(watch('role') === 'PETUGAS' || watch('role') === 'BANKAMDA' || watch('role') === 'Bankamda') && (
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamjabatan" className="block mb-1 font-semibold md:mb-0">
                      Jabatan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select defaultValue="" className={`w-full text-sm cursor-pointer ${errors.satpamjabatan ? 'invalidForm' : 'inputForm'}`} {...register('satpamjabatan')}>
                      <option value="" disabled>
                        Pilih jabatan personil
                      </option>
                      <option value="Linmas">Linmas</option>
                      <option value="Satgas Pantai">Satgas Pantai</option>
                      <option value="Pecalang">Pecalang</option>
                      <option value="Jagabaya">Jagabaya</option>
                      <option value="Bhabinkamtibmas">Bhabinkamtibmas</option>
                    </select>
                    <p className="text-xs text-primary">{errors.satpamjabatan?.message}</p>
                  </div>
                </div>
              )}
              <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                <div className="md:w-1/3">
                  <label htmlFor="satpamnip" className="block mb-1 font-semibold md:mb-0">
                    NIP
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    id="satpamnip"
                    placeholder="Nomor NIP personil"
                    className={`w-full text-sm ${errors.satpamnip ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamnip')}
                  />
                  <p className="text-xs text-primary">{errors.satpamnip?.message}</p>
                </div>
              </div>
              <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                <div className="md:w-1/3">
                  <label htmlFor="satpamnamalengkap" className="block mb-1 font-semibold md:mb-0">
                    Nama Lengkap
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    placeholder="Nama lengkap personil"
                    id="satpamnamalengkap"
                    className={`w-full text-sm ${errors.satpamnamalengkap ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamnamalengkap')}
                  />
                  <p className="text-xs text-primary">{errors.satpamnamalengkap?.message}</p>
                </div>
              </div>
              <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                <div className="md:w-1/3">
                  <label htmlFor="satpamnohp" className="block mb-1 font-semibold md:mb-0">
                    Nomor HP
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    placeholder="Contoh: 082376525674"
                    className={`w-full text-sm ${errors.satpamnohp ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamnohp')}
                  />
                  <p className="text-xs text-primary">{errors.satpamnohp?.message}</p>
                </div>
              </div>
              <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                <div className="md:w-1/3">
                  <label htmlFor="satpamemail" className="block mb-1 font-semibold md:mb-0">
                    Email
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="email"
                    placeholder="Contoh: personil@gmail.com"
                    className={`w-full text-sm ${errors.satpamemail ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamemail')}
                  />
                  <p className="text-xs text-primary">{errors.satpamemail?.message}</p>
                </div>
              </div>
              <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                <div className="md:w-1/3">
                  <label htmlFor="satpamjeniskelamin" className="block mb-1 font-semibold md:mb-0">
                    Jenis Kelamin
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select id="satpamjeniskelamin" className={`w-full text-sm ${errors.satpamjeniskelamin ? 'invalidForm' : 'inputForm'}`} {...register('satpamjeniskelamin')}>
                    <option value="" disabled>
                      Pilih jenis kelamin
                    </option>
                    <option value="Laki-laki">Laki-laki</option>
                    <option value="Perempuan">Perempuan</option>
                  </select>
                  <p className="text-xs text-primary">{errors.satpamjeniskelamin?.message}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full p-5 rounded-lg lg:w-1/2 bg-light shadowAll ">
              <div className="flex-col gap-3 px-2 md:flex justify-arround md:px-5">
                <div>
                  <label htmlFor="satpamfoto" className="block mb-1 font-semibold md:mb-0">
                    Foto Profil
                  </label>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <label className="flex flex-col items-center justify-center w-full h-56 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fotoSatpamPreview ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fotoSatpamPreview} className="object-contain w-full h-48" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamfoto')} accept="image/*" />
                        </label>
                      </div>
                      <p className="text-xs text-primary">{errors.satpamfoto?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full px-10 py-5 my-8 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-light bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>
            <button type="button" onClick={() => navigate(-1)} className="px-5 py-2 text-sm rounded-md text-light bg-dark hover:opacity-80">
              Kembali
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
