import { Timeline } from '@mantine/core';
import moment from 'moment';

export default function TimelineSatpamProses({ data }) {
  return (
    <Timeline color="blue" active={data.length} bulletSize={24} lineWidth={8}>
      {data === '' ? (
        <Timeline.Item title="Belum ada proses" />
      ) : (
        data.map((d) => (
          <Timeline.Item
            title={moment(d?.satpamprosestanggal).format('DD MMM YYYY HH:mm')}
            key={d.satpamprosesid}>
            <div className="mt-2 flex flex-wrap gap-2 text-sm items-center">
              <button className="btn btn-outline border-secondary border-2 btn-xs rounded px-2">
                {d.satpamprosesjabatan}
              </button>
              <span>-</span>
              <span className="font-semibold">{d.satpamprosesnamalengkap}</span>
            </div>
            <div className="mt-2">
              <p className="text-sm">{d.satpamprosesketerangan}</p>
            </div>
          </Timeline.Item>
        ))
      )}
    </Timeline>
  );
}
