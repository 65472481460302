import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Link } from 'react-router-dom';
import { Modal } from '@mantine/core';
import { Popover } from '@mantine/core';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import Swal from 'sweetalert2';
import ModalPOSDetail from '../../components/pos-checkpoint/ModalPOSDetail';
import { distanceFormat } from '../../utils/distanceFormat';
import { useDebouncedValue } from '@mantine/hooks';

export default function POSData() {
  const [datas, setDatas] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [posDetailKode, setPOSDetailKode] = useState('');

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.patroliGetPOS().then((res) => {
      setData(res.data.data);
      setDatas(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(
        ({
          satpamposkode,
          satpamposstatus,
          satpamposnama,
          satpamposketerangan,
          satpamposradius,
          satpamposlatitude,
          satpamposlongitudde
        }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamposkode} ${satpamposstatus} ${satpamposnama} ${satpamposketerangan} ${satpamposradius} ${satpamposlatitude} ${satpamposlongitudde}`
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
    );
  }, [data, debouncedQuery]);

  const handleOpenModalDetail = (poskode) => {
    setPOSDetailKode(poskode);
    setOpenModalDetail(true);
  };

  const handleImageData = (params) => {
    params?.split('/data-pos/')[1] && setImageData(params);
    setShowImage(!showImage);
  };

  const handleDelete = (satpamposkode) => {
    Swal.fire({
      text: 'Do you want to delete this POS?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .posDelete(satpamposkode)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamposkode',
      width: '50',
      render: ({ satpamposkode }) => (
        <div className="flex items-center justify-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div className="popOverItem" onClick={() => handleOpenModalDetail(satpamposkode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/pos/update/${satpamposkode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Edit
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleDelete(satpamposkode)}>
                <i className="fa-solid fa-trash"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto POS',
      accessor: 'satpamposfoto',
      width: 150,
      render: ({ satpamposfoto }) => (
        <div
          className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
          onClick={() => handleImageData(satpamposfoto)}>
          <img
            src={satpamposfoto?.split('/data-pos/')[1] ? satpamposfoto : ''}
            alt=""
            className="h-full object-contain rounded-lg"
          />
        </div>
      )
    },
    {
      title: 'Kode, Status',
      accessor: 'satpamposstatus',
      width: 200,
      render: ({ satpamposkode, satpamposstatus }) => (
        <div>
          <div className="py-0 my-0">
            <div className="py-0 my-0">{satpamposkode} &nbsp;</div>
            {satpamposstatus === 'Aktif' ? (
              <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">
                Aktif
              </div>
            ) : (
              <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">
                Tidak Aktif
              </div>
            )}
          </div>
        </div>
      )
    },
    {
      title: 'Nama POS',
      accessor: 'satpamposnama',
      width: 200
    },
    {
      title: 'Keterangan',
      accessor: 'satpamposketerangan',
      width: 350
    },
    {
      title: 'Radius',
      tooltipaccessor: 'satpamposradius',
      accessor: 'satpamposradius',
      width: 100,
      render: ({ satpamposradius }) => (
        <div className="py-0 my-0">
          <div className="py-0 my-0">
            {satpamposradius !== null && distanceFormat(parseInt(satpamposradius))}
          </div>
        </div>
      )
    },
    {
      title: 'Latitude',
      accessor: 'satpamposlatitude',
      width: 150
    },
    {
      title: 'Longtitude',
      accessor: 'satpamposlongitudde',
      width: 150
    }
  ];

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen justify-center bg-gray-50 rounded-lg shadowAll pb-8">
        <div className="flex justify-end items-center">
          {/* <h4 className="text-md">Data POS</h4> */}
          <div className="my-3 px-5 flex">
            <div className="flex gap-4 w-full">
              <Link to="/pos/create">
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <AiOutlineFileAdd size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Table Component */}
        {datas.message === 'Data empty' ? (
          <div className="text-center">
            <div className="mt-10">Belum ada POS yang terdaftar.</div>
            <div className="mb-72">Silahkan daftarkan POS terelebih dahulu!</div>
          </div>
        ) : (
          <div className="p-5">
            <Table data={filterData} columnData={columnData} setQuery={setQuery} />
          </div>
        )}
      </div>
      {/* Modal Detail Kegiatan */}
      <ModalPOSDetail
        kode={posDetailKode}
        openModalDetail={openModalDetail}
        setOpenModalDetail={setOpenModalDetail}
      />

      {/* Modal Image */}
      <Modal
        centered
        size="xl"
        opened={showImage}
        onClose={() => setShowImage(false)}
        className="overflow-auto bg-transparent -mt-10">
        {/* Content image */}
        <div className="w-full h-96">
          {imageData && (
            <div className="w-full">
              {imageData && (
                <img
                  src={imageData}
                  alt=""
                  className="w-full h-96 object-contain rounded-t-lg flex items-center justify-center"
                />
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
