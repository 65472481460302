// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Link } from 'react-router-dom';
import { Tooltip, Popover, Progress } from '@mantine/core';
import Cards from '../../components/general/Cards';
import FilterDate from '../../components/general/FilterDate';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { HiOutlinePrinter } from 'react-icons/hi';
import Swal from 'sweetalert2';
import ModalPatroliDetail from '../../components/patroli/ModalPatroliDetail';
import moment from 'moment';
import FilterKategori from '../../components/general/FilterKategori';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';

export default function Patroli({ isAdmin }) {
  const [datas, setDatas] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [data, setData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [patroliDetailKode, setPatroliDetailKode] = useState('');

  const [filterData, setFilterData] = useState([]);

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Jenis Patroli (Jalan kaki / Berkendara)
  const [kategori, setKategori] = useState('');

  // Date
  const { perusahaanKode, startDateAdmin, endDateAdmin } = useSelector((state) => state.perusahaan);
  const [date, setDate] = useState({
    startDate: startDateAdmin || moment().format('YYYY-MM-DD'),
    endDate: endDateAdmin || moment().format('YYYY-MM-DD')
  });

  // Get data
  useEffect(() => {
    setDate({
      startDate: startDateAdmin,
      endDate: endDateAdmin
    });
  }, [startDateAdmin, endDateAdmin]);

  // Column Data Table
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialColumnData = [
    {
      title: 'Action',
      accessor: 'satpampatrolikode',
      width: 100,
      render: ({ satpampatrolikode, satpampatrolikemajuan, satpampatrolisatpamkode }) => (
        <div className="flex items-stretch justify-center">
          <div className="h-32">
            <Popover width={180} position="right" withArrow shadow="xl">
              <Popover.Target>
                <div className="my-1 btnAksi">Action</div>
              </Popover.Target>
              <Popover.Dropdown className="popOverDropdown">
                {/* <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(satpampatrolikode)}>
                  <i className="fa-solid fa-circle-info"></i> Detail
                </div> */}
                <Link to={`/patroli/detail/${satpampatrolikode}`}>
                  <div className="popOverItem">
                    <i className="fa-solid fa-circle-info"></i> Detail
                  </div>
                </Link>
                {parseInt(satpampatrolikemajuan) <= 99 && (
                  <div size="sm" className="popOverItem" onClick={() => handleSendNotification(satpampatrolisatpamkode)}>
                    <i className="fa fa-bell"></i> Push Notif
                  </div>
                )}
                <Link to={`/patroli-printperpat/${satpampatrolikode}`}>
                  <div className="popOverItem">
                    <i className="fa-solid fa-print"></i> Print
                  </div>
                </Link>
                <div className="popOverItem" onClick={() => handleDelete(satpampatrolikode)}>
                  <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                </div>
              </Popover.Dropdown>
            </Popover>
          </div>
        </div>
      )
    },
    {
      title: 'Progress',
      accessor: 'satpampatrolikemajuan',
      width: 150,
      render: ({ satpampatrolikemajuan }) => (
        <>
          {!isNaN(satpampatrolikemajuan) ? (
            <div className="mt-5 text-center">
              {parseInt(satpampatrolikemajuan)} %
              <Progress
                className="mb-2"
                color={parseInt(satpampatrolikemajuan) <= 30 ? 'yellow' : parseInt(satpampatrolikemajuan) <= 70 ? 'teal' : 'blue'}
                value={parseInt(satpampatrolikemajuan)}
                size="md"
                radius="md"
              />
            </div>
          ) : (
            <div className="flex items-center justify-center mx-auto">0 %</div>
          )}
        </>
      )
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'POS',
      accessor: 'satpamposnama',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Tanggal Mulai',
      accessor: 'satpampatrolimulaitanggal',
      width: 160,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpampatrolimulaitanggal }) => <>{satpampatrolimulaitanggal !== '' && moment(satpampatrolimulaitanggal).format('DD MMM YYYY HH:mm')}</>
    },
    {
      title: 'Tanggal Selesai',
      accessor: 'satpampatroliselesaitanggal',
      width: 160,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpampatroliselesaitanggal }) => <>{satpampatroliselesaitanggal !== '' && moment(satpampatroliselesaitanggal).format('DD MMM YYYY HH:mm')}</>
    }
  ];

  const [columnData, setColumnData] = useState(initialColumnData);

  // Get data
  useEffect(() => {
    const formData = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpampatrolijenis: kategori
    };

    if (perusahaanKode) {
      formData.satpamperusahaankode = perusahaanKode;
    }

    if (kategori !== '') {
      kategori === 'Patroli Jalan Kaki'
        ? setColumnData(initialColumnData)
        : setColumnData((prev) => [
            ...prev,
            {
              title: 'Jarak Patroli',
              accessor: 'satpampatrolijarak',
              resizable: false,
              wrapText: true,
              autoHeight: true,
              width: 100
            }
          ]);
      const getData = async (formData) => {
        await api.patroli(formData).then((res) => {
          setData(res.data.data);
          setDatas(res.data);
        });
      };

      getData(formData);
    }

    setShowDate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, kategori]);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(({ satpamnamalengkap, satpamposnama, satpampatrolimulaitanggal, satpampatroliselesaitanggal }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamnamalengkap} ${satpamposnama} ${satpampatrolimulaitanggal} ${satpampatroliselesaitanggal} `.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        })
      );
    }
  }, [data, debouncedQuery]);

  const handleOpenModalDetail = (patrolikode) => {
    setPatroliDetailKode(patrolikode);
    setOpenModalDetail(true);
  };

  const handleSendNotification = async (patrolikode) => {
    await api.patroliSendNotif(patrolikode).then((res) => {
      Swal.fire('Good job!', res.data.message, 'success');
    });
  };

  const handleDelete = (kode) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpampatrolijenis: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    Swal.fire({
      text: 'Do you want to delete this patroli?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .patroliDelete(kode)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              if (kategori !== '') {
                const getData = async (formDate) => {
                  await api.patroli(formDate).then((res) => {
                    setData(res.data.data);
                    setDatas(res.data);
                  });
                };

                getData(formDate);
              }
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  return (
    <div className={`relative w-full min-h-screen p-5 ${isAdmin ? 'mt-1' : '-mt-10'}`}>
      {/* Cards */}
      <Cards
        title={`Statistik ${kategori}`}
        desc={kategori === '' ? 'Silahkan pilih kategori terlebih dahulu' : `Berikut ini adalah statistik ${kategori.toLowerCase()}`}
        card1={{ title: 'Selesai', value: datas?.patroli_selesai || 0 }}
        card2={{ title: 'Belum Selesai', value: datas?.patroli_belum_selesai || 0 }}
        card3={{
          title: 'Total Patroli',
          value: datas?.total_patroli || 0
        }}
      />

      <div className="justify-center w-full mt-10 rounded-lg bg-gray-50 shadowAll">
        <div className="flex flex-wrap items-center lg:flex-nowrap justify-beetween">
          <div className="flex flex-col flex-wrap items-center justify-start w-full md:flex-row">
            {!isAdmin && <FilterDate date={date} setDate={setDate} showDate={showDate} setShowDate={setShowDate} />}
            <FilterKategori kategori={kategori} setKategori={setKategori} page="patroli" />
          </div>
          <div className="flex justify-end px-5 my-3">
            <div className="flex gap-4 w-fit">
              <Tooltip label="Tambah patroli">
                <Link to="/patroli/create">
                  <div className="p-2 rounded-md cursor-pointer bg-customGray hover:opacity-80">
                    <AiOutlineFileAdd size={24} />
                  </div>
                </Link>
              </Tooltip>
              <Link to={`/patroli-print`} state={{ data: data, date: date }}>
                <div className="flex items-center p-2 text-gray-700 rounded-md cursor-pointer bg-customGray hover:opacity-80">
                  <HiOutlinePrinter size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Table Component */}
        {data.length === 0 ? (
          <div className="p-5 text-center">
            <div className="mt-10">Tidak ada data patroli {kategori === 'jalan_kaki' ? 'jalan kaki' : 'berkendara'}.</div>
            <div className="mb-72">Silahkan memilih rentang tanggal dan kategori lainnya!</div>
          </div>
        ) : (
          <div className="p-5">
            <Table data={filterData} columnData={columnData} setQuery={setQuery} />
          </div>
        )}
      </div>

      {/* Modal Detail Patroli Jalan Kaki */}
      <ModalPatroliDetail kode={patroliDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />
    </div>
  );
}
