import Select from 'react-select';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// Import files
import * as api from '../../redux/api';
import { banjarSchema } from '../../utils/schemaValidation';

export default function BanjarCreate({ personilType }) {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const perusahaan_kode = profile.user.adminpetugasperusahaankode;

  const [loading, setLoading] = useState(false);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [satpamKode, setSatpamKode] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(banjarSchema)
  });

  useEffect(() => {
    const getData = async () => {
      await api.personilListByType('pecalang').then((res) => {
        res.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [...prev, { value: data.satpamkode, label: data.satpamnamalengkap }]);
        });
      });
    };
    getData();
  }, [personilType]);

  const onSubmit = async (data) => {
    // data.periode_start = moment(data.periode_start).format('YYYY-MM-DD');
    // data.periode_end = moment(data.periode_end).format('YYYY-MM-DD');

    setLoading(true);

    // const formData = new FormData();
    // formData.append('satpamkode', satpamKode);
    // formData.append('perusahaan_kode', perusahaan_kode);

    // for (const key in data) {
    //   if (key === 'field') {
    //     formData.append(key, data[key][1]);
    //   } else {
    //     formData.append(key, data[key]);
    //   }
    // }

    const payload = {
      name: data.nama_banjar
    };

    await api
      .createBanjar(payload)
      .then((res) => {
        Swal.fire('Good job!', res.data.message, 'success');
        setLoading(false);
        reset();
        setSatpamKode('');
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative w-full h-full px-4 mx-auto -mt-8 md:px-10">
      <div className="w-full">
        <div className="flex items-center justify-center w-full py-5 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-light shadow-black/20">
          Registrasi Banjar
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full">
          {/* General Form */}
          {/* Personil */}
          <div className="flex flex-col justify-around p-5 rounded-lg bg-gray-50 shadowAll">
            {/* <div className="items-center justify-around gap-3 px-2 mb-3 md:flex md:px-5">
              <div className="md:w-1/2">
                <label className="block mb-1 font-semibold md:mb-0">Personil</label>
              </div>
              <div className="md:w-1/2">
                <Select
                  options={satpamDataOptions}
                  className="w-full text-sm"
                  placeholder="Pilih personil"
                  isSearchable={true}
                  defaultValue={satpamKode}
                  value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                  name="satpamkode"
                  required
                  onChange={(e) => setSatpamKode(e.value)}
                />
              </div>
            </div> */}
            {/* Nama Banjar */}
            <div className="items-center justify-around gap-3 px-2 mb-3 md:flex md:px-5">
              <div className="md:w-1/2">
                <label htmlFor="nama_banjar" className="block mb-1 font-semibold md:mb-0">
                  Nama Banjar
                </label>
              </div>
              <div className="md:w-1/2">
                <input type="text" id="nama_banjar" className={`w-full text-sm ${errors.nama_banjar ? 'invalidForm' : 'inputForm'}`} {...register('nama_banjar')} />
                <p className="text-sm text-red-600">{errors.nama_banjar?.message}</p>
              </div>
            </div>
            {/* Alamat Tinggal Sekarang */}
            {/* <div className="items-center justify-around gap-3 px-2 mb-3 md:flex md:px-5">
              <div className="md:w-1/2">
                <label htmlFor="alamat_tinggal" className="block mb-1 font-semibold md:mb-0">
                  Alamat Banjar
                </label>
              </div>
              <div className="md:w-1/2">
                <input type="text" id="alamat_tinggal" className={`w-full text-sm ${errors.alamat_tinggal ? 'invalidForm' : 'inputForm'}`} {...register('alamat_tinggal')} />
                <p className="text-sm text-red-600">{errors.alamat_tinggal?.message}</p>
              </div>
            </div> */}
            {/* Periode Awal Tinggal */}
            {/* <div className="items-center justify-around gap-3 px-2 mb-3 md:flex md:px-5">
              <div className="md:w-1/2">
                <label htmlFor="periode_start" className="block mb-1 font-semibold md:mb-0">
                  Periode Awal Tinggal
                </label>
              </div>
              <div className="md:w-1/2">
                <input
                  id="periode_start"
                  type="date"
                  className={`w-full text-sm ${errors.nama_banjar ? 'invalidForm' : 'inputForm'}`}
                  {...register('periode_start')}
                />
                <p className="text-sm text-red-600">{errors.periodeawaltinggal?.message}</p>
              </div>
            </div> */}
            {/* Periode Akhir Tinggal */}
            {/* <div className="items-center justify-around gap-3 px-2 mb-3 md:flex md:px-5">
              <div className="md:w-1/2">
                <label htmlFor="periode_end" className="block mb-1 font-semibold md:mb-0">
                  Periode Akhir Tinggal
                </label>
              </div>
              <div className="md:w-1/2">
                <input
                  id="periode_end"
                  type="date"
                  className={`w-full text-sm ${errors.nama_banjar ? 'invalidForm' : 'inputForm'}`}
                  {...register('periode_end')}
                />
                <p className="text-sm text-red-600">{errors.periode_end?.message}</p>
              </div>
            </div> */}
          </div>
          {/* End of General Form */}

          {/* Button simpan dan close */}
          <div className="flex items-center justify-between w-full px-10 py-5 my-8 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-light bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>
            <Link to="/banjar">
              <button className="px-5 py-2 text-sm rounded-md text-light bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
