import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ModalPatroliImage from './ModalPatroliImage';

export default function TableRiwayatPatroli({ rutePatroli, ruteDetail }) {
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [orientation, setOrientation] = useState(null);

  const handleImageData = (imageData) => {
    setSelectedImage(imageData);
    console.log(imageData);
    setShowImage(true);
  };

  return (
    <>
      {rutePatroli?.length > 0 &&
        rutePatroli?.map((titik) => (
          <table key={titik.satpamtitiknama} className="border-gray-50 w-full">
            <thead className="font-bold">
              <tr className="bg-primary text-white ">
                <th colSpan="5" className="border border-gray-400 py-2">
                  <div className="flex justify-between px-2 uppercase">
                    <p>{titik.satpamtitiknama}</p>
                    <p>{titik.satpampatrolidetailtarget}X</p>
                  </div>
                </th>
              </tr>
              <tr className="bg-gray-300">
                <th className="border border-gray-400 py-2">
                  <p className="text-center">TANGGAL MASUK</p>
                </th>
                <th className="border border-gray-400 py-2">
                  <p className="text-center">TANGGAL KELUAR</p>
                </th>
                <th className="border border-gray-400 py-2">
                  <p className="text-center">LAMA PATROLI</p>
                </th>
                <th className="border border-gray-400 py-2 w-1/4">
                  <p className="text-center">FOTO</p>
                </th>
                <th className="border border-gray-400 py-2 w-1/4">
                  <p className="text-center">KETERANGAN</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {ruteDetail?.map((rute) =>
                rute
                  ?.filter((r) => r.satpampatrolidetailtitiksatpampatrolidetailkode === titik.satpampatrolidetailkode)
                  ?.map((titik) => (
                    <tr key={titik.satpampatrolidetailkode}>
                      <td className="border border-gray-400 py-2 px-1">
                        <p className="text-center">
                          {titik.satpampatrolidetailtitikmasuktanggal !== null ? moment(titik.satpampatrolidetailtitikmasuktanggal).format('DD MMM YYYY HH:mm') : '-'}
                        </p>
                      </td>
                      <td className="border border-gray-400 py-2 px-1">
                        <p className="text-center">
                          {titik.satpampatrolidetailtitikkeluartanggal !== null ? moment(titik.satpampatrolidetailtitikkeluartanggal).format('DD MMM YYYY HH:mm') : '-'}
                        </p>
                      </td>
                      <td className="border border-gray-400 py-2 px-1">
                        <p className="text-center">{titik.satpampatrolidetailtitikdurasirute}</p>
                      </td>
                      <td className="border border-gray-400 py-2 px-1 w-1/4">
                        <p className="text-center">
                          <div className="flex -m-1 md:-m-2">
                            {titik.satpampatrolidetailtitikfotosatu?.split('/data-patroli/')[1] && (
                              <div className="flex w-1/2">
                                <div className="w-full p-1 md:p-2">
                                  <div className="h-24 min-h-min max-w-sm bg-white rounded-md border border-gray-200 shadow-md">
                                    <img
                                      className="rounded w-full h-24 object-contain"
                                      src={titik.satpampatrolidetailtitikfotosatu}
                                      alt=""
                                      onClick={() => handleImageData(titik.satpampatrolidetailtitikfotosatu)}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {titik.satpampatrolidetailtitikfotodua?.split('/data-patroli/')[1] && (
                              <div className="flex w-1/2">
                                <div className="w-full p-1 md:p-2">
                                  <div
                                    className="h-24 min-h-min max-w-sm bg-white rounded-md border border-gray-200 shadow-md
                                   ">
                                    <img
                                      className="rounded w-full h-24 object-contain"
                                      src={titik.satpampatrolidetailtitikfotodua}
                                      alt=""
                                      onClick={() => handleImageData(titik.satpampatrolidetailtitikfotodua)}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </p>
                      </td>
                      <td className="border border-gray-400 py-2 px-1 w-1/4 text-left">
                        <p>{titik.satpampatrolidetailtitikketerangan}</p>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        ))}
      <ModalPatroliImage setOpenModal={setShowImage} modalOpen={showImage} imgData={selectedImage} />
    </>
  );
}
