import { useState } from 'react';
import moment from 'moment';
import React from 'react';
import Headerlaporan from '../general/HeaderLaporan';
import TableRiwayatPatroli from './TableRiwayatPatroli';
import { Modal } from '@mantine/core';

export default function TableCetakPatroliPerPat({ dataPatroli, ruteDetail, as }) {
  const [showImage, setShowImage] = useState(false);
  const [imageData, setImageData] = useState(null);

  const handleImageData = ({ satpaminformasigambar }) => {
    setImageData(satpaminformasigambar);
    setShowImage(true);
  };

  return (
    <div className="w-full">
      <div className="flex w-full">
        <Headerlaporan />
        <div className="flex flex-col items-end justify-center w-full px-5 text-white bg-black">
          <div className="text-[18px] font-bold">LAPORAN PATROLI</div>
          <div className="text-[14px]">{dataPatroli.satpamperusahaannama}</div>
        </div>
      </div>
      <div className="m-5">
        <table className="w-full border-gray-50" id="kegiatanHarianPrint">
          <thead className="bg-primary font-bold text-white text-[12px]">
            <tr>
              <th colSpan="3" className="py-2 border border-gray-300">
                <div className="flex justify-between px-2 uppercase">
                  <p className="font-semibold text-[18px]">#{dataPatroli?.satpampatrolikode}</p>
                  <p className="font-semibold text-[18px]">{dataPatroli?.satpampatrolijenis}</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-black">
            <tr>
              <td colSpan={2} className="w-1/4 !h-[8px] border border-gray-300 px-2 py-2 font-semibold">
                <div className="flex justify-between gap-2 uppercase">
                  <p>NAMA PERSONIL</p>
                  <p>{dataPatroli?.satpamnamalengkap}</p>
                </div>
              </td>
              <td rowSpan={dataPatroli?.satpampatrolijenis === 'Patroli Berkendara' ? 6 : 2} className="w-1/5 px-2 py-2 text-left border border-gray-300 ">
                <h4 className="mb-2 font-semibold uppercase">Rute Patroli</h4>
                <div className="w-full overflow-x-auto">
                  <table className="w-full leading-normal table-auto">
                    <thead>
                      <tr>
                        <th colSpan={3} className="px-2 py-1 text-sm font-semibold tracking-wider text-center text-white align-middle border border-gray-200 bg-primary">
                          {dataPatroli?.satpamposnama}
                        </th>
                      </tr>
                      <tr>
                        <th className="px-2 py-1 text-sm font-semibold tracking-wider text-center align-middle border border-gray-200">CHECKPOINT</th>
                        <th className="px-2 py-1 text-sm font-semibold tracking-wider text-center align-middle border border-gray-200">TARGET</th>
                        <th className="px-2 py-1 text-sm font-semibold tracking-wider text-center align-middle border border-gray-200">KEMAJUAN</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataPatroli?.Detail?.length > 0 &&
                        dataPatroli?.Detail?.map((item) => (
                          <tr key={item.satpampatrolidetailsatpamtitikkode}>
                            <td className="px-2 py-1 text-sm bg-white border border-gray-200">
                              <p className="text-center">{item.satpamtitiknama}</p>
                            </td>
                            <td className="px-2 py-1 text-sm bg-white border border-gray-200">
                              <p className="text-center">{item.satpampatrolidetailtarget}</p>
                            </td>
                            <td className="px-2 py-1 text-sm bg-white border border-gray-200">
                              <p className="text-center">{item.presentase.toFixed()}%</p>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td className="px-2 py-2 font-semibold border border-gray-300 whitespace-nowrap">
                <div className="flex items-center justify-between gap-2">
                  <p>WAKTU MULAI</p>
                  <p className="text-primary">{dataPatroli.satpampatroliawalwaktu !== null ? moment(dataPatroli.satpampatroliawalwaktu).format('DD MMM YYYY HH:mm') : '-'}</p>
                </div>
                <div className="flex items-center justify-between gap-2">
                  <p>WAKTU AKHIR</p>
                  <p className="text-primary">
                    {dataPatroli.satpampatroliakhirwaktu !== null ? moment(dataPatroli.satpampatroliakhirwaktu).format('DD MMM YYYY HH:mm') : '-'}
                  </p>
                </div>
                <div className="flex items-center justify-between gap-2">
                  <p>WAKTU PATROLI</p>
                  <p>{dataPatroli?.durasi_kumulatif}</p>
                </div>
              </td>
              <td className="px-2 py-2 font-semibold align-top border border-gray-300 " height="5px">
                <div className="flex justify-between gap-2">
                  <p>TARGET</p>
                  <p>{dataPatroli?.target_kumulatif}X</p>
                </div>
                <div className="flex justify-between gap-2">
                  <p>KEMAJUAN</p>
                  <p>{dataPatroli?.persentase_kumulatif?.toFixed()}%</p>
                </div>
              </td>
            </tr>
            {dataPatroli?.satpampatrolijenis === 'Patroli Berkendara' && (
              <>
                <tr>
                  <td colSpan={2} className="px-2 py-2 font-semibold border border-gray-300 " height="5px">
                    <div className="flex justify-between gap-2">
                      <p>JENIS KENDARAAN</p>
                      <p>{dataPatroli?.satpampatrolikendaraanjenis}</p>
                    </div>
                    <div className="flex justify-between gap-2">
                      <p>NAMA KENDARAAN</p>
                      <p>{dataPatroli?.satpampatrolikendaraannama}</p>
                    </div>
                    <div className="flex justify-between gap-2">
                      <p>NOMOR KENDARAAN</p>
                      <p>{dataPatroli?.satpampatrolikendaraannomor}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="px-2 py-2 font-semibold border border-gray-300 " height="5px">
                    <p className="text-center">KILOMETER AWAL</p>
                  </td>
                  <td className="px-2 py-2 font-semibold border border-gray-300 " height="5px">
                    <p className="text-center">KILOMETER AKHIR</p>
                  </td>
                </tr>
                <tr>
                  <td className="w-1/4 px-2 py-2 font-semibold border border-gray-300" height="5px">
                    <div className="flex items-center gap-2 px-4 justify-arround">
                      <div className="w-1/2">{dataPatroli?.satpampatrolikilometerawalangka}</div>
                      {dataPatroli?.satpampatrolikilometerawalfoto.split('/data-patroli/')[1] && (
                        <div className="flex w-1/2">
                          <div className="w-full">
                            <div className="w-full h-24 break-all bg-white border border-gray-200 rounded-md shadow-md">
                              {as !== 'detail' ? (
                                <img className="object-cover w-full h-24 rounded" src={dataPatroli?.satpampatrolikilometerawalfoto} alt="" />
                              ) : (
                                <button
                                  aria-label
                                  onClick={() => {
                                    handleImageData({ satpaminformasigambar: dataPatroli?.satpampatrolikilometerawalfoto });
                                  }}>
                                  <img className="object-cover w-full h-24 rounded" src={dataPatroli?.satpampatrolikilometerawalfoto} alt="" />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="w-1/4 px-2 py-2 font-semibold border border-gray-300" height="5px">
                    <div className="flex items-center gap-2 px-4 justify-arround">
                      <div className="w-1/2">{dataPatroli?.satpampatrolikilometerakhirangka}</div>
                      {dataPatroli?.satpampatrolikilometerakhirfoto.split('/data-patroli/')[1] && (
                        <div className="flex w-1/2">
                          <div className="w-full">
                            <div className="w-full h-24 break-all bg-white border border-gray-200 rounded-md shadow-md">
                              {as !== 'detail' ? (
                                <img className="object-cover w-full h-24 rounded" src={dataPatroli?.satpampatrolikilometerakhirfoto} alt="" />
                              ) : (
                                <button
                                  aria-label
                                  onClick={() => {
                                    handleImageData({ satpaminformasigambar: dataPatroli?.satpampatrolikilometerakhirfoto });
                                  }}>
                                  <img className="object-cover w-full h-24 rounded" src={dataPatroli?.satpampatrolikilometerakhirfoto} alt="" />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="px-2 py-2 font-semibold border border-gray-300 " height="5px">
                    <div className="flex justify-between gap-2">
                      <p>JARAK (KM AWAL - KM AKHIR)</p>
                      <p>{dataPatroli?.satpampatrolikilometerakhirangka - dataPatroli?.satpampatrolikilometerawalangka} KM</p>
                    </div>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td colSpan={3} className="min-w-full px-2 py-2 border border-gray-300" height="5px">
                <h4 className="my-2 font-semibold text-center">RIWAYAT PATROLI</h4>
                {/* <TimelinePatroli data={data ? data : ''} /> */}
                <TableRiwayatPatroli rutePatroli={dataPatroli?.Detail} ruteDetail={ruteDetail} as={as} />
                <div className="page-break" />
              </td>
            </tr>
            {as !== 'detail' && (
              <tr>
                <td colSpan="2" className="px-2 py-2 text-center align-top border border-gray-300"></td>
                <td className="px-2 py-2 text-center align-middle border border-gray-300">
                  <div>Tanda Tangan</div>
                  <div className="mx-5 mt-16 mb-5 border" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {as !== 'detail' && (
        <div className="w-full my-2 text-center text-black">
          <b>Copyright&copy;{process.env.REACT_APP_YEAR}</b>
          <br />
          <b>{process.env.REACT_APP_COMPANY}</b>
        </div>
      )}

      <Modal centered size="xl" opened={showImage} onClose={() => setShowImage(false)} className="-mt-10 overflow-auto bg-transparent">
        {/* Content image */}
        {imageData && <img src={imageData} alt="" className="flex items-center justify-center object-contain w-full rounded-t-lg" />}
      </Modal>
    </div>
  );
}
