import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Collapse, Switch } from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// Import files
import * as api from '../../redux/api';
import { temuanUpdateSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';

export default function TemuanUpdate({ personilType }) {
  const [defaultValues, setDefaultValues] = useState({});
  const [kategoriKode, setKategoriKode] = useState('');
  const [kategoriOptions, setKategoriOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [satpamKode, setSatpamKode] = useState('');
  const [showInputAnalisis, setShowInputAnalisis] = useState(false);
  const [tanggalAktifitas, setTanggalAktifitas] = useState(new Date());
  const [tanggalMelaporkan, setTanggalMelaporkan] = useState(new Date());

  const { kode } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(temuanUpdateSchema),
    defaultValues: defaultValues
  });

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getTemuanByKode = async () => {
      await api.temuanByKode({ satpamtemuankode: kode }).then((res) => {
        if (res.data.data.length > 0) {
          const defaultValue = {
            satpamtemuanuraian: res.data.data[0].satpamtemuanuraian,
            satpamtemuantindakan: res.data.data[0].satpamtemuantindakan,
            satpamtemuanhasil: res.data.data[0].satpamtemuanhasil,
            satpamtemuanpotensi: res.data.data[0].satpamtemuanpotensi !== 'null' ? res.data.data[0].satpamtemuanpotensi : '',
            satpamtemuananalisis: res.data.data[0].satpamtemuananalisis !== 'null' ? res.data.data[0].satpamtemuananalisis : '',
            satpamtemuankesimpulan: res.data.data[0].satpamtemuankesimpulan !== 'null' ? res.data.data[0].satpamtemuankesimpulan : '',
            satpamtemuanrekomendasi: res.data.data[0].satpamtemuanrekomendasi !== 'null' ? res.data.data[0].satpamtemuanrekomendasi : '',
            satpamtemuanalamat: res.data.data[0].satpamtemuanalamat,
            satpamtemuanstatus: res.data.data[0].satpamtemuanstatus,
            satpamkegiatlatitude: res.data.data[0].satpamkegiatlatitude,
            satpamkegiatlongitude: res.data.data[0].satpamkegiatlongitude,
            satpamtemuanalamatpatokan: res.data.data[0].satpamtemuanalamatpatokan,
            satpamtemuanketeranganfotosatu:
              res.data.data[0].satpamtemuanketeranganfotosatu !== 'null' && res.data.data[0].satpamtemuanketeranganfotosatu !== '-'
                ? res.data.data[0].satpamtemuanketeranganfotosatu
                : '',
            satpamtemuanketeranganfotodua:
              res.data.data[0].satpamtemuanketeranganfotodua !== 'null' && res.data.data[0].satpamtemuanketeranganfotodua !== '-'
                ? res.data.data[0].satpamtemuanketeranganfotodua
                : '',
            satpamtemuanketeranganfototiga:
              res.data.data[0].satpamtemuanketeranganfototiga !== 'null' && res.data.data[0].satpamtemuanketeranganfototiga !== '-'
                ? res.data.data[0].satpamtemuanketeranganfototiga
                : '',
            satpamtemuanketeranganfotoempat:
              res.data.data[0].satpamtemuanketeranganfotoempat !== 'null' && res.data.data[0].satpamtemuanketeranganfotoempat !== '-'
                ? res.data.data[0].satpamtemuanketeranganfotoempat
                : '',
            satpamtemuanketeranganfotolima:
              res.data.data[0].satpamtemuanketeranganfotolima !== 'null' && res.data.data[0].satpamtemuanketeranganfotolima !== '-'
                ? res.data.data[0].satpamtemuanketeranganfotolima
                : '',
            satpamtemuanfotosatu:
              res.data.data[0].satpamtemuanfotosatu.split('/data-temuan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data[0].satpamtemuanfotosatu,
            satpamtemuanfotodua:
              res.data.data[0].satpamtemuanfotodua.split('/data-temuan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data[0].satpamtemuanfotodua,
            satpamtemuanfototiga:
              res.data.data[0].satpamtemuanfototiga.split('/data-temuan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data[0].satpamtemuanfototiga,
            satpamtemuanfotoempat:
              res.data.data[0].satpamtemuanfotoempat.split('/data-temuan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data[0].satpamtemuanfotoempat,
            satpamtemuanfotolima:
              res.data.data[0].satpamtemuanfotolima.split('/data-temuan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data[0].satpamtemuanfotolima
          };
          setDefaultValues(defaultValue);
          reset(defaultValue);
          setSatpamKode(res.data.data[0].satpamtemuansatpamkode);
          setKategoriKode(res.data.data[0].satpamtemuankategorikode);
          setTanggalMelaporkan(new Date(res.data.data[0].satpamtemuantanggalmelaporkan));
          setTanggalAktifitas(new Date(res.data.data[0].satpamtemuantanggalaktifitas));
        } else {
          setDefaultValues({});
        }
      });
    };
    getTemuanByKode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kode]);

  const [fileDataURL] = useImagePreview(watch('satpamtemuanfotosatu'));
  const [fileDataURL2] = useImagePreview(watch('satpamtemuanfotodua'));
  const [fileDataURL3] = useImagePreview(watch('satpamtemuanfototiga'));
  const [fileDataURL4] = useImagePreview(watch('satpamtemuanfotoempat'));
  const [fileDataURL5] = useImagePreview(watch('satpamtemuanfotolima'));

  useEffect(() => {
    const getData = async () => {
      await api.personilListByType('pecalang').then((res) => {
        res.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [...prev, { value: data.satpamkode, label: data.satpamnamalengkap }]);
        });
      });
    };
    const getDataKategori = async () => {
      await api.temuanKategori().then((res) => {
        res.data.data.map((data) => {
          return setKategoriOptions((prev) => [
            ...prev,
            {
              value: data.satpamtemuankategorikode,
              label: data.satpamtemuankategorinama
            }
          ]);
        });
      });
    };
    getData();
    getDataKategori();
  }, [personilType]);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('satpamtemuankode', kode);
    formData.append('satpamtemuansatpamkode', satpamKode);
    formData.append('satpamtemuankategori', kategoriKode);
    formData.append('satpamtemuantanggalmelaporkan', moment(tanggalMelaporkan).format('YYYY-MM-DDTHH:mm'));
    formData.append('satpamtemuantanggalaktifitas', moment(tanggalAktifitas).format('YYYY-MM-DDTHH:mm'));

    if (data.satpamtemuanfotosatu.length > 0 && typeof data.satpamtemuanfotosatu !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfotosatu[0], compressOptions);
      formData.append('satpamtemuanfotosatu', compressedFile, compressedFile.name);
    }

    if (data.satpamtemuanfotodua.length > 0 && typeof data.satpamtemuanfotodua !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfotodua[0], compressOptions);
      formData.append('satpamtemuanfotodua', compressedFile, compressedFile.name);
    }

    if (data.satpamtemuanfototiga.length > 0 && typeof data.satpamtemuanfototiga !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfototiga[0], compressOptions);
      formData.append('satpamtemuanfototiga', compressedFile, compressedFile.name);
    }

    if (data.satpamtemuanfotoempat.length > 0 && typeof data.satpamtemuanfotoempat !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfotoempat[0], compressOptions);
      formData.append('satpamtemuanfotoempat', compressedFile, compressedFile.name);
    }

    if (data.satpamtemuanfotolima.length > 0 && typeof data.satpamtemuanfotolima !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfotolima[0], compressOptions);
      formData.append('satpamtemuanfotolima', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    await api
      .temuanUpdate(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        navigate('/temuan');
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 overflow-x-hidden md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="flex items-center justify-center w-full py-3 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-gray-50 shadow-black/40">
          Edit Laporan Temuan
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="flex flex-col w-full gap-5 mb-5">
          <div className="flex flex-wrap gap-5 text-sm lg:flex-nowrap">
            <div className="flex flex-col w-full gap-5">
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Personil</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={satpamDataOptions}
                      value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                      className="w-full"
                      placeholder="Pilih personil"
                      isSearchable={true}
                      name="satpamkode"
                      required
                      onChange={(e) => setSatpamKode(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Uraian temuan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.satpamtemuanuraian ? 'invalidForm' : 'inputForm'}`} rows={4} {...register('satpamtemuanuraian')} />
                    <p className="text-xs text-errorText">{errors.satpamtemuanuraian?.message}</p>
                  </div>
                </div>
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Uraian tindakan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.satpamtemuantindakan ? 'invalidForm' : 'inputForm'}`} rows={3} {...register('satpamtemuantindakan')} />
                    <p className="text-xs text-errorText">{errors.satpamtemuantindakan?.message}</p>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Hasil tindakan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.satpamtemuanhasil ? 'invalidForm' : 'inputForm'}`} rows={4} {...register('satpamtemuanhasil')} />
                    <p className="text-xs text-errorText">{errors.satpamtemuanhasil?.message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-5">
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Kategori</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={kategoriOptions}
                      className="w-full"
                      placeholder="Pilih kategori"
                      isSearchable={true}
                      value={kategoriOptions.filter((option) => option.value === kategoriKode)}
                      onChange={(e) => setKategoriKode(e.value)}
                      name="kategorikode"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_aktifitas" className="block mb-1 font-semibold md:mb-0">
                      Tanggal temuan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalAktifitas}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalAktifitas(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                      Tanggal melaporkan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalMelaporkan}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalMelaporkan(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Status temuan</label>
                  </div>
                  <div className="md:w-2/3">
                    <select className={`w-full flex flex-col font-normal ${errors.satpamtemuanstatus ? 'invalidForm' : 'inputForm'}`} {...register('satpamtemuanstatus')}>
                      <option disabled value="">
                        Pilih status temuan
                      </option>
                      <option value={0}>Open</option>
                      <option value={1}>Close</option>
                    </select>
                    <p className="text-xs text-errorText">{errors.satpamtemuanstatus?.message}</p>
                  </div>
                </div>
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Keterangan</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full text-sm ${errors.satpamprosesketerangan ? 'invalidForm' : 'inputForm'}`} {...register('satpamprosesketerangan')} />
                    <p className="text-xs text-errorText">{errors.satpamprosesketerangan?.message}</p>
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Patokan lokasi</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className="w-full inputForm" {...register('satpamtemuanalamatpatokan')} />
                  </div>
                </div>
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Alamat</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.satpamtemuanalamat ? 'invalidForm' : 'inputForm'}`} {...register('satpamtemuanalamat')} />
                    <p className="text-xs text-errorText">{errors.satpamtemuanalamat?.message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-5 rounded-lg bg-gray-50 shadowAll">
            <div className="mx-auto">
              <div className="flex flex-wrap -m-1 md:-m-2">
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamtemuanfotosatu')} accept="image/*" />
                        </label>
                        <p className="text-xs text-errorText">{errors.satpamtemuanfotosatu?.message}</p>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 1</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamtemuanketeranganfotosatu')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL2 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL2} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamtemuanfotodua')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 2</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamtemuanketeranganfotodua')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL3 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL3} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamtemuanfototiga')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 3</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamtemuanketeranganfototiga')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL4 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL4} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamtemuanfotoempat')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 4</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamtemuanketeranganfotoempat')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL5 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL5} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamtemuanfotolima')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 5</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamtemuanketeranganfotolima')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-5 rounded-lg bg-gray-50 shadowAll">
            <div className="flex flex-wrap items-center justify-between mb-4 md:flex-nowrap">
              <div className="font-semibold">Masukkan analisis temuan</div>
              <div className="-mt-3">
                <Switch className="cursor-pointer" color="blue" label="Tampilkan" labelPosition="left" onClick={() => setShowInputAnalisis((prev) => !prev)} />
              </div>
            </div>
            <Collapse in={showInputAnalisis}>
              <div className="flex flex-col gap-4 md:flex-row">
                <div className="w-full md:w-1/2">
                  <div className="mb-3 md:flex">
                    <div className="md:w-2/5">
                      <label className="block mb-1 font-semibold md:mb-0">Potensi temuan</label>
                    </div>
                    <div className="md:w-3/5">
                      <textarea className={`w-full ${errors.satpamtemuanpotensi ? 'invalidForm' : 'inputForm'}`} rows={2} {...register('satpamtemuanpotensi')} />
                      <p className="text-xs text-errorText">{errors.satpamtemuanpotensi?.message}</p>
                    </div>
                  </div>
                  <div className="mb-3 md:flex">
                    <div className="md:w-2/5">
                      <label className="block mb-1 font-semibold md:mb-0">Analisis temuan</label>
                    </div>
                    <div className="md:w-3/5">
                      <textarea className={`w-full ${errors.satpamtemuananalisis ? 'invalidForm' : 'inputForm'}`} rows={2} {...register('satpamtemuananalisis')} />
                      <p className="text-xs text-errorText">{errors.satpamtemuananalisis?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <div className="mb-3 md:flex">
                    <div className="md:w-2/5">
                      <label className="block mb-1 font-semibold md:mb-0">Kesimpulan temuan</label>
                    </div>
                    <div className="md:w-3/5">
                      <textarea className={`w-full ${errors.satpamtemuankesimpulan ? 'invalidForm' : 'inputForm'}`} rows={2} {...register('satpamtemuankesimpulan')} />
                      <p className="text-xs text-errorText">{errors.satpamtemuankesimpulan?.message}</p>
                    </div>
                  </div>
                  <div className="md:flex">
                    <div className="md:w-2/5">
                      <label className="block mb-1 font-semibold md:mb-0">Saran dan Rekomendasi</label>
                    </div>
                    <div className="md:w-3/5">
                      <textarea className={`w-full ${errors.satpamtemuanrekomendasi ? 'invalidForm' : 'inputForm'}`} rows={2} {...register('satpamtemuanrekomendasi')} />
                      <p className="text-xs text-errorText">{errors.satpamtemuanrekomendasi?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>

          <div className="flex items-center justify-between w-full px-10 py-5 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <Link to="/temuan">
              <button className="px-5 py-2 text-sm rounded-md btn text-gray-50 bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
