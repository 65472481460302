import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect } from 'react';
import decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Router from './Router';
import Navbar from './components/partials/Navbar';
import Sidebar from './components/partials/Sidebar';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import withClearCache from './ClearCache';
import { setLogout } from './redux/features/authSlice';
import { mainMenu } from './redux/features/menuSlice';
import Footer from './components/general/Footer';
import { onMessageListener, requestForToken } from './config/firebase';
import { ToastContainer, toast } from 'react-toastify';

const idLocale = require('moment/locale/id');
moment.locale('id', idLocale);

const ClearCacheComponent = withClearCache(MainApp);

export default function App() {
  const MsgNotification = ({ title, body }) => (
    <div>
      <p className="font-bold">{title}</p>
      <p>{body}</p>
    </div>
  );

  requestForToken();

  onMessageListener()
    .then((payload) => {
      toast.info(<MsgNotification title={payload.notification.title} body={payload.notification.body} />, {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    })
    .catch((err) => console.log('failed notification: ', err));

  return (
    <React.Fragment>
      <ClearCacheComponent />
      <ToastContainer />
    </React.Fragment>
  );
}

function MainApp() {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const token = profile?.access_token;
  const dispatch = useDispatch();
  const location = useLocation();

  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      dispatch(setLogout());
    }
  }

  useEffect(() => {
    if (token) {
      dispatch(mainMenu());
    }
  }, [dispatch, token]);

  const handleLogout = () => {
    Swal.fire({
      text: 'Apakah anda ingin keluar dari aplikasi?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLogout());
      }
    });
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {location.pathname !== '/auth/login' ? (
        <>
          <Sidebar profile={profile} handleLogout={handleLogout} />
          <div className="flex flex-col justify-between min-h-screen lg:relative lg:ml-64 bg-slate-100">
            <>
              <Navbar profile={profile} handleLogout={handleLogout} />
              <Router />
            </>
            <Footer />
          </div>
        </>
      ) : (
        <Router />
      )}
    </>
  );
}
