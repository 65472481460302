import React from 'react';

export default function PhotoLaporan({ page, showImage, setShowImage, setImageData, foto1, foto2, foto3, foto4, foto5, ket1, ket2, ket3, ket4, ket5 }) {
  const handleImageData = ({ foto1, ket1, foto2, ket2, foto3, ket3, foto4, ket4, foto5, ket5 }) => {
    setImageData([
      {
        image: foto1?.split(page)[1] && process.env.REACT_APP_API_URL_PHOTO + foto1,
        keterangan: ket1 && ket1 !== 'null' && ket1
      },
      {
        image: foto2?.split(page)[1] && process.env.REACT_APP_API_URL_PHOTO + foto2,
        keterangan: ket2 && ket2 !== 'null' && ket2
      },
      {
        image: foto3?.split(page)[1] && process.env.REACT_APP_API_URL_PHOTO + foto3,
        keterangan: ket3 && ket3 !== 'null' && ket3
      },
      {
        image: foto4?.split(page)[1] && process.env.REACT_APP_API_URL_PHOTO + foto4,
        keterangan: ket4 && ket4 !== 'null' && ket4
      },
      {
        image: foto5?.split(page)[1] && process.env.REACT_APP_API_URL_PHOTO + foto5,
        keterangan: ket5 && ket5 !== 'null' && ket5
      }
    ]);
    setShowImage(!showImage);
  };

  return (
    <div
      className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
      onClick={() =>
        handleImageData({
          foto1,
          ket1,
          foto2,
          ket2,
          foto3,
          ket3,
          foto4,
          ket4,
          foto5,
          ket5
        })
      }>
      <img
        src={
          foto1?.split(page)[1]
            ? process.env.REACT_APP_API_URL_PHOTO + foto1
            : foto2?.split(page)[1]
            ? process.env.REACT_APP_API_URL_PHOTO + foto2
            : foto3?.split(page)[1]
            ? process.env.REACT_APP_API_URL_PHOTO + foto3
            : foto4?.split(page)[1]
            ? process.env.REACT_APP_API_URL_PHOTO + foto4
            : foto5?.split(page)[1]
            ? process.env.REACT_APP_API_URL_PHOTO + foto5
            : ''
        }
        alt="laporan"
        className="h-full object-contain rounded-lg"
      />
    </div>
  );
}
