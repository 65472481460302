import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal, Collapse, Switch } from '@mantine/core';
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { keadaanSchema } from '../../utils/schemaValidation';
import Swal from 'sweetalert2';
// import Select from 'react-select';
// import { validateLatitude, validateLongitude } from '../../utils/latLongValidation';

export default function ModalKeadaanCreate({ getData, dataSatpam, openModalKeadaanCreate, setOpenModalKeadaanCreate }) {
  // const [jenis, setJenis] = useState('Semua');
  const [satpamOptions, setSatpamOptions] = useState([]);
  const [showLocation, setShowLocation] = useState(false);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [tanggalKeadaan, setTanggalKeadaan] = useState(new Date());
  const [satpamPengirim, setSatpamPengirim] = useState('');
  // const [satpamSelected, setSatpamSelected] = useState([]);
  // const [latitudeValidation, setLatitudeValidation] = useState({ error: { status: false } });
  // const [longitudeValidation, setLongitudeValidation] = useState({ error: { status: false } });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(keadaanSchema)
  });

  useEffect(() => {
    dataSatpam.length > 0 &&
      dataSatpam.map((satpam) =>
        setSatpamOptions((prev) => [
          ...prev,
          // {
          //   label: satpam.satpamnamalengkap,
          //   value: { satpamkode: satpam.satpamkode, satpamtoken: satpam.satpamtoken }
          // }
          {
            nama: satpam.satpamnamalengkap,
            satpamkode: satpam.satpamkode
          }
        ])
      );
    setTanggalKeadaan(new Date());
  }, [dataSatpam]);

  // useEffect(() => {
  //   if (jenis === 'Semua') {
  //     setSatpamSelected(satpamOptions);
  //   } else {
  //     setSatpamSelected([]);
  //   }
  // }, [jenis, satpamOptions]);

  // const handleValidateLatLong = (e) => {
  //   const { name, value } = e.target;
  //   if (name === 'satpamkeadaanlatitude') {
  //     setLatitudeValidation(validateLatitude(value));
  //   }
  //   if (name === 'satpamkeadaanlongitude') {
  //     setLongitudeValidation(validateLongitude(value));
  //   }
  // };

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    // data.satpamkodeselected = satpamSelected;
    data.satpamkode = satpamPengirim;
    data.satpamkeadaantanggal = moment(tanggalKeadaan).format('YYYY-MM-DDTHH:mm');
    // if (!latitudeValidation.error.status && !longitudeValidation.error.status) {
    await api
      .createKeadaan(data)
      .then((res) => {
        if (res.data) {
          Swal.fire('Good job!', 'You successfully submit the form!', 'success');
          setLoadingSubmit(false);
          setOpenModalKeadaanCreate(false);
          reset();
          // setJenis('');
          // setSatpamSelected([]);
          setSatpamPengirim('');
          setTanggalKeadaan(new Date());
          getData();
        }
      })
      .catch((error) => {
        setLoadingSubmit(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
    // } else {
    //   setLoadingSubmit(false);
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'Latitude dan/atau Longitude tidak valid'
    //   });
    // }
  };

  return (
    <Modal opened={openModalKeadaanCreate} size="lg" centered closeOnClickOutside onClose={() => setOpenModalKeadaanCreate(false)}>
      <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        <div className="text-center text-lg font-bold mb-1">Kirim Keadaan Darurat</div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col p-5">
          {/* <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="satpamkeadaantanggal" className="block font-semibold mb-1 md:mb-0">
                Kirim Ke
              </label>
            </div>
            <div className="md:w-2/3">
              <select className="w-full inputForm" value={jenis} onChange={(e) => setJenis(e.target.value)}>
                <option value="">Pilih Jenis</option>
                <option value="Semua">Semua Personil</option>
                <option value="Beberapa">Beberapa Personil</option>
              </select>
            </div>
          </div> */}
          {/* {jenis === 'Beberapa' && (
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Pilih Personil</label>
            </div>
            <div className="md:w-2/3">
              <Select
                options={satpamOptions}
                defaultValue={satpamOptions.filter((option) => option.value === satpamSelected.map((selected) => selected.value))}
                className="w-full"
                placeholder="Pilih personil"
                isSearchable
                isMulti
                onChange={(e) => setSatpamSelected(e)}
              />
            </div>
          </div>
          )} */}
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Pilih Personil</label>
            </div>
            <div className="md:w-2/3">
              <select defaultValue="" className="inputForm w-full" onChange={(e) => setSatpamPengirim(e.target.value)}>
                <option value="" disabled>
                  Pilih salah satu personil
                </option>
                {satpamOptions.map((satpam, i) => (
                  <option key={i} value={satpam.satpamkode}>
                    {satpam.nama}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="satpamkeadaantanggal" className="block font-semibold mb-1 md:mb-0">
                Tanggal
              </label>
            </div>
            <div className="md:w-2/3">
              <DatePicker
                selected={tanggalKeadaan}
                className={`w-full cursor-pointer inputForm`}
                onChange={(date) => setTanggalKeadaan(date)}
                locale={id}
                showTimeSelect
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                placeholderText="dd/MM/yyyy HH:mm"
                required
              />
            </div>
          </div>
          <div className="md:flex mb-2">
            <div className="md:w-1/3">
              <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                Pesan
              </label>
            </div>
            <div className="md:w-2/3">
              <textarea rows={3} className={`w-full ${errors?.satpamkeadaanpesan ? 'invalidForm' : 'inputForm'}`} {...register('satpamkeadaanpesan')} />
              <p className="text-errorText text-xs">{errors.satpamkeadaanpesan?.message}</p>
            </div>
          </div>
          <div className="md:flex mb-5">
            <div className="md:w-1/3">
              <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                Alamat
              </label>
            </div>
            <div className="md:w-2/3">
              <textarea rows={3} className={`w-full ${errors?.satpamkeadaanalamat ? 'invalidForm' : 'inputForm'}`} {...register('satpamkeadaanalamat')} />
              <p className="text-errorText text-xs">{errors.satpamkeadaanalamat?.message}</p>
            </div>
          </div>
          <div className="mb-5">
            <div className="mb-3">
              <Switch
                checked={showLocation}
                color="blue"
                label="Input latitude dan longitude (opsional)"
                labelPosition="left"
                onClick={() => setShowLocation((prev) => !prev)}
              />
            </div>
            <Collapse in={showLocation}>
              <div className="md:flex mb-2 items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamkeadaantanggal" className="block font-semibold mb-1 md:mb-0">
                    Latitude
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    className={`w-full ${errors?.satpamkeadaanlatitude ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamkeadaanlatitude')}
                    // onKeyUp={handleValidateLatLong}
                  />
                  <p className="text-errorText text-xs">{errors.satpamkeadaanlatitude?.message}</p>
                </div>
              </div>
              <div className="md:flex items-center mb-2">
                <div className="md:w-1/3">
                  <label className="block font-semibold mb-1 md:mb-0">Longitude</label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    className={`w-full ${errors.satpamkeadaanlongitude ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamkeadaanlongitude')}
                    // onKeyUp={handleValidateLatLong}
                  />
                  <p className="text-errorText text-xs">{errors.satpamkeadaanlongitude?.message}</p>
                  {/* <p className="text-errorText text-xs">{longitudeValidation?.error?.message}</p> */}
                </div>
              </div>
            </Collapse>
          </div>

          <div className="flex justify-center gap-5 items-center w-full">
            <button type="submit" className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80" disabled={loadingSubmit === true ? true : false}>
              <span className="flex items-center gap-2">
                <ClipLoader color="white" loading={loadingSubmit} size={16} speedMultiplier={0.7} /> Simpan
              </span>
            </button>

            <button type="button" className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80" onClick={() => setOpenModalKeadaanCreate(false)}>
              Kembali
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
