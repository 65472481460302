import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { BsStopwatchFill, BsEmojiHeartEyesFill, BsEmojiFrownFill, BsSave2Fill } from 'react-icons/bs';

export default function CardsInfo({ sudahBayarCount, belumBayarCount, prosesCount, totalCount, loading, nominalUnpaid, nominalPaid, nominalTotal, nominalProses }) {
  return (
    <div className="w-full min-h-min bg-primary rounded-lg shadow-black/30 shadow-lg flex flex-col md:flex-row justify-between items-center p-6 gap-5 mb-8">
      {/* Title and Description */}
      <div className="w-full lg:w-2/5 flex flex-col justify-center space-y-4 text-white">
        <p className="font-bold text-xl">Statistik Kontribusi </p>
        <p className="text-md">Berikut ini adalah jumlah kontribusi yang telah dilakukan</p>
      </div>
      <div className="w-[70%] grid grid-cols-1 lg:grid-cols-2 justify-end items-center gap-5">
        {/* {' card 1'} */}
        <div className="w-full  bg-light  justify-center items-center rounded-lg relative py-3 px-2">
          <div className="w-10 h-10 rounded-md bg-blue-400 absolute left-6 top-[-17.5px] text-center">
            <BsEmojiHeartEyesFill size={20} color="white" className="ml-[10px] mt-[10px]" />
          </div>
          {loading ? ( // Display ClipLoader when loading is true
            <div className="w-full  bg-light flex flex-col justify-center items-center rounded-lg relative py-3 px-2">
              <ClipLoader color="#000" loading={true} size={30} />
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center mt-3">
                <div className="text-[24px] lg:text-base">Sudah bayar</div>
                <div className="text-[32px] font-bold">{sudahBayarCount}</div>
              </div>
            </>
          )}{' '}
          <div className="flex flex-col ">
            <p className="text-[12px] my-1 mx-2">Nominal : </p>
            <p className="mx-2 rounded-lg bg-blue-300 p-1"> {nominalPaid}</p>
          </div>
        </div>
        {/* {' card 2'} */}
        <div className="w-full  bg-light  justify-center items-center rounded-lg relative py-3 px-2">
          <div className="w-10 h-10 rounded-md bg-blue-400 absolute left-6 top-[-17.5px] text-center">
            <BsEmojiFrownFill size={20} color="white" className="ml-[10px] mt-[10px]" />
          </div>
          {loading ? ( // Display ClipLoader when loading is true
            <div className="w-full  bg-light flex flex-col justify-center items-center rounded-lg relative py-3 px-2">
              <ClipLoader color="#000" loading={true} size={30} />
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center mt-3">
                <div className="text-[24px] lg:text-base">Belum bayar</div>
                <div className="text-[32px] font-bold">{belumBayarCount}</div>
              </div>
            </>
          )}
          <div className="flex flex-col ">
            <p className="text-[12px] my-1 mx-2">Nominal : </p>
            <p className="mx-2 rounded-lg bg-blue-300 p-1"> {nominalUnpaid}</p>
          </div>
        </div>
        {/* {' card 3'} */}
        <div className="w-full bg-light ustify-center items-center rounded-lg relative py-3 px-2">
          <div className="w-10 h-10 rounded-md bg-blue-400 absolute left-6 top-[-17.5px] text-center">
            <BsStopwatchFill size={20} color="white" className="ml-[10px] mt-[10px]" />
          </div>
          {loading ? ( // Display ClipLoader when loading is true
            <div className="w-full  bg-light flex flex-col justify-center items-center rounded-lg relative py-3 px-2">
              <ClipLoader color="#000" loading={true} size={30} />
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center mt-3">
                <div className="text-[24px] lg:text-base">Proses (belum di verifikasi)</div>
                <div className="text-[32px] font-bold">{prosesCount}</div>
              </div>
            </>
          )}{' '}
          <div className="flex flex-col ">
            <p className="text-[12px] my-1 mx-2">Nominal : </p>
            <p className="mx-2 rounded-lg bg-blue-300 p-1"> {nominalProses}</p>
          </div>
        </div>{' '}
        {/* {' card 3'} */}
        <div className="w-full bg-light l justify-center items-center rounded-lg relative py-3 px-2">
          <div className="w-10 h-10 rounded-md bg-blue-400 absolute left-6 top-[-17.5px] text-center">
            <BsSave2Fill size={20} color="white" className="ml-[10px] mt-[10px]" />
          </div>
          {loading ? ( // Display ClipLoader when loading is true
            <div className="w-full  bg-light flex flex-col justify-center items-center rounded-lg relative py-3 px-2">
              <ClipLoader color="#000" loading={true} size={30} />
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center mt-3">
                <div className="text-[24px] lg:text-base">Total Kontribusi</div>
                <div className="text-[32px] font-bold">{totalCount}</div>
              </div>
            </>
          )}{' '}
          <div className="flex flex-col ">
            <p className="text-[12px] my-1 mx-2">Nominal : </p>
            <p className="mx-2 rounded-lg bg-blue-300 p-1"> {nominalTotal}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
