import React, { useState, useEffect } from 'react';
import * as api from './redux/api';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/auth/Login';
import Unauthorized from './pages/Unauthorized';
import NotFoundPage from './pages/NotFoundPage';
import Dashboard from './pages/dashboard/Dashboard';
// import SatpamCreate from './pages/satpam/SatpamCreate';
import SatpamData from './pages/satpam/SatpamData';
// import SatpamUpdate from './pages/satpam/SatpamUpdate';
import SatpamCetak from './components/satpam/SatpamCetak';
import KegiatanHarian from './pages/kegiatan-harian/KegiatanHarian';
import KegiatanHarianCreate from './pages/kegiatan-harian/KegiatanHarianCreate';
import KegiatanHarianPrint from './pages/kegiatan-harian/KegiatanHarianPrint';
import KegiatanHarianUpdate from './pages/kegiatan-harian/KegiatanHarianUpdate';
import Kejadian from './pages/kejadian/Kejadian';
import KejadianCreate from './pages/kejadian/KejadianCreate';
import KejadianPrint from './pages/kejadian/KejadianPrint';
import KejadianUpdate from './pages/kejadian/KejadianUpdate';
import Temuan from './pages/temuan/Temuan';
import Kontrbusi from './pages/kontribusi/Kontribusi';
import KontribusiCreate from './pages/kontribusi/Kontribusi_Create';
import TemuanCreate from './pages/temuan/TemuanCreate';
import TemuanPrint from './pages/temuan/TemuanPrint';
import TemuanUpdate from './pages/temuan/TemuanUpdate';
import LaporanSemua from './pages/laporan-semua/LaporanSemua';
import Patroli from './pages/patroli/Patroli';
import PatroliPrintPerPat from './pages/patroli/PatroliPrintPerPat';
import PatroliCreate from './pages/patroli/PatroliCreate';
import PatroliPrint from './pages/patroli/PatroliPrint';
import POSCreate from './pages/pos-checkpoint/POSCreate';
import CheckpointCreate from './pages/pos-checkpoint/CheckpointCreate';
import POSData from './pages/pos-checkpoint/POSData';
import POSUpdate from './pages/pos-checkpoint/POSUpdate';
import CheckpointData from './pages/pos-checkpoint/CheckpointData';
import CheckpointUpdate from './pages/pos-checkpoint/CheckpointUpdate';
import Analisis from './pages/analisis/Analisis';
import PerusahaanData from './pages/perusahaan/PerusahaanData';
import PerusahaanCreate from './pages/perusahaan/PerusahaanCreate';
import PetugasData from './pages/petugas/PetugasData';
import PetugasCreate from './pages/petugas/PetugasCreate';
import PerusahaanUpdate from './pages/perusahaan/PerusahaanUpdate';
import PetugasUpdate from './pages/petugas/PetugasUpdate';
import InformasiData from './pages/informasi/InformasiData';
import InformasiCreate from './pages/informasi/InformasiCreate';
import InformasiUpdate from './pages/informasi/InformasiUpdate';
import NotifikasiData from './pages/notifikasi/NotifikasiData';
import InboxData from './pages/inbox/InboxData';
import InboxCreate from './pages/inbox/InboxCreate';
import InboxUpdate from './pages/inbox/InboxUpdate';
import KeadaanData from './pages/keadaan/KeadaanData';
import KeluhanData from './pages/keluhan/KeluhanData';
// Banjar
import Banjar from './pages/banjar/Banjar';
import BanjarCreate from './pages/banjar/BanjarCreate';
import BanjarUpdate from './pages/banjar/BanjarUpdate';
import Penduduk from './pages/penduduk/Penduduk';
import PendudukCreate from './pages/penduduk/PendudukCreate';
import PendudukUpdate from './pages/penduduk/PendudukUpdate';
// Lalu Lintas
import LaluLintas from './pages/lalu-lintas/LaluLintas';
import LaluLintasCreate from './pages/lalu-lintas/LaluLintasCreate';
import LaluLintasUpdate from './pages/lalu-lintas/LaluLintasUpdate';
import LaluLintasPrint from './pages/lalu-lintas/LaluLintasPrint';
// Personil
import AdminPecalang from './pages/personil/AdminPecalang';
import AdminPecalangCreate from './pages/personil/AdminPecalangCreate';
import AdminPecalangUpdate from './pages/personil/AdminPecalangUpdate';
import Warga from './pages/personil/Warga';
import WargaCreate from './pages/personil/WargaCreate';
// Respon Darurat
import TimRespon from './pages/respon-darurat/TimRespon';
import ListRespon from './pages/respon-darurat/ListResponDarurat';
import InformasiPromo from './pages/informasi-promo/InformasiPromo';
import CreateEditPromo from './pages/informasi-promo/CreateEditPromo';
import UsahaCreateUpdate from './pages/personil/UsahaCreateUpdate';
import KramaUsaha from './pages/personil/KramaUsaha';
import PatroliDetail from './pages/patroli/PatroliDetail';

const ROLES = {
  ADMIN_PROJECT: 'Admin Project',
  ADMIN_CLIENT: 'Admin Client'
};

export default function Router() {
  const menuData = JSON.parse(localStorage.getItem('menuData'));
  const profile = JSON.parse(localStorage.getItem('profile'));
  const token = profile?.access_token;

  const [satpamData, setSatpamData] = useState([]);
  const [wargaData, setWarga] = useState([]);
  const personilType = 'pecalang';

  useEffect(() => {
    const getData = async () => {
      const res = await api.personilListByType(personilType);
      const res1 = await api.personilListByType('ktt');
      setSatpamData(res.data.data);
      setWarga(res1.data.data);
    };
    if (token) {
      getData();
    }
  }, [personilType, token]);

  return (
    <>
      <Routes>
        <Route path="/auth/login" element={token ? <Navigate to="/" /> : <Login />} />
        {/* <Route path="/auth/register" element={<Register />} /> */}
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/*" element={<NotFoundPage />} />
        {/* Private Route */}
        {/* Role: Admin Project and Admin Client */}
        {/* Dashboard */}
        <Route element={<PrivateRoute allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]} authorized={true} />}>
          <Route path="/" element={<Dashboard satpamData={satpamData} />} />
        </Route>
        {/* Satpam Pages */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/personil') ? true : false))}
            />
          }>
          <Route path="/personil/data" element={<SatpamData personilType={personilType} />} />
          <Route path="/personil/registrasi" element={<AdminPecalangCreate />} />
          <Route path="/personil/registrasi/warga" element={<WargaCreate />} />
          <Route path="/personil/warga/update/:id" element={<WargaCreate />} />
          <Route path="/personil/update/:id" element={<AdminPecalangUpdate />} />
          <Route path="/personil/cetak-all" element={<SatpamCetak satpamData={satpamData} />} />
          <Route path="/personil/cetak-all-warga" element={<SatpamCetak satpamData={wargaData} />} />
        </Route>
        {/* Kegiatan Harian */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/kegiatan-harian') ? true : false))}
            />
          }>
          <Route path="/kegiatan-harian" element={<KegiatanHarian />} />
          <Route path="/kegiatan-harian/create" element={<KegiatanHarianCreate satpamData={satpamData} />} />
          <Route path="/kegiatan-harian/update/:kode" element={<KegiatanHarianUpdate />} />
          <Route
            path="/kegiatan-harian-print"
            element={
              <React.Suspense
                fallback={
                  <div className="flex items-center justify-center min-h-screen-75">
                    <ClipLoader color="black" loading={true} size={40} speedMultiplier={0.7} />
                  </div>
                }>
                <KegiatanHarianPrint />
              </React.Suspense>
            }
          />
          <Route path="/kegiatan-harian-printperkeg/:kode" element={<KegiatanHarianPrint />} />
        </Route>
        {/* Kejadian */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/kejadian') ? true : false))}
            />
          }>
          <Route path="/kejadian" element={<Kejadian />} />
          <Route path="/kejadian/create" element={<KejadianCreate personilType={personilType} />} />
          <Route
            path="/kejadian-print"
            element={
              <React.Suspense
                fallback={
                  <div className="flex items-center justify-center min-h-screen-75">
                    <ClipLoader color="black" loading={true} size={40} speedMultiplier={0.7} />
                  </div>
                }>
                <KejadianPrint />
              </React.Suspense>
            }
          />
          <Route path="/kejadian-printperkej/:kode" element={<KejadianPrint />} />
        </Route>
        {/* Kontribusi */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/kontribusi') ? true : false))}
            />
          }>
          <Route path="/kontribusi" element={<Kontrbusi />} />
          <Route path="/kontribusi/create" element={<KontribusiCreate />} />
        </Route>
        {/* Temuan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/temuan') ? true : false))}
            />
          }>
          <Route path="/temuan" element={<Temuan />} />
          <Route path="/temuan/create" element={<TemuanCreate personilType={personilType} />} />
          <Route path="/temuan-print" element={<TemuanPrint />} />
          <Route path="/temuan-printpertem/:kode" element={<TemuanPrint />} />
        </Route>
        {/* Patroli */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/patroli') ? true : false))}
            />
          }>
          <Route path="/patroli" element={<Patroli />} />
          <Route path="/patroli/create" element={<PatroliCreate personilType={personilType} />} />
          <Route path="/patroli/detail/:kode" element={<PatroliDetail />} />
          <Route path="/patroli-print" element={<PatroliPrint />} />
          <Route path="/patroli-printperpat/:kode" element={<PatroliPrintPerPat />} />
        </Route>
        {/* Filter Laporan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/laporan-semua') ? true : false))}
            />
          }>
          <Route path="/laporan-semua" element={<LaporanSemua />} />
        </Route>
        {/* Analisis */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/laporan-bulanan') ? true : false))}
            />
          }>
          <Route path="/laporan-bulanan" element={<Analisis />} />
        </Route>
        {/* POS */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/pos') ? true : false))}
            />
          }>
          <Route path="/pos" element={<POSData />} />
          <Route path="/pos/create" element={<POSCreate />} />
          <Route path="/pos/update/:kode" element={<POSUpdate />} />
        </Route>
        {/* Checkpoint */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/checkpoint') ? true : false))}
            />
          }>
          <Route path="/checkpoint" element={<CheckpointData />} />
          <Route path="/checkpoint/create" element={<CheckpointCreate />} />
          <Route path="/checkpoint/update/:kode" element={<CheckpointUpdate />} />
        </Route>
        {/* Perusahaan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/desa-adat') ? true : false))}
            />
          }>
          <Route path="/desa-adat" element={<PerusahaanData />} />
          <Route path="/desa-adat/create" element={<PerusahaanCreate />} />
          <Route path="/desa-adat/update/:kode" element={<PerusahaanUpdate />} />
        </Route>
        {/* Personil */}
        <Route path="/personil/admin" element={<AdminPecalang />} />
        <Route path="/personil/ktt" element={<Warga />} />
        <Route path="/personil/petugas" element={<AdminPecalang />} />
        {/* Petugas */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/petugas') ? true : false))}
            />
          }>
          <Route path="/petugas" element={<PetugasData />} />
          <Route path="/petugas/create" element={<PetugasCreate />} />
          <Route path="/petugas/update/:kode" element={<PetugasUpdate />} />
        </Route>
        {/* Informasi */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/informasi') ? true : false))}
            />
          }>
          <Route path="/informasi" element={<InformasiData />} />
          <Route path="/informasi/create" element={<InformasiCreate />} />
          <Route path="/informasi/update/:kode" element={<InformasiUpdate />} />
        </Route>
        {/* Notifikasi */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/notifikasi') ? true : false))}
            />
          }>
          <Route path="/notifikasi" element={<NotifikasiData personilType={personilType} />} />
        </Route>
        {/* Inbox */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/inbox') ? true : false))}
            />
          }>
          <Route path="/inbox" element={<InboxData />} />
          <Route path="/inbox/create" element={<InboxCreate personilType={personilType} />} />
          <Route path="/inbox/update/:kode" element={<InboxUpdate />} />
        </Route>
        {/* Keadaan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/keadaan-darurat') ? true : false))}
            />
          }>
          <Route path="/keadaan-darurat" element={<KeadaanData personilType={personilType} />} />
        </Route>
        {/* Keluhan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/keluhan') ? true : false))}
            />
          }>
          <Route path="/keluhan" element={<KeluhanData />} />
        </Route>
        {/* Role: Admin Project */}
        {/* Kejadian */}
        <Route
          element={<PrivateRoute allowedRoles={[ROLES.ADMIN_PROJECT]} authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/kejadian') ? true : false))} />}>
          <Route path="/kejadian/update/:kode" element={<KejadianUpdate personilType={personilType} />} />
        </Route>
        {/* Temuan */}
        <Route element={<PrivateRoute allowedRoles={[ROLES.ADMIN_PROJECT]} authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/temuan') ? true : false))} />}>
          <Route path="/temuan/update/:kode" element={<TemuanUpdate personilType={personilType} />} />
        </Route>

        {/* informasi promo */}
        <Route>
          <Route path="/promo" element={<InformasiPromo />} />
          <Route path="/promo/update/:id" element={<CreateEditPromo />} />
          <Route path="/promo/create" element={<CreateEditPromo />} />
        </Route>

        {/* Banjar */}
        <Route path="/banjar" element={<Banjar />} />
        <Route path="/banjar/registrasi" element={<BanjarCreate personilType={personilType} />} />
        <Route path="/banjar/update/:id" element={<BanjarUpdate personilType={personilType} />} />

        {/* Banjar Penduduk */}
        <Route path="/banjar-penduduk/detail/:id" element={<Penduduk />} />
        <Route path="/banjar-penduduk/registrasi/:id" element={<PendudukCreate />} />
        <Route path="/banjar-penduduk/update/:id" element={<PendudukUpdate />} />

        {/* KTU */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) => (menu.adminmenulink.includes('/personil/usaha') ? true : false))}
            />
          }>
          <Route path="/personil/usaha" element={<KramaUsaha />} />
          <Route path="/personil/usaha/update/:id" element={<UsahaCreateUpdate />} />
          <Route path="/personil/usaha/create" element={<UsahaCreateUpdate />} />
        </Route>
        {/* Lalu Lintas */}
        <Route path="/lalu-lintas" element={<LaluLintas />} />
        <Route path="/lalu-lintas/create" element={<LaluLintasCreate personilType={personilType} />} />
        <Route path="/lalu-lintas/update/:kode" element={<LaluLintasUpdate personilType={personilType} />} />
        <Route
          path="/lalu-lintas-print"
          element={
            <React.Suspense
              fallback={
                <div className="flex items-center justify-center min-h-screen-75">
                  <ClipLoader color="black" loading={true} size={40} speedMultiplier={0.7} />
                </div>
              }>
              <LaluLintasPrint />
            </React.Suspense>
          }
        />
        <Route path="/lalu-lintas-printperkeg/:kode" element={<LaluLintasPrint />} />
        {/* Respon Darurat */}
        <Route path="/tim-respon" element={<TimRespon />} />
        <Route path="/respon-darurat" element={<ListRespon />} />
      </Routes>
    </>
  );
}
