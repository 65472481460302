import moment from 'moment';
import { Modal } from '@mantine/core';
import { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import LoadingModal from '../general/LoadingModal';
import PhotoDetail from '../general/PhotoDetail';
import { parseToNewLine } from '../../utils/parseToNewLine';

const ModalKegiatanHarianDetail = ({ kode, openModalDetail, setOpenModalDetail }) => {
  const [dataKegiatan, setDataKegiatan] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getDataKegiatanByKode = async () => {
        await api.kegiatanHarianByKode({ satpamkegiatankode: kode }).then((res) => {
          setDataKegiatan(res.data.data);
        });
      };
      getDataKegiatanByKode();
    }
    return setDataKegiatan({});
  }, [kode]);

  return (
    <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Kegiatan Harian</div>
      {dataKegiatan?.satpamkegiatankode ? (
        <div>
          <div className="text-dark scrollbar-thin px-5">
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kode Kegiatan</div>
              <div className="w-full">{dataKegiatan?.satpamkegiatankode}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nama Perusahaan</div>
              <div className="w-full">{dataKegiatan?.satpamperusahaannama}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kode Perusahaan</div>
              <div className="w-full">{dataKegiatan?.satpamkegiatansatpamperusahaankode}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kode Kegiatan</div>
              <div className="w-full">{dataKegiatan?.satpamkegiatansatpamkode}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nama Lengkap</div>
              <div className="w-full">{dataKegiatan?.satpamnamalengkap}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Tanggal Melaporkan</div>
              <div className="w-full">{moment(dataKegiatan?.satpamkegiatantanggalmelaporkan).format('DD MMM YYYY HH:mm') || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Tanggal Aktifitas</div>
              <div className="w-full">{moment(dataKegiatan?.satpamkegiatantanggalaktifitas).format('DD MMM YYYY HH:mm') || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Uraian Kegiatan</div>
              <div className="w-full">{dataKegiatan?.satpamkegiatanuraian !== '' && parseToNewLine(dataKegiatan?.satpamkegiatanuraian)}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Keterangan Kegiatan</div>
              <div className="w-full">{dataKegiatan?.satpamkegiatanketerangan !== '' && parseToNewLine(dataKegiatan?.satpamkegiatanketerangan)}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Latitude</div>
              <div className="w-full">
                {dataKegiatan?.satpamkegiatanlatitude && dataKegiatan?.satpamkegiatanlatitude !== 'undefined' ? dataKegiatan?.satpamkegiatanlatitude : '-'}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Longitude</div>
              <div className="w-full">
                {dataKegiatan?.satpamkegiatanlongitude && dataKegiatan?.satpamkegiatanlongitude !== 'undefined' ? dataKegiatan?.satpamkegiatanlongitude : '-'}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Alamat Kegiatan</div>
              <div className="w-full">{dataKegiatan?.satpamkegiatanalamat}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Patokan</div>
              <div className="w-full">{dataKegiatan?.satpamkegiatanalamatpatokan}</div>
            </div>
            {/* Album Photo */}
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.satpamkegiatanfotosatu}
              keterangan={dataKegiatan?.satpamkegiatanketeranganfotosatu}
              data="/data-kegiatan/"
              no="1"
            />
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.satpamkegiatanfotodua}
              keterangan={dataKegiatan?.satpamkegiatanketeranganfotodua}
              data="/data-kegiatan/"
              no="2"
            />
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.satpamkegiatanfototiga}
              keterangan={dataKegiatan?.satpamkegiatanketeranganfototiga}
              data="/data-kegiatan/"
              no="3"
            />
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.satpamkegiatanfotoempat}
              keterangan={dataKegiatan?.satpamkegiatanketeranganfotoempat}
              data="/data-kegiatan/"
              no="4"
            />
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.satpamkegiatanfotolima}
              keterangan={dataKegiatan?.satpamkegiatanketeranganfotolima}
              data="/data-kegiatan/"
              no="5"
            />
            <div className="flex justify-end mt-5">
              <div className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer" onClick={() => setOpenModalDetail(false)}>
                Kembali
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingModal />
      )}
    </Modal>
  );
};

export default ModalKegiatanHarianDetail;
