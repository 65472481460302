import React from 'react';
import { ScaleLoader } from 'react-spinners';
import Table from './Table';

export default function ShowTable({ loading, datas, filterData, columnData, setQuery }) {
  return (
    <>
      {loading ? (
        <div className="flex flex-col gap-2 pt-10 items-center h-[400px] bg-white">
          <ScaleLoader color="#0077B6" loading={true} size={20} speedMultiplier={0.7} />
          <span>Loading data...</span>
        </div>
      ) : datas.message === 'Data empty' || datas?.data?.length === 0 ? (
        <div className="text-center">
          <div className="mt-10">Tidak ada data laporan pada tanggal tersebut.</div>
          <div className="mb-72">Silahkan memilih rentang tanggal lainnya!</div>
        </div>
      ) : (
        <div className="p-5">
          <Table data={filterData} columnData={columnData} setQuery={setQuery} id="satpamkegiatanid" />
        </div>
      )}
    </>
  );
}
