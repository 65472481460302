import imageCompression from 'browser-image-compression';
import moment from 'moment';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
// Import files
import * as api from '../../redux/api';
import { wargaSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';

export default function WargaCreate() {
  const [loading, setLoading] = useState(false);
  const [satpamKode, setSatpamKode] = useState('');
  const [banjarOptions, setBanjarOptions] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(wargaSchema)
  });

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const [fotoKartuIdPreview] = useImagePreview(watch('foto_kartu_id'));
  const [fotoPasporPreview] = useImagePreview(watch('foto_paspor'));
  const [fotoSelfiePreview] = useImagePreview(watch('foto_selfie'));

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        await api.satpamProfile(id).then((res) => {
          setSatpamKode(res.data.data.satpamkode);
          const defaultValue = {
            banjar_code: res.data.data.banjar_code ? res.data.data.banjar_code : '',
            alamat_ktp: res.data.data.alamat_ktp ? res.data.data.alamat_ktp : '',
            alamat_sekarang: res.data.data.alamat_sekarang ? res.data.data.alamat_sekarang : '',
            jenis_kartu_id: res.data.data.jenis_kartu_id ? res.data.data.jenis_kartu_id : '',
            jenis_kewarganegaraan: res.data.data.jenis_kewarganegaraan ? res.data.data.jenis_kewarganegaraan : '',
            krama: res.data.data.krama ? res.data.data.krama : '',
            nomor_kartu: res.data.data.nomor_kartu ? res.data.data.nomor_kartu : '',
            paspor: res.data.data.paspor ? res.data.data.paspor : '',
            pekerjaan: res.data.data.pekerjaan ? res.data.data.pekerjaan : '',
            satpamemail: res.data.data.satpamemail ? res.data.data.satpamemail : '',
            satpamjeniskelamin: res.data.data.satpamjeniskelamin ? res.data.data.satpamjeniskelamin : '',
            satpamnamalengkap: res.data.data.satpamnamalengkap ? res.data.data.satpamnamalengkap : '',
            satpamnohp: res.data.data.satpamnohp ? res.data.data.satpamnohp : '',
            tanggal_lahir: res.data.data.tanggal_lahir && res.data.data.tanggal_lahir,
            tempat_lahir: res.data.data.tempat_lahir ? res.data.data.tempat_lahir : '',
            krama_jenis: res.data.data.krama_jenis ? res.data.data.krama_jenis : '',
            date_expired_kartu_id: res.data.data.date_expired_kartu_id && moment(res.data.data.date_expired_kartu_id).format('YYYY-MM-DD'),
            date_expired_paspor: res.data.data.date_expired_paspor && moment(res.data.data.date_expired_paspor).format('YYYY-MM-DD'),
            foto_kartu_id:
              res.data.data.foto_kartu_id && res.data.data.foto_kartu_id.split('/data-penduduk/')[1]
                ? `${process.env.REACT_APP_API_URL_PHOTO}${res.data.data.foto_kartu_id}`
                : '',
            foto_selfie:
              res.data.data.foto_selfie && res.data.data.foto_selfie.split('/data-penduduk/')[1] ? `${process.env.REACT_APP_API_URL_PHOTO}${res.data.data.foto_selfie}` : '',
            foto_paspor:
              res.data.data.foto_paspor && res.data.data.foto_paspor.split('/data-penduduk/')[1] ? `${process.env.REACT_APP_API_URL_PHOTO}${res.data.data.foto_paspor}` : '',
            agama: res.data.data.agama,
            gol_dar: res.data.data.gol_dar,
            pendidikan: res.data.data.pendidikan,
            status_tinggal: res.data.data.status_tinggal
          };
          reset(defaultValue);
        });
      };
      getDataById();
    }

    const getBanjarList = async () => {
      await api.personilBanjarData().then((res) => {
        setBanjarOptions(res.data.data);
      });
    };
    getBanjarList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (watch('jenis_kewarganegaraan') === 'WNI') {
      setValue('jenis_kartu_id', 'KTP');
      setValue('krama', '');
    } else {
      //setValue('jenis_kartu_id', '');
      setValue('krama', 'T');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('jenis_kewarganegaraan')]);

  const onSubmit = async (data) => {
    setLoading(true);

    if (watch('jenis_kewarganegaraan') === 'WNA' || watch('krama') == 'T') {
      delete data.krama_jenis;
    }

    if (watch('jenis_kewarganegaraan') === 'WNI') {
      delete data.date_expired_kartu_id;
      delete data.date_expired_paspor;
      delete data.paspor;
      delete data.foto_paspor;
    }

    if (id) data.satpamkode = satpamKode;

    const formData = new FormData();

    // Compress foto
    if (data?.foto_kartu_id?.length > 0 && typeof data.foto_kartu_id !== 'string') {
      const compressedFile = await imageCompression(data.foto_kartu_id[0], compressOptions);
      formData.append('foto_kartu_id', compressedFile, compressedFile.name);
    }
    if (data?.foto_paspor?.length > 0 && typeof data.foto_paspor !== 'string') {
      const compressedFile = await imageCompression(data.foto_paspor[0], compressOptions);
      formData.append('foto_paspor', compressedFile, compressedFile.name);
    }
    if (data?.foto_selfie?.length > 0 && typeof data.foto_selfie !== 'string') {
      const compressedFile = await imageCompression(data.foto_selfie[0], compressOptions);
      formData.append('foto_selfie', compressedFile, compressedFile.name);
    }

    if (typeof data.foto_kartu_id === 'string') delete data.foto_kartu_id;
    if (typeof data.foto_paspor === 'string') delete data.foto_paspor;
    if (typeof data.foto_selfie === 'string') delete data.foto_selfie;

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    if (data.krama_jenis === '') {
      formData.delete('krama_jenis');
    }

    if (id) {
      await api
        .updateWarga(formData)
        .then((res) => {
          Swal.fire('Good job!', res.data.message, 'success');
          setLoading(false);
          navigate(-1);
        })
        .catch((error) => {
          setLoading(false);
          let errMessage = [];
          if (error?.response?.data?.errors) {
            for (const key in error.response.data.errors) {
              errMessage.push(error.response.data.errors[key].toString());
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errMessage.length > 0 ? errMessage.join(', ') : 'Something went wrong!'
          });
        });
    } else {
      await api
        .createWarga(formData)
        .then((res) => {
          Swal.fire('Good job!', res.data.message, 'success');
          setLoading(false);
          reset();
          reset({ foto_paspor: '', satpamjeniskelamin: '', foto_selfie: '', foto_kartu_id: '', satpamfoto: '' });
        })
        .catch((error) => {
          setLoading(false);
          let errMessage = [];
          if (error?.response?.data?.errors) {
            for (const key in error.response.data.errors) {
              errMessage.push(error.response.data.errors[key].toString());
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errMessage.length > 0 ? errMessage.join(', ') : 'Something went wrong!'
          });
        });
    }
  };

  return (
    <div className="relative w-full h-full px-4 mx-auto -mt-8 md:px-10 bg-gray-50">
      <div className="w-full">
        <div className="flex items-center justify-center w-full py-5 mt-5 mb-5 text-lg font-semibold text-center rounded-lg bg-light shadowAll">Registrasi Personil</div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full">
          <div className="flex-wrap gap-5 text-sm lg:flex md:flex-nowrap">
            <div className="flex-col gap-5 lg:w-1/2 lg:flex">
              <div className="flex flex-col w-full p-5 mb-5 rounded-lg bg-light shadowAll lg:mb-0">
                {/* Banjar */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="banjar_code" className="block mb-1 font-semibold md:mb-0">
                      Banjar
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select defaultValue="" className={`w-full text-sm cursor-pointer ${errors.banjar_code ? 'invalidForm' : 'inputForm'}`} {...register('banjar_code')}>
                      <option value="" disabled>
                        Pilih Banjar
                      </option>
                      {banjarOptions?.map((item, i) => (
                        <option key={i} value={item.code} selected={item.code === watch('banjar_code')}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <p className="text-xs text-primary">{errors.banjar_code?.message}</p>
                  </div>
                </div>
                {/* Kewarganegaraan */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="jenis_kewarganegaraan" className="block mb-1 font-semibold md:mb-0">
                      Kewarganegaraan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      id="jenis_kewarganegaraan"
                      defaultValue="WNI"
                      className={`w-full text-sm cursor-pointer ${errors.jenis_kewarganegaraan ? 'invalidForm' : 'inputForm'}`}
                      {...register('jenis_kewarganegaraan')}>
                      <option value="" disabled>
                        Pilih kewarganegaraan
                      </option>
                      <option value="WNI">WNI</option>
                      <option value="WNA">WNA</option>
                    </select>
                    <p className="text-xs text-red-700">{errors.jenis_kewarganegaraan?.message}</p>
                  </div>
                </div>
                {/* Krama */}
                {watch('jenis_kewarganegaraan') === 'WNI' && (
                  <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                    <div className="md:w-1/3">
                      <label htmlFor="krama" className="block mb-1 font-semibold md:mb-0">
                        Krama
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <select id="krama" defaultValue="" className={`w-full text-sm cursor-pointer ${errors.krama ? 'invalidForm' : 'inputForm'}`} {...register('krama')}>
                        <option value="" disabled>
                          Pilih krama
                        </option>
                        <option value="T">Tamiu</option>
                        <option value="KT">Krama Tamiu</option>
                        <option value="KA">Krama Adat</option>
                      </select>
                      <p className="text-xs text-red-700">{errors.krama?.message}</p>
                    </div>
                  </div>
                )}

                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="krama" className="block mb-1 font-semibold md:mb-0">
                      Status Tinggal
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      id="krama"
                      defaultValue=""
                      className={`w-full text-sm cursor-pointer ${errors.status_tinggal ? 'invalidForm' : 'inputForm'}`}
                      {...register('status_tinggal')}>
                      <option value="" disabled>
                        Pilih Status Tinggal
                      </option>
                      <option value="pribadi">Pribadi</option>
                      <option value="kontrak">Kontrak</option>
                      <option value="kost">Kost</option>
                      <option value="lainnya">Lainnya</option>
                    </select>
                    <p className="text-xs text-red-700">{errors.status_tinggal?.message}</p>
                  </div>
                </div>

                {watch('krama') === 'KT' && watch('jenis_kewarganegaraan') === 'WNI' && (
                  <>
                    {/* Jenis Krama */}
                    {/* <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                      <div className="md:w-1/3">
                        <label htmlFor="krama_jenis" className="block mb-1 font-semibold md:mb-0">
                          Jenis krama
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <select defaultValue="" className={`w-full text-sm cursor-pointer ${errors.krama_jenis ? 'invalidForm' : 'inputForm'}`} {...register('krama_jenis')}>
                          <option value="" disabled>
                            Pilih jenis krama
                          </option>
                          <option value="RAJEG">RAJEG</option>
                          <option value="PADUNUNGAN">PADUNUNGAN</option>
                          <option value="PADATAKALA">PADATAKALA</option>
                        </select>
                        <p className="text-xs text-red-700">{errors.krama_jenis?.message}</p>
                      </div>
                    </div> */}
                  </>
                )}
                {/* Nama Lengkap */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamnamalengkap" className="block mb-1 font-semibold md:mb-0">
                      Nama Lengkap
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      id="satpamnamalengkap"
                      type="text"
                      placeholder="Nama lengkap personil"
                      className={`w-full text-sm ${errors.satpamnamalengkap ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamnamalengkap')}
                    />
                    <p className="text-xs text-red-700">{errors.satpamnamalengkap?.message}</p>
                  </div>
                </div>
                {/* Agama */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="agama" className="block mb-1 font-semibold md:mb-0">
                      Agama
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select id="agama" defaultValue="" className={`w-full text-sm cursor-pointer ${errors.agama ? 'invalidForm' : 'inputForm'}`} {...register('agama')}>
                      <option value="" disabled>
                        Pilih Agama
                      </option>
                      <option value="Islam">Islam</option>
                      <option value="Protestan">Protestan</option>
                      <option value="Kristen">Kristen</option>
                      <option value="Hindu">Hindu</option>
                      <option value="Budha">Budha</option>
                      <option value="Khonghucu">Khonghucu</option>
                    </select>
                    <p className="text-xs text-red-700">{errors.agama?.message}</p>
                  </div>
                </div>
                {/* Golongan Darah */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="gol_dar" className="block mb-1 font-semibold md:mb-0">
                      Golongan Darah
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select id="gol_dar" defaultValue="" className={`w-full text-sm cursor-pointer ${errors.gol_dar ? 'invalidForm' : 'inputForm'}`} {...register('gol_dar')}>
                      <option value="" disabled>
                        Pilih Golongan Darah
                      </option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="O">O</option>
                      <option value="AB">AB</option>
                    </select>
                    <p className="text-xs text-red-700">{errors.gol_dar?.message}</p>
                  </div>
                </div>
                {/* Pendidikan */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="pendidikan" className="block mb-1 font-semibold md:mb-0">
                      Pendidikan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      id="pendidikan"
                      defaultValue=""
                      className={`w-full text-sm cursor-pointer ${errors.pendidikan ? 'invalidForm' : 'inputForm'}`}
                      {...register('pendidikan')}>
                      <option value="" disabled>
                        Pilih Pendidikan
                      </option>
                      <option value="SD/Sederajat">SD/Sederajat</option>
                      <option value="SMP/Sederajat">SMP/Sederajat</option>
                      <option value="SMA/Sederajat">SMA/Sederajat</option>
                      <option value="D3">D3</option>
                      <option value="S1">S1</option>
                      <option value="S2">S2</option>
                    </select>
                    <p className="text-xs text-red-700">{errors.pendidikan?.message}</p>
                  </div>
                </div>
                {/* Nomor HP */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamnohp" className="block mb-1 font-semibold md:mb-0">
                      Nomor HP
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      placeholder="Contoh: 082376525674"
                      className={`w-full text-sm ${errors.satpamnohp ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamnohp')}
                    />
                    <p className="text-xs text-red-700">{errors.satpamnohp?.message}</p>
                  </div>
                </div>
                {/* Email */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamemail" className="block mb-1 font-semibold md:mb-0">
                      Email
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      id="satpamemail"
                      type="email"
                      placeholder="Contoh: personil@gmail.com"
                      className={`w-full text-sm ${errors.satpamemail ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamemail')}
                    />
                    <p className="text-xs text-red-700">{errors.satpamemail?.message}</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full p-5 mb-5 rounded-lg bg-light shadowAll lg:mb-0">
                {/* Nomor Id */}
                {watch('jenis_kewarganegaraan') === 'WNI' && (
                  <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                    <div className="md:w-1/3">
                      <label htmlFor="nomor_kartu" className="block mb-1 font-semibold md:mb-0">
                        Nomor {watch('jenis_kewarganegaraan') === 'WNI' ? 'KTP' : 'Id'}
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        id="nomor_kartu"
                        type="text"
                        placeholder="Contoh: 1405010114060008"
                        className={`w-full text-sm ${errors.nomor_kartu ? 'invalidForm' : 'inputForm'}`}
                        {...register('nomor_kartu')}
                      />
                      <p className="text-xs text-red-700">{errors.nomor_kartu?.message}</p>
                    </div>
                  </div>
                )}
                {/* Jenis Kelamin */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamjeniskelamin" className="block mb-1 font-semibold md:mb-0">
                      Jenis Kelamin
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      id="satpamjeniskelamin"
                      defaultValue="L"
                      className={`w-full text-sm ${errors.satpamjeniskelamin ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamjeniskelamin')}>
                      <option value="" disabled>
                        Pilih jenis kelamin
                      </option>
                      <option value="Laki-Laki">Laki-laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                    <p className="text-xs text-red-700">{errors.satpamjeniskelamin?.message}</p>
                  </div>
                </div>
                {/* Pekerjaan */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="pekerjaan" className="block mb-1 font-semibold md:mb-0">
                      Pekerjaan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      id="pekerjaan"
                      type="text"
                      placeholder="Pekerjaan berdasarkan kartu id"
                      className={`w-full text-sm ${errors.pekerjaan ? 'invalidForm' : 'inputForm'}`}
                      {...register('pekerjaan')}
                    />
                    <p className="text-xs text-red-700">{errors.pekerjaan?.message}</p>
                  </div>
                </div>
                {/* Tempat Lahir */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="tempat_lahir" className="block mb-1 font-semibold md:mb-0">
                      Tempat Lahir
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat lahir berdasarkan kartu id"
                      className={`w-full text-sm ${errors.tempat_lahir ? 'invalidForm' : 'inputForm'}`}
                      {...register('tempat_lahir')}
                    />
                    <p className="text-xs text-red-700">{errors.tempat_lahir?.message}</p>
                  </div>
                </div>
                {/* Tanggal Lahir */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_lahir" className="block mb-1 font-semibold md:mb-0">
                      Tanggal Lahir
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input id="tanggal_lahir" type="date" className={`w-full text-sm ${errors.tanggal_lahir ? 'invalidForm' : 'inputForm'}`} {...register('tanggal_lahir')} />
                    <p className="text-xs text-red-700">{errors.tanggal_lahir?.message}</p>
                  </div>
                </div>
                {/* Alamat Kartu Id */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="alamat_ktp" className="block mb-1 font-semibold md:mb-0">
                      Alamat {watch('jenis_kewarganegaraan') === 'WNI' ? 'KTP' : 'Kartu Id'}
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      id="alamat_ktp"
                      type="text"
                      placeholder="Alamat berdasarkan kartu id"
                      className={`w-full text-sm ${errors.alamat_ktp ? 'invalidForm' : 'inputForm'}`}
                      {...register('alamat_ktp')}
                    />
                    <p className="text-xs text-red-700">{errors.alamat_ktp?.message}</p>
                  </div>
                </div>
                {/* Alamat Sekarang */}
                <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                  <div className="md:w-1/3">
                    <label htmlFor="alamat_sekarang" className="block mb-1 font-semibold md:mb-0">
                      Alamat Saat Ini
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      id="alamat_sekarang"
                      type="text"
                      placeholder="Alamat tinggal saat ini"
                      className={`w-full text-sm ${errors.alamat_sekarang ? 'invalidForm' : 'inputForm'}`}
                      {...register('alamat_sekarang')}
                    />
                    <p className="text-xs text-red-700">{errors.alamat_sekarang?.message}</p>
                  </div>
                </div>
              </div>

              {watch('jenis_kewarganegaraan') !== 'WNI' && (
                <div className="flex flex-col w-full p-5 rounded-lg bg-light shadowAll">
                  {/* Kartu Id */}
                  <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                    <div className="md:w-1/3">
                      <label htmlFor="jenis_kartu_id" className="block mb-1 font-semibold md:mb-0">
                        {watch('jenis_kewarganegaraan') === 'WNI' ? 'KTP' : 'Kartu Id'}
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <select
                        id="jenis_kartu_id"
                        defaultValue=""
                        className={`w-full text-sm cursor-pointer ${errors.jenis_kartu_id ? 'invalidForm' : 'inputForm'}`}
                        {...register('jenis_kartu_id')}>
                        <option value="" disabled>
                          Pilih kartu id
                        </option>
                        {watch('jenis_kewarganegaraan') === 'WNI' && <option value="KTP">KTP</option>}
                        <option value="KITAS">KITAS</option>
                        <option value="VISA">VISA</option>
                      </select>
                      <p className="text-xs text-red-700">{errors.jenis_kartu_id?.message}</p>
                    </div>
                  </div>
                  {/* Nomor Id */}
                  <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                    <div className="md:w-1/3">
                      <label htmlFor="nomor_kartu" className="block mb-1 font-semibold md:mb-0">
                        Nomor {watch('jenis_kewarganegaraan') === 'WNI' ? 'KTP' : 'Id'}
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        id="nomor_kartu"
                        type="text"
                        placeholder="Contoh: 1405010114060008"
                        className={`w-full text-sm ${errors.nomor_kartu ? 'invalidForm' : 'inputForm'}`}
                        {...register('nomor_kartu')}
                      />
                      <p className="text-xs text-red-700">{errors.nomor_kartu?.message}</p>
                    </div>
                  </div>
                  {/* Tanggal Kadaluarsa Kartu Id */}
                  <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                    <div className="md:w-1/3">
                      <label htmlFor="date_expired_kartu_id" className="block mb-1 font-semibold md:mb-0">
                        Kadaluarsa Id
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input type="date" className={`w-full text-sm ${errors.date_expired_kartu_id ? 'invalidForm' : 'inputForm'}`} {...register('date_expired_kartu_id')} />
                      <p className="text-xs text-red-700">{errors.date_expired_kartu_id?.message}</p>
                    </div>
                  </div>
                  {/* Nomor Paspor */}
                  <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                    <div className="md:w-1/3">
                      <label htmlFor="paspor" className="block mb-1 font-semibold md:mb-0">
                        Nomor Paspor
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Contoh: 1405010114060008"
                        className={`w-full text-sm ${errors.paspor ? 'invalidForm' : 'inputForm'}`}
                        {...register('paspor')}
                      />
                      <p className="text-xs text-red-700">{errors.paspor?.message}</p>
                    </div>
                  </div>
                  {/* Tanggal Kadaluarsa Paspor */}
                  <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
                    <div className="md:w-1/3">
                      <label htmlFor="date_expired_paspor" className="block mb-1 font-semibold md:mb-0">
                        Kadaluarsa Paspor
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input type="date" className={`w-full text-sm ${errors.date_expired_paspor ? 'invalidForm' : 'inputForm'}`} {...register('date_expired_paspor')} />
                      <p className="text-xs text-red-700">{errors.date_expired_paspor?.message}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full p-5 mt-5 rounded-lg lg:w-1/2 bg-light shadowAll lg:mt-0">
              {/* Foto Selfie */}
              <div className="flex-col gap-2 px-2 md:flex justify-arround md:px-5">
                <div>
                  <label htmlFor="foto_selfie" className="block mb-1 font-semibold md:mb-0">
                    Foto Selfie
                  </label>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full">
                    <div className="h-auto p-3 bg-white rounded-md shadowAll">
                      <div className="flex flex-col w-full">
                        <label className="flex flex-col items-center justify-center w-full h-56 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fotoSelfiePreview ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fotoSelfiePreview} className="object-contain w-full h-48" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('foto_selfie')} accept="image/*" />
                        </label>
                      </div>
                      <p className="text-xs text-red-700">{errors.foto_selfie?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Foto Kartu Id */}
              <div className="flex-col gap-2 px-2 my-5 md:flex justify-arround md:px-5">
                <div>
                  <label htmlFor="foto_kartu_id" className="block mb-1 font-semibold md:mb-0">
                    Foto Kartu Id
                  </label>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full">
                    <div className="h-auto p-3 bg-white rounded-md shadowAll">
                      <div className="flex flex-col w-full">
                        <label className="flex flex-col items-center justify-center w-full h-56 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fotoKartuIdPreview ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fotoKartuIdPreview} className="object-contain w-full h-48" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('foto_kartu_id')} accept="image/*" />
                        </label>
                      </div>
                      <p className="text-xs text-red-700">{errors.foto_kartu_id?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Foto Paspor */}
              {watch('jenis_kewarganegaraan') === 'WNA' && (
                <div className="flex-col gap-2 px-2 md:flex justify-arround md:px-5">
                  <div>
                    <label htmlFor="foto_paspor" className="block mb-1 font-semibold md:mb-0">
                      Foto Paspor
                    </label>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <div className="w-full">
                      <div className="h-auto p-3 bg-white rounded-md shadowAll">
                        <div className="flex flex-col w-full">
                          <label className="flex flex-col items-center justify-center w-full h-56 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            {fotoPasporPreview ? (
                              <div className="flex flex-col items-center justify-center">
                                <img src={fotoPasporPreview} className="object-contain w-full h-48" alt="preview" />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                              </div>
                            )}
                            <input type="file" className="opacity-0" {...register('foto_paspor')} accept="image/*" />
                          </label>
                        </div>
                        <p className="text-xs text-red-700">{errors.foto_paspor?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between w-full px-10 py-5 my-8 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-light bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>
            <button type="button" onClick={() => navigate(-1)} className="px-5 py-2 text-sm rounded-md text-light bg-dark hover:opacity-80">
              Kembali
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
