export function validateLatitude(value) {
  // const regexLat = new RegExp(
  //   '^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?))$'
  // );

  if (value !== '') {
    if (value > 0) {
      return { error: { status: true, message: 'Latitude harus bernilai negatif' } };
    } else if (!value.includes('.') || isNaN(value)) {
      return { error: { status: true, message: 'Latitude tidak valid' } };
    } else if (value.includes('.')) {
      let newValue = value.split('.');
      if (newValue[1].length < 6 || newValue[1].length > 6) {
        return {
          error: { status: true, message: 'Panjang latitude harus 6 angka di belakang titik' }
        };
      } else {
        return { error: { status: false } };
      }
    } else {
      return { error: { status: false } };
    }
  } else {
    return { error: { status: false } };
  }
}

export function validateLongitude(value) {
  // const regexLong = new RegExp(
  //   '^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,6})?))$'
  // );

  if (value !== '') {
    if (value < 0) {
      return { error: { status: true, message: 'Longitude harus bernilai positif' } };
    } else if (!value.includes('.') || isNaN(value)) {
      return { error: { status: true, message: 'Longitude tidak valid' } };
    } else if (value.includes('.')) {
      let newValue = value.split('.');
      if (newValue[1].length < 6 || newValue[1].length > 6) {
        return {
          error: { status: true, message: 'Panjang longitude harus 6 angka di belakang titik' }
        };
      } else {
        return { error: { status: false } };
      }
    } else {
      return { error: { status: false } };
    }
  } else {
    return { error: { status: false } };
  }
}
