import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useImagePreview from '../../hooks/useImagePreview';
import { petugasSchema } from '../../utils/schemaValidation';
import imageCompression from 'browser-image-compression';
import Select from 'react-select';

export default function PetugasCreate() {
  const [loading, setLoading] = useState(false);
  const [perusahaanKode, setPerusahaanKode] = useState('');
  const [hakAksesMenusId, setHakAksesMenusId] = useState([]);
  const [allMenuData, setAllMenuData] = useState([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState([{ value: '', label: 'Pilih perusahaan' }]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(petugasSchema)
  });

  const [fileDataURL] = useImagePreview(watch('foto'));

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getDataPerusahaan = async () => {
      await api
        .getAllPerusahaan()
        .then((res) => {
          res.data.data.map((data) => {
            return setPerusahaanOptions((prev) => [...prev, { value: data.satpamperusahaankode, label: data.satpamperusahaannama }]);
          });
        })
        .catch((err) => console.error(err.message));
    };
    const getAllMenu = async () => {
      await api.allMenu().then((res) => {
        setAllMenuData(res.data.data);
      });
    };
    getAllMenu();
    getDataPerusahaan();
  }, []);

  const handleHakAkses = (e) => {
    if (e.target.checked) {
      setHakAksesMenusId([...hakAksesMenusId, { adminmenuid: e.target.value }]);
    } else {
      setHakAksesMenusId(hakAksesMenusId.filter((menu) => menu.adminmenuid !== e.target.value));
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    data.password_confirmation = data.password;
    data.perusahaankode = perusahaanKode;
    // data.selected_hakakses = hakAksesMenusId;

    const formData = new FormData();
    formData.append('selected_hakakses', JSON.stringify(hakAksesMenusId));

    if (data.foto.length > 0) {
      const compressedFile = await imageCompression(data.foto[0], compressOptions);
      formData.append('foto', compressedFile, compressedFile.name);
      // data.foto = data.foto[0];
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }
    // console.log(data);

    if (hakAksesMenusId.length > 0) {
      await api
        .createPetugas(formData)
        .then((res) => {
          Swal.fire('Good job!', 'You successfully submit the form!', 'success');
          setLoading(false);
          setPerusahaanKode('');
          setHakAksesMenusId([]);
          reset();
          reset({ foto: '' });
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error.response.data.message);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Belum ada hak akses yang dipilih'
      });
    }
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-10 text-center text-lg font-semibold">Tambah Petugas</div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap md:flex-nowrap text-sm gap-5">
            <div className="md:w-1/2 w-full flex flex-col gap-5">
              <div className="bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Pilih Perusahaan</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      placeholder="Pilih perusahaan"
                      options={perusahaanOptions}
                      className="w-full"
                      isSearchable={true}
                      defaultValue={perusahaanKode}
                      value={perusahaanOptions.filter((option) => option.value === perusahaanKode)}
                      name="perusahaanKode"
                      onChange={(e) => setPerusahaanKode(e.value)}
                      required
                    />
                  </div>
                </div>
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Nama Petugas</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.adminpetugasnamalengkap ? 'invalidForm' : 'inputForm'}`} {...register('adminpetugasnamalengkap')} />
                    <p className="text-errorText text-xs">{errors.adminpetugasnamalengkap?.message}</p>
                  </div>
                </div>
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Nomor Hp</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.nomor_telepon ? 'invalidForm' : 'inputForm'}`} {...register('nomor_telepon')} />
                    <p className="text-errorText text-xs">{errors.nomor_telepon?.message}</p>
                  </div>
                </div>
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Tipe</label>
                  </div>
                  <div className="md:w-2/3">
                    <select className={`w-full ${errors.adminpetugastipe ? 'invalidForm' : 'inputForm'}`} {...register('adminpetugastipe')}>
                      <option value="">Pilih tipe</option>
                      <option value="Admin Project">Admin Project</option>
                      <option value="Admin Client">Admin Client</option>
                    </select>
                    <p className="text-errorText text-xs">{errors.adminpetugastipe?.message}</p>
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Jabatan</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.jabatan ? 'invalidForm' : 'inputForm'}`} {...register('jabatan')} />

                    <p className="text-errorText text-xs">{errors.jabatan?.message}</p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Email Petugas</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="email" className={`w-full ${errors.adminpetugasusername ? 'invalidForm' : 'inputForm'}`} {...register('adminpetugasusername')} />
                    <p className="text-errorText text-xs">{errors.adminpetugasusername?.message}</p>
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Password</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="password" className={`w-full ${errors.password ? 'invalidForm' : 'inputForm'}`} {...register('password')} />
                    <p className="text-errorText text-xs">{errors.password?.message}</p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="foto" className="block font-semibold mb-1 md:mb-0">
                      Foto Profil
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="w-full">
                      <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                        <div className="flex flex-col w-full">
                          <label className="p-2 flex flex-col w-full h-28 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            {fileDataURL ? (
                              <div className="flex flex-col items-center justify-center">
                                <img src={fileDataURL} className="w-full h-24 object-contain" alt="preview" />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                              </div>
                            )}
                            <input type="file" className="opacity-0" {...register('foto')} accept="image/*" />
                          </label>
                        </div>
                        <p className="text-errorText text-xs">{errors.foto?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full flex flex-col gap-5 ">
              <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                <div className="md:flex flex-col">
                  <label className="block font-semibold mb-2">Hak Akses</label>
                  {allMenuData
                    ?.filter((item) => item?.adminmenugrup === item.adminmenugrup && item?.adminmenuclass === 'Main')
                    ?.map((mainmenu) => (
                      <div key={mainmenu.adminmenuid} className="flex flex-col w-full text-sm">
                        <div className="bg-customGray rounded p-1.5 flex gap-2 pr-2">
                          <input type="checkbox" id={mainmenu.adminmenuid} value={mainmenu.adminmenuid} onChange={handleHakAkses} />
                          <label htmlFor={mainmenu.adminmenuid}>{mainmenu.adminmenugrup}</label>
                        </div>
                        <div className="flex flex-wrap p-1.5">
                          {allMenuData
                            ?.filter((item) => item?.adminmenugrup === mainmenu.adminmenugrup && item?.adminmenuclass === 'Child')
                            ?.map((childmenu) => (
                              <div key={childmenu.adminmenuid} className="flex gap-2 pr-2">
                                <input type="checkbox" id={childmenu.adminmenuid} value={childmenu.adminmenuid} onChange={handleHakAkses} />
                                <label htmlFor={childmenu.adminmenuid}>{childmenu.adminmenuutama}</label>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-5 mb-5 px-10 shadowAll rounded-lg">
            <button type="submit" className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <Link to="/petugas">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
