/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { Loader } from '@mantine/core';

export default function ModalKeluhanDetail({ kode, selectedData, openModalDetail, setOpenModalDetail, fetchData }) {
  const [dataKeluhan, setDataKeluhan] = useState({});
  const [data, setData] = useState([]);
  const [selectedPetugas, setSelectedPetugas] = useState('');
  const [reassign, setReassign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);

  const getDataPetugas = async () => {
    await api.personilListByType('pecalang').then((response) => setData(response.data.data));
  };
  const getKeluhanByKode = async () => {
    setLoading(true);
    await api
      .keluhanDetail(kode)
      .then((res) => {
        setDataKeluhan(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(setLoading(false));
  };

  useEffect(() => {
    if (kode && selectedData) {
      getKeluhanByKode();
    }

    getDataPetugas();
  }, [kode, selectedData]);

  const handleReassign = async () => {
    try {
      setLoadingAssign(true);
      const payload = {
        satpamkode: selectedPetugas,
        keluhanid: selectedData
      };

      const { status } = await api.assignKeluhan(payload);

      if (status === 200) {
        Swal.fire('Good job!', 'Berhasil re-assign keluhan ke petugas', 'success').then((result) => {
          if (result.isConfirmed || result.isDenied || result.isDismissed) {
            getKeluhanByKode();
            setReassign(false);
            setLoadingAssign(false);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message
      });
    }
  };

  const handleCloseStatus = async () => {
    try {
      const payload = {
        satpamkeluhankode: kode,
        satpamkeluhanstatus: 'Close'
      };

      const { status } = await api.updateStatusKeluhan(payload);

      if (status === 200) {
        Swal.fire('Berhasil', 'Case Keluhan berhasil di tutup', 'success').then((result) => {
          if (result.isConfirmed || result.isDenied || result.isDismissed) {
            setOpenModalDetail(false);
            fetchData();
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message
      });
    }
  };

  const optionsData =
    data &&
    data?.map((value) => {
      return {
        label: value.satpamnamalengkap,
        value: value.satpamkode
      };
    });
  const handleColseModal = () => {
    setOpenModalDetail(false);
    setReassign(false);
  };

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => {
        handleColseModal();
      }}>
      {loading ? (
        <div>
          <Loader color="red" />;
        </div>
      ) : (
        <>
          <div className="mb-5 text-lg font-bold text-center">Detail Keluhan</div>
          <div>
            <div className="px-5 text-dark scrollbar-thin">
              <div className="flex flex-col w-full gap-2 mb-2 md:flex-row">
                <div className="w-full font-bold md:w-3/5">Tanggal</div>
                <div className="w-full">{dataKeluhan?.satpamkeluhantanggal ? moment(dataKeluhan?.satpamkeluhantanggal).format('DD MMMM YYYY') : ''}</div>
              </div>

              <div className="flex flex-col w-full gap-2 mb-2 md:flex-row">
                <div className="w-full font-bold md:w-3/5">Isi</div>
                <div className="w-full">{dataKeluhan?.satpamkeluhanisi || '-'}</div>
              </div>

              <div className="flex flex-col w-full gap-2 mb-2 md:flex-row">
                <div className="w-full font-bold md:w-3/5">Alamat</div>
                <div className="w-full">{dataKeluhan?.satpamkeluhanalamat || '-'}</div>
              </div>

              <div className="flex flex-col w-full gap-2 mb-2 md:flex-row">
                <div className="w-full font-bold md:w-3/5">Status</div>
                <div className="w-full">
                  {dataKeluhan?.satpamkeluhanstatus === '' ? (
                    <div className="px-4 text-sm text-center border-2 rounded border-primary text-primary btnStatus">Belum Di Assign</div>
                  ) : dataKeluhan?.satpamkeluhanstatus === 'Progress' ? (
                    <div className="px-4 text-sm text-center border-2 rounded border-secondary text-secondary btnStatus">On Progress</div>
                  ) : (
                    <div className="px-4 text-sm text-center border-2 rounded border-secondary text-secondary btnStatus">{dataKeluhan?.satpamkeluhanstatus}</div>
                  )}
                </div>
              </div>

              <div className="flex flex-col w-full gap-2 mb-2 md:flex-row">
                <div className="w-full font-bold md:w-3/5">Gambar</div>
                <div className="w-full">
                  <img
                    src={dataKeluhan?.satpamkeluhangambar ?? 'https://placehold.co/600x400?text=Cannot+Show\nImage'}
                    alt="gambar keluhan"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = 'https://placehold.co/600x400?text=Cannot+Show\nImage';
                    }}
                  />
                </div>
              </div>

              {dataKeluhan?.satpamkeluhanstatus === 'Progress' && (
                <>
                  {reassign && (
                    <div className="mt-5">
                      <p>Pilih Petugas</p>
                      <Select
                        options={optionsData}
                        placeholder="Pilih Petugas"
                        onChange={(event) => {
                          setSelectedPetugas(event.value);
                        }}
                      />
                      <button className="w-full h-10 p-2 mt-3 text-white bg-black rounded-md" onClick={() => handleReassign()} disabled={loadingAssign}>
                        {loadingAssign ? 'loading' : 'Tugaskan'}
                      </button>
                    </div>
                  )}
                </>
              )}

              <hr className="my-5" />

              {dataKeluhan?.satpamkeluhanstatus !== 'Open' && (
                <>
                  <h1 className="mt-5 mb-5 text-lg font-bold text-center">Response Keluhan</h1>
                  <div className="flex flex-col w-full gap-2 mb-2 md:flex-row">
                    <div className="w-full font-bold md:w-3/5">Nama Personil</div>
                    <div className="w-full">{dataKeluhan?.satpamnamalengkap || '-'}</div>
                  </div>

                  <div className="flex flex-col w-full gap-2 mb-2 md:flex-row">
                    <p className="w-full font-bold md:w-3/5">Keluhan Response</p>
                    <p className="w-full">{dataKeluhan?.satpamkeluhanresponse ?? '-'}</p>
                  </div>

                  <div className="flex flex-col w-full gap-2 mb-2 md:flex-row">
                    <p className="w-full font-bold md:w-3/5">Lokasi</p>
                    <a
                      className="w-full text-blue-400 underline"
                      href={`https://maps.google.com/?q=${dataKeluhan?.satpamkeluhanlatitude},${dataKeluhan?.satpamkeluhanlongitude}`}
                      target="_blank"
                      rel="noreferrer">
                      Lokasi Map
                    </a>
                  </div>

                  <div className="flex flex-col w-full gap-2 mb-2 md:flex-row">
                    <div className="w-full font-bold md:w-3/5">Gambar</div>
                    <div className="w-full">
                      <img
                        src={dataKeluhan?.gambarresponse ?? 'https://placehold.co/600x400?text=Cannot+Show\nImage'}
                        alt="gambar keluhan"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = 'https://placehold.co/600x400?text=Cannot+Show\nImage';
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex justify-between gap-2">
                    {dataKeluhan?.satpamkeluhanstatus === 'Progress' && (
                      <button className="w-full h-10 p-2 mt-3 text-white bg-black rounded-md" onClick={() => (reassign ? setReassign(false) : setReassign(true))}>
                        {reassign ? 'Batalkan Re-Assign ' : 'Re-Assign Petugas'}
                      </button>
                    )}
                    {dataKeluhan?.satpamkeluhanstatus !== 'Close' && (
                      <button className="w-full h-10 p-2 mt-3 text-black bg-white border-black border-2 rounded-md" onClick={() => handleCloseStatus()}>
                        Tutup kasus keluhan
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}
