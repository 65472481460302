import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';

const ModalPendudukDetail = ({ id, openModalDetail, setOpenModalDetail }) => {
  const [dataSatpam, setDataSatpam] = useState({});

  useEffect(() => {
    if (id !== '') {
      const getDataSatpamById = async () => {
        await api.getPendudukById(id).then((res) => {
          setDataSatpam(res.data.data[0]);
        });
      };

      getDataSatpamById();
    }
  }, [id, openModalDetail]);

  return (
    <Modal opened={openModalDetail} withCloseButton size="auto" onClose={() => setOpenModalDetail(false)}>
      <div className="text-dark scrollbar-thin px-5">
        <div className="flex flex-col justify-center items-center">
          <h3 className="font-bold text-center mb-5">Detail Data Penduduk</h3>
          <div className="flex justify-center">
            {dataSatpam?.foto_selfie?.split('/data-penduduk/')[1] ? (
              <img src={process.env.REACT_APP_API_URL_PHOTO + dataSatpam.foto_selfie} alt="" className="w-[150px] h-[150px] object-cover mb-5 rounded-xl" />
            ) : (
              <img src={require('../../images/satpam.jpg')} alt="" className="w-[150px] h-[150px] object-cover mb-5 rounded-xl" />
            )}
          </div>
          <div className="flex gap-10 text-sm">
            <div>
              <p className="font-semibold">Nama lengkap</p>
              <p className="font-semibold">Jenis kependudukan</p>
              <p className="font-semibold">Kewarganegaraan</p>
              <p className="font-semibold">Jenis kartu Id</p>
              <p className="font-semibold">Nomor kartu Id</p>
              {dataSatpam?.jenis_kewarganegaraan === 'WNA' && (
                <>
                  <p className="font-semibold">Tgl expired kartu Id</p>
                  <p className="font-semibold">Nomor paspor</p>
                  <p className="font-semibold">Tgl expired paspor</p>
                </>
              )}
              <p className="font-semibold">Jenis kelamin</p>
              <p className="font-semibold">Pekerjaan</p>
              <p className="font-semibold">Nomor hp</p>
              <p className="font-semibold">Alamat</p>
              <p className="font-semibold">Awal tinggal</p>
              <p className="font-semibold">Akhir tinggal</p>
            </div>
            <div>
              <p>: {dataSatpam?.nama}</p>
              <p>: {dataSatpam?.tipe === 'KT' ? 'Krama Tamiu' : dataSatpam?.tipe === 'T' ? 'Tamiu' : 'Krama Adat'}</p>
              <p>: {dataSatpam?.jenis_kewarganegaraan}</p>
              <p>: {dataSatpam?.jenis_kartu_id}</p>
              <p>: {dataSatpam?.nomor_kartu}</p>
              {dataSatpam?.jenis_kewarganegaraan === 'WNA' && (
                <>
                  <p>: {moment(dataSatpam?.date_expired_kartu_id).format('LL')}</p>
                  <p>: {dataSatpam?.paspor}</p>
                  <p>: {moment(dataSatpam?.date_expired_paspor).format('LL')}</p>
                </>
              )}
              <p>: {dataSatpam?.jenis_kelamin === 'L' ? 'Laki-laki' : 'Perempuan'}</p>
              <p>: {dataSatpam?.pekerjaan}</p>
              <p>: {dataSatpam?.no_hp}</p>
              <p>: {dataSatpam?.alamat_ktp}</p>
              <p>: {moment(dataSatpam?.periode_start).format('LL')}</p>
              <p>: {dataSatpam?.periode_end === null ? 'Menetap' : moment(dataSatpam?.periode_end).format('LL')}</p>
            </div>
          </div>
          {/* Foto Kartu Identitas */}
          <div>
            {dataSatpam?.foto_kartu_id?.split('/data-penduduk/')[1] && (
              <>
                <p className="text-sm text-center font-semibold mt-5">Kartu Id</p>
                <img className="w-[275px] object-cover" src={process.env.REACT_APP_API_URL_PHOTO + dataSatpam?.foto_kartu_id} alt="" />
              </>
            )}
            {dataSatpam?.jenis_kewarganegaraan === 'WNA' && dataSatpam?.foto_paspor?.split('/data-penduduk/')[1] && (
              <>
                <p className="text-sm text-center font-semibold mt-5">Kartu Paspor</p>
                <img className="w-[275px] object-cover" src={process.env.REACT_APP_API_URL_PHOTO + dataSatpam?.foto_paspor} alt="" />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <div className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer" onClick={() => setOpenModalDetail(false)}>
            Kembali
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPendudukDetail;
