import React, { useEffect, useState } from 'react';
import { Popover } from '@mantine/core';
import { AiOutlineFileAdd } from 'react-icons/ai';
import Table from '../../components/general/Table';
import ModalCreateTimRespon from '../../components/respon-darurat/ModalCreateTimRespon';
import ModalEditTimRespon from '../../components/respon-darurat/ModalEditTimRespon';
import { GetListTim, DeleteTimRespon } from '../../redux/api';
import Swal from 'sweetalert2';
import { useDebouncedValue } from '@mantine/hooks';
import LoadingPage from '../../components/general/LoadingPage';

const TimRespon = () => {
  const [listTim, setListTim] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 500);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedData, setSelectedData] = useState({});

  const GetDataListTim = async () => {
    try {
      const res = await GetListTim();

      if (res.status === 200) {
        setListTim(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleDelete = (name, id) => {
    Swal.fire({
      text: `Do you want to delete "${name}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteTim(id);
      }
    });
  };

  const handleDeleteTim = async (id) => {
    try {
      const res = await DeleteTimRespon(id);

      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil Menghapus Tim'
        });
        GetDataListTim();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message
      });
    }
  };

  const columnData = [
    {
      title: 'Action',
      accessor: 'id',
      width: 100,
      render: ({ id, name, perusahaan_request_name, perusahaan_code, anggota }) => (
        <div className="flex items-center justify-center gap-1">
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="px-2 py-1 my-1 btnAksi">Action</div>
            </Popover.Target>

            <Popover.Dropdown className="flex flex-col popOverDropdown">
              <button
                onClick={() => {
                  setSelectedData({ id, name, perusahaan_request_name, perusahaan_code, anggota });
                  setModalEdit(true);
                }}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Ubah
                </div>
              </button>

              <button
                className="popOverItem"
                onClick={() => {
                  handleDelete(name, id);
                }}>
                <i className="fa-solid fa-trash"></i> Hapus
              </button>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Nama Tim Respon',
      accessor: 'name',
      width: 150
    },
    {
      title: 'Client',
      accessor: 'perusahaan_request_name',
      width: 200
    },
    {
      title: 'Ketua Tim',
      accessor: 'anggota',
      width: 200,
      render: ({ anggota }) => (
        <div>
          {anggota
            .filter((value) => value.is_chief === 1)
            .map((value, index) => (
              <p key={index}>{value.nama}</p>
            ))}
        </div>
      )
    },
    {
      title: 'Anggota',
      accessor: 'anggota',
      width: 350,
      render: ({ anggota }) => (
        <div>
          {anggota
            .filter((value) => value.is_chief !== 1)
            .map((value, index) => (
              <p key={index}>&bull; {value.nama}</p>
            ))}
        </div>
      )
    }
  ];

  useEffect(() => {
    if (listTim?.length > 0) {
      setFilterData(
        listTim.filter(({ name, perusahaan_request_name, anggota }) => {
          if (
            debouncedQuery !== '' &&
            !`${name} ${perusahaan_request_name} ${anggota.filter((value) => value.is_chief === 1)[0]?.nama}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        })
      );
    }
  }, [listTim, debouncedQuery]);

  useEffect(() => {
    GetDataListTim();
  }, []);

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 overflow-x-hidden md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="justify-center w-full min-h-screen mb-5 pb-8 rounded-lg bg-gray-50 shadowAll">
        <div className="flex items-center justify-end">
          <div className="flex px-5 my-3">
            <div className="flex w-full gap-4">
              <button
                onClick={() => {
                  setModalCreate(true);
                }}>
                <div className="flex items-center p-2 text-gray-700 rounded-md cursor-pointer bg-customGray hover:opacity-80">
                  <AiOutlineFileAdd size={24} />
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="p-5">{isLoading ? <LoadingPage /> : <Table data={filterData} columnData={columnData} setQuery={setQuery} />}</div>

        <ModalCreateTimRespon refetch={GetDataListTim} open={modalCreate} onClose={setModalCreate} />

        <ModalEditTimRespon data={selectedData} refetch={GetDataListTim} open={modalEdit} onClose={setModalEdit} />
      </div>
    </div>
  );
};

export default TimRespon;
