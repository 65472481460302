import React, { useState, useRef, useEffect } from 'react';
import { Modal } from '@mantine/core';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import { ClipLoader } from 'react-spinners';
import * as api from '../../redux/api';

const ModalQrDetails = ({ qrDetail, openModalQR, setOpenModalQR, name }) => {
  const [loading, setLoading] = useState(false);
  const [dataCompany, setDataCompany] = useState({});

  const printRef = useRef();

  useEffect(() => {
    const dataPerusahaanDetail = async () => {
      await api.getPerusahaan().then((res) => {
        setDataCompany(res.data.data[0] || '');
      });
    };
    dataPerusahaanDetail();
  }, []);

  const handleDownloadImage = async () => {
    setLoading(true);
    const element = printRef.current;
    const canvas = await html2canvas(element, { useCORS: true, allowTaint: true });

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = `${name} - ${qrDetail.nama}.png`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    setLoading(false);
  };

  return (
    <Modal opened={openModalQR} size="md" centered closeOnClickOutside onClose={() => setOpenModalQR(false)}>
      <div ref={printRef} className="flex flex-col h-[400px] md:h-[500px] w-full bg-contain bg-no-repeat bg-center justify-between rounded-lg">
        <div className="flex flex-col justify-center items-center h-full p-1 rounded-md">
          <div className="w-full  relative h-8">
            <div className="w-full absolute top-0 z-0">
              <img src="/bg-qr.png" alt="background" className="w-full h-full object-fill" />
            </div>

            <div className="flex flex-col gap-y-2 absolute z-40 top-2 w-full">
              <div className="flex justify-center relative top-1 items-center gap-x-6 flex-row">
                <div className="w-12 h-12 ">
                  <img src={'/logoBankamda.png'} alt="logo" className=" object-cover mt-1    " />
                </div>

                <div className="w-12 h-12">
                  {dataCompany?.satpamperusahaanfoto && <img src={dataCompany?.satpamperusahaanfoto} alt="logo" className="w-full h-full  rounded-full   " />}
                </div>
              </div>
              <p className="uppercase text-white text-center max-w-full px-8 md:px-4 mx-auto items-center text-wrap text-[11px] caprasimo md:text-sm ">
                area ini di awasi oleh bankamda {dataCompany?.satpamperusahaannama || ''}
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center h-full mt-24">
            <div className="flex flex-col justify-center items-center mx-auto w-fit h-fit p-2  bg-white rounded ">
              <QRCode
                size={190}
                className="hidden lg:block "
                value={qrDetail.kode}
                level="L"
                lengthAdjust={'l'}
                imageSettings={{ src: '/logo.png', x: undefined, y: undefined, height: 35, width: 35, excavate: true }}
              />
              <QRCode
                size={165}
                className="hidden md:block lg:hidden "
                value={qrDetail.kode}
                level="L"
                lengthAdjust={'l'}
                imageSettings={{ src: '/logo.png', x: undefined, y: undefined, height: 20, width: 20, excavate: true }}
              />{' '}
              <QRCode
                className=" max-w-max md:hidden "
                value={qrDetail.kode}
                level="L"
                lengthAdjust={'l'}
                imageSettings={{ src: '/logo.png', x: undefined, y: undefined, height: 15, width: 15, excavate: true }}
              />
              <p className="text-center text-sm font-semibold uppercase my-1">{qrDetail.nama}</p>
            </div>
            <p className="uppercase text-center text-wrap w-fit  text-[8px] mt-5 montserrat font-bold ">
              Powered by
              <br /> kalpika loka persada
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-2">
        <button type="button" className="btnAksi py-1 text-sm" onClick={handleDownloadImage}>
          {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Download as Image'}
        </button>
      </div>
    </Modal>
  );
};

export default ModalQrDetails;
