import React, { useEffect, useState } from 'react';
import { Modal } from '@mantine/core';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useForm, Controller } from 'react-hook-form';
import { getAllPerusahaan, personilListByType, UpdateTimRespon } from '../../redux/api';

const ModalEditTimRespon = ({ data, refetch, open, onClose }) => {
  const [clientOption, setClientOption] = useState([]);
  const [pecalangOption, setPecalangOption] = useState([]);
  const [changeTeam, setChangeTeam] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors: errorTim }
  } = useForm();

  const defaultValuePetugas = data?.anggota
    ?.filter((value) => value.is_chief !== 1)
    ?.map((value) => {
      return {
        label: value.nama,
        value: value.satpamkode
      };
    });

  const defaultValueKetua = data?.anggota
    ?.filter((value) => value.is_chief === 1)
    ?.map((value) => {
      return {
        label: value.nama,
        value: value.satpamkode
      };
    }) ?? [{ label: '', value: '' }];

  const getListPecalang = async () => {
    try {
      const res = await personilListByType('pecalang');

      if (res.status === 200) {
        const option = res.data.data.map((value) => {
          return {
            label: value.satpamnamalengkap,
            value: value.satpamkode
          };
        });

        setPecalangOption(option);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllClient = async () => {
    try {
      const res = await getAllPerusahaan();
      if (res.status === 200) {
        const options = res.data.data.map((value) => {
          return {
            label: value.satpamperusahaannama,
            value: value.satpamperusahaankode
          };
        });
        setClientOption(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateTimRespon = async (dataUpdate) => {
    try {
      let payload = {};

      if (changeTeam) {
        const selectedAnggota = dataUpdate.petugas.map((data) => {
          return {
            satpamkode: data.value,
            is_chief: false
          };
        });

        payload = {
          id: data.id,
          name: dataUpdate.namaTim,
          client_code: dataUpdate.client.value,
          client_name: dataUpdate.client.label,
          satpam_selected: [
            {
              satpamkode: dataUpdate.ketuaTim.value,
              is_chief: true
            },
            ...selectedAnggota
          ]
        };
      } else {
        payload = {
          id: data.id,
          name: dataUpdate.namaTim,
          client_code: dataUpdate.client.value,
          client_name: dataUpdate.client.label
        };
      }

      const res = await UpdateTimRespon(payload);

      if (res.status === 200) {
        onClose(false);
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil Mengubah Tim Respon'
        });
        refetch();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message
      });
    }
  };

  useEffect(() => {
    if (open) {
      getListPecalang();
      getAllClient();
    }

    return () => {
      reset();
      setChangeTeam(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      opened={open}
      onClose={() => {
        onClose(false);
      }}
      centered>
      <h1 className="text-center">Buat Tim Respon</h1>

      <hr className="mt-3" />

      <form onSubmit={handleSubmit(handleUpdateTimRespon)}>
        <div className="mt-5">
          <label htmlFor="">Nama Tim Respon</label>
          <input defaultValue={data.name} type="text" placeholder="Masukan Nama Tim Respn" className="w-full inputForm" {...register('namaTim', { required: true })} />

          {errorTim?.namaTim && errorTim?.namaTim?.type === 'required' && <p className="text-sm text-red-400">Nama Tim Belum Dimasukan</p>}
        </div>

        <div className="mt-3">
          <p>Pilih Client</p>
          <Controller
            control={control}
            name="client"
            rules={{ required: true }}
            defaultValue={{
              label: data.perusahaan_request_name,
              value: data.perusahaan_code
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                defaultValue={{
                  label: data.perusahaan_request_name,
                  value: data.perusahaan_code
                }}
                inputRef={ref}
                onChange={(val) => onChange(val)}
                options={clientOption}
                placeholder="Pilih Client"
              />
            )}
          />

          {errorTim?.client && errorTim?.client?.type === 'required' && <p className="text-sm text-red-400">Client Belum Dipilih</p>}
        </div>

        {!changeTeam && (
          <button
            className="w-full h-10 p-2 mt-5 text-white bg-black rounded-md"
            onClick={() => {
              setChangeTeam(true);
            }}
            type="button">
            Ubah Tim
          </button>
        )}

        {changeTeam && (
          <React.Fragment>
            <div className="mt-3">
              <p>Ketua Tim</p>
              <Controller
                control={control}
                name="ketuaTim"
                rules={{ required: true }}
                defaultValue={{
                  label: defaultValueKetua[0]?.label,
                  value: defaultValueKetua[0]?.value
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    inputRef={ref}
                    defaultValue={{
                      label: defaultValueKetua[0]?.label,
                      value: defaultValueKetua[0]?.value
                    }}
                    onChange={(val) => onChange(val)}
                    options={pecalangOption}
                    placeholder="Pilih Ketua Tim"
                  />
                )}
              />

              {errorTim?.ketuaTim && errorTim?.ketuaTim?.type === 'required' && <p className="text-sm text-red-400">Ketua Tim Belum Dipilih</p>}
            </div>

            <div className="mt-3">
              <p>Pilih Petugas</p>
              <Controller
                control={control}
                name="petugas"
                rules={{ required: true }}
                defaultValue={defaultValuePetugas}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    inputRef={ref}
                    defaultValue={defaultValuePetugas}
                    options={pecalangOption}
                    placeholder="Pilih Petugas"
                    isMulti
                    onChange={(val) => onChange(val)}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                )}
              />

              {errorTim?.petugas && errorTim?.petugas?.type === 'required' && <p className="text-sm text-red-400">Anggota Petugas Belum Dipilih</p>}
            </div>

            <button
              className="w-full h-10 p-2 mt-5 text-white bg-black rounded-md"
              onClick={() => {
                setChangeTeam(false);
              }}
              type="button">
              Batalkan Ubah Tim
            </button>
          </React.Fragment>
        )}

        <div className="flex flex-row justify-between mt-5">
          <button
            className="w-32 h-10 p-2 text-white bg-black rounded-md"
            onClick={() => {
              onClose(false);
            }}>
            Batalkan
          </button>

          <button className="w-40 h-10 p-2 text-white bg-black rounded-md" type="submit">
            Simpan
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalEditTimRespon;
