import React from 'react';

const SectionInputDataUsaha = ({ register, watch, errors, posOptions, paketOptions }) => {
  return (
    <div className="flex flex-col w-full p-5 mb-5 rounded-lg bg-light shadowAll lg:mb-0">
      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="nama_usaha" className="block mb-1 font-semibold md:mb-0">
            Nama Usaha
          </label>
        </div>

        <div className="md:w-2/3">
          <input
            id="nama_usaha"
            type="text"
            placeholder="Nama usaha"
            className={`w-full text-sm ${errors.nama_usaha ? 'invalidForm' : 'inputForm'}`}
            {...register('nama_usaha')}
          />
          <p className="text-xs text-red-700">{errors.nama_usaha?.message}</p>
        </div>
      </div>

      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="jenis_usaha" className="block mb-1 font-semibold md:mb-0">
            Jenis Usaha
          </label>
        </div>

        <div className="md:w-2/3">
          <select
            id="jenis_usaha"
            defaultValue=""
            className={`w-full text-sm cursor-pointer ${errors.jenis_usaha ? 'invalidForm' : 'inputForm'}`}
            {...register('jenis_usaha')}>
            <option value="" disabled>
              Pilih Jenis Usaha
            </option>
            <option value="Akomodasi">Akomodasi</option>
            <option value="Restaurant">Restaurant</option>
            <option value="Services">Personal Services / Jasa</option>
            <option value="Retails">Retails</option>
            <option value="Entertainment">Entertainment / Hiburan</option>
          </select>
          <p className="text-xs text-red-700">{errors.jenis_usaha?.message}</p>
        </div>
      </div>

      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="izin_usaha" className="block mb-1 font-semibold md:mb-0">
            Izin Usaha
          </label>
        </div>

        <div className="md:w-2/3">
          <select id="izin_usaha" defaultValue="" className={`w-full text-sm cursor-pointer ${errors.izin_usaha ? 'invalidForm' : 'inputForm'}`} {...register('izin_usaha')}>
            <option value="" disabled>
              Pilih Izin Usaha
            </option>
            <option value="digital">SIUP</option>
            <option value="Tidak Ada">Tidak Ada</option>
          </select>
          <p className="text-xs text-red-700">{errors.izin_usaha?.message}</p>
        </div>
      </div>

      {watch('izin_usaha') === 'digital' && (
        <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
          <div className="md:w-1/3">
            <label htmlFor="izin_nomor" className="block mb-1 font-semibold md:mb-0">
              Izin Nomor
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              id="izin_nomor"
              type="number"
              placeholder="Contoh: 1405010114060008"
              className={`w-full text-sm ${errors.izin_nomor ? 'invalidForm' : 'inputForm'}`}
              disabled={watch('izin_usaha') === 'Tidak Ada'}
              {...register('izin_nomor')}
            />
            <p className="text-xs text-red-700">{errors.izin_nomor?.message}</p>
          </div>
        </div>
      )}

      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="alamat" className="block mb-1 font-semibold md:mb-0">
            Alamat
          </label>
        </div>

        <div className="md:w-2/3">
          <input
            id="alamat"
            type="text"
            placeholder="Alamat berdasarkan kartu id"
            className={`w-full text-sm ${errors.alamat ? 'invalidForm' : 'inputForm'}`}
            {...register('alamat')}
          />
          <p className="text-xs text-red-700">{errors.alamat?.message}</p>
        </div>
      </div>

      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="status_kepemilikan" className="block mb-1 font-semibold md:mb-0">
            Status Kepemilikan
          </label>
        </div>

        <div className="md:w-2/3">
          <select
            id="status_kepemilikan"
            defaultValue=""
            className={`w-full text-sm cursor-pointer ${errors.status_kepemilikan ? 'invalidForm' : 'inputForm'}`}
            {...register('status_kepemilikan')}>
            <option value="" disabled>
              Pilih Status Kepemilikan
            </option>
            <option value="Pribadi">Pribadi</option>
            <option value="Kontrak">Kontrak</option>
          </select>
          <p className="text-xs text-red-700">{errors.status_kepemilikan?.message}</p>
        </div>
      </div>

      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="role" className="block mb-1 font-semibold md:mb-0">
            Paket
          </label>
        </div>

        <div className="md:w-2/3">
          <select id="paket" defaultValue="" className={`w-full text-sm cursor-pointer ${errors.paket ? 'invalidForm' : 'inputForm'}`} {...register('paket')}>
            <option value="" disabled>
              Pilih Paket
            </option>
            {paketOptions.map((value) => (
              <option key={value.id} value={value.id}>
                {value.nama}
              </option>
            ))}
          </select>
          <p className="text-xs text-red-700">{errors.paket?.message}</p>
        </div>
      </div>

      {watch('paket') === '3' && (
        <>
          <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
            <div className="md:w-1/3">
              <label htmlFor="kode_pos" className="block mb-1 font-semibold md:mb-0">
                Kode Pos
              </label>
            </div>

            <div className="md:w-2/3">
              {watch('paket') === '3' ? (
                <React.Fragment>
                  <select
                    id="kode_pos"
                    required
                    defaultValue=""
                    className={`w-full text-sm cursor-pointer ${errors.kode_pos ? 'invalidForm' : 'inputForm'}`}
                    {...register('kode_pos')}>
                    <option value="" disabled>
                      Pilih POS
                    </option>
                    {posOptions.map((value, index) => (
                      <option key={index} value={value.satpamposkode}>
                        {value.satpamposnama}
                      </option>
                    ))}
                  </select>
                  <p className="text-xs text-red-700">{errors.kode_pos?.message}</p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <select id="kode_pos" defaultValue="" className={`w-full text-sm cursor-pointer ${errors.kode_pos ? 'invalidForm' : 'inputForm'}`} {...register('kode_pos')}>
                    <option value="" disabled>
                      Pilih POS
                    </option>
                    {posOptions.map((value, index) => (
                      <option key={index} value={value.satpamposkode}>
                        {value.satpamposnama}
                      </option>
                    ))}
                  </select>
                  <p className="text-xs text-red-700">{errors.kode_pos?.message}</p>
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
            <div className="md:w-1/3">
              <label htmlFor="lat" className="block mb-1 font-semibold md:mb-0">
                Latitude
              </label>
            </div>

            <div className="md:w-2/3">
              {watch('paket') === '3' ? (
                <>
                  <input id="lat" type="text" placeholder="Latitude" className={`w-full text-sm ${errors.lat ? 'invalidForm' : 'inputForm'}`} {...register('lat')} required />
                  <p className="text-xs text-red-700">{errors.lat?.message}</p>
                </>
              ) : (
                <>
                  <input id="lat" type="text" placeholder="Latitude" className={`w-full text-sm ${errors.lat ? 'invalidForm' : 'inputForm'}`} {...register('lat')} />
                  <p className="text-xs text-red-700">{errors.lat?.message}</p>
                </>
              )}
            </div>
          </div>

          <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
            <div className="md:w-1/3">
              <label htmlFor="long" className="block mb-1 font-semibold md:mb-0">
                Longitude
              </label>
            </div>

            <div className="md:w-2/3">
              {watch('paket') === '3' ? (
                <>
                  <input
                    id="long"
                    type="text"
                    placeholder="Latitude"
                    className={`w-full text-sm ${errors.long ? 'invalidForm' : 'inputForm'}`}
                    {...register('long')}
                    required
                  />
                  <p className="text-xs text-red-700">{errors.long?.message}</p>
                </>
              ) : (
                <>
                  <input id="long" type="text" placeholder="Latitude" className={`w-full text-sm ${errors.long ? 'invalidForm' : 'inputForm'}`} {...register('long')} />
                  <p className="text-xs text-red-700">{errors.long?.message}</p>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SectionInputDataUsaha;
