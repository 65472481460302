/* eslint-disable array-callback-return */
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { ClipLoader } from 'react-spinners';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Tooltip } from '@mantine/core';
// Import files
import * as api from '../../redux/api';
import Headerlaporan from '../../components/general/HeaderLaporan';
import TableKejadian from '../../components/analisis/TableKejadian';
import TablePatroli from '../../components/analisis/TablePatroli';
import FormKeterangan from '../../components/analisis/FormKeterangan';
// import LoadingPage from '../../components/general/LoadingPage';

export default function Analisis() {
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const [laporan, setLaporan] = useState('');
  const [month, setMonth] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(['']);
  const [dataPatroli, setDataPatroli] = useState([]);
  // const [dataPersonil, setDataPersonil] = useState([]);

  const [totalMinggu1, setTotalMinggu1] = useState({ kejadian: 0, keluhan: 0, personil: 0, patroli: 0 });
  const [totalMinggu2, setTotalMinggu2] = useState({ kejadian: 0, keluhan: 0, personil: 0, patroli: 0 });
  const [totalMinggu3, setTotalMinggu3] = useState({ kejadian: 0, keluhan: 0, personil: 0, patroli: 0 });
  const [totalMinggu4, setTotalMinggu4] = useState({ kejadian: 0, keluhan: 0, personil: 0, patroli: 0 });

  const componentRef = useRef();

  useEffect(() => {
    setLoading(true);

    let dataMinggu1Kejadian = [];
    let dataMinggu2Kejadian = [];
    let dataMinggu3Kejadian = [];
    let dataMinggu4Kejadian = [];

    let dataMinggu1Patroli = [];
    let dataMinggu2Patroli = [];
    let dataMinggu3Patroli = [];
    let dataMinggu4Patroli = [];

    if (month !== '') {
      const getDataKejadian = async (month) => {
        await api
          .analisisKejadian({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setData(res.data.data);
              res.data.data.map((jadwal) => {
                dataMinggu1Kejadian.push(parseInt(jadwal.minggu1));
                dataMinggu2Kejadian.push(parseInt(jadwal.minggu2));
                dataMinggu3Kejadian.push(parseInt(jadwal.minggu3));
                dataMinggu4Kejadian.push(parseInt(jadwal.minggu4));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                kejadian: dataMinggu1Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                kejadian: dataMinggu2Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                kejadian: dataMinggu3Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                kejadian: dataMinggu4Kejadian.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };

      const getDataHarian = async (month) => {
        await api
          .analisisHarian({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setData(res.data.data);
              res.data.data.map((jadwal) => {
                dataMinggu1Kejadian.push(parseInt(jadwal.minggu1));
                dataMinggu2Kejadian.push(parseInt(jadwal.minggu2));
                dataMinggu3Kejadian.push(parseInt(jadwal.minggu3));
                dataMinggu4Kejadian.push(parseInt(jadwal.minggu4));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                kejadian: dataMinggu1Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                kejadian: dataMinggu2Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                kejadian: dataMinggu3Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                kejadian: dataMinggu4Kejadian.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };

      const getDataTemuan = async (month) => {
        await api
          .analisisTemuan({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setData(res.data.data);
              res.data.data.map((jadwal) => {
                dataMinggu1Kejadian.push(parseInt(jadwal.minggu1));
                dataMinggu2Kejadian.push(parseInt(jadwal.minggu2));
                dataMinggu3Kejadian.push(parseInt(jadwal.minggu3));
                dataMinggu4Kejadian.push(parseInt(jadwal.minggu4));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                kejadian: dataMinggu1Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                kejadian: dataMinggu2Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                kejadian: dataMinggu3Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                kejadian: dataMinggu4Kejadian.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };

      const getDataPatroli = async (month) => {
        await api
          .analisisPatroli({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setDataPatroli(res.data.data);
              res.data.data.forEach((item) => {
                item.data_usaha.forEach((jadwal) => {
                  dataMinggu1Patroli.push(parseInt(jadwal.summary.minggu1));
                  dataMinggu2Patroli.push(parseInt(jadwal.summary.minggu2));
                  dataMinggu3Patroli.push(parseInt(jadwal.summary.minggu3));
                  dataMinggu4Patroli.push(parseInt(jadwal.summary.minggu4));
                });
              });

              setTotalMinggu1({ patroli: dataMinggu1Patroli.reduce((a, b) => a + b, 0) });
              setTotalMinggu2({ patroli: dataMinggu2Patroli.reduce((a, b) => a + b, 0) });
              setTotalMinggu3({ patroli: dataMinggu3Patroli.reduce((a, b) => a + b, 0) });
              setTotalMinggu4({ patroli: dataMinggu4Patroli.reduce((a, b) => a + b, 0) });
            }

            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };
      laporan === 'kejadian' && getDataKejadian(month);
      laporan === 'harian' && getDataHarian(month);
      laporan === 'temuan' && getDataTemuan(month);
      laporan === 'patroli' && getDataPatroli(month);
    }
    setLoading(false);
  }, [laporan, month]);

  // if (monthOptions.length === 0) return <LoadingPage />;

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="mb-5 w-full min-h-screen justify-center bg-white rounded-lg shadowAll">
          <div className="pt-5 ml-5 flex flex-col sm:flex-row items-center gap-3 w-fit">
            <div className="font-semibold text-md mb-1 md:mb-0 w-full">Filter laporan</div>
            <select defaultValue="" className="inputForm text-sm cursor-pointer" onChange={(e) => setLaporan(e.target.value)}>
              <option value="" disabled>
                Pilih laporan
              </option>
              <option value="harian">Kegiatan Harian</option>
              <option value="kejadian">Kejadian</option>
              <option value="temuan">Temuan</option>
              <option value="patroli">Patroli</option>
            </select>
          </div>
          <div className="flex flex-nowrap justify-beetween items-center mb-2">
            <div className="flex flex-col md:flex-row flex-wrap justify-start w-full">
              <div className="w-fit flex flex-col sm:items-center sm:flex-row sm:gap-5 md:my-3 my-2 pr-5 md:pr-2 pl-5">
                <div className="font-semibold text-md mb-1 md:mb-0 w-full">Filter month</div>
                <DatePicker
                  selected={month}
                  className="inputForm text-sm cursor-pointer"
                  onChange={(date) => setMonth(date)}
                  locale={id}
                  timeIntervals={15}
                  dateFormat="MMMM yyyy"
                  placeholderText="MMMM yyyy"
                  showMonthYearPicker
                  required
                />
              </div>
            </div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <Tooltip label="Cetak laporan">
                  <ReactToPrint
                    trigger={() => (
                      <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                        <HiOutlinePrinter size={24} />
                      </div>
                    )}
                    content={() => componentRef.current}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="px-5">
            <table className="w-full" ref={componentRef}>
              <thead>
                <tr>
                  <th>
                    <div className="flex w-full">
                      <Headerlaporan />
                      <div className="w-full px-5 bg-dark flex flex-col items-end justify-center text-white">
                        <div className="text-gray-100 text-[18px] font-bold">LAPORAN BULANAN</div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {month === '' ? (
                      <div className="text-center">
                        <div className="mt-10">Belum ada bulan yang dipilih.</div>
                      </div>
                    ) : loading ? (
                      <div className="flex min-h-screen-75 justify-center items-center">
                        <ClipLoader color="#052D5A" loading={true} size={40} speedMultiplier={0.7} />
                      </div>
                    ) : (
                      <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                        <div className="block w-full p-5">
                          {laporan === 'temuan' && (
                            <TableKejadian
                              name={laporan}
                              data={data}
                              totalMinggu1={totalMinggu1.kejadian}
                              totalMinggu2={totalMinggu2.kejadian}
                              totalMinggu3={totalMinggu3.kejadian}
                              totalMinggu4={totalMinggu4.kejadian}
                            />
                          )}
                          {laporan === 'harian' && (
                            <TableKejadian
                              name={laporan}
                              data={data}
                              totalMinggu1={totalMinggu1.kejadian}
                              totalMinggu2={totalMinggu2.kejadian}
                              totalMinggu3={totalMinggu3.kejadian}
                              totalMinggu4={totalMinggu4.kejadian}
                            />
                          )}
                          {laporan === 'kejadian' && (
                            <TableKejadian
                              name={laporan}
                              data={data}
                              totalMinggu1={totalMinggu1.kejadian}
                              totalMinggu2={totalMinggu2.kejadian}
                              totalMinggu3={totalMinggu3.kejadian}
                              totalMinggu4={totalMinggu4.kejadian}
                            />
                          )}

                          {laporan === 'patroli' && (
                            <TablePatroli
                              dataPatroli={dataPatroli}
                              name={laporan}
                              totalMinggu1={totalMinggu1.patroli}
                              totalMinggu2={totalMinggu2.patroli}
                              totalMinggu3={totalMinggu3.patroli}
                              totalMinggu4={totalMinggu4.patroli}
                            />
                          )}

                          <div className="page-break" />
                          {/* Input Analisis */}
                          <FormKeterangan adminType={adminType} />
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
