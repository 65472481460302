import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import { MdVerified } from 'react-icons/md';

const ModalSatpamVerifikasi = ({ id, openModalVerifikasi, setOpenModalVerifikasi, handleVerification, data }) => {
  const [dataSatpam, setDataSatpam] = useState('');

  useEffect(() => {
    if (id !== '' && openModalVerifikasi) {
      const getDataSatpamById = async () => {
        await api.satpamProfile(id).then((res) => {
          setDataSatpam(res.data.data);
        });
      };
      getDataSatpamById();
    }
    return () => setDataSatpam('');
  }, [id, openModalVerifikasi]);

  return (
    <Modal opened={openModalVerifikasi} withCloseButton size="auto" onClose={() => setOpenModalVerifikasi(false)}>
      <div className="text-dark scrollbar-thin px-5 min-w-[250px] min-h-[400px]">
        <div className="flex flex-col justify-center items-center">
          <h3 className="font-bold text-center mb-5">Personil Verifikasi</h3>
          <div className="flex justify-center">
            {dataSatpam?.foto_selfie ? (
              <img src={`${process.env.REACT_APP_API_URL_PHOTO}${dataSatpam?.foto_selfie}`} alt="" className="w-[150px] h-[150px] object-cover mb-5 rounded-xl" />
            ) : (
              <div className="text-red-700 text-sm mb-5">Foto profil tidak ada! </div>
            )}
          </div>
          <div className="flex gap-10 text-sm">
            <div>
              <p className="font-semibold">Nama</p>
              <p className="font-semibold">Jenis Kewarganegaraan</p>
              <p className="font-semibold">Nomor hp</p>
              <p className="font-semibold">Krama Jenis</p>
              <p className="font-semibold">Jenis kelamin</p>
              <p className="font-semibold">Alamat KTP</p>
              <p className="font-semibold">Pekerjaan</p>
              <p className="font-semibold">Jenis Kartu Id</p>
              <p className="font-semibold">Nomor Kartu Id</p>
              {dataSatpam?.jenis_kewarganegaraan === 'WNA' && <p className="font-semibold">Kadaluarsa Kartu Id</p>}
              {dataSatpam?.jenis_kewarganegaraan === 'WNA' && <p className="font-semibold">Nomor Paspor</p>}
              {dataSatpam?.jenis_kewarganegaraan === 'WNA' && <p className="font-semibold">Kadaluarsa Paspor</p>}
            </div>
            <div>
              <p>: {dataSatpam?.satpamnamalengkap ? dataSatpam?.satpamnamalengkap : <span className="text-red-700">Data kosong!</span>}</p>
              <p>: {dataSatpam?.jenis_kewarganegaraan ? dataSatpam?.jenis_kewarganegaraan : <span className="text-red-700">Data kosong!</span>}</p>
              <p>: {dataSatpam?.satpamnohp ? dataSatpam?.satpamnohp : <span className="text-red-700">Data kosong!</span>}</p>
              <p>: {dataSatpam?.krama_text ? dataSatpam?.krama_text : <span className="text-red-700">Data kosong!</span>}</p>
              <p>: {dataSatpam?.satpamjeniskelamin ? dataSatpam?.satpamjeniskelamin : <span className="text-red-700">Data kosong!</span>}</p>
              <p>: {dataSatpam?.alamat_ktp ? dataSatpam?.alamat_ktp : <span className="text-red-700">Data kosong!</span>}</p>
              <p>: {dataSatpam?.pekerjaan ? dataSatpam?.pekerjaan : <span className="text-red-700">Data kosong!</span>}</p>
              <p>: {dataSatpam?.jenis_kartu_id ? dataSatpam?.jenis_kartu_id : <span className="text-red-700">Data kosong!</span>}</p>
              <p>: {dataSatpam?.nomor_kartu ? dataSatpam?.nomor_kartu : <span className="text-red-700">Data kosong!</span>}</p>
              {dataSatpam?.jenis_kewarganegaraan === 'WNA' && (
                <p>: {dataSatpam?.date_expired_kartu_id ? dataSatpam?.date_expired_kartu_id : <span className="text-red-700">Data kosong!</span>}</p>
              )}
              {dataSatpam?.jenis_kewarganegaraan === 'WNA' && <p>: {dataSatpam?.paspor ? dataSatpam?.paspor : <span className="text-red-700">Data kosong!</span>}</p>}
              {dataSatpam?.jenis_kewarganegaraan === 'WNA' && (
                <p>: {dataSatpam?.date_expired_paspor ? dataSatpam?.date_expired_paspor : <span className="text-red-700">Data kosong!</span>}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mt-5">
            <div className="font-semibold text-sm mb-1">Foto {dataSatpam?.jenis_kartu_id}</div>
            {dataSatpam.foto_kartu_id && dataSatpam.foto_kartu_id !== '' ? (
              <img src={`${process.env.REACT_APP_API_URL_PHOTO}${dataSatpam.foto_kartu_id}`} alt="" className="h-[150px] object-cover mb-5 rounded-xl" />
            ) : (
              <span className="text-red-700 text-sm">Data kosong!</span>
            )}
          </div>
          {dataSatpam.foto_paspor && (
            <div className="flex flex-col items-center justify-center">
              <div className="font-semibold text-sm mb-1">Foto Paspor</div>
              {dataSatpam.foto_paspor && dataSatpam.foto_paspor !== '' ? (
                <img src={`${process.env.REACT_APP_API_URL_PHOTO}${dataSatpam.foto_paspor}`} alt="" className="h-[150px] object-cover mb-5 rounded-xl" />
              ) : (
                '-'
              )}
            </div>
          )}
        </div>
        <div className={`flex mt-5 ${dataSatpam ? 'justify-between' : 'justify-end'}`}>
          {dataSatpam && (
            <div
              className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer flex items-center gap-1"
              onClick={() => handleVerification(data)}>
              <MdVerified size={16} /> Verifikasi
            </div>
          )}
          <div className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer" onClick={() => setOpenModalVerifikasi(false)}>
            Kembali
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSatpamVerifikasi;
