import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import imageCompression from 'browser-image-compression';
// Import files
import * as api from '../../redux/api';
import useImagePreview from '../../hooks/useImagePreview';
import { pendudukSchema } from '../../utils/schemaValidation';

export default function PendudukUpdate() {
  const [loading, setLoading] = useState(false);
  const [isPermanent, setIsPermanent] = useState(false);
  const [banjarId, setBanjarId] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(pendudukSchema)
  });

  useEffect(() => {
    const getBanjarById = async () => {
      await api.getPendudukById(id).then((res) => {
        setBanjarId(res.data.data[0].banjar_id);
        const defaultValue = {
          jenis_kewarganegaraan: res.data.data[0].jenis_kewarganegaraan !== null ? res.data.data[0].jenis_kewarganegaraan : '',
          nomor_kartu: res.data.data[0].nomor_kartu !== null ? res.data.data[0].nomor_kartu : '',
          jenis_kartu_id: res.data.data[0].jenis_kartu_id !== null ? res.data.data[0].jenis_kartu_id : '',
          paspor: res.data.data[0].paspor !== null ? res.data.data[0].paspor : '',
          nama: res.data.data[0].nama !== null ? res.data.data[0].nama : '',
          jenis_kelamin: res.data.data[0].jenis_kelamin !== null ? res.data.data[0].jenis_kelamin : '',
          alamat_ktp: res.data.data[0].alamat_ktp !== null ? res.data.data[0].alamat_ktp : '',
          pekerjaan: res.data.data[0].pekerjaan !== null ? res.data.data[0].pekerjaan : '',
          no_hp: res.data.data[0].no_hp !== null ? res.data.data[0].no_hp : '',
          date_expired_kartu_id: res.data.data[0].date_expired_kartu_id !== null ? res.data.data[0].date_expired_kartu_id : '',
          date_expired_paspor: res.data.data[0].date_expired_paspor !== null ? res.data.data[0].date_expired_paspor : '',
          foto_selfie: res.data.data[0].foto_selfie.split('/data-penduduk/')[1] ? process.env.REACT_APP_API_URL_PHOTO + res.data.data[0].foto_selfie : '',
          foto_kartu_id: res.data.data[0].foto_kartu_id.split('/data-penduduk/')[1] ? process.env.REACT_APP_API_URL_PHOTO + res.data.data[0].foto_kartu_id : '',
          foto_paspor: res.data.data[0].foto_paspor.split('/data-penduduk/')[1] ? process.env.REACT_APP_API_URL_PHOTO + res.data.data[0].foto_paspor : '',
          periode_start: res.data.data[0].periode_start ? res.data.data[0].periode_start : '',
          periode_end: res.data.data[0].periode_end ? res.data.data[0].periode_end : '',
          tipe: res.data.data[0].tipe ? res.data.data[0].tipe : ''
        };
        reset(defaultValue);
        if (defaultValue.periode_end === '') setIsPermanent(true);
      });
    };
    getBanjarById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const [fotoSelfiePreview] = useImagePreview(watch('foto_selfie'));
  const [fotoKartuIdPreview] = useImagePreview(watch('foto_kartu_id'));
  const [fotoPasporPreview] = useImagePreview(watch('foto_paspor'));

  const onSubmit = async (data) => {
    setLoading(true);

    data.periode_start = moment(data.periode_start).format('YYYY-MM-DD');

    if (!data.periode_end || isPermanent) delete data.periode_end;
    else data.periode_end = moment(data.periode_end).format('YYYY-MM-DD');

    data.id = id;
    data.banjar_id = banjarId;
    data._method = 'PUT';

    if (data.jenis_kewarganegaraan === 'WNI') {
      delete data.paspor;
      delete data.date_expired_kartu_id;
      delete data.date_expired_paspor;
    }

    if (data.jenis_kewarganegaraan === 'WNA' && data.date_expired_kartu_id) {
      data.date_expired_kartu_id = moment(data.date_expired_kartu_id).format('YYYY-MM-DD');
    }

    if (data.jenis_kewarganegaraan === 'WNA' && data.date_expired_paspor) {
      data.date_expired_paspor = moment(data.date_expired_paspor).format('YYYY-MM-DD');
    }

    const formData = new FormData();
    // formData.append('_method', 'PUT');
    // formData.append('id', id);
    // formData.append('banjar_id', banjarId);

    if (data.foto_selfie.length > 0 && typeof data.foto_selfie !== 'string') {
      const compressedFile = await imageCompression(data.foto_selfie[0], compressOptions);
      formData.append('foto_selfie', compressedFile, compressedFile.name);
    }

    if (data.foto_kartu_id?.length > 0 && typeof data.foto_kartu_id !== 'string') {
      const compressedFile = await imageCompression(data.foto_kartu_id[0], compressOptions);
      formData.append('foto_kartu_id', compressedFile, compressedFile.name);
    }

    if (data.foto_paspor?.length > 0 && typeof data.foto_paspor !== 'string') {
      const compressedFile = await imageCompression(data.foto_paspor[0], compressOptions);
      formData.append('foto_paspor', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key !== 'foto_selfie' && key !== 'foto_kartu_id' && key !== 'foto_paspor') {
        if (key === 'field') {
          formData.append(key, data[key][1]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    await api
      .updatePenduduk(formData)
      .then((res) => {
        Swal.fire('Good job!', res.data.message, 'success');
        setLoading(false);
        reset();
        reset({
          jenis_kewarganegaraan: 'WNI',
          foto_selfie: '',
          foto_ktp: '',
          foto_paspor: '',
          foto_kitas: ''
        });
        setBanjarId('');
        navigate(-1);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full h-full -mt-8">
      <div className="w-full">
        <div className="w-full py-5 bg-light rounded-lg shadow-black/20 shadow-lg flex justify-center items-center mb-10 text-lg text-center font-semibold">
          Update Penduduk
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full">
          {/* Penduduk Form */}
          <div className="bg-light p-5 my-5 rounded-lg shadowAll md:flex">
            {/* Left Form */}
            <div className="w-full md:w-1/2 flex flex-col justify-between">
              <div className="">
                {/* Kewarganegaraan */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="jenis_kewarganegaraan" className="block font-semibold mb-1 md:mb-0">
                      Kewarganegaraan
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <select
                      id="jenis_kewarganegaraan"
                      defaultValue=""
                      className={`w-full text-sm cursor-pointer ${errors.jenis_kewarganegaraan ? 'invalidForm' : 'inputForm'}`}
                      {...register('jenis_kewarganegaraan')}>
                      <option value="" disabled>
                        Pilih kewarganegaraan
                      </option>
                      <option value="WNI">WNI</option>
                      <option value="WNA">WNA</option>
                    </select>
                    <p className="text-red-600 text-sm">{errors.jenis_kewarganegaraan?.message}</p>
                  </div>
                </div>
                {/* Jenis Kartu Id */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="jenis_kartu_id" className="block font-semibold mb-1 md:mb-0">
                      Jenis Kartu Id
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <select
                      id="jenis_kartu_id"
                      defaultValue=""
                      className={`w-full text-sm cursor-pointer ${errors.jenis_kartu_id ? 'invalidForm' : 'inputForm'}`}
                      {...register('jenis_kartu_id')}>
                      <option value="" disabled>
                        Pilih jenis kartu id
                      </option>
                      <option value="KTP">KTP</option>
                      <option value="VISA">Visa</option>
                      <option value="KITAS">Kitas</option>
                    </select>
                    <p className="text-red-600 text-sm">{errors.jenis_kartu_id?.message}</p>
                  </div>
                </div>
                {/* Nomor Kartu */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="nomor_kartu" className="block font-semibold mb-1 md:mb-0">
                      {watch('jenis_kewarganegaraan') === 'WNI'
                        ? 'Nomor NIK'
                        : watch('jenis_kartu_id') === 'VISA'
                        ? 'Nomor Visa'
                        : watch('jenis_kartu_id') === 'KITAS'
                        ? 'Nomor Kitas'
                        : 'Nomor Identitas'}
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input id="nomor_kartu" type="text" className={`w-full text-sm ${errors.nomor_kartu ? 'invalidForm' : 'inputForm'}`} {...register('nomor_kartu')} />
                    <p className="text-red-600 text-sm">{errors.nomor_kartu?.message}</p>
                  </div>
                </div>
                {/* Expired Kitas/Visa */}
                {watch('jenis_kewarganegaraan') === 'WNA' && (
                  <>
                    <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                      <div className="md:w-1/2">
                        <label htmlFor="date_expired_kartu_id" className="block font-semibold mb-1 md:mb-0">
                          Tgl Expired {watch('jenis_kartu_id') === 'VISA' ? 'Nomor Visa' : watch('jenis_kartu_id') === 'KITAS' ? 'Nomor Kitas' : 'Nomor Identitas'}
                        </label>
                      </div>
                      <div className="md:w-1/2">
                        <input
                          id="date_expired_kartu_id"
                          type="date"
                          className={`w-full text-sm ${errors.date_expired_kartu_id ? 'invalidForm' : 'inputForm'}`}
                          {...register('date_expired_kartu_id')}
                        />
                        <p className="text-red-600 text-sm">{errors.date_expired_kartu_id?.message}</p>
                      </div>
                    </div>
                    {/* Paspor */}
                    <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                      <div className="md:w-1/2">
                        <label htmlFor="paspor" className="block font-semibold mb-1 md:mb-0">
                          Nomor Paspor
                        </label>
                      </div>
                      <div className="md:w-1/2">
                        <input id="paspor" type="text" className={`w-full text-sm ${errors.paspor ? 'invalidForm' : 'inputForm'}`} {...register('paspor')} />
                        <p className="text-red-600 text-sm">{errors.paspor?.message}</p>
                      </div>
                    </div>
                    {/* Expired Paspor */}
                    <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                      <div className="md:w-1/2">
                        <label htmlFor="date_expired_paspor" className="block font-semibold mb-1 md:mb-0">
                          Tgl Expired Paspor
                        </label>
                      </div>
                      <div className="md:w-1/2">
                        <input
                          id="date_expired_paspor"
                          type="date"
                          className={`w-full text-sm ${errors.date_expired_paspor ? 'invalidForm' : 'inputForm'}`}
                          {...register('date_expired_paspor')}
                        />
                        <p className="text-red-600 text-sm">{errors.date_expired_paspor?.message}</p>
                      </div>
                    </div>
                  </>
                )}

                {/* Jenis Kependudukan */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="type" className="block font-semibold mb-1 md:mb-0">
                      Status Kependudukan
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <select id="tipe" defaultValue="" className={`w-full text-sm ${errors.tipe ? 'invalidForm' : 'inputForm'}`} {...register('tipe')}>
                      <option value="" disabled>
                        Status kependudukan
                      </option>
                      <option value="T">Tamiu</option>
                      <option value="KT">Krama Tamiu</option>
                      <option value="KA">Krama Adat</option>
                    </select>
                    <p className="text-primary text-xs">{errors.tipe?.message}</p>
                  </div>
                </div>

                {/* Nama */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="nama" className="block font-semibold mb-1 md:mb-0">
                      Nama Lengkap
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input id="nama" type="text" className={`w-full text-sm ${errors.nama ? 'invalidForm' : 'inputForm'}`} {...register('nama')} />
                    <p className="text-red-600 text-sm">{errors.nama?.message}</p>
                  </div>
                </div>
                {/* Jenis Kelamin */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="jenis_kelamin" className="block font-semibold mb-1 md:mb-0">
                      Jenis Kelamin
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <select
                      id="jenis_kelamin"
                      defaultValue=""
                      className={`w-full text-sm cursor-pointer ${errors.jenis_kelamin ? 'invalidForm' : 'inputForm'}`}
                      {...register('jenis_kelamin')}>
                      <option value="" disabled>
                        Pilih Jenis Kelamin
                      </option>
                      <option value="L">Laki-laki</option>
                      <option value="P">Perempuan</option>
                    </select>
                    <p className="text-red-600 text-sm">{errors.jenis_kelamin?.message}</p>
                  </div>
                </div>
                {/* Pekerjaan */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="pekerjaan" className="block font-semibold mb-1 md:mb-0">
                      Pekerjaan
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input id="pekerjaan" type="text" className={`w-full text-sm ${errors.pekerjaan ? 'invalidForm' : 'inputForm'}`} {...register('pekerjaan')} />
                    <p className="text-red-600 text-sm">{errors.pekerjaan?.message}</p>
                  </div>
                </div>
                {/* No. Hp */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="no_hp" className="block font-semibold mb-1 md:mb-0">
                      No. Hp
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input id="no_hp" type="text" className={`w-full text-sm ${errors.no_hp ? 'invalidForm' : 'inputForm'}`} {...register('no_hp')} />
                    <p className="text-red-600 text-sm">{errors.no_hp?.message}</p>
                  </div>
                </div>
                {/* Alamat ktp */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="alamat_ktp" className="block font-semibold mb-1 md:mb-0">
                      Alamat Sesuai Identitas
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input id="alamat_ktp" type="text" className={`w-full text-sm ${errors.alamat_ktp ? 'invalidForm' : 'inputForm'}`} {...register('alamat_ktp')} />
                    <p className="text-red-600 text-sm">{errors.alamat_ktp?.message}</p>
                  </div>
                </div>
                {/* Periode Awal Tinggal */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="periode_start" className="block font-semibold mb-1 md:mb-0">
                      Periode Awal Tinggal
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input id="periode_start" type="date" className={`w-full text-sm ${errors.periode_start ? 'invalidForm' : 'inputForm'}`} {...register('periode_start')} />
                    <p className="text-red-600 text-sm">{errors.periodeawaltinggal?.message}</p>
                  </div>
                </div>
                {/* Periode Akhir Tinggal */}
                <div className="md:flex mb-3 justify-around items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="periode_end" className="block font-semibold mb-1 md:mb-0">
                      Periode Akhir Tinggal
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <div>
                      <input type="checkbox" onChange={(e) => setIsPermanent(e.target.checked)} value={watch('periode_end') === '' ? true : false} id="myCheck" />
                      <label className="text-sm"> Menetap</label>
                    </div>
                    {isPermanent ? (
                      ''
                    ) : (
                      <input
                        id="periode_end"
                        type="date"
                        className={`w-full mt-1 text-sm ${errors.periode_end ? 'invalidForm' : 'inputForm'}`}
                        disabled={isPermanent ? true : false}
                        {...register('periode_end')}
                      />
                    )}
                    <p className="text-red-600 text-sm">{errors.periode_end?.message}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Right form (Upload photos) */}
            <div className="w-full md:w-1/2">
              {/* Foto selfie */}
              <div className="justify-around items-center gap-3 px-2 md:px-5 mt-5 md:mt-0">
                <div>
                  <label htmlFor="foto_selfie" className="block font-semibold mb-1 md:mb-0 text-center">
                    Foto Selfie
                  </label>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <label className="p-2 flex flex-col w-full h-48 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fotoSelfiePreview && watch('foto_selfie') !== 'https://web-api.ldileh.com/uploads/data-penduduk/' ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fotoSelfiePreview}
                                className="w-full h-24 object-contain"
                                alt="preview"
                                // alt={fotoSelfiePreview.split('\\')[2]}
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input id="foto_selfie" type="file" className="opacity-0" {...register('foto_selfie')} accept="image/*" />
                        </label>
                      </div>
                      <p className="text-red-600 text-sm">{errors.foto_selfie?.message}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Foto Kartu Identitas */}
              <div className=" justify-around items-center gap-3 px-2 md:px-5 mt-5">
                <div>
                  <label htmlFor="foto_kartu_id" className="block font-semibold mb-1 md:mb-0 text-center">
                    Foto {watch('jenis_kewarganegaraan') === 'WNI' ? 'KTP' : watch('jenis_kewarganegaraan') === 'WNA' ? 'Kitas/Visa' : 'Identitas'}
                  </label>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <label className="p-2 flex flex-col w-full h-48 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fotoKartuIdPreview && watch('foto_kartu_id') !== 'https://web-api.ldileh.com/uploads/data-penduduk/' ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fotoKartuIdPreview} className="w-full h-24 object-contain" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input id="foto_kartu_id" type="file" className="opacity-0" {...register('foto_kartu_id')} accept="image/*" />
                        </label>
                      </div>
                      <p className="text-red-600 text-sm">{errors.foto_kartu_id?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Foto Paspor */}
              {watch('jenis_kewarganegaraan') === 'WNA' && (
                <div className="justify-around items-center gap-3 px-2 md:px-5 mt-5">
                  <div>
                    <label htmlFor="foto_paspor" className="block font-semibold mb-1 md:mb-0 text-center">
                      Foto Paspor
                    </label>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <div className="w-full p-1 md:p-2">
                      <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                        <div className="flex flex-col w-full">
                          <label className="p-2 flex flex-col w-full h-48 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            {fotoPasporPreview && watch('foto_paspor') !== 'https://web-api.ldileh.com/uploads/data-penduduk/' ? (
                              <div className="flex flex-col items-center justify-center">
                                <img src={fotoPasporPreview} className="w-full h-24 object-contain" alt="preview" />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                              </div>
                            )}
                            <input id="foto_paspor" type="file" className="opacity-0" {...register('foto_paspor')} accept="image/*" />
                          </label>
                        </div>
                        <p className="text-red-600 text-sm">{errors.foto_paspor?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* End of Penduduk Form */}

          {/* Button simpan dan close */}
          <div className="flex justify-between items-center w-full py-5 px-10 my-8 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-light py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80 z-2"
              // disabled={loading === true ? true : false}
            >
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>
            <Link to={`/banjar-penduduk/detail/${banjarId}`}>
              <button type="button" className="text-light py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80 z-2">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
