import React from 'react';
import { ScaleLoader } from 'react-spinners';

export default function LoadingModal() {
  return (
    <div className="my-[20vh] flex flex-col items-center">
      <ScaleLoader color="#0077B6" loading={true} height={20} speedMultiplier={0.7} />
      <span>Loading data...</span>
    </div>
  );
}
