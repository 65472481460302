// Import files
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Popover } from '@mantine/core';
import { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import LoadingPage from '../../components/general/LoadingPage';
import TableKontribusi from '../../components/kontribusi/Table_Kontribusi';
import ModalKontribusiDetails from '../../components/kontribusi/Modal_Kontribusi_Detail';
import { MdVerified } from 'react-icons/md';
import ModalKontribusiUpdate from '../../components/kontribusi/Modal_Kontribusi_Update';
import CardsInfo from '../../components/kontribusi/Card_Info';

export default function Kontrbusi() {
  const [data, setData] = useState(['']);
  const [datas, setDatas] = useState(['']);
  const [kontribusiDetails, setKontribusiDetail] = useState('');
  const [updateData, setUpdateData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [updateModal, setUpdateModal] = useState(false);
  const [isuserName, setNameUser] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    fetchData(selectedStatus, selectedMonth);
    
    const fetchNameUser = async () => {
      try {
        const response = await api.listWarga();
        const data = response.data.data.reduce((acc, item) => {
          acc[item.user_code] = item.nama;
          return acc;
        }, {});
        setNameUser(data);
      } catch (error) {
        console.error('API Error:', error);
      }
    };

    fetchNameUser();
  }, []);

  const fetchData = async (status, month) => {
    setLoading(true);

    try {
      const res = await api.getKontribusi(status, month);
      setData(res.data.data);
      setDatas(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModalDetail = (id) => {
    setKontribusiDetail(id);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const refreshData = async (status, month) => {
    setLoading(true);
    try {
      const res = await api.getKontribusi(status, month);
      setData(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async (id) => {
    try {
      const response = await api.verifyKontribusi(id.id);
      if (response.data.status) {
        refreshData(selectedStatus, selectedMonth);
        // Verification was successful
        Swal.fire('Success', 'Verification successful!', 'success');
        // You can also perform additional actions here if needed
        console.log('Verification successful');
      } else {
        // Verification failed, handle error and show an error message if needed.
        Swal.fire('Error', 'Verification failed', 'error');
        console.error('Verification failed');
      }
    } catch (error) {
      // Handle any API request errors here and show an error message if needed.
      Swal.fire('Error', 'An error occurred while verifying', 'error');
      console.error('API request error', error);
    }
  };

  const handleDelete = (id) => {
    setShowPopover('');
    Swal.fire({
      text: 'Do you want to delete this message?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .deleteKontribusi(id.id)
            .then((res) => {
              if (res.data) {
                Swal.fire('Good job!', res.data.message, 'success');
                refreshData(selectedStatus, selectedMonth);
              } else Swal.fire({ icon: 'error', title: 'Oops...', text: 'Failed to delete!' });
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  const handelUpdate = (id) => {
    setUpdateData(id);
    setUpdateModal(true);
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'id',
      width: 100,
      textAlignment: 'center',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: (row) => {
        const { id, status } = row;

        return (
          <div className="flex justify-center gap-1">
            {/* Button Aksi */}
            <Popover opened={showPopover === id} onClick={() => setShowPopover(showPopover ? '' : id)} width={110} position="right" withArrow shadow="xl">
              <Popover.Target>
                <div className="px-2 py-1 my-1 btnAksi">Action</div>
              </Popover.Target>
              <Popover.Dropdown className="popOverDropdown">
                <div className="flex flex-col gap-y-3">
                  <div size="sm" className="flex popOverItem gap-x-2" onClick={() => handleOpenModalDetail(row)}>
                    <i className="mt-1 fa-solid fa-circle-info "></i>Details
                  </div>
                  <div size="sm" className="flex popOverItem gap-x-2" onClick={() => handelUpdate(row)}>
                    <i className="mt-1 fa-solid fa-pen"></i>Update
                  </div>
                  {status === 'PROGRESS' && (
                    <div className="flex popOverItem gap-x-2" onClick={() => handleVerify(row)}>
                      <MdVerified size={18} /> Verifikasi
                    </div>
                  )}
                  <div className="flex popOverItem gap-x-2" onClick={() => handleDelete(row)}>
                    <i className="mt-1 fa-solid fa-trash"></i> Delete
                  </div>
                </div>
              </Popover.Dropdown>
            </Popover>
          </div>
        );
      }
    },

    {
      title: 'Kode', // Column title
      accessor: 'code', // The data field in your API response
      width: 120, // Adjust the width as needed
      textAlignment: 'center', // Center-align the cell content
      cellsStyle: {
        verticalAlign: 'top' // Adjust cell style as needed
      },
      render: ({ code }) => <div className="font-semibold text-primary">{code}</div>
    },
    {
      title: 'Nama',
      accessor: 'user_code',
      width: 120,
      textAlignment: 'center',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ user_code }) => {
        // Access the user's name from the mapping based on user_code
        const userName = isuserName[user_code] || '-';

        return <div>{userName}</div>;
      }
    },
    {
      title: 'Krama',
      accessor: 'user.profile.krama',
      width: 120,
      textAlignment: 'center',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ user }) => {
        const krama = user?.profile?.krama;

        const kramaText = krama === 'T' ? 'Tamiu' : krama === 'KT' ? 'Krama Tamiu' : '-';

        return <div>{kramaText}</div>;
      }
    },
    {
      title: 'Nominal',
      accessor: 'price',
      width: 150,
      textAlignment: 'center',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ price }) => <div className="w-full h-auto py-0 my-0 mb-1 text-center cursor-pointer">{price}</div>
    },
    {
      title: 'Bulan dan Tahun',
      accessor: 'date',
      width: 150,
      textAlignment: 'center',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ date }) => <div className="w-full h-auto py-0 my-0 mb-1 text-center cursor-pointer">{date}</div>
    },
    {
      title: 'Status',
      accessor: 'status',
      textAlignment: 'center',
      width: 120,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ status }) => (
        <div>
          {' '}
          {status === 'UNPAID' ? (
            <div>
              <div className="flex">
                <i className="fa fa-circle text-red-500 fa-1 mr-3 mt-[4.4px] " aria-hidden="true"></i>
                Belum Bayar
              </div>
            </div>
          ) : status === 'PAID' ? (
            <div>
              <div className="flex">
                <i className="fa fa-circle text-green-500 fa-1 mr-3 mt-[4.4px]" aria-hidden="true"></i>
                Sudah Bayar
              </div>
            </div>
          ) : status === 'PROGRESS' ? (
            <div>
              <div className="flex">
                <i className="fa fa-circle text-yellow-500 fa-1 mr-3 mt-[4.4px]" aria-hidden="true"></i>
                Proses
              </div>
            </div>
          ) : (
            '-'
          )}
        </div>
      )
    }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div
      className="relative w-full px-4 mx-auto -mt-8 overflow-x-hidden md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300"
      onClick={() => showPopover && setShowPopover('')}>
      <div className="w-full min-h-screen">
        <CardsInfo
          sudahBayarCount={datas.summary.count_by_status.paid}
          belumBayarCount={datas.summary.count_by_status.unpaid}
          prosesCount={datas.summary.count_by_status.progress}
          totalCount={datas.total_items}
          nominalUnpaid={datas.summary.price_by_status.unpaid}
          nominalPaid={datas.summary.price_by_status.paid}
          nominalTotal={datas.summary.price_total}
          nominalProses={datas.summary.price_by_status.progress}
          loading={loading}
        />
        <div className="justify-center w-full pb-8 rounded-lg bg-gray-50 shadowAll">
          <div className="flex flex-wrap items-center lg:flex-nowrap justify-beetween">
            <div className="flex flex-col flex-wrap items-center justify-start w-full md:flex-row"></div>
          </div>

          {/* Table Component */}
          <div className="p-5">
            <TableKontribusi 
              data={data} 
              columnData={columnData} 
              searchQuery={searchQuery} 
              setSearchQuery={setSearchQuery} 
              loading={loading} 
              isuserName={isuserName}
              setSelectedStatus={(value) => {
                setSelectedStatus(value);

                fetchData(value, selectedMonth);
              }}
              setSelectedMonth={(value) => {
                setSelectedMonth(value);

                fetchData(selectedStatus, value);
              }} />
          </div>
        </div>

        <div className="flex justify-center mt-5">
          <Link to="/">
            <button className="px-20 py-2 btnAksi bg-primary">Dashboard</button>
          </Link>
        </div>

        {/* Modal Detail Kontribusi */}
        <ModalKontribusiDetails id={kontribusiDetails} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

        {/* Modal Update Kontribusi */}
        <ModalKontribusiUpdate openModalUpdate={updateModal} setModalUpdate={setUpdateModal} id={updateData} refreshData={refreshData} />
      </div>
    </div>
  );
}
