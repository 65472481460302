import React from 'react';
import { BsGearFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { MdVerified } from 'react-icons/md';
import { Popover } from '@mantine/core';

export default function BtnAction({
  showPopover,
  setShowPopover,
  data,
  id,
  handleOpenModalDetail,
  handleDelete,
  handleReset,
  sendInfo,
  handleSendInfo,
  reset,
  page,
  noPrint,
  noDetail,
  verifikasi,
  handleVerification,
  handleOpenModalVerifikasi,
  HideDelete
}) {
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  return (
    <div className="flex items-center justify-center gap-1">
      {/* Button Aksi */}
      <Popover opened={showPopover === data} onClick={() => setShowPopover(showPopover ? '' : data)} width={180} position="right" withArrow shadow="xl">
        <Popover.Target>
          <div className="btnAksi my-1 py-1 px-2">
            <BsGearFill className="mb-[2px]" /> &nbsp; Aksi
          </div>
        </Popover.Target>
        <Popover.Dropdown className="popOverDropdown">
          {!noDetail && (
            <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(data)}>
              <i className="fa-solid fa-circle-info"></i> Detail
            </div>
          )}
          {adminType === 'Admin Project' && (
            <>
              <Link to={`${page}/update/${data}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Ubah
                </div>
              </Link>
              {!HideDelete && (
                <div className="popOverItem" onClick={() => handleDelete(id)}>
                  <i className="fa-solid fa-trash mr-[2px]"></i> Hapus
                </div>
              )}

              {sendInfo && (
                <div className="popOverItem" onClick={() => handleSendInfo(id)}>
                  <i className="fa fa-share-alt"></i> Sebarkan Informasi
                </div>
              )}
            </>
          )}
          {!noPrint && (
            <Link to={`${page}-printperkeg/${data}`}>
              <div className="popOverItem">
                <i className="fa-solid fa-print"></i> Cetak
              </div>
            </Link>
          )}
          {reset && (
            <div className="popOverItem" onClick={() => handleReset(id)}>
              <i className="fa-solid fa-power-off"></i> Reset
            </div>
          )}
          {verifikasi && id.is_verified === 0 && (
            <div className="popOverItem flex items-center gap-[2px]" onClick={() => handleOpenModalVerifikasi(data)}>
              <span>
                <MdVerified size={16} />
              </span>
              Verifikasi
            </div>
          )}
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}
