import React, { useRef, useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { createPopper } from '@popperjs/core';
import { setLogout } from '../../redux/features/authSlice';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const UserDropdown = ({ profile }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [dataPerusahaan, setDataPerusahaan] = useState({});
  const btnDropdownRef = useRef();
  const popoverDropdownRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const getImage = async () => {
      await api.getProfile().then((res) => {
        setDataPerusahaan(res.data.data[0] || '');
      });
    };
    getImage();
  }, []);

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'bottom-end'
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    Swal.fire({
      text: 'Apakah anda ingin keluar dari aplikasi?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#0E69D3'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLogout());
      }
    });
  };

  return (
    <>
      <Link
        className="text-slate-500 block cursor-pointer"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}>
        <div className="items-center flex flex-col cursor-pointer">
          <span className="w-12 h-12 text-sm text-gray-100 bg-white inline-flex items-center justify-center rounded-full drop-shadow-lg md:drop-shadow-none  ">
            {/* <img src={dataPerusahaan?.satpamperusahaanfoto || require('../../images/logo.png')} alt="..." className="w-full object-cover align-middle border-none shadow-lg" />
             */}
            {dataPerusahaan?.adminpetugasfoto?.split('/data-admin/')[1].length > 0 ? (
              <img src={process.env.REACT_APP_API_URL_PHOTO + dataPerusahaan.adminpetugasfoto} alt="" className="w-full h-full object-fill rounded-full border-none  " />
            ) : (
              <img src={require('../../images/logo.png')} alt="" className="w-full h-full object-fill rounded-full border-none " />
            )}
          </span>
          <p className="hidden lg:block text-sm font-semibold text-gray-100 my-1 mt-2">{dataPerusahaan?.adminpetugasnamalengkap}</p>
          <p className="hidden lg:block text-sm font-semibold text-gray-100">{dataPerusahaan?.adminpetugasperusahaannama}</p>
        </div>
      </Link>
      <div
        ref={popoverDropdownRef}
        className={(dropdownPopoverShow ? 'block ' : 'hidden ') + 'bg-white text-base z-[99999] py-2 float-left list-none text-left rounded shadow-lg min-w-48'}
        onMouseLeave={() => closeDropdownPopover()}>
        <Link
          className={
            'text-sm font-semibold py-2 z-[99999] px-4 mx-2 rounded block whitespace-nowrap bg-transparent text-slate-700 cursor-pointer hover:bg-primary hover:text-gray-100 active:bg-primary'
          }
          onClick={handleLogout}>
          <i className="fas fa-sign-out"></i> Logout
        </Link>
      </div>
    </>
  );
};

export default UserDropdown;
