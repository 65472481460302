import React, { useEffect, useState } from 'react';
import { Modal } from '@mantine/core';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useForm, Controller } from 'react-hook-form';
import { CreateTimRespon, getAllPerusahaan, personilListByType } from '../../redux/api';

const ModalCreateTimRespon = ({ refetch, open, onClose }) => {
  const [clientOption, setClientOption] = useState([]);
  const [pecalangOption, setPecalangOption] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors: errorTim }
  } = useForm();

  const getListPecalang = async () => {
    try {
      const res = await personilListByType('pecalang');

      if (res.status === 200) {
        const option = res.data.data.map((value) => {
          return {
            label: value.satpamnamalengkap,
            value: value.satpamkode
          };
        });

        setPecalangOption(option);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllClient = async () => {
    try {
      const res = await getAllPerusahaan();
      if (res.status === 200) {
        const options = res.data.data.map((value) => {
          return {
            label: value.satpamperusahaannama,
            value: value.satpamperusahaankode
          };
        });
        setClientOption(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateTimRespon = async (data) => {
    try {
      const selectedAnggota = data.petugas.map((data) => {
        return {
          satpamkode: data.value,
          is_chief: false
        };
      });

      const payload = {
        name: data.namaTim,
        client_code: data.client.value,
        client_name: data.client.label,
        satpam_selected: [
          {
            satpamkode: data.ketuaTim.value,
            is_chief: true
          },
          ...selectedAnggota
        ]
      };

      const res = await CreateTimRespon(payload);

      if (res.status === 200) {
        onClose(false);
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil Membuat Tim Respons'
        });
        refetch();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message
      });
    }
  };

  useEffect(() => {
    if (open) {
      getListPecalang();
      getAllClient();
    }

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      opened={open}
      onClose={() => {
        onClose(false);
      }}
      centered>
      <h1 className="text-center">Buat Tim Respon</h1>

      <hr className="mt-3" />

      <form onSubmit={handleSubmit(handleCreateTimRespon)}>
        <div className="mt-5">
          <label htmlFor="">Nama Tim Respon</label>
          <input type="text" placeholder="Masukan Nama Tim Respn" className="w-full inputForm" {...register('namaTim', { required: true })} />

          {errorTim?.namaTim && errorTim?.namaTim?.type === 'required' && <p className="text-sm text-red-400">Nama Tim Belum Dimasukan</p>}
        </div>

        <div className="mt-3">
          <p>Pilih Client</p>
          <Controller
            control={control}
            name="client"
            rules={{ required: true }}
            render={({ field: { onChange, value, ref } }) => <Select inputRef={ref} onChange={(val) => onChange(val)} options={clientOption} placeholder="Pilih Client" />}
          />

          {errorTim?.client && errorTim?.client?.type === 'required' && <p className="text-sm text-red-400">Client Belum Dipilih</p>}
        </div>

        <div className="mt-3">
          <p>Ketua Tim</p>
          <Controller
            control={control}
            name="ketuaTim"
            rules={{ required: true }}
            render={({ field: { onChange, value, ref } }) => (
              <Select inputRef={ref} onChange={(val) => onChange(val)} options={pecalangOption} placeholder="Pilih Ketua Tim" />
            )}
          />

          {errorTim?.ketuaTim && errorTim?.ketuaTim?.type === 'required' && <p className="text-sm text-red-400">Ketua Tim Belum Dipilih</p>}
        </div>

        <div className="mt-3">
          <p>Pilih Petugas</p>
          <Controller
            control={control}
            name="petugas"
            rules={{ required: true }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                inputRef={ref}
                options={pecalangOption}
                placeholder="Pilih Petugas"
                isMulti
                onChange={(val) => onChange(val)}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            )}
          />

          {errorTim?.petugas && errorTim?.petugas?.type === 'required' && <p className="text-sm text-red-400">Anggota Petugas Belum Dipilih</p>}
        </div>

        <div className="flex flex-row justify-between mt-5">
          <button
            className="w-32 h-10 p-2 text-white bg-black rounded-md"
            onClick={() => {
              onClose(false);
            }}>
            Batalkan
          </button>

          <button className="w-40 h-10 p-2 text-white bg-black rounded-md" type="submit">
            Simpan
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalCreateTimRespon;
