import React from 'react';

const FormKeterangan = ({ adminType }) => {
  return (
    <div>
      <div className="flex md:flex-row flex-col gap-3 text-sm mt-5">
        <div className="md:w-1/2 w-full py-2 flex flex-col justify-around">
          <div className="md:flex mb-3">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Potensi kejadian</label>
            </div>
            <div className="md:w-2/3">
              <textarea className={`w-full inputForm`} rows={3} disabled={adminType !== 'Admin Project' ? true : false} />
              <p className="text-primary text-xs"></p>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Potensi kejadian</label>
            </div>
            <div className="md:w-2/3">
              <textarea className={`w-full inputForm`} rows={3} disabled={adminType !== 'Admin Project' ? true : false} />
              <p className="text-primary text-xs"></p>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full py-2 flex flex-col justify-around">
          <div className="md:flex mb-3">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Kesimpulan</label>
            </div>
            <div className="md:w-2/3">
              <textarea className={`w-full inputForm`} rows={3} disabled={adminType !== 'Admin Project' ? true : false} />
            </div>
          </div>
          <div className="md:flex mb-3">
            <div className="md:w-1/3 md:px-2">
              <label className="block font-semibold mb-1 md:mb-0">Saran dan Rekomendasi</label>
            </div>
            <div className="md:w-2/3">
              <textarea className={`w-full inputForm`} rows={3} disabled={adminType !== 'Admin Project' ? true : false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormKeterangan;
