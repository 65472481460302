import React from 'react';

const SectionInputFotoUsaha = ({ register, errors, fotoSelfiePreview, fotoUsahaPreview }) => {
  return (
    <div className="flex flex-col w-full gap-5 p-5 mt-5 rounded-lg lg:w-1/2 bg-light shadowAll lg:mt-0 h-1/2">
      {/* Foto Selfie */}
      <div className="flex-col gap-2 px-2 md:flex justify-arround md:px-5">
        <div>
          <label htmlFor="foto_selfie" className="block mb-1 font-semibold md:mb-0">
            Foto Selfie
          </label>
        </div>
        <div className="flex flex-wrap w-full">
          <div className="w-full">
            <div className="h-auto p-3 bg-white rounded-md shadowAll">
              <div className="flex flex-col w-full">
                <label className="flex flex-col items-center justify-center w-full h-56 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                  {fotoSelfiePreview ? (
                    <div className="flex flex-col items-center justify-center">
                      <img src={fotoSelfiePreview} className="object-contain w-full h-48" alt="preview" />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center pt-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                      </svg>
                      <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                    </div>
                  )}
                  <input type="file" className="opacity-0" {...register('foto_selfie')} accept="image/*" />
                </label>
              </div>
              <p className="text-xs text-red-700">{errors.foto_selfie?.message}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Foto Usaha */}
      <div className="flex-col gap-2 px-2 md:flex justify-arround md:px-5">
        <div>
          <label htmlFor="foto_usaha" className="block mb-1 font-semibold md:mb-0">
            Logo Usaha
          </label>
        </div>
        <div className="flex flex-wrap w-full">
          <div className="w-full">
            <div className="h-auto p-3 bg-white rounded-md shadowAll">
              <div className="flex flex-col w-full">
                <label className="flex flex-col items-center justify-center w-full h-56 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                  {fotoUsahaPreview ? (
                    <div className="flex flex-col items-center justify-center">
                      <img src={fotoUsahaPreview} className="object-contain w-full h-48" alt="preview" />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center pt-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                      </svg>
                      <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                    </div>
                  )}
                  <input type="file" className="opacity-0" {...register('foto_usaha')} accept="image/*" />
                </label>
              </div>
              <p className="text-xs text-red-700">{errors.foto_usaha?.message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionInputFotoUsaha;
