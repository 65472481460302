import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// Import files
import * as api from '../../redux/api';
import { kejadianSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';

export default function KejadianCreate({ personilType }) {
  const [kategoriKode, setKategoriKode] = useState('');
  const [kategoriOptions, setKategoriOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [satpamKode, setSatpamKode] = useState('');
  const [tanggalAktifitas, setTanggalAktifitas] = useState(new Date());
  const [tanggalMelaporkan, setTanggalMelaporkan] = useState(new Date());

  // const [subKategoriKode, setSubKategoriKode] = useState('');
  // const [subKategoriOptions, setSubKategoriOptions] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(kejadianSchema)
  });

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const [fileDataURL] = useImagePreview(watch('satpamkejadianfotosatu'));
  const [fileDataURL2] = useImagePreview(watch('satpamkejadianfotodua'));
  const [fileDataURL3] = useImagePreview(watch('satpamkejadianfototiga'));
  const [fileDataURL4] = useImagePreview(watch('satpamkejadianfotoempat'));
  const [fileDataURL5] = useImagePreview(watch('satpamkejadianfotolima'));

  useEffect(() => {
    const getData = async () => {
      await api.personilListByType('pecalang').then((res) => {
        res.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [...prev, { value: data.satpamkode, label: data.satpamnamalengkap }]);
        });
      });
    };
    const getDataKategori = async () => {
      await api.kejadianKategori().then((res) => {
        res.data.data.map((data) => {
          return setKategoriOptions((prev) => [
            ...prev,
            {
              value: data.satpamkejadiankategorikode,
              label: data.satpamkejadiankategorinama
            }
          ]);
        });
      });
    };
    getData();
    getDataKategori();
  }, [personilType]);

  // useEffect(() => {
  //   if (kategoriKode !== '') {
  //     const getKejadianSubKategori = async () => {
  //       await api.kejadianSubKategori({ kategori_kode: kategoriKode }).then((res) => {
  //         res.data.data.map((data) => {
  //           return setSubKategoriOptions((prev) => [
  //             ...prev,
  //             {
  //               value: data.satpamkejadiankategoridetailkode,
  //               label: data.satpamkejadiankategoridetailnama
  //             }
  //           ]);
  //         });
  //       });
  //     };
  //     getKejadianSubKategori();
  //   }
  // }, [kategoriKode]);

  const handleChangeKategori = (e) => {
    setKategoriKode(e.value);
    // setSubKategoriOptions([]);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const formData = new FormData();

    formData.append('satpamkejadiansatpamkode', satpamKode);
    formData.append('satpamkejadiankategori', kategoriKode);
    // formData.append('satpamkejadiankategoridetail', subKategoriKode);
    formData.append('satpamkejadiantanggalmelaporkan', moment(tanggalMelaporkan).format('YYYY-MM-DDTHH:mm'));
    formData.append('satpamkejadiantanggalaktifitas', moment(tanggalAktifitas).format('YYYY-MM-DDTHH:mm'));

    if (data.satpamkejadianfotosatu.length > 0) {
      const compressedFile = await imageCompression(data.satpamkejadianfotosatu[0], compressOptions);
      formData.append('satpamkejadianfotosatu', compressedFile, compressedFile.name);
    }

    if (data.satpamkejadianfotodua.length > 0) {
      const compressedFile = await imageCompression(data.satpamkejadianfotodua[0], compressOptions);
      formData.append('satpamkejadianfotodua', compressedFile, compressedFile.name);
    }

    if (data.satpamkejadianfototiga.length > 0) {
      const compressedFile = await imageCompression(data.satpamkejadianfototiga[0], compressOptions);
      formData.append('satpamkejadianfototiga', compressedFile, compressedFile.name);
    }

    if (data.satpamkejadianfotoempat.length > 0) {
      const compressedFile = await imageCompression(data.satpamkejadianfotoempat[0], compressOptions);
      formData.append('satpamkejadianfotoempat', compressedFile, compressedFile.name);
    }

    if (data.satpamkejadianfotolima.length > 0) {
      const compressedFile = await imageCompression(data.satpamkejadianfotolima[0], compressOptions);
      formData.append('satpamkejadianfotolima', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    await api
      .createKejadian(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        // setSubKategoriKode('');
        setKategoriKode('');
        setSatpamKode('');
        reset({
          satpamkejadianuraian: '',
          satpamkejadiantindakan: '',
          satpamkejadianhasil: '',
          satpamkejadianpelaku: '',
          satpamkejadiankorban: '',
          satpamkejadiansaksi: '',
          satpamkejadianstatus: '',
          satpamkejadianlatitude: '',
          satpamkejadianlongitude: '',
          satpamkejadianalamat: '',
          satpamkejadianalamatpatokan: '',
          satpamkejadianketeranganfotosatu: '',
          satpamkejadianketeranganfotodua: '',
          satpamkejadianketeranganfototiga: '',
          satpamkejadianketeranganfotoempat: '',
          satpamkejadianketeranganfotolima: '',
          satpamkejadianfotosatu: '',
          satpamkejadianfotodua: '',
          satpamkejadianfototiga: '',
          satpamkejadianfotoempat: '',
          satpamkejadianfotolima: ''
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 overflow-x-hidden md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="flex items-center justify-center w-full py-3 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-gray-50 shadow-black/40">
          Tambah Kejadian
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="flex flex-col w-full gap-5 mb-5">
          <div className="flex flex-wrap gap-5 text-sm lg:flex-nowrap">
            <div className="flex flex-col w-full gap-5">
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Personil</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={satpamDataOptions}
                      className="w-full"
                      placeholder="Pilih personil"
                      isSearchable={true}
                      defaultValue={satpamKode}
                      value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                      name="satpamkode"
                      required
                      onChange={(e) => setSatpamKode(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Uraian kejadian</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.satpamkejadianuraian ? 'invalidForm' : 'inputForm'}`} rows={3} {...register('satpamkejadianuraian')} />
                    <p className="text-xs text-errorText">{errors.satpamkejadianuraian?.message}</p>
                  </div>
                </div>
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Uraian tindakan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.satpamkejadiantindakan ? 'invalidForm' : 'inputForm'}`} rows={2} {...register('satpamkejadiantindakan')} />
                    <p className="text-xs text-errorText">{errors.satpamkejadiantindakan?.message}</p>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Hasil tindakan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.satpamkejadianhasil ? 'invalidForm' : 'inputForm'}`} rows={2} {...register('satpamkejadianhasil')} />
                    <p className="text-xs text-errorText">{errors.satpamkejadianhasil?.message}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Kategori</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={kategoriOptions}
                      className="w-full"
                      placeholder="Pilih kategori"
                      isSearchable={true}
                      defaultValue={kategoriKode}
                      value={kategoriOptions.filter((option) => option.value === kategoriKode)}
                      onChange={handleChangeKategori}
                      name="kategorikode"
                      required
                    />
                  </div>
                </div>
                {/* <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Sub kategori</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={subKategoriOptions}
                      className="w-full"
                      placeholder="Pilih sub kategori"
                      isSearchable={true}
                      value={subKategoriOptions.filter(
                        (option) => option.value === subKategoriKode
                      )}
                      name="subkategorikode"
                      required
                      onChange={(e) => setSubKategoriKode(e.value)}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="flex flex-col w-full gap-5">
              <div className="p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_aktifitas" className="block mb-1 font-semibold md:mb-0">
                      Tanggal kejadian
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative">
                      <DatePicker
                        selected={tanggalAktifitas}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalAktifitas(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                      Tanggal melaporkan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalMelaporkan}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalMelaporkan(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Status kejadian</label>
                  </div>
                  <div className="md:w-2/3">
                    <select className={`w-full flex flex-col font-normal ${errors.satpamkejadianstatus ? 'invalidForm' : 'inputForm'}`} {...register('satpamkejadianstatus')}>
                      <option disabled value="">
                        Pilih status kejadian
                      </option>
                      <option value={0}>Open</option>
                      <option value={1}>Close</option>
                    </select>
                    <p className="text-xs text-errorText">{errors.satpamkejadianstatus?.message}</p>
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Pelaku</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      placeholder="Pelaku1, pelaku2, dsb"
                      className={`w-full text-sm ${errors.satpamkejadianpelaku ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamkejadianpelaku')}
                    />
                    <p className="text-xs text-errorText">{errors.satpamkejadianpelaku?.message}</p>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Korban</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      placeholder="Korban1, korban2, dsb"
                      className={`w-full ${errors.satpamkejadiankorban ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamkejadiankorban')}
                    />
                    <p className="text-xs text-errorText">{errors.satpamkejadiankorban?.message}</p>
                  </div>
                </div>
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Saksi</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      placeholder="Saksi1, saksi2, dsb"
                      className={`w-full ${errors.satpamkejadiansaksi ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamkejadiansaksi')}
                    />
                    <p className="text-xs text-errorText">{errors.satpamkejadiansaksi?.message}</p>
                  </div>
                </div>
                <div className="items-center hidden md:flex">
                  <div className="hidden md:w-1/3">
                    <label className="hidden mb-1 font-semibold md:mb-0">Latitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input type="text" className="hidden w-full inputForm" {...register('satpamkejadianlatitude')} />
                  </div>
                </div>
                <div className="items-center hidden md:flex">
                  <div className="hidden md:w-1/3">
                    <label className="hidden mb-1 font-semibold md:mb-0">Longtitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input type="text" className="hidden w-full inputForm" {...register('satpamkejadianlongitude')} />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Patokan lokasi</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className="w-full inputForm" {...register('satpamkejadianalamatpatokan')} />
                  </div>
                </div>
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Alamat</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.satpamkejadianalamat ? 'invalidForm' : 'inputForm'}`} {...register('satpamkejadianalamat')} />
                    <p className="text-xs text-errorText">{errors.satpamkejadianalamat?.message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-5 rounded-lg bg-gray-50 shadowAll">
            <div className="mx-auto">
              <div className="flex flex-wrap -m-1 md:-m-2">
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamkejadianfotosatu')} accept="image/*" />
                        </label>
                        <p className="text-xs text-errorText">{errors.satpamkejadianfotosatu?.message}</p>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 1</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamkejadianketeranganfotosatu')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL2 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL2} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamkejadianfotodua')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 2</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamkejadianketeranganfotodua')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL3 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL3} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamkejadianfototiga')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 3</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamkejadianketeranganfototiga')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL4 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL4} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamkejadianfotoempat')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 4</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamkejadianketeranganfotoempat')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL5 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL5} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamkejadianfotolima')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 5</label>
                        <textarea className="w-full inputForm" rows={2} {...register('satpamkejadianketeranganfotolima')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full px-10 py-5 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <Link to="/kejadian">
              <button className="px-5 py-2 text-sm rounded-md btn text-gray-50 bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
