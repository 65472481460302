import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const perusahaanSlice = createSlice({
  name: 'perusahaan',
  initialState: {
    perusahaanKode: '',
    startDateAdmin: moment().startOf('month').format('YYYY-MM-DD'),
    endDateAdmin: moment().endOf('month').format('YYYY-MM-DD')
  },
  reducers: {
    setPerusahaan: (state, action) => {
      state.perusahaanKode = action.payload;
    },
    setDate: (state, action) => {
      state.startDateAdmin = action.payload.startDate;
      state.endDateAdmin = action.payload.endDate;
    }
  }
});

export const { setPerusahaan, setDate } = perusahaanSlice.actions;
export default perusahaanSlice.reducer;
