import React from 'react';
import TableCetakPatroli from './TableCetakPatroli';

export default function PatroliCetak({
  refPropWithAnotherName,
  data,
  date,
  dataPatroli,
  ruteDetail
}) {
  return (
    <div
      className="w-full bg-white text-[12px]"
      id="kegiatanHarianPrint"
      ref={refPropWithAnotherName}>
      <TableCetakPatroli dataPatroli={dataPatroli} ruteDetail={ruteDetail} date={date} />
    </div>
  );
}
