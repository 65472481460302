import { useLocation, Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ allowedRoles, authorized }) => {
  const location = useLocation();

  const profile = JSON.parse(localStorage.getItem('profile'));

  return allowedRoles.find((role) => profile?.user?.adminpetugastipe.includes(role)) &&
    authorized ? (
    <Outlet />
  ) : profile ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
