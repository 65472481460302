import React from 'react';

export default function Footer() {
  return (
    <footer className="mt-5 py-5 bg-primary text-center text-white text-xs">
      <p>Copyright&copy;{process.env.REACT_APP_YEAR}</p>
      <p>{process.env.REACT_APP_COMPANY}</p>
    </footer>
  );
}
