import React from 'react';

export default function PhotoCetak({ data, photo, keterangan }) {
  return (
    <>
      {photo?.split(data)[1] && (
        <div className="flex flex-wrap w-1/5">
          <div className="w-full p-1 md:p-2">
            <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all rounded-t-md ">
              <img className="h-36 object-contain w-full" src={photo} alt="" />
              <div className="p-3">
                <p className="mb-2 text-center">{keterangan && keterangan !== 'null' ? keterangan : '-'}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
