import moment from 'moment';
import React from 'react';
import { parseToNewLine } from '../../utils/parseToNewLine';
import Headerlaporan from '../general/HeaderLaporan';
import TimelineSatpamProses from '../general/TimelineSatpamProses';
import PhotoCetak from '../general/PhotoCetak';

const TableCetakKejadian = ({ data, date, kode }) => {
  return (
    <div className="w-full">
      {data.map((item) => (
        <div key={item.satpamkejadiankode}>
          {!kode && typeof date !== 'undefined' && (
            <h4 className="text-md p-2">
              <span className="font-bold">Periode : </span>
              {moment(date.startDate).format('DD MMM YYYY')} s.d {moment(date.endDate).format('DD MMM YYYY')}
            </h4>
          )}
          <div className="flex w-full">
            <Headerlaporan />
            <div className="w-full px-5 bg-black flex flex-col items-end justify-center text-white">
              <div className="text-[18px] font-bold">LAPORAN KEJADIAN</div>
              <div className="text-[14px]">{item.satpamperusahaannama}</div>
            </div>
          </div>
          <div className="m-5">
            <table className="border-gray-50" id="KejadianPrint">
              <thead className="bg-primary font-bold text-white text-[12px]">
                <tr>
                  <th colSpan="4" className="border border-gray-300 px-2 py-2 text-left">
                    <p className="font-semibold text-[18px]">#{item?.satpamkejadiankode}</p>
                  </th>
                </tr>
              </thead>
              <tbody className="text-black">
                <tr>
                  <td colSpan="2" className="w-[20%] border border-gray-300 px-2 py-2 text-left font-semibold" height="5px">
                    {moment(item?.satpamkejadiantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
                  </td>
                  <td rowSpan="4" className="border border-gray-300 px-2 py-2 text-left align-top">
                    <h4 className="font-semibold">DETAIL KEJADIAN</h4>
                    <p className="mb-2">{parseToNewLine(item?.satpamkejadianuraian)}</p>
                    <h4 className="font-semibold">TINDAKAN</h4>
                    <p className="mb-2">{parseToNewLine(item?.satpamkejadiantindakan)}</p>
                    <h4 className="font-semibold">HASIL TINDAKAN</h4>
                    <p>{parseToNewLine(item?.satpamkejadianhasil)}</p>
                  </td>
                  <td rowSpan="5" className="w-[20%] border border-gray-300 px-2 py-2 text-left align-top">
                    <h4 className="font-semibold mb-2">DETAIL PROSES</h4>
                    <TimelineSatpamProses data={item?.satpam_proses ? item?.satpam_proses : ''} />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="border border-gray-300 px-2 py-2 text-left align-top">
                    <span className="font-semibold">PATOKAN </span>: {item?.satpamkejadianalamatpatokan}
                    <br />
                    <span className="font-semibold">ALAMAT LENGKAP</span> : {item?.satpamkejadianalamat}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-2 py-2 text-left w-[20%] align-top">
                    <p className="font-semibold">KATEGORI :</p>
                    <p className="mb-2">{item?.satpamkejadiankategori}</p>
                    <p className="font-semibold">SUB KATEGORI :</p>
                    <p>{item?.satpamkejadiankategoridetail}</p>
                  </td>
                  <td rowSpan="2" className="border border-gray-300 px-2 py-2 text-left w-[15%] align-top">
                    <h4 className="text-md font-bold">PELAKU</h4>
                    <p className="mb-2">{item?.satpamkejadianpelaku}</p>
                    <h4 className="text-md font-bold">KORBAN</h4>
                    <p className="mb-2">{item?.satpamkejadiankorban}</p>
                    <h4 className="text-md font-bold">SAKSI</h4>
                    <p>{item?.satpamkejadiansaksi}</p>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-2 py-2 text-left align-top">
                    <h4 className="text-md font-bold">PELAPOR : {item?.satpamnamalengkap}</h4>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" className="border border-gray-300 p-5">
                    <div className="mx-auto">
                      <div className="flex flex-wrap -m-1 md:-m-2">
                        <PhotoCetak
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfotosatu}
                          keterangan={item?.satpamkejadianketeranganfotosatu}
                        />
                        <PhotoCetak
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfotodua}
                          keterangan={item?.satpamkejadianketeranganfotodua}
                        />
                        <PhotoCetak
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfototiga}
                          keterangan={item?.satpamkejadianketeranganfototiga}
                        />
                        <PhotoCetak
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfotoempat}
                          keterangan={item?.satpamkejadianketeranganfotoempat}
                        />
                        <PhotoCetak
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfotolima}
                          keterangan={item?.satpamkejadianketeranganfotolima}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="w-full">
                  <td colSpan="2" className="border border-gray-300 p-2 text-left align-top">
                    <p className="font-semibold">POTENSI KEJADIAN :</p>
                    <p>{item?.satpamkejadianpotensi && item?.satpamkejadianpotensi !== 'null' ? item?.satpamkejadianpotensi : 'Belum ada potensi'}</p>
                    <p className="font-semibold mt-2">ANALISIS KEJADIAN :</p>
                    <p>{item?.satpamkejadiananalisis && item?.satpamkejadiananalisis !== 'null' ? item?.satpamkejadiananalisis : 'Belum ada analisis'}</p>
                  </td>
                  <td className="border border-gray-300 p-2 text-left align-top">
                    <p className="font-semibold">KESIMPULAN KEJADIAN :</p>
                    <p>{item?.satpamkejadiankesimpulan && item?.satpamkejadiankesimpulan !== 'null' ? item?.satpamkejadiankesimpulan : 'Belum ada kesimpulan'}</p>
                    <p className="font-semibold mt-2">SARAN DAN REKOMENDASI :</p>
                    <p>{item?.satpamkejadianrekomendasi && item?.satpamkejadianrekomendasi !== 'null' ? item?.satpamkejadianrekomendasi : 'Belum ada rekomendasi'}</p>
                  </td>
                  <td className="border border-gray-300 px-2 py-2 text-center align-middle">
                    <div>Tanda Tangan</div>
                    <div className="border mt-16 mb-5 mx-5" />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-center w-full my-1.5 text-black">
              <b>Copyright&copy;{process.env.REACT_APP_YEAR}</b>
              <br />
              <b>{process.env.REACT_APP_COMPANY}</b>
            </div>
          </div>

          <div className="page-break" />
        </div>
      ))}
    </div>
  );
};

export default TableCetakKejadian;
