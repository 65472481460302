import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';

export default function ModalPetugasChangePassword({
  kode,
  openModalPassword,
  setOpenModalPassword
}) {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (password !== '' || passwordConfirm !== '') {
      if (password !== passwordConfirm) {
        setErrorMsg("Password doesn't match");
      } else {
        setErrorMsg('');
      }
    }
  }, [password, passwordConfirm]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    if (password !== '' || passwordConfirm !== '') {
      if (password === passwordConfirm) {
        await api
          .changePasswordPetugas({
            adminpetugaskode: kode,
            password: password
          })
          .then((res) => {
            setLoadingSubmit(false);
            Swal.fire('Good job!', 'You successfully submit the form!', 'success');
            setOpenModalPassword(false);
            setPassword('');
            setPasswordConfirm('');
          })
          .catch((error) => {
            setLoadingSubmit(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message
            });
            setPassword('');
            setPasswordConfirm('');
          });
      } else {
        setLoadingSubmit(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errorMsg
        });
        setPassword('');
        setPasswordConfirm('');
      }
    } else {
      setLoadingSubmit(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Silahkan isi form dengan benar'
      });
      setPassword('');
      setPasswordConfirm('');
    }
  };

  return (
    <Modal
      opened={openModalPassword}
      onClose={() => setOpenModalPassword(false)}
      size="lg"
      overflow="inside"
      centered
      closeOnClickOutside>
      <div className="text-center text-lg font-bold mb-5">Ganti Password Petugas</div>
      <form onSubmit={handleSubmit} className="w-full flex flex-col px-6">
        <div className="md:flex items-center mb-3">
          <div className="md:w-1/3">
            <label htmlFor="password" className="block font-semibold mb-1 md:mb-0">
              Password
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              type="password"
              className="inputForm w-full rounded py-2 px-3"
              name="password"
              placeholder="Masukkan password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="md:flex items-center">
          <div className="md:w-1/3">
            <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
              Konfirmasi Password
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              type="password"
              className="inputForm w-full rounded py-2 px-3"
              name="password_confirm"
              placeholder="Masukkan password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <p className="text-errorText">{errorMsg}</p>
          </div>
        </div>

        <div className="flex justify-between items-center w-full py-5 rounded-lg">
          <button
            type="submit"
            className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
            disabled={loadingSubmit === true ? true : false}>
            {loadingSubmit ? (
              <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
            ) : (
              'Simpan'
            )}
          </button>

          <button
            type="button"
            className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
            onClick={() => setOpenModalPassword(false)}>
            Kembali
          </button>
        </div>
      </form>
    </Modal>
  );
}
