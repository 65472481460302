import React from 'react';

export default function PhotoMail({ data, photo, keterangan }) {
  return (
    <>
      {photo?.split(data)[1] && (
        <div style={{ display: 'flex', width: '20%', flexWrap: 'wrap' }}>
          <div style={{ width: '100%', padding: '0.25rem' }}>
            <div
              style={{
                maxWidth: '24rem',
                wordBreak: 'break-all',
                borderRadius: '0.375rem',
                borderTopLeftRadius: '0.375rem',
                borderTopRightRadius: '0.375rem',
                borderWidth: '1px',
                borderColor: 'rgb(229, 231, 235)',
                backgroundColor: 'rgb(255, 255, 255)',
                boxShadow: '0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)'
              }}>
              <img style={{ height: '9rem', width: '100%', objectFit: 'contain' }} src={photo} alt="" />
              <div style={{ padding: '0.75rem' }}>
                <p style={{ marginBottom: '0.5rem' }}>{keterangan && keterangan !== 'null' ? keterangan : '-'}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
