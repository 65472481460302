import 'swiper/css';
import 'swiper/css/navigation';
import { Modal } from '@mantine/core';
import { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function ShowModalImage({ showImage, setShowImage, imageData }) {
  return (
    <Modal centered size="lg" opened={showImage} onClose={() => setShowImage(false)} className="overflow-auto bg-transparent -mt-10">
      <Swiper modules={[Navigation, Pagination, Scrollbar]} spaceBetween={0} slidesPerView={1} navigation pagination={{ clickable: true }} scrollbar={{ draggable: true }}>
        {imageData
          ?.filter((data) => data.image)
          .map((item, i) => (
            <SwiperSlide key={i}>
              {item.image && <img src={item.image} alt="" className="w-full object-contain rounded-t-lg flex items-center justify-center" />}
              {item.keterangan && (
                <div className="text-black font-bold text-center text-md rounded-b-lg py-2">{item.keterangan && item.keterangan !== 'null' ? item.keterangan : ''}</div>
              )}
            </SwiperSlide>
          ))}
      </Swiper>
    </Modal>
  );
}
