import React, { useEffect, useState } from 'react';
import { Modal } from '@mantine/core';
import * as api from '../../redux/api';
import { MdVerified } from 'react-icons/md';

const ModalKtuDetail = ({ openModalDetail, setOpenModalDetail, id }) => {
  const [dataKrama, setDataKrama] = useState(['']);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDataKTU = async () => {
      setLoading(true);
      try {
        const res = await api.getDetailKTU(id);
        setDataKrama(res.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getDataKTU();
  }, [id]);

  return (
    <>
      {loading ? (
        <p className="item-center justify-center flex text-sm">Loading..</p>
      ) : (
        <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
          <h2 className="text-center text-lg font-bold mb-5">
            Detail Informasi <br />
            Krama Tamiu Usaha
          </h2>
          <div className="flex justify-center gap-5 mb-5">
            <div>
              <h2 className="text-center font-semibold mb-2">Foto Pemilik</h2>
              {dataKrama?.foto_selfie?.split('/data-usaha/')[1].length > 0 ? (
                <img src={dataKrama?.foto_selfie} alt="" className="w-full h-56 object-contain mb-5 rounded-xl" />
              ) : (
                <img src={require('../../images/logo.png')} alt="" className="w-full h-56 object-contain mb-5 rounded-xl" />
              )}
            </div>
            <div>
              <h2 className="text-center font-semibold mb-2">Foto Usaha</h2>
              {dataKrama?.foto_usaha?.split('/data-usaha/')[1].length > 0 ? (
                <img src={dataKrama?.foto_usaha} alt="" className="w-full h-56 object-contain mb-5 rounded-xl" />
              ) : (
                <img src={require('../../images/logo.png')} alt="" className="w-full h-56 object-contain mb-5 rounded-xl" />
              )}
            </div>
          </div>
          <div className="text-dark scrollbar-thin px-5">
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nama Pemilik</div>
              <div className="w-full text-black">{dataKrama?.satpamnamalengkap || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Jenis Kelamin</div>
              <div className="w-full text-black">{dataKrama?.satpamjeniskelamin || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">No Hp</div>
              <div className="w-full text-black">{dataKrama?.phone || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kewarganegaraan</div>
              <div className="w-full text-black">{dataKrama?.jenis_kewarganegaraan || '-'}</div>
            </div>{' '}
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">No Identitas</div>
              <div className="w-full text-black">{dataKrama?.nomor_identitas || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nama Usaha</div>
              <div className="w-full text-black">{dataKrama?.nama || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Jenis Usaha</div>
              <div className="w-full text-black">{dataKrama?.jenis_usaha || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Izin Usaha</div>
              <div className="w-full text-black">{dataKrama?.izin_usaha || '-'}</div>
            </div>{' '}
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nomor Usaha</div>
              <div className="w-full text-black">{dataKrama?.izin_nomor || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Banjar</div>
              <div className="w-full text-black">{dataKrama?.banjar_name || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Alamat</div>
              <div className="w-full text-black">{dataKrama?.alamat || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Verifikasi</div>
              <div className="w-full text-black">
                {dataKrama?.is_verified === 1 ? (
                  <p className="flex gap-x-3">
                    <MdVerified size={16} className="my-auto" />
                    <span className="text-black">Terverifikasi</span>
                  </p>
                ) : (
                  'Tidak Terverifikasi'
                )}
              </div>
            </div>{' '}
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Status</div>
              <div className="w-full text-black">
                {dataKrama?.satpamstatus === 'Aktif' ? (
                  <p className="py-1 px-1.5  text-sm border-2 border-blue-500 rounded-md w-fit font-medium">
                    <span className="text-black">Aktif</span>
                  </p>
                ) : (
                  <p className="py-1 px-1.5  text-sm border-2 border-blue-500 rounded-md w-fit font-medium">
                    <span className="text-black">Tidak Aktif</span>
                  </p>
                )}
              </div>
            </div>{' '}
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Status Kepemilikan</div>
              <div className="w-full text-black">{dataKrama?.status_kepemilikan || '-'}</div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalKtuDetail;
