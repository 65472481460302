import React from 'react';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';

export default function BtnAdd({ path }) {
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  return (
    <>
      {adminType === 'Admin Project' && (
        <Tooltip label="Tambah laporan">
          <Link to={path}>
            <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
              <AiOutlineFileAdd size={24} />
            </div>
          </Link>
        </Tooltip>
      )}
    </>
  );
}
