import BtnAdd from '../../components/general/BtnAdd';
import { useDebouncedValue } from '@mantine/hooks';
import { useState, useEffect } from 'react';
import moment from 'moment';
// Import files
import * as api from '../../redux/api';
import BtnAction from '../../components/general/BtnAction';
import LoadingPage from '../../components/general/LoadingPage';
import Table from '../../components/general/Table';
import ModalKtuDetail from '../../components/personil/ModalKtuDetail';
import QRCode from 'qrcode.react';
import ModalQrDetails from '../../components/general/ModalQrDetails';

const parse = require('html-react-parser');

const KramaUsaha = () => {
  const [data, setData] = useState(['']);
  const [filterData, setFilterData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [openModalQR, setOpenModalQR] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [detailId, setDetailId] = useState('');
  const [qrDetail, setQrDetail] = useState({});
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.getKTU().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(({ banjar_name, role, jenis_usaha, nama, satpamnamalengkap, titik_code }) => {
        if (
          debouncedQuery !== '' &&
          !`${banjar_name} ${role}  ${jenis_usaha} ${nama}${satpamnamalengkap}${titik_code} `.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  // const handleImageData = ({ satpaminformasigambar }) => {
  //   setImageData(satpaminformasigambar?.split('/data-informasi/')[1] ? satpaminformasigambar : require('../../images/logo.png'));
  //   setShowImage(!showImage);
  // };

  const handleOpenModalDetail = (id) => {
    setDetailId(id);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleOpenModalQR = ({ kode, nama }) => {
    setQrDetail({ kode, nama });
    setOpenModalQR(true);
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'id',
      width: 100,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ id }) => (
        <BtnAction
          showPopover={showPopover}
          HideDelete={true}
          setShowPopover={setShowPopover}
          handleOpenModalDetail={handleOpenModalDetail}
          data={id}
          id={{ id }}
          noPrint={true}
          page="/personil/usaha"
        />
      )
    },

    {
      title: 'Nama Usaha',
      accessor: 'nama',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Pemilik Usaha',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Jenis Usaha',
      accessor: 'jenis_usaha',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Titik QR',
      accessor: 'titik_code',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ titik_code, nama }) => {
        const codeqr = titik_code;
        return (
          <div>
            <div
              className="flex justify-center text-center h-auto w-full py-0 my-0 mb-1 cursor-pointer"
              onClick={() =>
                handleOpenModalQR({
                  kode: codeqr,
                  nama: nama
                })
              }>
              <QRCode
                className=" max-w-max  "
                value={codeqr}
                level="L"
                lengthAdjust={'l'}
                imageSettings={{ src: '/logo.png', x: undefined, y: undefined, height: 25, width: 25, excavate: true }}
              />
            </div>
          </div>
        );
      },
      width: 150
    },
    {
      title: 'Nama Paket',
      accessor: 'role',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ role }) => {
        const JeniPaket = () => {
          if (role === 'Krama Tamiu & Usaha 1') {
            return 'Bronze';
          } else if (role === 'Krama Tamiu & Usaha 2') {
            return 'Silver';
          } else if (role === 'Krama Tamiu & Usaha 3') {
            return 'Gold';
          } else {
            return 'Tidak Berlangganan';
          }
        };
        return JeniPaket();
      },
      width: 250
    },
    {
      title: 'Banjar',
      accessor: 'banjar_name',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 100
    },
    {
      title: 'Tanggal, Status',
      accessor: 'satpamstatus',
      width: 180,
      cellsStyle: {
        verticalAlign: 'top'
      },
      textAlignment: 'center',
      render: ({ created_at, satpamstatus }) => (
        <div>
          <div className="flex justify-center w-full h-auto py-0 my-0 mb-1 cursor-pointer">{moment(created_at).format('DD MMM YYYY')}</div>
          {satpamstatus === 'Aktif' ? (
            <div className="block w-full mb-2 text-sm text-center border-2 rounded-full border-primary text-primary btnStatus">Aktif</div>
          ) : (
            <div className="block w-full mb-2 text-sm text-center border-2 rounded-full border-secondary text-secondary btnStatus">Tidak Aktif</div>
          )}
        </div>
      )
    }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div
      className="relative w-full px-4 mx-auto -mt-8  overflow-x-hidden md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300"
      onClick={() => showPopover && setShowPopover('')}>
      <div className="w-full min-h-screen mb-10">
        <div className="justify-center w-full pb-8 rounded-lg bg-gray-50 shadowAll">
          <div className="flex flex-wrap items-center lg:flex-nowrap justify-beetween">
            <div className="flex flex-col flex-wrap items-center justify-start w-full md:flex-row"></div>
            <div className="flex justify-end px-5 my-3">
              <div className="flex w-full gap-4">
                <BtnAdd path="/personil/usaha/create" />
              </div>
            </div>
          </div>
          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5 mb-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data informasi.</div>
            </div>
          )}
        </div>
      </div>
      <ModalKtuDetail id={detailId} setOpenModalDetail={setOpenModalDetail} openModalDetail={openModalDetail} />
      <ModalQrDetails openModalQR={openModalQR} setOpenModalQR={setOpenModalQR} qrDetail={qrDetail} name={'Qr_Usaha'} />
    </div>
  );
};
export default KramaUsaha;
