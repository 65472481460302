import React, { useEffect, useState, useRef } from 'react';
import * as api from '../../redux/api';
import { AiFillPrinter } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import PatroliCetakPerPat from '../../components/patroli/PatroliCetakPerPat';
import TableCetakPatroliPerPat from '../../components/patroli/TableCetakPatroliPerPat';
import { Helmet } from 'react-helmet';

export default function PatroliPrintPerPat() {
  const [dataPatroli, setDataPatroli] = useState([]);
  const [ruteDetail, setRuteDetail] = useState([]);

  const { kode } = useParams();

  const componentRef = useRef();

  useEffect(() => {
    // Ambil data patroli
    const getDataPatroli = async () => {
      await api
        .patroliDetail({ patroli_kode: kode })
        .then((resDetailPatroli) => {
          setDataPatroli(resDetailPatroli.data);

          // Ambil data detail titik
          resDetailPatroli.data.Detail.map((titik_detail) => {
            const getDetailTitik = async () => {
              await api
                .patroliDetailRute(titik_detail.satpampatrolidetailkode)
                .then((resDetailTitik) => {
                  setRuteDetail((prev) => [...prev, resDetailTitik.data.Data]);
                });
            };
            return getDetailTitik();
          });
        })
        .catch((err) => console.log(err.message));
    };
    getDataPatroli();
  }, [kode]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <div className="absolute top-0 right-0 bottom-0 z-20 h-screen w-screen overflow-y-auto bg-white text-dark">
        <div className="flex p-8 justify-center items-center flex-col text-center">
          <div className="font-bold text-xl">Preview Cetak Patroli</div>
          <div className="flex gap-5 mb-3">
            <ReactToPrint
              trigger={() => (
                <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
                  <AiFillPrinter size={18} color="white" />
                </button>
              )}
              content={() => componentRef.current}
            />
            <div className="hidden">
              <PatroliCetakPerPat
                refPropWithAnotherName={componentRef}
                dataPatroli={dataPatroli}
                ruteDetail={ruteDetail}
              />
            </div>
            <Link to="/patroli">
              <button className="btnAksi h-fit py-[7px] mt-1 text-[13px]">Kembali</button>
            </Link>
          </div>
          <div className="w-full border-4 border-primary p-3">
            <TableCetakPatroliPerPat dataPatroli={dataPatroli} ruteDetail={ruteDetail} />
          </div>
        </div>
      </div>
    </>
  );
}
