import React from 'react';
import { parseToNewLine } from '../../utils/parseToNewLine';
import HeaderlaporanMail from '../general/HeaderLaporanMail';
import TimelineSatpamProses from '../general/TimelineSatpamProses';
import moment from 'moment';
import PhotoMail from '../general/PhotoMail';

const TableCetakKejadianMail = ({ data }) => {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {data.map((item) => (
        <div key={item.satpamkejadiankode}>
          <div style={{ display: 'flex', width: '100%' }}>
            <HeaderlaporanMail />
            <div
              style={{
                width: ' 100%',
                padding: '0.7rem',
                textAlign: 'right',
                backgroundColor: '#0f172a',
                textColor: 'white'
              }}>
              <div
                style={{
                  display: 'block',
                  fontSize: '18px',
                  fontWeight: '700',
                  color: 'rgb(243, 244, 246)'
                }}>
                LAPORAN KEJADIAN
              </div>
              <div
                style={{
                  display: 'block',
                  fontSize: '15px',
                  fontWeight: '700',
                  color: 'rgb(243, 244, 246)'
                }}>
                {item.satpamperusahaannama}
              </div>
            </div>
          </div>
          <div style={{ margin: '1.25rem' }}>
            <table style={{ borderColor: 'rgb(249, 250, 251)' }} id="kegiatanHarianPrint">
              <thead
                style={{
                  backgroundColor: 'rgb(5, 45, 90)',
                  fontSize: '12px',
                  fontWeight: 700,
                  color: 'rgb(255, 255, 255)'
                }}>
                <tr>
                  <th
                    colSpan={4}
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left'
                    }}>
                    <p style={{ fontSize: '18px', fontWeight: 600 }}>#{item?.satpamkejadiankode}</p>
                  </th>
                </tr>
              </thead>
              <tbody style={{ color: 'rgb(0, 0, 0)' }}>
                <tr>
                  <td
                    colSpan={2}
                    style={{
                      width: '20%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left',
                      fontWeight: 600
                    }}
                    height="5px">
                    {moment(item?.satpamkejadiantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
                  </td>
                  <td
                    rowSpan={4}
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <h4 style={{ fontWeight: 600 }}>DETAIL KEJADIAN</h4>
                    <p style={{ marginBottom: '0.5rem' }}>{parseToNewLine(item?.satpamkejadianuraian)}</p>
                    <h4 style={{ fontWeight: 600 }}>TINDAKAN</h4>
                    <p style={{ marginBottom: '0.5rem' }}>{parseToNewLine(item?.satpamkejadiantindakan)}</p>
                    <h4 style={{ fontWeight: 600 }}>HASIL TINDAKAN</h4>
                    <p>{parseToNewLine(item?.satpamkejadianhasil)}</p>
                  </td>
                  <td
                    rowSpan={5}
                    style={{
                      width: '20%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <h4 style={{ marginBottom: '0.5rem', fontWeight: 600 }}>DETAIL PROSES</h4>
                    <TimelineSatpamProses data={item?.satpam_proses ? item?.satpam_proses : ''} />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <span style={{ fontWeight: 600 }}>PATOKAN </span>: {item?.satpamkejadianalamatpatokan}
                    <br />
                    <span style={{ fontWeight: 600 }}>ALAMAT LENGKAP</span> : {item?.satpamkejadianalamat}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '20%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <p style={{ fontWeight: 600 }}>KATEGORI :</p>
                    <p style={{ marginBottom: '0.5rem' }}>{item?.satpamkejadiankategori}</p>
                    <p style={{ fontWeight: 600 }}>SUB KATEGORI :</p>
                    <p>{item?.satpamkejadiankategoridetail}</p>
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: '15%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <h4 style={{ fontWeight: 700 }}>PELAKU</h4>
                    <p style={{ marginBottom: '0.5rem' }}>{item?.satpamkejadianpelaku}</p>
                    <h4 style={{ fontWeight: 700 }}>KORBAN</h4>
                    <p style={{ marginBottom: '0.5rem' }}>{item?.satpamkejadiankorban}</p>
                    <h4 style={{ fontWeight: 700 }}>SAKSI</h4>
                    <p>{item?.satpamkejadiansaksi}</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <h4 style={{ fontWeight: 700 }}>PELAPOR : {item?.satpamnamalengkap}</h4>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '1.25rem'
                    }}>
                    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <div
                        style={{
                          margin: '-0.25rem',
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}>
                        <PhotoMail
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfotosatu}
                          keterangan={item?.satpamkejadianketeranganfotosatu}
                        />
                        <PhotoMail
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfotodua}
                          keterangan={item?.satpamkejadianketeranganfotodua}
                        />
                        <PhotoMail
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfototiga}
                          keterangan={item?.satpamkejadianketeranganfototiga}
                        />
                        <PhotoMail
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfotoempat}
                          keterangan={item?.satpamkejadianketeranganfotoempat}
                        />
                        <PhotoMail
                          data="/data-kejadian/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkejadianfotolima}
                          keterangan={item?.satpamkejadianketeranganfotolima}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr style={{ width: '100%' }}>
                  <td
                    colSpan={2}
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <p style={{ fontWeight: 600 }}>POTENSI KEJADIAN :</p>
                    <p>{item?.satpamkejadianpotensi !== null ? item?.satpamkejadianpotensi : 'Belum ada potensi'}</p>
                    <p style={{ marginTop: '0.5rem', fontWeight: 600 }}>ANALISIS KEJADIAN :</p>
                    <p>{item?.satpamkejadiananalisis !== null ? item?.satpamkejadiananalisis : 'Belum ada analisis'}</p>
                  </td>
                  <td
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <p style={{ fontWeight: 600 }}>KESIMPULAN KEJADIAN :</p>
                    <p>{item?.satpamkejadiankesimpulan !== null ? item?.satpamkejadiankesimpulan : 'Belum ada kesimpulan'}</p>
                    <p style={{ marginTop: '0.5rem', fontWeight: 600 }}>SARAN DAN REKOMENDASI :</p>
                    <p>{item?.satpamkejadianrekomendasi !== null ? item?.satpamkejadianrekomendasi : 'Belum ada rekomendasi'}</p>
                  </td>
                  <td
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      textAlign: 'center',
                      verticalAlign: 'middle'
                    }}>
                    <div>Tanda Tangan</div>
                    <div
                      style={{
                        marginLeft: '1.25rem',
                        marginRight: '1.25rem',
                        marginTop: '4rem',
                        marginBottom: '1.25rem',
                        border: '1px solid #c0c0c0'
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                marginTop: '0.25rem',
                marginBottom: '0.25rem',
                width: '100%',
                textAlign: 'center',
                color: 'rgb(0, 0, 0)'
              }}>
              <b>Copyright&copy;{process.env.REACT_APP_YEAR}</b>
              <br />
              <b>{process.env.REACT_APP_COMPANY}</b>
            </div>
          </div>

          <div className="page-break" />
        </div>
      ))}
    </div>
  );
};

export default TableCetakKejadianMail;
