import React, { useEffect, useState } from 'react';
import { Modal } from '@mantine/core';
import Select from 'react-select';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';

const ModalAssignKeluhan = ({ refetch, keluhanID, isOpen, setModal }) => {
  const [data, setData] = useState([]);
  const [selectedPetugas, setSelectedPetugas] = useState('');
  const getData = async () => {
    await api.personilListByType('pecalang').then((response) => setData(response.data.data));
  };

  const optionsData =
    data &&
    data?.map((value) => {
      return {
        label: value.satpamnamalengkap,
        value: value.satpamkode
      };
    });

  const handleSendData = async () => {
    try {
      const payload = {
        satpamkode: selectedPetugas,
        keluhanid: keluhanID
      };

      const { status } = await api.assignKeluhan(payload);

      if (status === 200) {
        setModal(false);
        Swal.fire('Good job!', 'Berhasil assign keluhan ke petugas', 'success').then((result) => {
          if (result.isConfirmed || result.isDenied || result.isDismissed) {
            refetch();
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message
      });
    }
  };

  useEffect(() => {
    getData();
  }, [isOpen]);

  return (
    <Modal opened={isOpen} onClose={() => setModal(false)} centered>
      <h1 className="mb-1 text-lg font-bold text-center">Ajukan Petugas</h1>

      <div className="mt-5">
        <p>Pilih Petugas</p>
        <Select
          options={optionsData}
          placeholder="Pilih Petugas"
          onChange={(event) => {
            setSelectedPetugas(event.value);
          }}
        />
      </div>

      <div className="flex flex-row justify-between mt-5">
        <button
          className="w-32 h-10 p-2 text-white bg-black rounded-md"
          onClick={() => {
            setModal(false);
          }}>
          Batalkan
        </button>
        <button
          className="w-40 h-10 p-2 text-white bg-black rounded-md"
          disabled={selectedPetugas.length === 0}
          onClick={() => {
            handleSendData();
          }}>
          Tugaskan Petugas
        </button>
      </div>
    </Modal>
  );
};

export default ModalAssignKeluhan;
