import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';

export default function InputMultipleMail({ emails, setEmails }) {
  const [error, setError] = useState('');

  // Add or remove emails by using the key
  const handleEmails = (event) => {
    if (
      (event.key === 'Enter' && event.target.value !== '') ||
      (event.keyCode === 32 && event.target.value !== '')
    ) {
      setEmails([...emails, event.target.value]);
      event.target.value = '';
    } else if (event.keyCode === 8 && emails.length && event.target.value === '') {
      const emailsCopy = [...emails];
      emailsCopy.pop();
      event.preventDefault();
      setEmails(emailsCopy);
    } else if (emails.length < 1 && event.keyCode === 8) {
      setError("Since there is no emails you can't able to delete any emails");
    }
  };

  //Remove emails by clicking the cross sign
  const removeEmails = (index) => {
    setEmails([...emails.filter((tag) => emails.indexOf(tag) !== index)]);
  };

  //To update the error after user changes something
  const handleError = () => {
    setError('');
  };

  return (
    <>
      <div className="w-full flex flex-wrap overflow-hidden border border-gray-300 rounded-md">
        {emails.map((email, index) => (
          <div
            className="list-none flex items-center px-2 mt-2 ml-2 border bg-primary rounded whitespace-nowrap text-gray-300"
            key={index}>
            <span className="pr-2">{email}</span>
            <i onClick={() => removeEmails(index)}>
              <MdClose className="cursor-pointer" />
            </i>
          </div>
        ))}
        <input
          type="text"
          className="w-full outline-none mx-2 m-2 px-2"
          onKeyDown={(event) => handleEmails(event)}
          onBlur={(event) => {
            if (event.target.value !== '') {
              setEmails([...emails, event.target.value]);
              event.target.value = '';
            }
          }}
          onChange={handleError}
          placeholder="Masukkan email dan tekan enter / spasi"
        />
      </div>
      <div className="text-errorText">{error}</div>
    </>
  );
}
