import React, { useState, useEffect } from 'react';
import { Modal } from '@mantine/core';
import * as api from '../../redux/api';
import moment from 'moment';

export default function ModalDetailListResponse({ open, onClose, id }) {
  const [data, setData] = useState({});
  const [showImage, setShowImage] = useState(false);
  const [response, setRes] = useState('');
  useEffect(() => {
    if (id !== '' && open) {
      const getDetailList = async () => {
        await api.getDetailListClient(id).then((res) => {
          if (res.data.data) {
            setData(res.data.data);
            setRes('');
          } else {
            setRes(res.data.message);
          }
        });
      };
      getDetailList();
    }
  }, [id, open]);
  const handleImageData = () => {
    setShowImage(!showImage);
  };

  return (
    <Modal
      opened={open}
      size={'55%'}
      onClose={() => {
        onClose(false);
      }}
      centered>
      <h1 className="text-center capitalize font-bold text-xl mb-4">Detail Laporan Keadaan Darurat</h1>
      <div className="flex flex-col gap-2 w-full lg:max-w-[966px]">
        <div className="flex flex-col md:flex-row">
          <p className="capitalize text-sm font-semibold w-full 2xl:w-1/5">tanggal</p>
          <p className="text-sm w-full">{moment(data.created_at).format('DD MMM YYYY HH:mm')}</p>
        </div>
        <div className="flex flex-col md:flex-row">
          <p className="capitalize text-sm font-semibold w-full 2xl:w-1/5">kode laporan</p>
          <p className="text-sm w-full">{data.id}</p>
        </div>
        <div className="flex flex-col md:flex-row">
          <p className="capitalize text-sm font-semibold w-full 2xl:w-1/5">pengirim</p>
          <p className="capitalize text-sm w-full">{data.perusahaan_request_code ? data.perusahaan_request_code : '-'}</p>
        </div>
        <div className="flex flex-col md:flex-row">
          <p className="capitalize text-sm font-semibold w-full 2xl:w-1/5">kategori</p>
          <p className="capitalize text-sm w-full">{data.type ? data.type : '-'}</p>
        </div>
        <div className="flex flex-col md:flex-row">
          <p className="capitalize text-sm font-semibold w-full 2xl:w-1/5">pesan</p>
          <p className="capitalize text-sm w-full">{data.content ? data.content : '-'}</p>
        </div>
        <div className="flex flex-col md:flex-row">
          <p className="capitalize text-sm font-semibold w-full 2xl:w-1/5">lokasi</p>
          <p className="capitalize text-sm w-full">{data.address ? data.address : '-'}</p>
        </div>
        <div className="flex flex-col md:flex-row">
          <p className="capitalize text-sm font-semibold w-full 2xl:w-1/5">foto</p>
          <div className="w-full">
            {data.images ? <img src={data.images} alt="foto" className="w-full rounded-md md:w-[346px]" /> : <p className="text-sm">Data Not Found</p>}
          </div>
        </div>

        <h1 className="py-4 border-t capitalize text-xl font-bold mt-3 text-center">Respon Keadaan Darurat</h1>
        {data.tim?.length > 0 ? (
          <div>
            {data.tim?.map((val, index) => (
              <React.Fragment key={index}>
                <div className="flex flex-col md:flex-row">
                  {/* <p className="capitalize text-sm font-semibold w-full 2xl:w-1/5">tanggal</p>
                  <p className="text-sm w-full">{val.created_at ? moment(val.created_at).format('DD MMM YYYY HH:mm') : 'No Response'}</p> */}
                </div>

                <div className="flex flex-col md:flex-row">
                  <p className="capitalize text-sm font-semibold w-full 2xl:w-1/5">Tim respon</p>
                  <p className="text-sm w-full">{val.name}</p>
                </div>
                <div className="p-4">
                  <table width={'100%'}>
                    <thead>
                      <tr className="w-full">
                        <th className="border p-2 text-center">
                          <p className="capitalize">nama petugas</p>
                        </th>

                        <th className="border p-2 text-center">
                          <p className="capitalize">tindakan</p>
                        </th>

                        <th className="border p-2 text-center">
                          <p className="capitalize">foto tindakan</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {val.anggota?.map((v) => (
                        <tr key={v.id}>
                          <td className="border p-2 text-center" width={344}>
                            <p className="flex gap-x-2">
                              {v.nama}
                              {v.is_chief === 1 && <span className="text-xs mx-auto my-auto ">(Ketua)</span>}
                            </p>
                          </td>
                          {v.response.length > 0 ? (
                            <>
                              {v.response?.map((val1, index) => (
                                <React.Fragment key={index}>
                                  <td className="border p-2 text-center" width={344}>
                                    <p>{val1.content} </p>
                                  </td>
                                  <td className="border p-2 cursor-pointer" width={344} onClick={() => handleImageData()}>
                                    <img src={val1?.images} alt="foto" className="max-h-[116px] mx-auto rounded-md" />
                                  </td>
                                  <Modal centered size="xl" opened={showImage} onClose={() => setShowImage(false)} className="overflow-auto bg-transparent -mt-10">
                                    {/* Content image */}
                                    {val1?.images && <img src={val1?.images} alt="" className="w-full object-contain rounded-t-lg flex items-center justify-center" />}
                                  </Modal>
                                </React.Fragment>
                              ))}
                            </>
                          ) : (
                            <>
                              <td className="border p-2 text-center" width={344}>
                                <p>-</p>
                              </td>
                              <td className="border p-2" width={344}>
                                -
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className=" p-2 border text-center text-sm">No Data</div>
        )}
      </div>
    </Modal>
  );
}
