import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
// import { AiFillFileText } from 'react-icons/ai';
import kriminal_umum from '../../images/icons/1.svg';
import perda from '../../images/icons/2.svg';
import terorisme from '../../images/icons/3.svg';
import prostitusi from '../../images/icons/4.svg';
import tanpa_identitas from '../../images/icons/5.svg';
import ancaman from '../../images/icons/6.svg';
import konflik_adat from '../../images/icons/7.svg';
import perusakan_lingkungan from '../../images/icons/8.svg';
import ancaman_kelestarian from '../../images/icons/9.svg';
import penyakit_menular from '../../images/icons/10.svg';

export default function CardDashboard({ data, loading }) {
  data.kategori_name === 'Tamiu Tanpa Identitas' && console.log('test');

  return (
    // <>
    //   {dasaBaya
    //     .filter((item) => item.kode === data.kategori_kode)
    //     .map((picture, i) => (
    //       <div key={i} className="flex flex-col justify-between rounded-lg mt-10 md:w-[18%] h-[110px] p-2 relative shadow-lg border-2 border-blue-500">
    //         <div className="absolute bg-blue-700 p-2 rounded-md -mt-8 ml-5 shadow-lg">
    //           {picture.icon ? <img src={picture.icon} alt="" className="w-[30px] h-[30px] object-contain" /> : <AiOutlineFilePpt className="w-[30px] h-[30px]" />}
    //         </div>
    //         <div className="text-center text-sm leading-none mt-5 whitespace-break-spaces text-black">{data.kategori_name}</div>
    //         <div className="text-center text-[32px] text-black">{!loading ? data.jumlah : <ClipLoader color="black" loading={true} size={20} speedMultiplier={0.7} />}</div>
    //       </div>
    //     ))}
    // </>

    <div className="flex flex-col justify-between rounded-lg mt-10 w-full sm:w-[130px] xl:w-[18%] h-[110px] p-2 relative shadow-lg border-2 border-primary">
      <div className="absolute bg-red-700 p-2 rounded-md -mt-8 ml-5 shadow-lg w-[45px] h-[45px] flex justify-center items-center">
        {data.kategori_name === 'Desa Adat' && <i className="fa-solid fa-house fa-xl"></i>}
        {data.kategori_name === 'Hotel' && <i className="fa-solid fa-hotel fa-xl"></i>}
        {data.kategori_name === 'BUPDA' && <i className="fa-solid fa-building fa-xl"></i>}
        {data.kategori_name === 'Daerah Tujuan Wisata' && <i className="fa-solid fa-umbrella-beach fa-xl"></i>}
        {data.kategori_name === 'Lain-lain' && <i className="fa-solid fa-ethereum fa-xl"></i>}
        {data.kategori_name === 'Ancaman Orang' && <i className="fa-solid fa-person-harassing fa-xl"></i>}
        {data.kategori_name === 'Ancaman Aset' && <i className="fa-solid fa-wallet fa-xl"></i>}
        {data.kategori_name === 'Ancaman Informasi' && <i className="fa-solid fa-square-rss fa-xl"></i>}
        {data.kategori_name === 'Bencana Industri' && <i className="fa-solid fa-industry fa-xl"></i>}
        {data.kategori_name === 'Gangguan Sipil' && <i className="fa-solid fa-triangle-exclamation fa-xl"></i>}
        {data.kategori_name === 'Ancaman Teroris' && <i className="fa-solid fa-user-ninja fa-xl"></i>}
        {data.kategori_name === 'Instabilitas Sosial' && <i className="fa-solid fa-hand-fist fa-xl"></i>}
        {data.kategori_name === 'Bencana Alam' && <i className="fa-solid fa-volcano fa-xl"></i>}
        {data.kategori_name === 'Keselamatan Transportasi' && <i className="fa-solid fa-car-burst fa-xl"></i>}
        {data.kategori_name === 'Unsafe Action' && <i className="fa-solid fa-person-circle-exclamation fa-xl"></i>}
        {data.kategori_name === 'Lost & Found' && <i className="fa-solid fa-boxes-packing fa-xl"></i>}
        {data.kategori_name === 'Unsafe Condition' && <i className="fa-solid fa-temperature-arrow-down fa-xl"></i>}
        {data.kategori_name === 'Keadaan Darurat' && <i className="fa-solid fa-bell fa-xl"></i>}
        {data.kategori_name === 'Penutupan Lalu Lintas' && <i className="fa-solid fa-road-barrier fa-xl"></i>}
        {data.kategori_name === 'Kriminal Umum' && <img src={kriminal_umum} alt="" className="w-[30px] h-[30px] object-contain" />}
        {data.kategori_name === 'Pelanggaran Perda' && <img src={perda} alt="" className="w-[30px] h-[30px] object-contain" />}
        {data.kategori_name === 'Radikalisme & Terorisme' && <img src={terorisme} alt="" className="w-[30px] h-[30px] object-contain" />}
        {data.kategori_name === 'Narkoba, Prostitusi & Premanisme' && <img src={prostitusi} alt="" className="w-[30px] h-[30px] object-contain" />}
        {data.kategori_name === 'Tamiu Tanpa Identitas' && <img src={tanpa_identitas} alt="" className="w-[30px] h-[30px] object-contain" />}
        {data.kategori_name === 'Propaganda & Ancaman' && <img src={ancaman} alt="" className="w-[30px] h-[30px] object-contain" />}
        {data.kategori_name === 'Kekerasan Konflik Adat' && <img src={konflik_adat} alt="" className="w-[30px] h-[30px] object-contain" />}
        {data.kategori_name === 'Pencemaran Lingkungan' && <img src={perusakan_lingkungan} alt="" className="w-[30px] h-[30px] object-contain" />}
        {data.kategori_name === 'Ancaman Kelestarian' && <img src={ancaman_kelestarian} alt="" className="w-[30px] h-[30px] object-contain" />}
        {data.kategori_name === 'Bahaya Penyakit Menular' && <img src={penyakit_menular} alt="" className="w-[30px] h-[30px] object-contain" />}

        {/* {data.kategori_name === 'Desa Adat' ? (
          <i className="fa-solid fa-house fa-xl"></i>
        ) : data.kategori_name === 'Hotel' ? (
          <i className="fa-solid fa-hotel fa-xl"></i>
        ) : data.kategori_name === 'BUPDA' ? (
          <i className="fa-solid fa-building fa-xl"></i>
        ) : data.kategori_name === 'Daerah Tujuan Wisata' ? (
          <i className="fa-solid fa-umbrella-beach fa-xl"></i>
        ) : data.kategori_name === 'Lain-lain' ? (
          <i className="fa-brands fa-ethereum fa-xl"></i>
        ) : data.kategori_name === 'Ancaman Orang' ? (
          <i className="fa-solid fa-person-harassing fa-xl"></i>
        ) : data.kategori_name === 'Ancaman Aset' ? (
          <i className="fa-solid fa-wallet fa-xl"></i>
        ) : data.kategori_name === 'Ancaman Informasi' ? (
          <i className="fa-solid fa-square-rss fa-xl"></i>
        ) : data.kategori_name === 'Bencana Industri' ? (
          <i className="fa-solid fa-industry fa-xl"></i>
        ) : data.kategori_name === 'Gangguan Sipil' ? (
          <i className="fa-solid fa-triangle-exclamation fa-xl"></i>
        ) : data.kategori_name === 'Ancaman Teroris' ? (
          <i className="fa-solid fa-user-ninja fa-xl"></i>
        ) : data.kategori_name === 'Instabilitas Sosial' ? (
          <i className="fa-solid fa-hand-fist fa-xl"></i>
        ) : data.kategori_name === 'Bencana Alam' ? (
          <i className="fa-solid fa-volcano fa-xl"></i>
        ) : data.kategori_name === 'Keselamatan Transportasi' ? (
          <i className="fa-solid fa-car-burst fa-xl"></i>
        ) : data.kategori_name === 'Unsafe Action' ? (
          <i className="fa-solid fa-person-circle-exclamation fa-xl"></i>
        ) : data.kategori_name === 'Lost & Found' ? (
          <i className="fa-solid fa-boxes-packing fa-xl"></i>
        ) : data.kategori_name === 'Unsafe Condition' ? (
          <i className="fa-solid fa-temperature-arrow-down fa-xl"></i>
        ) : data.kategori_name === 'Keadaan Darurat' ? (
          <i className="fa-solid fa-bell fa-xl"></i>
        ) : data.kategori_name === 'Penutupan Lalu Lintas' ? (
          <i className="fa-solid fa-road-barrier fa-xl"></i>
        ) : data.kategori_name === 'Kriminal Umum' ? (
          <img src={kriminal_umum} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : data.kategori_name === 'Pelanggaran Perda' ? (
          <img src={perda} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : data.kategori_name === 'Radikalisme & Terorisme' ? (
          <img src={terorisme} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : data.kategori_name === 'Narkoba, Prostitusi & Premanisme' ? (
          <img src={prostitusi} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : data.kategori_name === 'Tamiu Tanpa Identitas' ? (
          <img src={tanpa_identitas} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : data.kategori_name === 'Propaganda & Ancaman' ? (
          <img src={ancaman} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : data.kategori_name === 'Kekerasan Konflik Adat' ? (
          <img src={konflik_adat} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : data.kategori_name === 'Pencemaran Lingkungan' ? (
          <img src={perusakan_lingkungan} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : data.kategori_name === 'Ancaman Kelestarian' ? (
          <img src={ancaman_kelestarian} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : data.kategori_name === 'Bahaya Penyakit Menular' ? (
          <img src={penyakit_menular} alt="" className="w-[30px] h-[30px] object-contain" />
        ) : (
          <AiFillFileText className="w-[30px] h-[30px]" />
        )} */}
      </div>
      <div className="text-center text-sm leading-none mt-5 whitespace-break-spaces text-black">{data.kategori_name}</div>
      <div className="text-center text-[32px] text-black">{!loading ? data.jumlah : <ClipLoader color="black" loading={true} size={20} speedMultiplier={0.7} />}</div>
    </div>
  );
}
