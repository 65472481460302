import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as api from '../../redux/api.js';
import UserDropdown from '../dropdowns/UserDropdown';

export default function Navbar({ profile, handleLogout }) {
  const [childMenuData, setChildMenuData] = useState([]);

  const location = useLocation();

  const [activeMainMenu, setActiveMainMenu] = useState({
    adminmenuutama: 'Dashboard',
    adminmenulink: '/'
  });

  useEffect(() => {
    const getChildMenu = async () => {
      await api.childMenu().then((res) => {
        setChildMenuData(res.data.data);
      });
    };
    getChildMenu();
  }, []);

  useEffect(() => {
    const filteredChildMenu = childMenuData?.filter((childmenu) => childmenu.adminmenulink === location.pathname);

    location.pathname === '/'
      ? setActiveMainMenu({
          adminmenuutama: 'Dashboard',
          adminmenulink: '/'
        })
      : filteredChildMenu.map((childmenu) => setActiveMainMenu(childmenu));
  }, [childMenuData, location]);

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 flex items-center w-full p-4 bg-transparent lg:z-10 md:flex-row md:flex-nowrap md:justify-start">
        <div className="flex flex-wrap items-center justify-between w-full px-4 mx-auto md:flex-nowrap md:px-10">
          {/* Brand */}
          {activeMainMenu !== '' && (
            <Link to={location.pathname !== '/keluhan' ? activeMainMenu.adminmenulink : '/keluhan'} className="hidden text-lg font-bold text-white uppercase md:inline-block">
              {location.pathname !== '/keluhan' ? activeMainMenu.adminmenuutama : 'Keluhan'}
            </Link>
          )}
          {/* User */}
          <ul className="flex-col items-center hidden list-none md:flex-row lg:flex">
            <UserDropdown profile={profile} handleLogout={handleLogout} />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
      {/* Header */}
      <div className="relative pt-3 pb-0 bg-primary md:pt-3">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={require('../../images/bg-bali.png')} className="object-cover w-full -mt-2" alt="image" />
      </div>
    </>
  );
}
