import React from 'react';

const TablePatroli = ({ dataPatroli, totalMinggu1, totalMinggu2, totalMinggu3, totalMinggu4, name }) => {
  return (
    <>
      <table className="table-auto border-gray-50 text-sm mx-auto w-full">
        <thead className="bg-primary font-bold text-gray-100">
          <tr>
            <th colSpan="8" className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
              <p className="font-semibold uppercase">LAPORAN {name}</p>
            </th>
          </tr>
        </thead>
        {dataPatroli.map((i, index) => (
          <tbody key={index}>
            <tr>
              <td rowSpan="2" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                <p className="font-semibold">No</p>
              </td>
              <td rowSpan="2" className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                <p className="font-semibold">{i.pos_nama}</p>
              </td>
              <td colSpan="4" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                <p className="font-semibold">Minggu Ke-</p>
              </td>
              <td rowSpan="2" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                <p className="font-semibold">Total</p>
              </td>
            </tr>
            <tr>
              <td className="border border-customGray px-2 py-2 text-center">
                <p className="font-semibold">I</p>
              </td>
              <td className="border border-customGray px-2 py-2 text-center">
                <p className="font-semibold">II</p>
              </td>
              <td className="border border-customGray px-2 py-2 text-center">
                <p className="font-semibold">III</p>
              </td>
              <td className="border border-customGray px-2 py-2 text-center">
                <p className="font-semibold">VI</p>
              </td>
            </tr>
            {i.data_usaha?.map((patroli, index) => (
              <tr key={patroli.titik_kode}>
                <td className="border border-customGray px-2 py-2 text-center">{index + 1}.</td>
                <td className="border border-customGray px-2 py-2 text-left">{patroli.titik_nama}</td>
                <td className="border border-customGray px-2 py-2 text-center">{patroli.summary.minggu1}</td>
                <td className="border border-customGray px-2 py-2 text-center">{patroli.summary.minggu2}</td>
                <td className="border border-customGray px-2 py-2 text-center">{patroli.summary.minggu3}</td>
                <td className="border border-customGray px-2 py-2 text-center">{patroli.summary.minggu4}</td>

                <td className="border border-customGray px-2 py-2 text-center">
                  {parseInt(patroli.summary.minggu1) + parseInt(patroli.summary.minggu2) + parseInt(patroli.summary.minggu3) + parseInt(patroli.summary.minggu4)}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="2" className="border border-customGray px-2 py-2 text-left">
                <p className="font-semibold">Total</p>
              </td>
              <td className="border border-customGray px-2 py-2 text-center">
                <p className="font-semibold">{totalMinggu1}</p>
              </td>
              <td className="border border-customGray px-2 py-2 text-center">
                <p className="font-semibold">{totalMinggu2}</p>
              </td>
              <td className="border border-customGray px-2 py-2 text-center">
                <p className="font-semibold">{totalMinggu3}</p>
              </td>
              <td className="border border-customGray px-2 py-2 text-center">
                <p className="font-semibold">{totalMinggu4}</p>
              </td>
              <td className="border border-customGray px-2 py-2 text-center">
                <p className="font-semibold">{totalMinggu1 + totalMinggu2 + totalMinggu3 + totalMinggu4}</p>
              </td>
            </tr>
          </tbody>
        ))}
      </table>
      {/* <table className="table-auto border-gray-50 text-sm mx-auto w-full">
                            <thead className="bg-primary font-bold text-gray-100">
                              <tr>
                                <th
                                  colSpan="8"
                                  className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
                                  <p className="font-semibold">C. PERSONIL</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">No</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                                  <p className="font-semibold">Nama Personil</p>
                                </td>
                                <td
                                  colSpan="4"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Status</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">M</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">S</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">I</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">A</p>
                                </td>
                              </tr>
                              {dataPersonil?.map((personil, index) => (
                                <tr key={personil.satpamnamalengkap}>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {index + 1}.
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left">
                                    <p className="font-semibold">{personil.satpamnamalengkap}</p>
                                    <p>{personil.satpamjabatan}</p>
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.sudah_absen}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.izin}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.sakit}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.alfa}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {parseInt(personil.sudah_absen) +
                                      parseInt(personil.izin) +
                                      parseInt(personil.sakit) +
                                      parseInt(personil.alfa)}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left"></td>
                                </tr>
                              ))}
                              <tr>
                                <td
                                  colSpan="2"
                                  className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu2.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu3.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu4.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">
                                    {totalMinggu1.personil +
                                      totalMinggu2.personil +
                                      totalMinggu3.personil +
                                      totalMinggu4.personil}
                                  </p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td>
                              </tr>
                            </tbody>
                          </table> */}
    </>
  );
};

export default TablePatroli;
