import React from 'react';

export default function PhotoDetail({ data, photo, keterangan, no }) {
  return (
    <>
      {photo?.split(data)[1]?.length > 0 && (
        <>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Foto {no}</div>
            <div className="w-full">
              <img className="max-w-full max-h-64 object-contain" src={photo} alt="" />
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-3">
            <div className="md:w-3/5 w-full font-bold">Ket. Foto {no}</div>
            <div className="w-full">{keterangan && keterangan !== 'null' ? keterangan : '-'}</div>
          </div>
        </>
      )}
    </>
  );
}
