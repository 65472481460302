import React from 'react';
import TableCetakPatroliPerPat from './TableCetakPatroliPerPat';

export default function PatroliCetakPerPat({ refPropWithAnotherName, dataPatroli, ruteDetail }) {
  return (
    <div
      className="w-full bg-white text-[12px]"
      id="kegiatanHarianPrint"
      ref={refPropWithAnotherName}>
      <TableCetakPatroliPerPat dataPatroli={dataPatroli} ruteDetail={ruteDetail} />
    </div>
  );
}
