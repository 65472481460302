import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
// Import files
import * as api from '../../redux/api';
import BtnAction from '../../components/general/BtnAction';
import BtnAdd from '../../components/general/BtnAdd';
import Cards from '../../components/general/Cards';
import CardDashboard from '../../components/dashboard/CardDashboard';
import FilterDate from '../../components/general/FilterDate';
import FilterKategori from '../../components/general/FilterKategori';
import LoadingPage from '../../components/general/LoadingPage';
import ModalKegiatanHarianDetail from '../../components/kegiatan-harian/ModalKegiatanHarianDetail';
import PhotoLaporan from '../../components/general/PhotoLaporan';
import ReadMore from '../../components/general/ReadMore';
import ShowModalImage from '../../components/general/ShowModalImage';
import ShowTable from '../../components/general/ShowTable';
import StatusLaporan from '../../components/general/StatusLaporan';

export default function KegiatanHarian({ isAdmin }) {
  const [data, setData] = useState(['']);
  const [datas, setDatas] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [kategori, setKategori] = useState('');
  const [kategoriCount, setKategoriCount] = useState(['']);

  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [kegiatanDetailKode, setKegiatanDetailKode] = useState('');
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const [tipeLaporan, setTipeLaporan] = useState('');
  const location = useLocation();
  const { perusahaanKode } = useSelector((state) => state.perusahaan);

  // Date
  const [date, setDate] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  // Get data
  const getData = async (formData) => {
    await api.kegiatanHarian(formData).then((res) => {
      setData(res.data.data);
      setDatas(res.data);
      setLoading(false);
    });
  };

  const getCount = async (formData) => {
    await api.countHarian(formData).then((res) => {
      setKategoriCount(res.data.data);
    });
  };

  useEffect(() => {
    if (location?.state?.date) {
      setDate(location?.state?.date);
      setKategori(location?.state?.kategori);
      setTipeLaporan(location?.state?.tipeLaporan);
    }
  }, [location?.state]);

  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpamkegiatansatpamkegiatankategorikode: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    setLoading(true);
    getCount(formDate);
    getData(formDate);
  }, [date, kategori, perusahaanKode]);

  useEffect(() => {
    setFilterData(
      data.filter(
        ({
          satpamkegiatantanggalmelaporkan,
          satpamkegiatankode,
          satpamkegiatankategorinama,
          satpamnamalengkap,
          satpamkegiatanuraian,
          satpamkegiatanketerangan,
          satpamkegiatanalamat
        }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamkegiatantanggalmelaporkan} ${satpamkegiatankode} ${satpamkegiatankategorinama} ${satpamnamalengkap} ${satpamkegiatanuraian} ${satpamkegiatanketerangan} ${satpamkegiatanalamat}`
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
    );
  }, [data, debouncedQuery]);

  const handleOpenModalDetail = (kegiatanKode) => {
    setKegiatanDetailKode(kegiatanKode);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleDelete = (formData) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate
    };

    setShowPopover('');

    Swal.fire({
      text: 'Do you want to delete this report?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .kegiatanHarianDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData(formDate);
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamkegiatankode',
      width: 100,
      render: ({ satpamkegiatankode }) => (
        <BtnAction
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          handleOpenModalDetail={handleOpenModalDetail}
          handleDelete={handleDelete}
          data={satpamkegiatankode}
          id={{ satpamkegiatankode }}
          page="/kegiatan-harian"
        />
      )
    },
    {
      title: 'Foto Kegiatan',
      accessor: 'satpamkegiatanfotosatu',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({
        satpamkegiatanfotosatu,
        satpamkegiatanketeranganfotosatu,
        satpamkegiatanfotodua,
        satpamkegiatanketeranganfotodua,
        satpamkegiatanfototiga,
        satpamkegiatanketeranganfototiga,
        satpamkegiatanfotoempat,
        satpamkegiatanketeranganfotoempat,
        satpamkegiatanfotolima,
        satpamkegiatanketeranganfotolima
      }) => (
        <PhotoLaporan
          page="/data-kegiatan/"
          foto1={satpamkegiatanfotosatu}
          foto2={satpamkegiatanfotodua}
          foto3={satpamkegiatanfototiga}
          foto4={satpamkegiatanfotoempat}
          foto5={satpamkegiatanfotolima}
          ket1={satpamkegiatanketeranganfotosatu}
          ket2={satpamkegiatanketeranganfotodua}
          ket3={satpamkegiatanketeranganfototiga}
          ket4={satpamkegiatanketeranganfotoempat}
          ket5={satpamkegiatanketeranganfotolima}
          showImage={showImage}
          setShowImage={setShowImage}
          setImageData={setImageData}
        />
      )
    },
    {
      title: 'Tanggal, Kode, dan Status',
      accessor: 'satpamkegiatantanggalmelaporkan,',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkegiatantanggalmelaporkan, satpamkegiatankode, satpamkegiatanstatus }) => (
        <StatusLaporan kode={satpamkegiatankode} tanggal={satpamkegiatantanggalmelaporkan} status={satpamkegiatanstatus} />
      )
    },
    {
      title: 'Kategori',
      accessor: 'satpamkegiatankategorinama',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Uraian',
      accessor: 'satpamkegiatanuraian',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamkegiatanuraian }) => <>{satpamkegiatanuraian !== null && <ReadMore>{satpamkegiatanuraian}</ReadMore>}</>
    },
    {
      title: 'Keterangan',
      accessor: 'satpamkegiatanketerangan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamkegiatanketerangan }) => <>{satpamkegiatanketerangan !== null && <ReadMore>{satpamkegiatanketerangan}</ReadMore>}</>
    },
    {
      title: 'Alamat',
      accessor: 'satpamkegiatanalamat',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 300
    }
  ];

  if ((data && data[0] === '') || (kategoriCount && kategoriCount[0] === '')) return <LoadingPage />;

  return (
    <div className={`relative w-full min-h-screen p-5 ${isAdmin ? 'mt-1' : '-mt-10'}`} onClick={() => showPopover && setShowPopover('')}>
      {/* Cards */}
      <Cards
        title="Statistik Kegiatan Harian"
        desc="Berikut ini adalah jumlah kegiatan harian yang telah dilakukan"
        card1={{ title: 'Verifikasi', value: datas?.sudah_verifikasi || 0 }}
        card2={{
          title: 'Belum Verifikasi',
          value: datas?.belum_verifikasi || 0
        }}
        card3={{ title: 'Total Data', value: datas?.jumlah_data || 0 }}
        loading={loading}
      />

      {/* Cards */}
      <div className="flex flex-wrap gap-5 text-white">
        {kategoriCount?.map((data) => (
          <CardDashboard data={data} loading={loading} />
        ))}
      </div>

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            {!isAdmin && <FilterDate date={date} setDate={setDate} tipeLaporan={tipeLaporan} />}
            <FilterKategori kategori={kategori} setKategori={setKategori} page="kegiatan" />
          </div>

          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-full">
              <BtnAdd path="/kegiatan-harian/create" />

              <Tooltip label="Print laporan">
                <Link to={`/kegiatan-harian-print`} state={{ data: data, date: date }}>
                  <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                    <HiOutlinePrinter size={24} />
                  </div>
                </Link>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* Show Table */}
        <ShowTable loading={loading} datas={datas} filterData={filterData} columnData={columnData} setQuery={setQuery} />
      </div>

      <div className="flex justify-center mt-5">
        <Link to="/">
          <button className="btnAksi bg-primary py-2 px-20">Dashboard</button>
        </Link>
      </div>

      {/* Modal Detail Kegiatan */}
      <ModalKegiatanHarianDetail kode={kegiatanDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

      {/* Modal Image */}
      <ShowModalImage showImage={showImage} setShowImage={setShowImage} imageData={imageData} />
    </div>
  );
}
