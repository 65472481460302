import React from 'react';

const TableKejadian = ({ data, totalMinggu1, totalMinggu2, totalMinggu3, totalMinggu4, name }) => {
  return (
    <div>
      <table className="table-auto border-gray-50 text-sm mx-auto w-full">
        <thead className="bg-primary font-bold text-gray-100">
          <tr>
            <th colSpan="8" className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
              <p className="font-semibold uppercase">LAPORAN {name}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Tabel Kejadian */}
          <tr>
            <td rowSpan="2" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
              <p className="font-semibold">No</p>
            </td>
            <td rowSpan="2" className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
              <p className="font-semibold capitalize">Kategori {name}</p>
            </td>
            <td colSpan="4" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
              <p className="font-semibold">Minggu Ke-</p>
            </td>
            <td rowSpan="2" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
              <p className="font-semibold">Total</p>
            </td>
            {/* <td rowSpan="2" className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td> */}
          </tr>
          <tr>
            <td className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">I</p>
            </td>
            <td className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">II</p>
            </td>
            <td className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">III</p>
            </td>
            <td className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">IV</p>
            </td>
          </tr>
          {data?.map((kejadian, index) => (
            <tr key={kejadian.satpamkejadiankategori}>
              <td className="border border-customGray px-2 py-2 text-center">{index + 1}.</td>
              <td className="border border-customGray px-2 py-2 text-left">{kejadian.kategori_name}</td>
              <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu1}</td>
              <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu2}</td>
              <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu3}</td>
              <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu4}</td>
              <td className="border border-customGray px-2 py-2 text-center">
                {parseInt(kejadian.minggu1) + parseInt(kejadian.minggu2) + parseInt(kejadian.minggu3) + parseInt(kejadian.minggu4)}
              </td>
              {/* <td className="border border-customGray px-2 py-2 text-left"></td> */}
            </tr>
          ))}
          <tr>
            <td colSpan="2" className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">Total</p>
            </td>
            <td className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">{totalMinggu1}</p>
            </td>
            <td className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">{totalMinggu2}</p>
            </td>
            <td className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">{totalMinggu3}</p>
            </td>
            <td className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">{totalMinggu4}</p>
            </td>
            <td className="border border-customGray px-2 py-2 text-center">
              <p className="font-semibold">{totalMinggu1 + totalMinggu2 + totalMinggu3 + totalMinggu4}</p>
            </td>
            {/* <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td> */}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableKejadian;
