import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
// Import files
import * as api from '../../redux/api';
import { satpamUpdateSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';

export default function AdminPecalangUpdate() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(satpamUpdateSchema)
  });

  const [loading, setLoading] = useState(false);
  const [roleValue, setRoleValue] = useState('');
  const [satpamKode, setSatpamKode] = useState('');
  const [satpamMenuMaster, setSatpamMenuMaster] = useState([]);
  const [satpamMenu, setSatpamMenu] = useState([]);
  const [satpamRoles, setSatpamRoles] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getSatpamById = async () => {
      await api.satpamById(id).then((res) => {
        setRoleValue(res.data.data[0].role === 'Bankamda' ? 'PETUGAS' : 'ADMIN');
        setSatpamKode(res.data.data[0].satpamkode);
        const defaultValue = {
          is_verified: res.data.data[0].is_verified !== null ? res.data.data[0].is_verified : '',
          role: res.data.data[0].role !== null ? res.data.data[0].role : '',
          satpamnip: res.data.data[0].satpamnip !== null ? res.data.data[0].satpamnip : '',
          satpamstatus: res.data.data[0].satpamstatus !== null ? res.data.data[0].satpamstatus : '',
          satpamnamalengkap: res.data.data[0].satpamnamalengkap !== null ? res.data.data[0].satpamnamalengkap : '',
          satpamnohp: res.data.data[0].satpamnohp !== null ? res.data.data[0].satpamnohp : '',
          satpamemail: res.data.data[0].satpamemail !== null ? res.data.data[0].satpamemail : '',
          satpamjeniskelamin: res.data.data[0].satpamjeniskelamin,
          satpambanknama: res.data.data[0].satpambanknama !== null ? res.data.data[0].satpambanknama : '',
          satpambanknomorrekening: res.data.data[0].satpambanknomorrekening !== null ? res.data.data[0].satpambanknomorrekening : '',
          satpamfoto:
            res.data.data[0].satpamfoto && res.data.data[0].satpamfoto.split('/data-satpam/')[1] ? `${process.env.REACT_APP_API_URL_PHOTO}${res.data.data[0].satpamfoto}` : '',
          satpambankfoto:
            res.data.data[0].satpambankfoto && res.data.data[0].satpambankfoto.split('/data-bank/')[1]
              ? `${process.env.REACT_APP_API_URL_PHOTO}${res.data.data[0].satpambankfoto}`
              : ''
        };
        reset(defaultValue);
      });
    };
    const getSatpamMenuMaster = async () => {
      await api.satpamMenuMaster().then((res) => {
        setSatpamMenuMaster(res.data.data);
      });
    };
    const getSatpamRoles = async () => {
      await api.satpamRoles().then((res) => {
        setSatpamRoles(res.data.data);
      });
    };
    getSatpamMenuMaster();
    getSatpamById();
    getSatpamRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getSatpamMenu = async () => {
      satpamKode &&
        (await api.satpamMenu({ satpamkode: satpamKode }).then((res) => {
          if (res.data.data.length > 0) {
            res.data.data.map((menu) => {
              return setSatpamMenu((prev) => [...prev, { satpammenukode: menu.satpammenukode }]);
            });
          } else {
            setSatpamMenu([]);
          }
        }));
    };
    getSatpamMenu();
  }, [satpamKode]);

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const [fotoSatpamPreview] = useImagePreview(watch('satpamfoto'));

  const handleSatpamMenu = (e) => {
    if (e.target.checked) {
      setSatpamMenu([...satpamMenu, { satpammenukode: e.target.value }]);
    } else {
      setSatpamMenu(satpamMenu.filter((menu) => menu.satpammenukode !== e.target.value));
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    data.satpamid = id;
    data.satpamkode = satpamKode;

    const formData = new FormData();
    formData.append('satpammenu', JSON.stringify(satpamMenu));

    if (data.satpamfoto.length > 0 && typeof data.satpamfoto !== 'string') {
      const compressedFile = await imageCompression(data.satpamfoto[0], compressOptions);
      formData.append('satpamfoto', compressedFile, compressedFile.name);
    }

    if (data.satpambankfoto.length > 0 && typeof data.satpambankfoto !== 'string') {
      const compressedFile = await imageCompression(data.satpambankfoto[0], compressOptions);
      formData.append('satpambankfoto', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    await api
      .satpamUpdate(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        navigate('/personil/data');
      })
      .catch((error) => {
        setLoading(false);
        let errMessage = [];
        if (error?.response?.data?.errors) {
          for (const key in error.response.data.errors) {
            errMessage.push(error.response.data.errors[key].toString());
          }
        }
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errMessage.length > 0 ? errMessage.join(', ') : 'Something went wrong!'
        });
      });
  };

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 md:px-10">
      <div className="w-full">
        <div className="flex items-center justify-center w-full py-3 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-gray-50 shadow-black/20">
          Update Personil
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full">
          <div className="flex flex-wrap gap-5 text-sm md:flex-nowrap">
            <div className="flex flex-col w-full gap-5 md:w-1/2">
              <div className="flex flex-col justify-around p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex justify-arround">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamnip" className="block mb-1 font-semibold md:mb-0">
                      NIP
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" id="satpamnip" className={`w-full text-sm ${errors.satpamnip ? 'invalidForm' : 'inputForm'}`} {...register('satpamnip')} />
                    <p className="text-xs text-errorText">{errors.satpamnip?.message}</p>
                  </div>
                </div>
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamnamalengkap" className="block mb-1 font-semibold md:mb-0">
                      Nama Lengkap
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      id="satpamnamalengkap"
                      className={`w-full text-sm ${errors.satpamnamalengkap ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamnamalengkap')}
                    />
                    <p className="text-xs text-errorText">{errors.satpamnamalengkap?.message}</p>
                  </div>
                </div>
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamjeniskelamin" className="block mb-1 font-semibold md:mb-0">
                      Jenis Kelamin
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      id="satpamjeniskelamin"
                      className={`w-full text-sm ${errors.satpamjeniskelamin ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamjeniskelamin')}>
                      <option value="">Pilih jenis kelamin</option>
                      <option value="Laki-Laki">Laki-laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                    <p className="text-xs text-errorText">{errors.satpamjeniskelamin?.message}</p>
                  </div>
                </div>
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamnohp" className="block mb-1 font-semibold md:mb-0">
                      Nomor HP
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full text-sm ${errors.satpamnohp ? 'invalidForm' : 'inputForm'}`} {...register('satpamnohp')} />
                    <p className="text-xs text-errorText">{errors.satpamnohp?.message}</p>
                  </div>
                </div>
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamemail" className="block mb-1 font-semibold md:mb-0">
                      Email
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="email" className={`w-full text-sm ${errors.satpamemail ? 'invalidForm' : 'inputForm'}`} {...register('satpamemail')} />
                    <p className="text-xs text-errorText">{errors.satpamemail?.message}</p>
                  </div>
                </div>
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamstatus" className="block mb-1 font-semibold md:mb-0">
                      Status
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select id="satpamstatus" className={`w-full text-sm ${errors.satpamstatus ? 'invalidForm' : 'inputForm'}`} {...register('satpamstatus')}>
                      <option value="">Pilih status</option>
                      <option value="Aktif">Aktif</option>
                      <option value="Tidak Aktif">Tidak Aktif</option>
                    </select>
                    <p className="text-xs text-errorText">{errors.satpamstatus?.message}</p>
                  </div>
                </div>
              </div>
              {roleValue === 'PETUGAS' && (
                <div className="flex flex-col justify-around p-5 rounded-lg bg-gray-50 shadowAll">
                  <div className="flex-col md:flex">
                    <label className="block mb-2 font-semibold">Menu Satpam</label>
                    {satpamMenuMaster?.map((menumaster) => (
                      <div key={menumaster.satpammenukode} className="flex flex-row flex-wrap p-1.5 text-sm">
                        <div className="flex gap-2 pr-2">
                          <input
                            type="checkbox"
                            id={menumaster.satpammenukode}
                            value={menumaster.satpammenukode}
                            onChange={handleSatpamMenu}
                            checked={satpamMenu.filter((menu) => menu.satpammenukode === menumaster.satpammenukode).length > 0 ? true : false}
                          />
                          <label htmlFor={menumaster.satpammenukode} className="cursor-pointer ">
                            {menumaster.satpammenunama?.replace('<br>', ' ')}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="w-full gap-5 p-5 rounded-lg md:w-1/2 bg-gray-50 shadowAll">
              <div className="flex-col gap-3 px-2 md:flex justify-arround md:px-5">
                <div>
                  <label htmlFor="satpamfoto" className="block mb-1 font-semibold md:mb-0">
                    Foto Profil
                  </label>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <label className="flex flex-col items-center justify-center w-full h-56 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fotoSatpamPreview ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fotoSatpamPreview} className="object-contain w-full h-48" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('satpamfoto')} accept="image/*" />
                        </label>
                      </div>
                      <p className="text-xs text-errorText">{errors.satpamfoto?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full px-10 py-5 my-8 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>
            <button type="button" onClick={() => navigate(-1)} className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80">
              Kembali
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
