import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { BsHourglassSplit, BsPatchCheckFill, BsFillBarChartFill } from 'react-icons/bs';

export default function Cards({ datas, title, desc, card1, card2, card3, margin, loading }) {
  return (
    <div className={`${margin} w-full min-h-min bg-primary rounded-lg shadow-black/30 shadow-lg flex flex-col md:flex-row justify-between items-center p-8 gap-5`}>
      {/* Title and Description */}
      <div className="w-full lg:w-2/5 flex flex-col justify-center space-y-4 text-white">
        <p className="font-bold text-xl">{title}</p>
        <p className="text-md">{desc}</p>
      </div>
      <div className="w-full flex flex-col lg:flex-row justify-end items-center gap-5">
        {/* Card 1 */}
        <div className="w-full lg:w-1/3 h-28 md:h-32 bg-light flex flex-col justify-center items-center rounded-lg relative py-3 px-2">
          <div className="w-10 h-10 rounded-md bg-red-400 absolute left-6 top-[-17.5px] text-center">
            <BsPatchCheckFill size={20} color="white" className="ml-[10px] mt-[10px]" />
          </div>
          {(Number(card1?.value) !== 0 && !card1.value) || card1.value === 'loading' ? (
            <div className="flex items-center">
              <ClipLoader color="red" loading={true} size={20} speedMultiplier={0.7} /> &nbsp; data loading...
            </div>
          ) : (
            <>
              <div className="text-base">{card1?.title}</div>
              <div className="text-[32px] font-bold">{!loading ? card1?.value : <ClipLoader color="black" loading={true} size={20} speedMultiplier={0.7} />}</div>
            </>
          )}
        </div>
        {/* Card 2 */}
        <div className="w-full lg:w-1/3 h-28 md:h-32 bg-light flex flex-col justify-center items-center rounded-lg relative p-5">
          <div className="w-10 h-10 rounded-md bg-red-400 absolute left-6 top-[-17.5px] text-center">
            <BsHourglassSplit size={20} color="white" className="ml-[10px] mt-[10px]" />
          </div>
          {(Number(card2?.value) !== 0 && !card2.value) || card2.value === 'loading' ? (
            <div className="flex items-center">
              <ClipLoader color="red" loading={true} size={20} speedMultiplier={0.7} /> &nbsp; data loading...
            </div>
          ) : (
            <>
              <div className="text-base">{card2?.title}</div>
              <div className="text-[32px] font-bold">{!loading ? card2?.value : <ClipLoader color="black" loading={true} size={20} speedMultiplier={0.7} />}</div>
            </>
          )}
        </div>
        {/* Card 3 */}
        <div className="w-full lg:w-1/3 h-28 md:h-32 bg-light flex flex-col justify-center items-center rounded-lg relative p-5">
          <div className="w-10 h-10 rounded-md bg-red-400 absolute left-6 top-[-17.5px] text-center">
            <BsFillBarChartFill size={20} color="white" className="ml-[10px] mt-[10px]" />
          </div>
          {(Number(card3?.value) !== 0 && !card3.value) || card3.value === 'loading' ? (
            <div className="flex items-center">
              <ClipLoader color="red" loading={true} size={20} speedMultiplier={0.7} /> &nbsp; data loading...
            </div>
          ) : (
            <>
              <div className="text-base">{card3?.title}</div>
              <div className="text-[32px] font-bold">{!loading ? card3?.value : <ClipLoader color="black" loading={true} size={20} speedMultiplier={0.7} />}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
