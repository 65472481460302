import { Modal } from '@mantine/core';
import { useState, useEffect } from 'react';
import * as api from '../../redux/api';

const ModalPersonilDetail = ({ id, openModalDetail, setOpenModalDetail }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (id !== '' && openModalDetail) {
      const getDataProfileById = async () => {
        await api.satpamProfile(id).then((res) => {
          setData(res.data.data);
        });
      };
      getDataProfileById();
    }
  }, [id, openModalDetail]);

  return (
    <Modal opened={openModalDetail} withCloseButton size="auto" onClose={() => setOpenModalDetail(false)}>
      <div className="px-5 text-dark scrollbar-thin">
        <div className="flex flex-col items-center justify-center">
          <h3 className="mb-5 font-bold text-center">Personil Data Detail</h3>
          <div className="flex justify-center">
            {data.foto_selfie && data.foto_selfie.split('/data-penduduk/')[1] ? (
              <img src={`${process.env.REACT_APP_API_URL_PHOTO}${data.foto_selfie}`} alt="" className="w-[150px] h-[150px] object-cover mb-5 rounded-xl" />
            ) : (
              <img src={require('../../images/satpam.jpg')} alt="" className="w-[150px] h-[150px] object-cover mb-5 rounded-xl" />
            )}
          </div>
          <div className="flex gap-10 text-sm">
            <div>
              <p className="font-semibold">Nama</p>
              <p className="font-semibold">Agama</p>
              <p className="font-semibold">Nomor HP</p>
              <p className="font-semibold">Kewarganegaraan</p>
              <p className="font-semibold">Golongan Darah</p>
              <p className="font-semibold">Jenis Krama</p>
              <p className="font-semibold">Jenis Kelamin</p>
              <p className="font-semibold">Pendidikan</p>
              <p className="font-semibold">Pekerjaan</p>
              <p className="font-semibold">Tempat Lahir</p>
              <p className="font-semibold">Tanggal Lahir</p>
              <p className="font-semibold">Alamat Kartu Id</p>
              <p className="font-semibold">Alamat Sekarang</p>
              <p className="font-semibold">Jenis Kartu Id</p>
              <p className="font-semibold">Nomor Kartu Id</p>
              {data?.jenis_kewarganegaraan !== 'WNI' && (
                <>
                  <p className="font-semibold">Tgl Kadaluarsa Kartu Id</p>
                  <p className="font-semibold">Nomor Paspor</p>
                  <p className="font-semibold">Tgl Kadaluarsa Paspor</p>
                </>
              )}
            </div>
            <div>
              <p>: {data?.satpamnamalengkap}</p>
              <p>: {data?.agama ? data?.agama : '-'}</p>
              <p>: {data?.satpamnohp ? data?.satpamnohp : '-'}</p>
              <p>: {data?.jenis_kewarganegaraan ? data?.jenis_kewarganegaraan : '-'}</p>
              <p>: {data?.gol_dar ? data?.gol_dar : '-'}</p>
              <p>: {data?.krama_text ? data?.krama_text : '-'}</p>
              <p>: {data?.satpamjeniskelamin}</p>
              <p>: {data?.pekerjaan ? data?.pendidikan : '-'}</p>
              <p>: {data?.pekerjaan ? data?.pekerjaan : '-'}</p>
              <p>: {data?.tempat_lahir ? data?.tempat_lahir : '-'}</p>
              <p>: {data?.tanggal_lahir ? data?.tanggal_lahir : '-'}</p>
              <p>: {data?.alamat_ktp ? data?.alamat_ktp : '-'}</p>
              <p>: {data?.alamat_sekarang ? data?.alamat_sekarang : '-'}</p>
              <p>: {data?.jenis_kartu_id ? data?.jenis_kartu_id : '-'}</p>
              <p>: {data?.nomor_kartu ? data?.nomor_kartu : '-'}</p>
              {data?.jenis_kewarganegaraan !== 'WNI' && (
                <>
                  <p>: {data?.date_expired_kartu_id ? data?.date_expired_kartu_id : '-'}</p>
                  <p>: {data?.paspor ? data?.paspor : '-'}</p>
                  <p>: {data?.date_expired_paspor ? data?.date_expired_paspor : '-'}</p>
                </>
              )}
            </div>
          </div>

          {/* Foto Kartu Id dan Paspor */}
          <div className="flex flex-col items-center justify-center mt-5">
            <div className="mb-1 text-sm font-semibold">Foto {data?.jenis_kartu_id}</div>
            {data.foto_kartu_id && data.foto_kartu_id !== '' ? (
              <img src={`${process.env.REACT_APP_API_URL_PHOTO}${data.foto_kartu_id}`} alt="" className="h-[150px] object-cover mb-5 rounded-xl" />
            ) : (
              <span className="text-sm text-red-700">Data kosong!</span>
            )}
          </div>
          {data.foto_paspor && (
            <div className="flex flex-col items-center justify-center">
              <div className="mb-1 text-sm font-semibold">Foto Paspor</div>
              {data.foto_paspor && data.foto_paspor !== '' ? (
                <img src={`${process.env.REACT_APP_API_URL_PHOTO}${data.foto_paspor}`} alt="" className="h-[150px] object-cover mb-5 rounded-xl" />
              ) : (
                '-'
              )}
            </div>
          )}
        </div>
        <div className="flex justify-end mt-5">
          <div className="px-3 py-2 text-xs rounded-md cursor-pointer w-fit text-gray-50 bg-dark hover:opacity-80" onClick={() => setOpenModalDetail(false)}>
            Kembali
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPersonilDetail;
