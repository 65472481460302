import moment from 'moment/moment';
import { Modal } from '@mantine/core';
import { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import LoadingModal from '../general/LoadingModal';
import PhotoDetail from '../general/PhotoDetail';
import { parseToNewLine } from '../../utils/parseToNewLine';

const ModalKejadianDetail = ({ kode, openModalDetail, setOpenModalDetail }) => {
  const [dataKejadian, setDataKejadian] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getDataKejadianByKode = async () => {
        await api.kejadianByKode({ satpamkejadiankode: kode }).then((res) => {
          setDataKejadian(res.data.data[0]);
        });
      };
      getDataKejadianByKode();
    }
    return setDataKejadian({});
  }, [kode]);

  return (
    <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Kejadian</div>
      {dataKejadian?.satpamkejadiankode ? (
        <div>
          <div className="text-dark scrollbar-thin px-5">
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kode Kejadian</div>
              <div className="w-full">{dataKejadian?.satpamkejadiankode}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kode Perusahaan</div>
              <div className="w-full">{dataKejadian?.satpamkejadiansatpamperusahaankode}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nama Perusahaan</div>
              <div className="w-full">{dataKejadian?.satpamperusahaannama}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kode Personil</div>
              <div className="w-full">{dataKejadian?.satpamkejadiansatpamkode}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nama Personil</div>
              <div className="w-full">{dataKejadian?.satpamnamalengkap}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Tanggal Melaporkan</div>
              <div className="w-full">{moment(dataKejadian?.satpamkejadiantanggalmelaporkan).format('DD MMM YYYY HH:mm') || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Tanggal Aktifitas</div>
              <div className="w-full">{moment(dataKejadian?.satpamkejadiantanggalaktifitas).format('DD MMM YYYY HH:mm') || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Uraian Kejadian</div>
              <div className="w-full">{dataKejadian?.satpamkejadianuraian !== '' && parseToNewLine(dataKejadian?.satpamkejadianuraian)}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Uraian Tindakan</div>
              <div className="w-full">{dataKejadian?.satpamkejadiantindakan !== '' && parseToNewLine(dataKejadian?.satpamkejadiantindakan)}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Hasil Tindakan</div>
              <div className="w-full">{dataKejadian?.satpamkejadianhasil !== '' && parseToNewLine(dataKejadian?.satpamkejadianhasil)}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Potensi Kejadian</div>
              <div className="w-full">
                {dataKejadian?.satpamkejadianpotensi && dataKejadian?.satpamkejadianpotensi !== 'null' ? dataKejadian?.satpamkejadianpotensi : 'Belum ada potensi'}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Analisis Kejadian</div>
              <div className="w-full">
                {dataKejadian?.satpamkejadiananalisis && dataKejadian?.satpamkejadiananalisis !== 'null' ? dataKejadian?.satpamkejadiananalisis : 'Belum ada analisis'}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kesimpulan Kejadian</div>
              <div className="w-full">
                {dataKejadian?.satpamkejadiankesimpulan && dataKejadian?.satpamkejadiankesimpulan !== 'null' ? dataKejadian?.satpamkejadiankesimpulan : 'Belum ada kesimpulan'}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Saran dan Rekomendasi</div>
              <div className="w-full">
                {dataKejadian?.satpamkejadianrekomendasi && dataKejadian?.satpamkejadianrekomendasi !== 'null'
                  ? dataKejadian?.satpamkejadianrekomendasi
                  : 'Belum ada rekomendasi'}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Pelaku</div>
              <div className="w-full">{dataKejadian?.satpamkejadianpelaku}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Korban</div>
              <div className="w-full">{dataKejadian?.satpamkejadiankorban}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Saksi</div>
              <div className="w-full">{dataKejadian?.satpamkejadiansaksi}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Latitude</div>
              <div className="w-full">{dataKejadian?.satpamkejadianlatitude || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Longitude</div>
              <div className="w-full">{dataKejadian?.satpamkejadianlongitude || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Alamat kejadian</div>
              <div className="w-full">{dataKejadian?.satpamkejadianalamat}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Patokan</div>
              <div className="w-full">{dataKejadian?.satpamkejadianalamatpatokan}</div>
            </div>
            {/* Album Photo */}
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKejadian?.satpamkejadianfotosatu}
              keterangan={dataKejadian?.satpamkejadianketeranganfotosatu}
              data="/data-kejadian/"
              no="1"
            />
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKejadian?.satpamkejadianfotodua}
              keterangan={dataKejadian?.satpamkejadianketeranganfotodua}
              data="/data-kejadian/"
              no="2"
            />
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKejadian?.satpamkejadianfototiga}
              keterangan={dataKejadian?.satpamkejadianketeranganfototiga}
              data="/data-kejadian/"
              no="3"
            />
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKejadian?.satpamkejadianfotoempat}
              keterangan={dataKejadian?.satpamkejadianketeranganfotoempat}
              data="/data-kejadian/"
              no="4"
            />
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKejadian?.satpamkejadianfotolima}
              keterangan={dataKejadian?.satpamkejadianketeranganfotolima}
              data="/data-kejadian/"
              no="5"
            />
            <div className="flex justify-end mt-5">
              <div className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer" onClick={() => setOpenModalDetail(false)}>
                Kembali
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingModal />
      )}
    </Modal>
  );
};

export default ModalKejadianDetail;
