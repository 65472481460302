import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { login } from '../../redux/features/authSlice';

const initialState = {
  adminpetugasusername: '',
  password: ''
};

export default function Login() {
  const { error, loading, errorMessage } = useSelector((state) => ({ ...state.auth }));
  const [formValue, setFormValue] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const { adminpetugasusername, password } = formValue;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (adminpetugasusername && password) dispatch(login({ formValue, navigate }));
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  return (
    <div className="text-gray-800 scrollbar-none">
      <div className="flex bg-gray">
        <div className="flex-1 hidden sm:block">
          <img src={require('../../images/build-login1.png')} alt="" className="h-screen" />
        </div>
        <div className="flex flex-col items-center justify-center flex-1 h-screen mt-8 mr-0 bg-no-repeat bg-contain sm:mt-0 sm:mr-5 sm:bg-none bg-building">
          <img src={require('../../images/logo.png')} alt="" className="w-[150px] sm:w-[275px]" />
          <p className="mt-3 font-bold sm:text-xl text-primary">Bankamda Kalpika Bali</p>
          <form className={`flex flex-col items-center gap-5 ${error ? 'mt-5' : 'mt-10'} sm:mt-10`} onSubmit={handleSubmit}>
            {/* Error message */}
            {error && !loading && (
              <div className="bg-primary text-white h-auto p-2 mb-2 rounded-md text-[11px] text-center sm:text-base">
                {errorMessage === 'Unauthorized' ? 'Username or Password is wrong' : 'Something went wrong. Please try again'}
              </div>
            )}
            <input
              className="px-3 py-2 border border-gray-400 rounded-md input focus:outline-slate-700"
              type="email"
              placeholder="Email"
              value={adminpetugasusername}
              name="adminpetugasusername"
              onChange={handleChange}
            />
            <div className="relative">
              <input
                className="px-3 py-2 border border-gray-400 rounded-md input focus:outline-slate-700"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                name="password"
                onChange={handleChange}
              />
              {showPassword && <i className="fas fa-eye cursor-pointer absolute top-[30%] right-3" onClick={() => setShowPassword(!showPassword)}></i>}
              {!showPassword && <i className="fas fa-eye-slash cursor-pointer absolute top-[30%] right-3" onClick={() => setShowPassword(!showPassword)}></i>}
            </div>
            <button className="bg-black text-white w-[155px] py-2 rounded-lg flex items-center justify-center">
              {loading && <ClipLoader color="blue" loading={true} size={20} speedMultiplier={0.7} />} &nbsp; LOGIN
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
