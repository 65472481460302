import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { MdVerified } from 'react-icons/md';
import { Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useState, useEffect } from 'react';
// Import files
import * as api from '../../redux/api';
import BtnAction from '../../components/general/BtnAction';
import LoadingPage from '../../components/general/LoadingPage';
// import ModalImportSatpam from '../../components/satpam/ModalImportSatpam';
import ModalPersonilDetail from '../../components/personil/ModalPersonilDetail';
import ModalSatpamCetak from '../../components/satpam/ModalSatpamCetak';
import ModalSatpamVerifikasi from '../../components/satpam/ModalSatpamVerifikasi';
import Table from '../../components/general/Table';
import { HiOutlinePrinter } from 'react-icons/hi';

export default function Warga() {
  const [data, setData] = useState(['']);
  const [filterData, setFilterData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalVerifikasi, setOpenModalVerifikasi] = useState(false);
  const [satpamDetailId, setSatpamDetailId] = useState('');
  const [showPopover, setShowPopover] = useState('');
  const [isVerify, setVerify] = useState('all');
  const [banjarOptions, setBanjarOptions] = useState([]);
  const [banjarFilterSelected, setBanjarFilterSelected] = useState('all');

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const getData = async () => {
    await api.personilListByType('ktt').then((response) => {
      setData(response.data.data);
    });
  };

  const getBanjarList = async () => {
    await api.personilBanjarData().then((res) => {
      setBanjarOptions(res.data.data);
    });
  };

  useEffect(() => {
    getData();
    getBanjarList();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filteredData = data;
      if (debouncedQuery !== '') {
        filteredData = filteredData.filter(({ satpamkode, satpamstatus, satpamnamalengkap, satpamtipe, satpamjabatan, satpamnohp }) => {
          return `${satpamkode} ${satpamstatus} ${satpamnamalengkap} ${satpamtipe} ${satpamjabatan} ${satpamnohp}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase());
        });
      }

      if (isVerify === 'verify') {
        filteredData = filteredData.filter((item) => item?.is_verified === 1);
      } else if (isVerify === 'unverify') {
        filteredData = filteredData.filter((item) => item?.is_verified !== 1);
      }
      if (banjarFilterSelected !== 'all') {
        filteredData = filteredData.filter((item) => item.banjar_code === banjarFilterSelected);
      }

      setFilterData(filteredData);
    };

    applyFilters();
  }, [data, debouncedQuery, isVerify, banjarFilterSelected]);

  const handleDelete = (data) => {
    setShowPopover('');
    Swal.fire({
      text: `Do you want to delete "${data.satpamnamalengkap}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .satpamDelete({ satpamid: data.satpamid })
            .then((res) => {
              if (res.data.status === false) Swal.fire({ icon: 'error', title: 'Oops...', text: res.data.Message });
              else Swal.fire('Good job!', res.data.Message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  const handleReset = (data) => {
    Swal.fire({
      text: `Do you want to reset "${data.satpamnamalengkap}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const resetData = async () => {
          const res = await api.satpamReset({ satpamid: data.satpamid });
          if (res.data.status) {
            Swal.fire('Good job!', res.data.message, 'success');
          } else
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.data.message
            });
        };
        resetData();
      }
    });
  };

  const handleVerification = (data) => {
    Swal.fire({
      text: `Apakah anda ingin memverifikasi "${data.satpamnamalengkap}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const verifikasiData = async () => {
          const res = await api.satpamVerify({ satpamid: data.satpamid });
          if (res.data.status) {
            Swal.fire('Good job!', res.data.message, 'success');
            getData();
          } else
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.data.message
            });
        };
        verifikasiData();
        setOpenModalVerifikasi(false);
      }
    });
  };

  const handleOpenModalDetail = (id) => {
    setSatpamDetailId(id);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleOpenModalVerifikasi = (id) => {
    setSatpamDetailId(id);
    setOpenModalVerifikasi(true);
    setShowPopover('');
  };

  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamid',
      width: 90,
      render: (data) => (
        <BtnAction
          showPopover={showPopover}
          HideDelete={true}
          setShowPopover={setShowPopover}
          handleOpenModalDetail={handleOpenModalDetail}
          data={data.satpamid}
          id={data}
          page="/personil/warga"
          noPrint={true}
          reset={true}
          handleReset={handleReset}
          verifikasi={true}
          handleVerification={handleVerification}
          handleOpenModalVerifikasi={handleOpenModalVerifikasi}
        />
      )
    },
    {
      title: 'Status',
      accessor: 'is_verified',
      width: 120,
      render: ({ is_verified }) => (
        <div className="flex justify-center">
          {is_verified === 1 ? (
            <span className="flex items-center justify-center gap-1">
              <MdVerified size={20} className="text-primary" /> Terverifikasi
            </span>
          ) : (
            <span className="text-red-700">Belum verifikasi</span>
          )}
        </div>
      )
    },
    {
      title: 'Foto Personil',
      accessor: 'satpamfoto',
      textAlignment: 'center',
      width: 175,
      render: ({ satpamfoto }) => (
        <div className="flex justify-center">
          {satpamfoto && satpamfoto.split('/data-penduduk/')[1] ? (
            <img src={`${process.env.REACT_APP_API_URL_PHOTO}${satpamfoto}`} alt="" className="h-[125px] w-[125px] object-cover my-2 rounded-lg" />
          ) : (
            <img src={require('../../images/avatar.jpg')} alt="" className="h-[125px] w-[125px] object-cover my-2 rounded-lg" />
          )}
        </div>
      )
    },
    {
      title: 'Banjar',
      accessor: 'banjar',
      width: 120,
      render: ({ banjar }) => (banjar ? banjar : '-')
    },
    {
      title: 'Nama Lengkap',
      accessor: 'satpamnamalengkap',
      width: 150,
      render: ({ satpamnamalengkap }) => (satpamnamalengkap ? satpamnamalengkap : '-')
    },
    { title: 'No. Hp', accessor: 'satpamnohp', width: 100, render: ({ satpamnohp }) => (satpamnohp ? satpamnohp : '-') },
    { title: 'Jenis Kelamin', accessor: 'satpamjeniskelamin', width: 110, render: ({ satpamjeniskelamin }) => (satpamjeniskelamin ? satpamjeniskelamin : '-') }
    // {
    //   title: 'Email',
    //   accessor: 'satpamemail',
    //   width: 150,
    //   render: ({ satpamemail }) => (satpamemail ? satpamemail : '-')
    // }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 md:px-10" onClick={() => showPopover && setShowPopover('')}>
      <div className="w-full">
        {/* Table Component */}
        <div className="w-full p-5 mb-10 rounded-lg bg-light shadowAll">
          <div className="flex justify-end mb-3">
            <div className="flex gap-4 w-fit">
              <Tooltip label="Cetak pdf">
                <Link to="/personil/cetak-all-warga">
                  <button className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-900">
                    <HiOutlinePrinter size={24} />
                  </button>
                </Link>
              </Tooltip>
              {adminType === 'Admin Project' && (
                <>
                  {/* <ModalImportSatpam getData={getData} /> */}
                  <Tooltip label="Tambah data">
                    <Link to="/personil/registrasi/warga">
                      <div className="p-2 rounded-md cursor-pointer bg-customGray hover:opacity-80">
                        <AiOutlineFileAdd size={24} />
                      </div>
                    </Link>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          {data.length > 0 ? (
            <div className="p-5">
              <Table
                id="satpamid"
                data={filterData}
                columnData={columnData}
                setQuery={setQuery}
                verify={true}
                setStatus={setVerify}
                banjarOptions={banjarOptions}
                onBanjarFilterChange={setBanjarFilterSelected}
              />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data personil.</div>
            </div>
          )}
        </div>

        <ModalPersonilDetail id={satpamDetailId} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />
        <ModalSatpamVerifikasi
          id={satpamDetailId}
          openModalVerifikasi={openModalVerifikasi}
          setOpenModalVerifikasi={setOpenModalVerifikasi}
          handleVerification={handleVerification}
          data={data.filter((item) => item.satpamid === satpamDetailId)[0]}
        />
      </div>
    </div>
  );
}
