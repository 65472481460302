import React, { useEffect, useState } from 'react';
import * as api from '../../redux/api';
import { useParams, useNavigate } from 'react-router-dom';
import TableCetakPatroliPerPat from '../../components/patroli/TableCetakPatroliPerPat';

const PatroliDetail = () => {
  const [dataPatroli, setDataPatroli] = useState([]);
  const [ruteDetail, setRuteDetail] = useState([]);

  const { kode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Ambil data patroli
    const getDataPatroli = async () => {
      await api
        .patroliDetail({ patroli_kode: kode })
        .then((resDetailPatroli) => {
          setDataPatroli(resDetailPatroli.data);

          // Ambil data detail titik
          resDetailPatroli.data.Detail.map((titik_detail) => {
            const getDetailTitik = async () => {
              await api.patroliDetailRute(titik_detail.satpampatrolidetailkode).then((resDetailTitik) => {
                setRuteDetail((prev) => [...prev, resDetailTitik.data.Data]);
              });
            };
            return getDetailTitik();
          });
        })
        .catch((err) => console.log(err.message));
    };
    getDataPatroli();
  }, [kode]);

  return (
    <div className="w-full p-3">
      <TableCetakPatroliPerPat dataPatroli={dataPatroli} ruteDetail={ruteDetail} as="detail" />

      <button className="w-full h-10 btnAksi" onClick={() => navigate(-1)}>
        Kembali
      </button>
    </div>
  );
};

export default PatroliDetail;
