import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// Import files
import * as api from '../../redux/api';
import { laluLintasUpdateSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';
import { Tooltip } from '@mantine/core';
import { HiOutlineInformationCircle } from 'react-icons/hi';

export default function LaluLintasUpdate({ personilType }) {
  const { kode } = useParams();
  const navigate = useNavigate();
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const kodePerusahaan = profile.user.adminpetugasperusahaankode;

  const [defaultValues, setDefaultValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [satpamKode, setSatpamKode] = useState('');
  const [tanggalAktifitas, setTanggalAktifitas] = useState(new Date());
  const [tanggalMelaporkan, setTanggalMelaporkan] = useState(new Date());
  const [openLalulintas, setOpenLalulintas] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(laluLintasUpdateSchema)
  });

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getLaluLintasByKode = async () => {
      await api.laluLintasByKode({ kode: kode }).then((res) => {
        if (res.data.data) {
          const defaultValue = {
            kategorikode: res.data.data.kategorikode,
            uraian: res.data.data.uraian,
            keterangan: res.data.data.keterangan,
            alamat: res.data.data.alamat,
            status: res.data.data.status,
            is_open: res.data.data.is_open,
            latitude: res.data.data.satpamkegiatlatitude,
            longitude: res.data.data.satpamkegiatlongitude,
            patokan: res.data.data.alamatpatokan,
            keteranganfotosatu: res.data.data.keteranganfotosatu !== 'null' && res.data.data.keteranganfotosatu !== '-' ? res.data.data.keteranganfotosatu : '',
            keteranganfotodua: res.data.data.keteranganfotodua !== 'null' && res.data.data.keteranganfotodua !== '-' ? res.data.data.keteranganfotodua : '',
            keteranganfototiga: res.data.data.keteranganfototiga !== 'null' && res.data.data.keteranganfototiga !== '-' ? res.data.data.keteranganfototiga : '',
            keteranganfotoempat: res.data.data.keteranganfotoempat !== 'null' && res.data.data.keteranganfotoempat !== '-' ? res.data.data.keteranganfotoempat : '',
            keteranganfotolima: res.data.data.keteranganfotolima !== 'null' && res.data.data.keteranganfotolima !== '-' ? res.data.data.keteranganfotolima : '',
            fotosatu: res.data.data.fotosatu.split('/data-lalulintas/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.fotosatu,
            fotodua: res.data.data.fotodua.split('/data-lalulintas/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.fotodua,
            fototiga: res.data.data.fototiga.split('/data-lalulintas/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.fototiga,
            fotoempat: res.data.data.fotoempat.split('/data-lalulintas/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.fotoempat,
            fotolima: res.data.data.fotolima.split('/data-lalulintas/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.fotolima
          };
          setDefaultValues(defaultValue);
          setTanggalMelaporkan(new Date(res.data.data.tanggalmelaporkan));
          setTanggalAktifitas(new Date(res.data.data.tanggalaktifitas));
          reset(defaultValue);
          setOpenLalulintas(res.data.data.is_open);
          setSatpamKode(res.data.data.satpamkode);
        } else {
          setDefaultValues({});
        }
      });
    };
    getLaluLintasByKode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kode]);

  const [fileDataURL] = useImagePreview(watch('fotosatu'));
  const [fileDataURL2] = useImagePreview(watch('fotodua'));
  const [fileDataURL3] = useImagePreview(watch('fototiga'));
  const [fileDataURL4] = useImagePreview(watch('fotoempat'));
  const [fileDataURL5] = useImagePreview(watch('fotolima'));

  useEffect(() => {
    const getData = async () => {
      await api.personilListByType('pecalang').then((res) => {
        res.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [...prev, { value: data.satpamkode, label: data.satpamnamalengkap }]);
        });
      });
    };
    getData();
  }, [personilType]);

  const onSubmit = async (data) => {
    setLoading(true);
    data._method = 'PUT';

    const formData = new FormData();
    formData.append('kode', kode);
    formData.append('perusahaankode', kodePerusahaan);
    formData.append('satpamkode', satpamKode);
    formData.append('tanggal', moment(tanggalMelaporkan).format('YYYY-MM-DDTHH:mm'));
    formData.append('tanggal_aktifitas', moment(tanggalAktifitas).format('YYYY-MM-DDTHH:mm'));

    if (data.fotosatu.length > 0 && typeof data.fotosatu !== 'string') {
      const compressedFile = await imageCompression(data.fotosatu[0], compressOptions);
      formData.append('fotosatu', compressedFile, compressedFile.name);
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    }

    if (data.fotodua.length > 0 && typeof data.fotodua !== 'string') {
      const compressedFile = await imageCompression(data.fotodua[0], compressOptions);
      formData.append('fotodua', compressedFile, compressedFile.name);
    }

    if (data.fototiga.length > 0 && typeof data.fototiga !== 'string') {
      const compressedFile = await imageCompression(data.fototiga[0], compressOptions);
      formData.append('fototiga', compressedFile, compressedFile.name);
    }

    if (data.fotoempat.length > 0 && typeof data.fotoempat !== 'string') {
      const compressedFile = await imageCompression(data.fotoempat[0], compressOptions);
      formData.append('fotoempat', compressedFile, compressedFile.name);
    }

    if (data.fotolima.length > 0 && typeof data.fotolima !== 'string') {
      const compressedFile = await imageCompression(data.fotolima[0], compressOptions);
      formData.append('fotolima', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key !== 'fotosatu' && key !== 'fotodua' && key !== 'fototiga' && key !== 'fotoempat' && key !== 'fotolima') {
        if (key === 'field') {
          formData.append(key, data[key][1]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    await api
      .laluLintasUpdate(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        navigate('/lalu-lintas');
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="flex items-center justify-center w-full py-3 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-gray-50 shadow-black/40">
          Edit Laporan Penutupan Lalu Lintas
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="flex flex-col w-full gap-5">
          <div className="flex flex-wrap gap-5 text-sm lg:flex-nowrap">
            <div className="flex flex-col w-full gap-5">
              <div className="flex flex-col justify-around p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Personil</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={satpamDataOptions}
                      value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                      className="w-full"
                      placeholder="Pilih personil"
                      isSearchable={true}
                      defaultValue={satpamKode}
                      name="satpamkode"
                      required
                      onChange={(e) => setSatpamKode(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-around p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Uraian kegiatan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.uraian ? 'invalidForm' : 'inputForm'}`} rows={3} {...register('uraian')} />
                    <p className="text-xs text-errorText">{errors.uraian?.message}</p>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Keterangan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.keterangan ? 'invalidForm' : 'inputForm'}`} rows={3} {...register('keterangan')} />
                    <p className="text-xs text-errorText">{errors.keterangan?.message}</p>
                  </div>
                </div>
                <div className="items-center my-3 md:flex">
                  <div className="flex gap-2 md:w-1/3">
                    <label className="block mb-1 font-semibold capitalize md:mb-0">Sebarkan Informasi</label>
                    <Tooltip
                      multiline
                      w={220}
                      withArrow
                      transitionProps={{ duration: 200 }}
                      label={'Informasi penutupan lalu lintas yang bersifat publik  akan dibagikan kepada masyarakat dan Hotel yang berada di sekitar desa adat'}>
                      <button className="my-auto">
                        <HiOutlineInformationCircle size={16} />
                      </button>
                    </Tooltip>
                  </div>
                  <div className="md:w-2/3">
                    <select className={`w-full flex flex-col font-normal ${errors.is_open ? 'invalidForm' : 'inputForm'}`} {...register('is_open')}>
                      <option disabled value="">
                        Belum Ditentukan
                      </option>
                      <option value={0}>Private</option>
                      <option value={1}>Publik</option>
                    </select>
                    <p className="text-xs text-errorText">{errors.status?.message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-5">
              <div className="p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Patokan lokasi</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className="w-full inputForm" {...register('patokan')} />
                  </div>
                </div>
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Alamat</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.alamat ? 'invalidForm' : 'inputForm'}`} {...register('alamat')} />
                    <p className="text-xs text-errorText">{errors.alamat?.message}</p>
                  </div>
                </div>
                <div className="items-center hidden md:flex">
                  <div className="hidden md:w-1/3">
                    <label className="hidden mb-1 font-semibold md:mb-0">Latitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input type="text" className="hidden w-full inputForm" {...register('latitude')} />
                  </div>
                </div>
                <div className="items-center hidden md:flex">
                  <div className="hidden md:w-1/3">
                    <label className="hidden mb-1 font-semibold md:mb-0">Longtitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input type="text" className="hidden w-full inputForm" {...register('longtitude')} />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_aktifitas" className="block mb-1 font-semibold md:mb-0">
                      Tanggal kegiatan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalAktifitas}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalAktifitas(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                      Tanggal melaporkan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalMelaporkan}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalMelaporkan(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Status kegiatan</label>
                  </div>
                  <div className="md:w-2/3">
                    <select className={`w-full flex flex-col font-normal ${errors.status ? 'invalidForm' : 'inputForm'}`} {...register('status')}>
                      <option disabled value="">
                        Pilih status kegiatan
                      </option>
                      <option value={0}>Open</option>
                      <option value={1}>Close</option>
                    </select>
                    <p className="text-xs text-errorText">{errors.status?.message}</p>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="proses_keterangan" className="block mb-1 font-semibold md:mb-0">
                      Keterangan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full text-sm cursor-pointer ${errors.proses_keterangan ? 'invalidForm' : 'inputForm'}`}
                      {...register('proses_keterangan')}
                    />
                    <p className="text-xs text-errorText">{errors.proses_keterangan?.message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-5 rounded-lg bg-gray-50 shadowAll">
            <div className="mx-auto">
              <div className="flex flex-wrap -m-1 md:-m-2">
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('fotosatu')} accept="image/*" />
                        </label>
                        <p className="text-xs text-errorText">{errors.fotosatu?.message}</p>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 1</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keteranganfotosatu')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL2 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL2} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('fotodua')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 2</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keteranganfotodua')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL3 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL3} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('fototiga')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 3</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keteranganfototiga')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL4 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL4} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('fotoempat')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 4</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keteranganfotoempat')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL5 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL5} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('fotolima')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 5</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keteranganfotolima')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full px-10 py-5 mb-5 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <Link to="../lalu-lintas">
              <button className="px-5 py-2 text-sm rounded-md btn text-gray-50 bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
