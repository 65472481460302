import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../api';

export const mainMenu = createAsyncThunk('menu', async () => {
  try {
    let menuData = [];
    const res = await api.mainMenu();
    const resChild = await api.childMenu();
    menuData = [...res.data.data, ...resChild.data.data];
    return menuData;
  } catch (error) {
    return error.response.data;
  }
});

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menuData: [],
    error: '',
    loading: false
  },
  reducers: {},
  extraReducers: {
    [mainMenu.pending]: (state, action) => {
      state.loading = true;
    },
    [mainMenu.fulfilled]: (state, action) => {
      state.loading = false;
      state.menuData = action.payload;
      localStorage.setItem('menuData', JSON.stringify(action.payload));
    },
    [mainMenu.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      localStorage.removeItem('menuData');
    }
  }
});

export default menuSlice.reducer;
