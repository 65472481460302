import moment from 'moment';
import { Modal } from '@mantine/core';
import { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import LoadingModal from '../general/LoadingModal';
import PhotoDetail from '../general/PhotoDetail';
import { parseToNewLine } from '../../utils/parseToNewLine';

const ModalLaluLintasDetail = ({ kode, openModalDetail, setOpenModalDetail }) => {
  const [dataKegiatan, setDataKegiatan] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getDataKegiatanByKode = async () => {
        await api.laluLintasByKode({ kode: kode }).then((res) => {
          setDataKegiatan(res.data.data);
        });
      };
      getDataKegiatanByKode();
    }
    return setDataKegiatan({});
  }, [kode]);

  return (
    <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Laporan</div>
      {dataKegiatan?.kode ? (
        <div>
          <div className="text-dark scrollbar-thin px-5">
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kode Kegiatan</div>
              <div className="w-full">{dataKegiatan?.kode}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nama Perusahaan</div>
              <div className="w-full">{dataKegiatan?.satpamperusahaannama}</div>
            </div>
            {/* <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Perusahaan</div>
            <div className="w-full">{dataKegiatan?.satpamperusahaankode}</div>
          </div> */}
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Kode Kegiatan</div>
              <div className="w-full">{dataKegiatan?.satpamkode}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nama Lengkap</div>
              <div className="w-full">{dataKegiatan?.satpamnamalengkap}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Tanggal Melaporkan</div>
              <div className="w-full">{moment(dataKegiatan?.tanggalmelaporkan).format('DD MMM YYYY HH:mm') || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Tanggal Aktifitas</div>
              <div className="w-full">{moment(dataKegiatan?.tanggalaktifitas).format('DD MMM YYYY HH:mm') || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Uraian Kegiatan</div>
              <div className="w-full">{dataKegiatan?.uraian !== '' && parseToNewLine(dataKegiatan?.uraian)}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Keterangan Kegiatan</div>
              <div className="w-full">{dataKegiatan?.keterangan !== '' && parseToNewLine(dataKegiatan?.keterangan)}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Latitude</div>
              <div className="w-full">{dataKegiatan?.latitude && dataKegiatan?.latitude !== 'undefined' ? dataKegiatan?.latitude : '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Longitude</div>
              <div className="w-full">{dataKegiatan?.longitude && dataKegiatan?.longitude !== 'undefined' ? dataKegiatan?.longitude : '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Alamat Kegiatan</div>
              <div className="w-full">{dataKegiatan?.alamat}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Patokan</div>
              <div className="w-full">{dataKegiatan?.alamatpatokan}</div>
            </div>
            {/* Album Photo */}
            <PhotoDetail photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.fotosatu} keterangan={dataKegiatan?.keteranganfotosatu} data="/data-lalulintas/" no="1" />
            <PhotoDetail photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.fotodua} keterangan={dataKegiatan?.keteranganfotodua} data="/data-lalulintas/" no="2" />
            <PhotoDetail photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.fototiga} keterangan={dataKegiatan?.keteranganfototiga} data="/data-lalulintas/" no="3" />
            <PhotoDetail
              photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.fotoempat}
              keterangan={dataKegiatan?.keteranganfotoempat}
              data="/data-lalulintas/"
              no="4"
            />
            <PhotoDetail photo={process.env.REACT_APP_API_URL_PHOTO + dataKegiatan?.fotolima} keterangan={dataKegiatan?.keteranganfotolima} data="/data-lalulintas/" no="5" />
            <div className="flex justify-end mt-5">
              <div className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer" onClick={() => setOpenModalDetail(false)}>
                Kembali
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingModal />
      )}
    </Modal>
  );
};

export default ModalLaluLintasDetail;
