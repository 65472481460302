import React, { useState, useEffect } from 'react';
import { Popover } from '@mantine/core';
import moment from 'moment';
import * as api from '../../redux/api';
import Table from '../../components/general/Table';
import ModalDetailListResponse from '../../components/respon-darurat/ModalDetailListResponse';
import { useDebouncedValue } from '@mantine/hooks';

const ListRespon = () => {
  const [data, setData] = useState(['']);
  const [modalDetail, setModalDetail] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [detailId, setDetailId] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  const getData = async () => {
    await api.listKeadaanClient().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(({ perusahaankode, type, address, content, perusahaan_request_code, tim }) => {
        if (
          debouncedQuery !== '' &&
          !`${perusahaankode} ${type} ${address} ${content}${perusahaan_request_code}${tim} `.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  // open modal
  const openDetail = (id) => {
    setDetailId(id);
    setModalDetail(true);
  };

  const columnData = [
    {
      title: 'Action',
      accessor: 'id',
      width: 70,
      render: (row) => {
        return (
          <div className="flex items-center justify-center gap-1">
            {/* Button Aksi */}
            <Popover width={110} position="right" withArrow shadow="xl">
              <Popover.Target>
                <div className="px-2 py-1 my-1 btnAksi">Action</div>
              </Popover.Target>

              <Popover.Dropdown className="popOverDropdown">
                <div className="popOverItem" onClick={() => openDetail(row.id)}>
                  <i className="fa-solid fa-circle-info"></i> Detail
                </div>
              </Popover.Dropdown>
            </Popover>
          </div>
        );
      }
    },
    {
      title: 'Tanggal',
      accessor: 'created_at',
      width: 150,
      cellsStyle: {
        verticalAlign: 'center'
      },
      render: (row) => {
        return <div>{moment(row.created_at).format('DD MMM YYYY HH:mm')}</div>;
      }
    },
    {
      title: 'Tim Respon',
      accessor: 'tim',
      width: 150,
      cellsStyle: {
        verticalAlign: 'center'
      },
      render: (row) => {
        const { tim } = row;
        if (Array.isArray(tim) && tim.length > 0) {
          return (
            <div>
              {tim.map((val) => (
                <React.Fragment key={val.id}>
                  <div className="flex flex-col gap-2">
                    <p className="flex ">
                      <span>-</span>
                      <span>{val.name}</span>
                    </p>
                  </div>
                </React.Fragment>
              ))}
            </div>
          );
        } else {
          return <p>No data available</p>;
        }
      }
    },

    {
      title: 'Client',
      accessor: 'perusahaan_request_code',
      width: 150,
      cellsStyle: {
        verticalAlign: 'center'
      }
    },
    {
      title: 'Kategori Keadaan Darurat',
      accessor: 'type',
      width: 150,
      cellsStyle: {
        verticalAlign: 'center'
      }
    },
    {
      title: 'Pesan',
      accessor: 'content',
      width: 150,
      cellsStyle: {
        verticalAlign: 'center'
      }
    }
  ];

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 overflow-x-hidden md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="justify-center w-full min-h-screen pb-8 mb-5 rounded-lg bg-gray-50 shadowAll">
        <div className="flex items-center justify-end">
          <div className="flex px-5 my-3">
            <div className="flex w-full gap-4">
              {/* <button
                onClick={() => {
                  setModalCreate(true);
                }}>
                <div className="flex items-center p-2 text-gray-700 rounded-md cursor-pointer bg-customGray hover:opacity-80">
                  <AiOutlineFileAdd size={24} />
                </div>
              </button> */}
            </div>
          </div>
        </div>

        <div className="p-5">
          <Table data={filterData} columnData={columnData} setQuery={setQuery} />
        </div>
        <ModalDetailListResponse id={detailId} open={modalDetail} onClose={setModalDetail} />
      </div>
    </div>
  );
};

export default ListRespon;
