import React, { useEffect, useState } from 'react';
import { DataTable } from 'mantine-datatable';
import { ScaleLoader } from 'react-spinners';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export default function Table_Kontribusi({ columnData, data, searchQuery, setSearchQuery, id, loading, isuserName, setSelectedMonth, setSelectedStatus }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [records, setRecords] = useState(data?.slice(0, pageSize));
  const [totalFilteredRecords, setTotalFilteredRecords] = useState(data?.length);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    const filteredData = data.filter((item) => {
      const searchQueryLower = searchQuery.toLowerCase();
      const codeMatch = item.code.toLowerCase().includes(searchQueryLower);
      const kramaMatch = item.user?.profile?.krama?.toUpperCase().includes(searchQueryLower);
      const nameMatch = isuserName[item.user_code]?.toLowerCase().includes(searchQueryLower);

      // Check if "krama" matches "KT" or "T"
      const kramaIsKT = kramaMatch === 'KT';
      const kramaIsT = kramaMatch === 'T';

      return codeMatch || nameMatch || kramaIsKT || kramaIsT;
    });
    const filteredRecords = filteredData.slice(from, to);

    setRecords(filteredRecords);
    setTotalFilteredRecords(filteredData.length); // Update total filtered records
  }, [data, page, pageSize, searchQuery, isuserName]);

  const handleMonthFilterChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  return (
    <>
      {/* Show data and search */}
      <div className="flex flex-col flex-wrap justify-between py-2 mb-0 md:flex-row-reverse md:mb-5 ">
        <div className="p-2">
          <div className="bg-gray-300 hover:bg-gray-200 rounded-md border p-2 justify-end max-w-[40px] mb-5 md:mb-0">
            <Link to={'/kontribusi/create'}>
              <AiOutlineFileAdd size={24} className="font-semibold" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ">
          {/* filter by status */}
          <div className="flex flex-col w-full mb-5 text-xs md:w-fit md:mb-0">
            <span>Filter Status</span>
            <select className=" inputForm" onChange={(e) => setSelectedStatus(e.target.value)}>
              <option value="">Semua</option>
              <option value="PAID">Sudah Bayar</option>
              <option value="PROGRESS">Proses</option>
              <option value="UNPAID">Belum Bayar</option>
            </select>
          </div>
          {/* filter by bulan */}
          <div className="flex flex-col w-full mb-5 ml-0 text-xs md:w-fit md:mb-0 md:ml-5">
            <span>Filter Bulan</span>
            <input type="month" className="inputForm" onChange={handleMonthFilterChange} />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between w-full mb-4 md:flex-row md:items-center">
        <div className="flex flex-col w-full gap-3 mb-2 sm:flex-row md:mb-0">
          <div className="flex flex-col w-full text-xs font-semibold md:w-fit">
            <span>Show &nbsp;</span>
            <select className="w-full inputForm" onChange={(e) => setPageSize(e.target.value)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div className="w-full text-xs md:w-1/4">
          <span className="font-semibold">Search: &nbsp;</span>
          <input className="w-full inputForm" type="search" placeholder="search here..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <div></div>
      </div>

      {/* Table */}
      {data.length > 0 ? (
        <div className="w-full h-[80vh] overflow-y-auto overflow-x-auto">
          {loading ? (
            <div className="flex flex-col gap-2 pt-10 items-center h-[400px] bg-white">
              <ScaleLoader color="#B20404" loading={true} size={20} speedMultiplier={0.7} />
              <span>Loading data...</span>
            </div>
          ) : (
            <DataTable
              styles={{
                header: {
                  wordWrap: 'normal'
                }
              }}
              shadow="xs"
              paginationColor="red"
              withColumnBorders
              withBorder
              striped
              highlightOnHover
              verticalSpacing="xs"
              columns={columnData}
              records={records}
              totalRecords={totalFilteredRecords}
              recordsPerPage={pageSize}
              page={page}
              onPageChange={(p) => setPage(p)}
              idAccessor={id}
            />
          )}
        </div>
      ) : (
        <div className="text-center">
          <div className="mt-10 mb-72">Tidak ada data Kontribusi.</div>
        </div>
      )}
    </>
  );
}
