import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useImagePreview from '../../hooks/useImagePreview';
import { perusahaanUpdateSchema } from '../../utils/schemaValidation';
import imageCompression from 'browser-image-compression';
import Select from 'react-select';

export default function PerusahaanUpdate() {
  const [loading, setLoading] = useState(false);
  const [kotaOptions, setKotaOptions] = useState([{ value: '', label: 'Pilih Kota/Kabupaten' }]);
  const [kecamatanOptions, setKecamatanOptions] = useState([{ value: '', label: 'Pilih Kecamatan' }]);
  const [kotaSelected, setKotaSelected] = useState('');
  const [kecamatanSelected, setKecamatanSelected] = useState({ value: '', label: 'Pilih Kecamatan' });
  const [messageKecamatan, setMessageKecamatan] = useState(null);

  const navigate = useNavigate();
  const { kode } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(perusahaanUpdateSchema)
  });

  const [fileDataURL] = useImagePreview(watch('satpamperusahaanfoto'));

  useEffect(() => {
    const getDataKota = async (formData, kotaSelected) => {
      await api
        .getKota(formData)
        .then((res) => {
          const result = res.data.data.map((data) => {
            return { value: data.satpamkotakode, label: data.satpamkotanama };
          });
          setKotaOptions(result);

          getDataKecamatan(kotaSelected, true);
        })
        .catch((err) => console.error(err.message));
    };

    const getPerusahaanByKode = async () => {
      await api.getDetailPerusahaan(kode).then((res) => {
        if (res.data.data.length > 0) {
          const item = res.data.data[0];

          const defaultValue = {
            satpamperusahaannama: item.satpamperusahaannama !== null ? item.satpamperusahaannama : '',
            satpamperusahaanalamat: item.satpamperusahaanalamat !== null ? item.satpamperusahaanalamat : '',
            satpamperusahaanstatus: item.satpamperusahaanstatus !== null ? item.satpamperusahaanstatus : '',
            satpamperusahaanradius: item.satpamperusahaanradius !== null ? item.satpamperusahaanradius : '',
            satpamperusahaanlatitude: item.satpamperusahaanlatitude !== null ? item.satpamperusahaanlatitude : '',
            satpamperusahaanlongitude: item.satpamperusahaanlongitude !== null ? item.satpamperusahaanlongitude : '',
            satpamperusahaanfoto: item.satpamperusahaanfoto.split('/data-perusahaan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + item.satpamperusahaanfoto,
            otp_pass: item?.otp_pass === 1 ? true : item.otp_pass === 0 ? false : false
          };

          const resKotaSelected = item.kota_code;
          setKotaSelected(resKotaSelected);
          setKecamatanSelected({ value: item.kecamatan_code, label: item.kecamatan_name });

          const reqDataKota = new FormData();
          // set default value of province bali
          reqDataKota.append('provinsi_kode', 51);
          getDataKota(reqDataKota, resKotaSelected);

          reset(defaultValue);
        }
      });
    };
    getPerusahaanByKode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kode]);

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const onSubmit = async (data) => {
    const otpStatusValue = data.otp_pass === 'true' ? '1' : '0';

    if (kecamatanSelected.value === '') {
      setMessageKecamatan('Kecamatan harus dipilih!');
      return;
    }
    setMessageKecamatan(null);

    setLoading(true);
    data.satpamperusahaankode = kode;
    const formData = new FormData();

    if (data.satpamperusahaanfoto.length > 0 && typeof data.satpamperusahaanfoto !== 'string') {
      const compressedFile = await imageCompression(data.satpamperusahaanfoto[0], compressOptions);
      formData.append('satpamperusahaanfoto', compressedFile, compressedFile.name);
    }

    formData.append('kecamatan', kecamatanSelected.value);

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    formData.set('otp_pass', otpStatusValue);

    await api
      .updatePerusahaan(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        navigate('/desa-adat');
        reset();
        reset({ satpamperusahaanfoto: '' });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  const getDataKecamatan = async (kotaCode, isDefault) => {
    setKotaSelected(kotaCode);
    // reset selected value kecamatan
    if (!isDefault) {
      setKecamatanSelected('');
    }

    const reqDataKecamatan = new FormData();
    // set default value of province bali
    reqDataKecamatan.append('kota_kode', kotaCode);

    await api
      .getKecamatan(reqDataKecamatan)
      .then((res) => {
        const result = res.data.data.map((data) => {
          return { value: data.satpamkecamatankode, label: data.satpamkecamatannama };
        });
        setKecamatanOptions(result);
      })
      .catch((err) => console.error(err.message));
  };

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="flex items-center justify-center py-3 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-gray-50 shadow-black/40">Update Desa Adat</div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="flex flex-col w-full gap-5">
          <div className="flex flex-wrap gap-5 text-sm lg:flex-nowrap">
            <div className="flex flex-col w-full gap-5">
              <div className="flex flex-col justify-around p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Nama Desa Adat</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.satpamperusahaannama ? 'invalidForm' : 'inputForm'}`} {...register('satpamperusahaannama')} />
                    <p className="text-xs text-errorText">{errors.satpamperusahaannama?.message}</p>
                  </div>
                </div>
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Alamat</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.satpamperusahaanalamat ? 'invalidForm' : 'inputForm'}`} rows={3} {...register('satpamperusahaanalamat')} />
                    <p className="text-xs text-errorText">{errors.satpamperusahaanalamat?.message}</p>
                  </div>
                </div>

                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Kota/Kabupaten</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      placeholder="Pilih Kota/Kabupaten"
                      options={kotaOptions}
                      className="w-full"
                      isSearchable={true}
                      value={kotaOptions.filter((item) => item.value === kotaSelected)}
                      name="kota"
                      onChange={(e) => getDataKecamatan(e.value, false)}
                      required
                    />
                  </div>
                </div>

                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Kecamatan</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      placeholder="Pilih Kecamatan"
                      options={kecamatanOptions}
                      className="w-full"
                      isSearchable={true}
                      value={kecamatanSelected}
                      name="kecamatan"
                      onChange={(e) => setKecamatanSelected(e)}
                      required
                    />
                  </div>

                  {messageKecamatan === null || messageKecamatan !== '' ? (
                    ''
                  ) : (
                    <div className="md:w-2/3">
                      <p className="text-xs text-errorText">{messageKecamatan}</p>
                    </div>
                  )}
                </div>

                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Status</label>
                  </div>
                  <div className="md:w-2/3">
                    <select type="text" className={`w-full ${errors.satpamperusahaanstatus ? 'invalidForm' : 'inputForm'}`} {...register('satpamperusahaanstatus')}>
                      <option value="">Pilih status</option>
                      <option value="Aktif">Aktif</option>
                      <option value="Tidak Aktif">Tidak Aktif</option>
                    </select>
                    <p className="text-xs text-errorText">{errors.satpamperusahaanstatus?.message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-5 ">
              <div className="p-5 mb-3 rounded-lg bg-gray-50 shadowAll">
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamfoto" className="block mb-1 font-semibold md:mb-0">
                      Foto Profil
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="flex w-full">
                      <div className="w-full">
                        <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                          <div className="flex flex-col w-full">
                            <label className="flex flex-col items-center justify-center w-full p-2 border-4 border-dashed h-28 hover:bg-gray-100 hover:border-gray-300">
                              {fileDataURL ? (
                                <div className="flex flex-col items-center justify-center">
                                  <img src={fileDataURL} className="object-contain w-full h-24" alt="preview" />
                                </div>
                              ) : (
                                <div className="flex flex-col items-center justify-center pt-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                    viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path
                                      fillRule="evenodd"
                                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                                </div>
                              )}
                              <input type="file" className="opacity-0" {...register('satpamperusahaanfoto')} accept="image/*" />
                            </label>
                          </div>
                          <p className="text-xs text-errorText">{errors.satpamperusahaanfoto?.message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_aktifitas" className="block mb-1 font-semibold md:mb-0">
                      Radius
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.satpamperusahaanradius ? 'invalidForm' : 'inputForm'}`} {...register('satpamperusahaanradius')} />
                    <p className="text-xs text-errorText">{errors.satpamperusahaanradius?.message}</p>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                      Latitude
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.satpamperusahaanlatitude ? 'invalidForm' : 'inputForm'}`} {...register('satpamperusahaanlatitude')} />
                    <p className="text-xs text-errorText">{errors.satpamperusahaanlatitude?.message}</p>
                  </div>
                </div>
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                      longitude
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.satpamperusahaanlongitude ? 'invalidForm' : 'inputForm'}`} {...register('satpamperusahaanlongitude')} />
                    <p className="text-xs text-errorText">{errors.satpamperusahaanlongitude?.message}</p>
                  </div>
                </div>
                <div className="items-center mt-5 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                      akses OTP
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="checkbox" {...register('otp_pass')} />
                    <p className="text-xs text-errorText">{errors.otp_pass?.message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full px-10 py-5 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <Link to="/desa-adat">
              <button className="px-5 py-2 text-sm rounded-md btn text-gray-50 bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
