import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import { distanceFormat } from '../../utils/distanceFormat';

const ModalPOSDetail = ({ kode, openModalDetail, setOpenModalDetail }) => {
  const [dataPOS, setDataPOS] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getDataPOSByKode = async () => {
        await api.detailPos(kode).then((res) => {
          setDataPOS(res.data.data[0]);
        });
      };

      getDataPOSByKode();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="lg"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail POS</div>
      <div className="flex justify-center">
        {dataPOS?.satpamposfoto?.split('data-pos')[1].length > 0 !== null && (
          <img
            src={dataPOS.satpamposfoto}
            alt=""
            className="w-[250px] h-[250px] object-cover mb-5 rounded-xl"
          />
        )}
      </div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode POS</div>
            <div className="w-full">{dataPOS?.satpamposkode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama POS</div>
            <div className="w-full">{dataPOS?.satpamposnama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Keterangan</div>
            <div className="w-full">{dataPOS?.satpamposketerangan}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Radius</div>
            <div className="w-full">
              {dataPOS?.satpamposradius !== null &&
                distanceFormat(parseInt(dataPOS?.satpamposradius))}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Latitude</div>
            <div className="w-full">{dataPOS?.satpamposlatitude}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Longtitude</div>
            <div className="w-full">{dataPOS?.satpamposlongitude}</div>
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <div
            className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer"
            onClick={() => setOpenModalDetail(false)}>
            Kembali
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPOSDetail;
