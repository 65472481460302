import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useState, useEffect } from 'react';
// Import files
import * as api from '../../redux/api';
import BtnAction from '../../components/general/BtnAction';
import LoadingPage from '../../components/general/LoadingPage';
import ModalSatpamDetail from '../../components/satpam/ModalSatpamDetail';
import Table from '../../components/general/Table';

export default function AdminPecalang() {
  const [data, setData] = useState(['']);
  const [filterData, setFilterData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [satpamDetailId, setSatpamDetailId] = useState('');
  const [showPopover, setShowPopover] = useState('');
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  const location = useLocation();
  const pathName = location.pathname.split('/')[2];
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const getData = async () => await api.personilListByType(pathName === 'admin' ? 'admin' : 'pecalang').then((response) => setData(response.data.data));

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  useEffect(() => {
    setFilterData(
      data.filter(({ satpamkode, satpamstatus, satpamnamalengkap, satpamtipe, satpamjabatan, satpamnohp }) => {
        if (
          debouncedQuery !== '' &&
          !`${satpamkode} ${satpamstatus} ${satpamnamalengkap} ${satpamtipe} ${satpamjabatan} ${satpamnohp}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  const handleDelete = (data) => {
    setShowPopover('');
    Swal.fire({
      text: `Do you want to delete "${data.satpamnamalengkap}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .satpamDelete({ satpamid: data.satpamid })
            .then((res) => {
              if (res.data.status === false) Swal.fire({ icon: 'error', title: 'Oops...', text: res.data.Message });
              else Swal.fire('Good job!', res.data.Message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  const handleReset = (data) => {
    Swal.fire({
      text: `Do you want to reset "${data.satpamnamalengkap}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const resetData = async () => {
          const res = await api.satpamReset({ satpamid: data.satpamid });
          if (res.data.status) {
            Swal.fire('Good job!', res.data.message, 'success');
          } else
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.data.message
            });
        };
        resetData();
      }
    });
  };

  const handleOpenModalDetail = (id) => {
    setSatpamDetailId(id);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamid',
      width: 90,
      render: (data) => (
        <BtnAction
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          handleOpenModalDetail={handleOpenModalDetail}
          HideDelete={true}
          data={data.satpamid}
          id={data}
          page="/personil"
          noPrint={true}
          reset={true}
          handleReset={handleReset}
        />
      )
    },
    {
      title: 'Foto Personil',
      accessor: 'satpamfoto',
      textAlignment: 'center',
      render: ({ satpamfoto }) => (
        <div className="flex justify-center">
          {satpamfoto && satpamfoto.split('/data-satpam/')[1] ? (
            <img src={`${process.env.REACT_APP_API_URL_PHOTO}${satpamfoto}`} alt="" className="h-[125px] w-[125px] object-cover my-2 rounded-lg" />
          ) : (
            <img src={require('../../images/satpam.jpg')} alt="" className="h-[125px] w-[125px] object-cover my-2 rounded-lg" />
          )}
        </div>
      )
    },
    {
      title: 'Nama Lengkap',
      accessor: 'satpamnamalengkap',
      render: ({ satpamnamalengkap }) => (satpamnamalengkap ? satpamnamalengkap : '-')
    },
    {
      title: 'Jabatan',
      accessor: 'satpamjabatan',
      render: ({ satpamjabatan }) => (satpamjabatan ? satpamjabatan : '-')
    },
    { title: 'No. Hp', accessor: 'satpamnohp', render: ({ satpamnohp }) => (satpamnohp ? satpamnohp : '-') },
    { title: 'Jenis Kelamin', accessor: 'satpamjeniskelamin', render: ({ satpamjeniskelamin }) => (satpamjeniskelamin ? satpamjeniskelamin : '-') }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 md:px-10" onClick={() => showPopover && setShowPopover('')}>
      <div className="w-full">
        {/* Table Component */}
        <div className="w-full p-5 mb-10 rounded-lg bg-light shadowAll">
          <div className="flex justify-end mb-3">
            <div className="flex gap-4 w-fit">
              {/* <ModalSatpamCetak /> */}
              {adminType === 'Admin Project' && (
                <>
                  {/* <ModalImportSatpam getData={getData} /> */}
                  <Tooltip label="Tambah data">
                    <Link to="/personil/registrasi">
                      <div className="p-2 rounded-md cursor-pointer bg-customGray hover:opacity-80">
                        <AiOutlineFileAdd size={24} />
                      </div>
                    </Link>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          {data.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} id="satpamid" />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data personil.</div>
            </div>
          )}
        </div>

        <ModalSatpamDetail id={satpamDetailId} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} showMenu={pathName === 'petugas'} />
      </div>
    </div>
  );
}
