import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { MdVerified } from 'react-icons/md';
import { Modal } from '@mantine/core';
import { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import LoadingModal from '../general/LoadingModal';

export default function ModalKeadaanDetail({ kode, openModalDetail, setOpenModalDetail, data, handleVerification, loading }) {
  const [dataKeadaan, setDataKeadaan] = useState({});
  // const [dataSatpam, setDataSatpam] = useState([]);

  useEffect(() => {
    if (kode !== '') {
      const getKeadaanByKode = async () => {
        await api.keadaanDetail(kode).then((res) => {
          setDataKeadaan(res.data.keadaan);
          // setDataSatpam(res.data.satpam);
        });
      };
      getKeadaanByKode();
    }
    return setDataKeadaan({});
  }, [kode]);

  return (
    <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">{data?.is_verified === 1 ? 'Detail' : 'Verifikasi'} Laporan Keadaan Darurat</div>
      {dataKeadaan?.satpamkeadaantanggal ? (
        <div>
          <div className="text-dark scrollbar-thin px-5">
            <div className="flex flex-col  w-full gap-2 mb-4 mx-auto ">
              <div className="w-full font-bold text-center">Foto Keadaan</div>
              <div className="w-full mx-auto">
                <img src={dataKeadaan?.file_image} alt="image" className="max-h-80 w-full lg:max-w-md rounded-md mx-auto" />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row w-full gap-2 mb-2">
              <div className="sm:w-3/5 w-full font-bold">Tanggal</div>
              <div className="w-full">
                {dataKeadaan?.satpamkeadaantanggal !== '' &&
                  dataKeadaan?.satpamkeadaantanggal !== null &&
                  moment(dataKeadaan?.satpamkeadaantanggal).format('DD MMM YYYY HH:mm')}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row w-full gap-2 mb-2">
              <div className="sm:w-3/5 w-full font-bold">Pesan</div>
              <div className="w-full">{dataKeadaan?.satpamkeadaanpesan || '-'}</div>
            </div>
            <div className="flex flex-col sm:flex-row w-full gap-2 mb-2">
              <div className="sm:w-3/5 w-full font-bold">Alamat</div>
              {dataKeadaan?.link_maps ? (
                <a href={dataKeadaan?.link_maps} target="_blank" rel="noreferrer" className="text-primary underline w-full">
                  {dataKeadaan?.satpamkeadaanalamat || '-'}
                </a>
              ) : (
                <span className="w-full">{dataKeadaan?.satpamkeadaanalamat || '-'}</span>
              )}
            </div>
            <div className="flex flex-col sm:flex-row w-full gap-2 mb-2">
              <div className="sm:w-3/5 w-full font-bold">Pengirim</div>
              <div className="w-full">{dataKeadaan?.satpamnamalengkap || '-'}</div>
              {/* <div className="w-full">
                <div className="flex flex-col">
                  {dataSatpam?.length > 0
                    ? dataSatpam.map((personil) => (
                        <div key={personil.satpamkode}>
                          {dataSatpam?.length > 1 && '-'} {personil.satpamnamalengkap}
                        </div>
                      ))
                    : 'Semua personil'}
                </div>
              </div> */}
            </div>
          </div>
          {data?.is_verified !== 1 && (
            <div className="flex flex-col justify-center items-center gap-2">
              <div className="mt-10 bg-primary text-white w-fit rounded-lg py-1 px-3 cursor-pointer hover:opacity-80" onClick={() => handleVerification(data)}>
                <span className="flex justify-center items-center gap-1">
                  <ClipLoader color="white" loading={loading} size={16} speedMultiplier={0.7} /> {!loading && <MdVerified size={16} />} Verifikasi
                </span>
              </div>
              <div className="text-sm text-red-700">* Laporan ini perlu diverifikasi agar terkirim ke personil</div>
            </div>
          )}
        </div>
      ) : (
        <LoadingModal />
      )}
    </Modal>
  );
}
