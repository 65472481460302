import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import CurrencyInput from 'react-currency-input-field';

export default function KontribusiCreate() {
  const [loading, setIsLoading] = useState(false);
  const [DaftarKode, setDaftarKode] = useState([]);
  const [wargaCode, setWarga] = useState('');
  const [price, setPrice] = useState('');
  const [year, setYear] = useState(moment().format('YYYY'));
  const [month, setMonth] = useState(moment().format('MM'));

  // get data

  const fetchData = async () => {
    try {
      const response = await api.listWarga();
      const data = response.data.data.map((item) => ({
        value: item.user_code,
        label: item.nama
      }));
      setDaftarKode(data);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmitForm = async (e) => {
    e.preventDefault();

    // Check if required fields are empty
    if (!wargaCode || !price || !year || !month) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill in all required fields'
      });
      return;
    }

    const formData = new FormData();
    formData.append('user_code', wargaCode);
    formData.append('price', price);

    // Create a moment object for the selected year and month
    const selectedDate = moment(`${year}-${month}`, 'YYYY-MM');

    // Extract month and year from the moment object
    const selectedMonthValue = selectedDate.month() + 1; // Moment months are 0-based
    const selectedYearValue = selectedDate.year();

    formData.append('bulan', selectedMonthValue);
    formData.append('tahun', selectedYearValue);

    try {
      setIsLoading(true);

      const response = await api.createKontribusi(formData);

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Data submitted successfully'
        });
        // Clear form fields and reset year and month
        setWarga('');
        setPrice('');
        setYear(moment().format('')); // Reset year to current year
        setMonth(moment().format('')); // Reset month to current month
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to submit data'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while submitting data'
      });
      console.error('API Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePriceChange = (value) => {
    setPrice(value);
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full h-screen ">
        <div className="py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-md flex justify-center items-center mb-10 text-center text-lg font-semibold">Tambah Kontribusi</div>
        <form className="w-full flex flex-col gap-5" onSubmit={onSubmitForm}>
          <div className="w-full flex flex-col gap-5">
            <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
              <div className="md:flex">
                <div className="md:w-1/3">
                  <label className="block font-semibold  md:mb-0">Nama Warga</label>
                </div>
                <div className="md:w-2/3">
                  <Select
                    options={DaftarKode}
                    defaultInputValue={wargaCode}
                    className="w-full text-black"
                    placeholder="Pilih Warga"
                    isSearchable={true}
                    value={wargaCode ? DaftarKode.find((option) => option.value === wargaCode) : null}
                    name="wargakode"
                    required
                    onChange={(e) => {
                      // Handle the selected option here
                      setWarga(e.value);
                    }}
                  />
                </div>
              </div>
              <div className="md:flex my-5">
                <div className="md:w-1/3">
                  <label className="block font-semibold  md:mb-0">Masukan Harga</label>
                </div>
                <div className="md:w-2/3 ">
                  <CurrencyInput
                    id="price-input"
                    name="price"
                    placeholder="Enter price"
                    prefix="Rp"
                    decimalsLimit={0}
                    decimalSeparator=","
                    groupSeparator="."
                    allowNegativeValue={false}
                    onValueChange={handlePriceChange}
                    value={price}
                    className="w-full p-2 outline-none border-[1.5px] focus:border-blue-600 hover:border-blue-500 rounded-md"
                  />
                </div>
              </div>
              <div className="md:flex mb-5">
                <div className="md:w-1/3">
                  <label className="block font-semibold  md:mb-0">Pilih Bulan dan Tahun</label>
                </div>
                <div className="md:w-2/3 ">
                  <input
                    type="month"
                    value={`${year}-${month}`}
                    onChange={(e) => {
                      const selectedDate = moment(e.target.value, 'YYYY-MM');
                      setYear(selectedDate.format('YYYY'));
                      setMonth(selectedDate.format('MM'));
                    }}
                    className="w-full p-2 outline-none border-[1.5px] focus:border-blue-600 hover:border-blue-500 rounded-md"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg">
              <Link to="/kontribusi">
                <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">Kembali</button>
              </Link>
              <button type="submit" className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
                {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
