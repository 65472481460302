import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { notifikasiSchema } from '../../utils/schemaValidation';
import Swal from 'sweetalert2';
import Select from 'react-select';

export default function ModalSendNotifikasi({ dataSatpam, openModalSendNotif, setOpenModalSendNotif }) {
  const pengirim = JSON.parse(localStorage.getItem('profile'));
  const pengirimNama = pengirim?.user?.adminpetugasnamalengkap;
  const pengirimJabatan = pengirim?.user?.adminpetugasjabatan;

  const [jenis, setJenis] = useState('Semua');
  const [satpamOptions, setSatpamOptions] = useState([]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [tanggalNotif, setTanggalNotif] = useState(new Date());
  const [satpamSelected, setSatpamSelected] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(notifikasiSchema)
  });

  useEffect(() => {
    dataSatpam?.length > 0 &&
      dataSatpam.map((satpam) =>
        setSatpamOptions((prev) => [
          ...prev,
          {
            label: satpam.satpamnamalengkap,
            value: {
              satpamkode: satpam.satpamkode,
              satpamtoken: satpam.satpamtoken,
              satpamnohp: satpam.satpamnohp
            }
          }
        ])
      );
    setTanggalNotif(new Date());
    reset({
      satpamnotifikasipengirimnama: pengirimNama,
      satpamnotifikasipengirimjabatan: pengirimJabatan
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSatpam, pengirimJabatan, pengirimNama]);

  useEffect(() => {
    if (jenis === 'Semua') {
      setSatpamSelected(satpamOptions);
    } else {
      setSatpamSelected([]);
    }
  }, [jenis, satpamOptions]);

  const onSubmit = async (data) => {
    // setLoadingSubmit(true);
    data.satpamkodeselected = satpamSelected;
    data.satpamnotifikasitanggal = moment(tanggalNotif).format('YYYY-MM-DDTHH:mm');

    if (data.satpamnotifikasijenis !== 'Keadaan Darurat') {
      data.satpamnotifikasilatitude = '';
      data.satpamnotifikasilongitude = '';
    }
    await api
      .createNotifikasi(data)
      .then((res) => {
        setOpenModalSendNotif(false);
        reset();
        setSatpamSelected([]);
        setTanggalNotif(new Date());
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoadingSubmit(false);
      })
      .catch((error) => {
        setLoadingSubmit(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <Modal opened={openModalSendNotif} size="lg" centered closeOnClickOutside onClose={() => setOpenModalSendNotif(false)}>
      <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        <div className="text-center text-lg font-bold mb-1">Kirim Notifikasi</div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col p-5">
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="satpamnotifikasitanggal" className="block font-semibold mb-1 md:mb-0">
                Kirim Ke
              </label>
            </div>
            <div className="md:w-2/3">
              <select className="w-full inputForm" value={jenis} onChange={(e) => setJenis(e.target.value)}>
                <option value="">Pilih Jenis</option>
                <option value="Semua">Semua Personil</option>
                <option value="Beberapa">Beberapa Personil</option>
              </select>
            </div>
          </div>
          {jenis === 'Beberapa' && (
            <div className="md:flex mb-2 items-center">
              <div className="md:w-1/3">
                <label htmlFor="satpamnotifikasitanggal" className="block font-semibold mb-1 md:mb-0">
                  Pilih Personil
                </label>
              </div>
              <div className="md:w-2/3">
                <Select
                  options={satpamOptions}
                  defaultValue={satpamOptions.filter((option) => option.value === satpamSelected.map((selected) => selected.value))}
                  className="w-full"
                  placeholder="Pilih personil"
                  isSearchable
                  isMulti
                  onChange={(e) => setSatpamSelected(e)}
                />
              </div>
            </div>
          )}
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="satpamnotifikasitanggal" className="block font-semibold mb-1 md:mb-0">
                Jenis Notifikasi
              </label>
            </div>
            <div className="md:w-2/3 flex flex-col">
              <select className="w-full inputForm" {...register('satpamnotifikasijenis')}>
                <option value="">Pilih Jenis</option>
                <option value="Umum">Umum</option>
                <option value="Keadaan Darurat">Keadaan Darurat</option>
              </select>
              <p className="text-errorText text-xs">{errors.satpamnotifikasijenis?.message}</p>
            </div>
          </div>
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="satpamnotifikasitanggal" className="block font-semibold mb-1 md:mb-0">
                Tanggal
              </label>
            </div>
            <div className="md:w-2/3">
              <DatePicker
                selected={tanggalNotif}
                className={`w-full text-sm cursor-pointer inputForm`}
                onChange={(date) => setTanggalNotif(date)}
                locale={id}
                showTimeSelect
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                placeholderText="dd/MM/yyyy HH:mm"
                required
              />
            </div>
          </div>
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                Nama Pengirim
              </label>
            </div>
            <div className="md:w-2/3">
              <input type="text" className="w-full inputForm" {...register('satpamnotifikasipengirimnama')} />
            </div>
            <p className="text-errorText text-xs">{errors.satpamnotifikasipengirimnama?.message}</p>
          </div>
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                Jabatan Pengirim
              </label>
            </div>
            <div className="md:w-2/3">
              <input type="text" className="w-full inputForm" {...register('satpamnotifikasipengirimjabatan')} />
              <p className="text-errorText text-xs">{errors.satpamnotifikasipengirimjabatan?.message}</p>
            </div>
          </div>
          {watch('satpamnotifikasijenis') === 'Keadaan Darurat' && (
            <>
              <div className="md:flex mb-2 items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamnotifikasialamat" className="block font-semibold mb-1 md:mb-0">
                    Alamat
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input type="text" className="w-full inputForm" {...register('satpamnotifikasialamat', { required: true })} required />
                  <p className="text-errorText text-xs">{errors.satpamnotifikasialamat?.message}</p>
                </div>
              </div>
              <div className="md:flex mb-2 items-center">
                <div className="md:w-1/3">
                  <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                    Latitude
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    className={`w-full inputForm ${errors.satpamnotifikasilatitude ? 'border-red-500' : ''}`}
                    {...register('satpamnotifikasilatitude', {
                      required: true,
                      pattern: {
                        value: /^-\d+(\.\d+)?$/, // Ensures the format is "-6.193125"
                        message: 'Invalid latitude format'
                      }
                    })}
                    placeholder="ex: -6.19xx"
                    inputMode="decimal"
                    onKeyPress={(e) => {
                      const allowedChars = ['-', '.', ',']; // Allow "-" and "." and ","
                      if (!/[0-9]/.test(e.key) && !allowedChars.includes(e.key)) {
                        e.preventDefault(); // Block all other characters
                      }
                    }}
                    required
                  />
                  <p className="text-errorText text-xs">{errors.satpamnotifikasilatitude?.message}</p>
                </div>
              </div>
              <div className="md:flex mb-2 items-center">
                <div className="md:w-1/3">
                  <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                    Longitude
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    className={`w-full inputForm ${errors.satpamnotifikasilongitude ? 'border-red-500' : ''}`}
                    {...register('satpamnotifikasilongitude', {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d+)?$/, // Ensures the format is "106.821810"
                        message: 'Invalid longitude format'
                      }
                    })}
                    inputMode="decimal"
                    placeholder="ex : 106.xxx"
                    onKeyPress={(e) => {
                      const allowedChars = ['-', '.', ',']; // Allow "-" and "." and ","
                      if (!/[0-9]/.test(e.key) && !allowedChars.includes(e.key)) {
                        e.preventDefault(); //
                      }
                    }}
                    required
                  />
                  <p className="text-errorText text-xs">{errors.satpamnotifikasilongitude?.message}</p>
                </div>
              </div>
            </>
          )}
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                Judul
              </label>
            </div>
            <div className="md:w-2/3">
              <input type="text" className={`w-full inputForm${errors.satpamnotifikasijudul ? 'border-red-500' : ''}`} {...register('satpamnotifikasijudul')} required />
              <p className="text-errorText text-xs">{errors.satpamnotifikasijudul?.message}</p>
            </div>
          </div>
          <div className="md:flex mb-5">
            <div className="md:w-1/3">
              <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                Isi
              </label>
            </div>
            <div className="md:w-2/3">
              <textarea rows={3} className={`w-full inputForm $errors`} {...register('satpamnotifikasiisi')} required />
              <p className="text-errorText text-xs">{errors.satpamnotifikasiisi?.message}</p>
            </div>
          </div>

          <div className="flex justify-center gap-5 items-center w-full">
            <button type="submit" className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80" disabled={loadingSubmit === true ? true : false}>
              {loadingSubmit ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <button type="button" className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80" onClick={() => setOpenModalSendNotif(false)}>
              Kembali
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
