import ClipLoader from 'react-spinners/ClipLoader';
import React, { useState, useEffect } from 'react';
import UserDropdown from '../dropdowns/UserDropdown';
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from '@mantine/core';
import { useSelector } from 'react-redux';

export default function Sidebar({ handleLogout }) {
  const { menuData, loading } = useSelector((state) => ({ ...state.menu }));

  const [collapseShow, setCollapseShow] = useState('hidden');
  const [activeMainMenu, setActiveMainMenu] = useState('Dashboard');

  const location = useLocation();

  useEffect(() => {
    const filteredChildMenu = menuData?.filter((childmenu) => childmenu.adminmenulink === location.pathname);
    filteredChildMenu?.map((childmenu) => setActiveMainMenu(childmenu.adminmenugrup));
  }, [menuData, location.pathname]);

  return (
    <>
      <nav className="relative z-10 flex flex-wrap items-center justify-between h-full px-3 py-4 bg-bottom bg-no-repeat bg-cover shadow-xl lg:left-0 lg:pb-10 lg:block lg:fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto lg:flex-row lg:flex-nowrap lg:overflow-hidden lg:w-64 bg-none lg:bg-tariBali lg:scrollbar-thin lg:scrollbar-thumb-gray-300 lg:scrollbar-track-gray-100">
        <div className="flex flex-wrap items-center justify-between w-full px-0 mx-auto lg:flex-col lg:items-stretch lg:flex-nowrap">
          {/* Toggler */}
          <button
            aria-label="burger"
            className="px-3 py-1 text-xl leading-none text-black bg-transparent border border-transparent border-solid rounded opacity-50 cursor-pointer lg:hidden"
            type="button"
            onClick={() => setCollapseShow('bg-white bg-tariBali bg-cover bg-no-repeat bg-right-bottom m-2 py-2 px-6')}>
            <i className="fas fa-bars fa-lg"></i>
          </button>
          {/* Brand */}
          <Link to="/">
            <div className="flex flex-col items-center p-0 text-sm lg:p-2">
              <img src={require('../../images/logo.png')} alt="" className="hidden w-32 h-32 mb-3 lg:block" />
              {/* <img
                src={require('../../images/tulisan_bali_hitam.png')}
                alt=""
                className="hidden lg:block h-[40px] w-full"
              /> */}
              <h4 className="text-2xl text-center lg:text-lg">
                <div className="font-semibold text-primary">Bankamda Kalpika Bali</div>
              </h4>

              {/* <h5 className="mt-3">{profile?.user?.adminpetugasnamalengkap}</h5> */}
            </div>
          </Link>

          {/* User */}
          <ul className="flex flex-wrap items-center list-none lg:hidden">
            <li className="relative inline-block">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'lg:flex lg:flex-col lg:items-stretch lg:opacity-100 lg:relative lg:mt-4 lg:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }>
            {/* Collapse header */}
            <div className="block lg:min-w-full lg:hidden">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link to="/" className="inline-block p-4 px-0 mr-0 text-lg font-bold text-left lg:block text-primary whitespace-nowrap">
                    {process.env.REACT_APP_NAME}
                  </Link>
                </div>
                <div className="flex justify-end w-6/12">
                  <button
                    aria-label="menu"
                    type="button"
                    className="px-3 py-1 text-xl leading-none text-black bg-transparent border border-transparent border-solid rounded opacity-50 cursor-pointer lg:hidden"
                    onClick={() => setCollapseShow('hidden')}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Divider */}
            {/* <hr className="my-4 lg:min-w-full" /> */}
            {/* Heading */}
            {loading ? (
              <div className="flex items-center justify-center">
                <ClipLoader color="blue" loading={true} size={20} speedMultiplier={0.7} /> &nbsp; data loading...
              </div>
            ) : (
              menuData?.filter((mainmenu) => mainmenu?.adminmenuclass === 'Main')?.length > 0 &&
              menuData
                ?.filter((menufilter) => menufilter?.adminmenuclass === 'Main')
                ?.map((mainmenu) => (
                  <div key={mainmenu.adminhakaksesadminmenuid}>
                    {mainmenu?.adminmenulink !== '#' && mainmenu?.adminmenulink !== '' ? (
                      <div key={mainmenu?.adminhakaksesadminmenuid}>
                        <ul className="flex flex-col list-none lg:flex-col lg:min-w-full">
                          <li
                            className={`items-center my-1 pl-2.5 rounded ${
                              location.pathname === mainmenu.adminmenulink
                                ? 'font-bold text-white bg-primary'
                                : 'text-black font-semibold hover:bg-primary hover:text-light hover:font-bold'
                            }`}>
                            <Link
                              to={mainmenu?.adminmenulink}
                              className={`text-sm py-1 block`}
                              state={mainmenu}
                              onClick={() => {
                                setCollapseShow('hidden');
                                setActiveMainMenu(mainmenu.adminmenugrup);
                              }}>
                              <i className={`${mainmenu.adminmenuicon} mr-2 text-sm `}></i> {mainmenu.adminmenuutama}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div key={mainmenu?.adminhakaksesadminmenuid}>
                        <NavLink
                          defaultOpened={activeMainMenu === mainmenu.adminmenugrup ? true : false}
                          className={`font-semibold py-1 my-1 rounded ${
                            activeMainMenu === mainmenu.adminmenugrup ? 'bg-gray-500 hover:bg-gray-600 text-white' : 'hover:bg-gray-500 hover:text-white'
                          }`}
                          label={mainmenu?.adminmenuutama}
                          icon={<i className={mainmenu?.adminmenuicon} />}>
                          {menuData?.filter((childmenu) => childmenu?.adminmenugrup === mainmenu.adminmenugrup && childmenu?.adminmenuclass === 'Child')?.length > 0 && (
                            <>
                              <ul className="flex flex-col list-none lg:flex-col lg:min-w-full">
                                {menuData
                                  ?.filter((childmenu) => childmenu?.adminmenugrup === mainmenu.adminmenugrup && childmenu?.adminmenuclass === 'Child')
                                  ?.map((item) => (
                                    <li
                                      className={`items-center rounded pl-5 ${
                                        location.pathname === item.adminmenulink
                                          ? 'font-bold text-white bg-primary my-1'
                                          : 'text-slate-600 font-semibold hover:bg-primary hover:text-light hover:font-bold'
                                      }`}
                                      key={item?.adminhakaksesadminmenuid}>
                                      <Link
                                        to={item?.adminmenulink}
                                        className={`text-xs py-1 block`}
                                        state={item}
                                        onClick={() => {
                                          setCollapseShow('hidden');
                                          setActiveMainMenu(item.adminmenugrup ? item.adminmenugrup : '');
                                        }}>
                                        <i className={`${item.adminmenuicon} mr-2 text-sm`}></i>
                                        {item.adminmenuutama}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </>
                          )}
                        </NavLink>
                      </div>
                    )}
                  </div>
                ))
            )}
            <NavLink
              className="flex py-1 my-1 font-semibold rounded lg:hidden hover:bg-gray-200"
              label="Logout"
              onClick={handleLogout}
              icon={<i className="fas fa-sign-out"></i>}
            />
          </div>
        </div>
      </nav>
      <div
        className="hidden lg:block border-t border-gray-300 fixed cursor-pointer bottom-0 z-10 lg:w-[256px] py-1 px-4 bg-white hover:bg-primary hover:text-white"
        onClick={handleLogout}>
        <span className="text-sm font-semibold">
          <i className="mr-1 fas fa-sign-out"></i> Logout
        </span>
      </div>
    </>
  );
}
