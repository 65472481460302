import { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Tabs } from '@mantine/core';
import Select from 'react-select';
import KegiatanHarian from '../kegiatan-harian/KegiatanHarian';
import Kejadian from '../kejadian/Kejadian';
import Patroli from '../patroli/Patroli';
import Temuan from '../temuan/Temuan';
import { useSelector, useDispatch } from 'react-redux';
import { setDate, setPerusahaan } from '../../redux/features/perusahaanSlice';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import { lastDayOfMonth } from 'date-fns';

export default function LaporanSemua() {
  const { perusahaanKode, startDateAdmin, endDateAdmin } = useSelector((state) => state.perusahaan);

  const [perusahaanOptions, setPerusahaanOptions] = useState([]);
  const [dateAdmin, setDateAdmin] = useState({
    startDate: startDateAdmin,
    endDate: endDateAdmin
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAllPerusahaan = async () => {
      await api.getAllPerusahaan().then((res) =>
        res.data.data.map((data) => {
          return setPerusahaanOptions((prev) => [...prev, { value: data.satpamperusahaankode, label: data.satpamperusahaannama }]);
        })
      );
    };
    getAllPerusahaan();
  }, []);

  return (
    <>
      <div className="px-8 -mt-8 mb-2">
        <div className="w-full flex flex-col gap-1 items-center">
          <Select
            className="w-full text-sm bg-slate-100"
            options={perusahaanOptions}
            value={perusahaanOptions?.filter((option) => option.value === perusahaanKode)}
            onChange={(e) => dispatch(setPerusahaan(e.value))}
            placeholder="Pilih perusahaan"
            isSearchable
          />
          <div className="w-full flex md:flex-row flex-col justify-center items-center">
            <div className="w-full flex flex-col justify-center items-center">
              <div className="relative block w-full">
                <DatePicker
                  locale={id}
                  className="text-sm cursor-pointer inputForm w-full"
                  selected={new Date(dateAdmin.startDate)}
                  value={dateAdmin.startDate}
                  onChange={(date) =>
                    setDateAdmin({
                      startDate: moment(date).format('YYYY-MM-DD'),
                      endDate: moment(date).endOf('month').format('YYYY-MM-DD')
                    })
                  }
                  placeholderText="YYYY-MM-DD"
                  dateFormat="YYYY-MM-DD"
                />
                <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                  <i className="far fa-calendar"></i>
                </div>
              </div>
            </div>
            <div className="mx-2 my-1 text-sm text-center">to</div>
            <div className="w-full flex flex-col justify-center items-center">
              <div className="relative block w-full">
                <DatePicker
                  locale={id}
                  className="text-sm cursor-pointer inputForm w-full"
                  selected={new Date(dateAdmin.endDate)}
                  value={dateAdmin.endDate}
                  minDate={new Date(dateAdmin.startDate)}
                  maxDate={lastDayOfMonth(new Date(dateAdmin.startDate))}
                  onChange={(date) =>
                    setDateAdmin({
                      ...dateAdmin,
                      endDate: moment(date).format('YYYY-MM-DD')
                    })
                  }
                  placeholderText="YYYY-MM-DD"
                  dateFormat="YYYY-MM-DD"
                />
                <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                  <i className="far fa-calendar"></i>
                </div>
              </div>
            </div>
            <button
              type="submit"
              onClick={() => dispatch(setDate(dateAdmin))}
              className={`md:ml-2 ml-0 btnAksi ${
                moment(dateAdmin.startDate).diff(moment(dateAdmin.endDate)) > 0 && 'bg-gray-400'
              } w-full flex justify-center text-sm py-2 px-5 rounded`}
              disabled={moment(dateAdmin.startDate).diff(moment(dateAdmin.endDate)) > 0 ? true : false}>
              Filter
            </button>
          </div>
        </div>
      </div>

      <div className="w-full">
        <Tabs defaultValue="kegiatan-harian" className="bg-slate-100">
          <Tabs.List>
            <Tabs.Tab value="kegiatan-harian">Kegiatan Harian</Tabs.Tab>
            <Tabs.Tab value="kejadian">Kejadian</Tabs.Tab>
            <Tabs.Tab value="temuan">Temuan</Tabs.Tab>
            <Tabs.Tab value="patroli">Patroli</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="kegiatan-harian">
            <KegiatanHarian isAdmin />
          </Tabs.Panel>
          <Tabs.Panel value="kejadian">
            <Kejadian isAdmin />
          </Tabs.Panel>
          <Tabs.Panel value="temuan">
            <Temuan isAdmin />
          </Tabs.Panel>
          <Tabs.Panel value="patroli">
            <Patroli isAdmin />
          </Tabs.Panel>
        </Tabs>
      </div>
    </>
  );
}
