import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// Import files
import * as api from '../../redux/api';
import { kegiatanHarianUpdateSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';

export default function KegiatanHarianUpdate({ personilType }) {
  const { kode } = useParams();
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const kodePerusahaan = profile.user.adminpetugasperusahaankode;
  const navigate = useNavigate();

  const [defaultValues, setDefaultValues] = useState({});
  const [kategoriDataOptions, setKategoriDataOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [satpamKode, setSatpamKode] = useState('');
  const [tanggalAktifitas, setTanggalAktifitas] = useState(new Date());
  const [tanggalMelaporkan, setTanggalMelaporkan] = useState(new Date());

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(kegiatanHarianUpdateSchema)
  });

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getKegiatanHarianByKode = async () => {
      await api.kegiatanHarianByKode({ satpamkegiatankode: kode }).then((res) => {
        if (res.data.data) {
          const defaultValue = {
            satpamkegiatansatpamkegiatankategorikode: res.data.data.satpamkegiatansatpamkegiatankategorikode,
            uraian: res.data.data.satpamkegiatanuraian,
            keterangan: res.data.data.satpamkegiatanketerangan,
            alamat: res.data.data.satpamkegiatanalamat,
            status: res.data.data.satpamkegiatanstatus,
            latitude: res.data.data.satpamkegiatlatitude,
            longitude: res.data.data.satpamkegiatlongitude,
            patokan: res.data.data.satpamkegiatanalamatpatokan,
            keterangan_foto1:
              res.data.data.satpamkegiatanketeranganfotosatu !== 'null' && res.data.data.satpamkegiatanketeranganfotosatu !== '-'
                ? res.data.data.satpamkegiatanketeranganfotosatu
                : '',
            keterangan_foto2:
              res.data.data.satpamkegiatanketeranganfotodua !== 'null' && res.data.data.satpamkegiatanketeranganfotodua !== '-'
                ? res.data.data.satpamkegiatanketeranganfotodua
                : '',
            keterangan_foto3:
              res.data.data.satpamkegiatanketeranganfototiga !== 'null' && res.data.data.satpamkegiatanketeranganfototiga !== '-'
                ? res.data.data.satpamkegiatanketeranganfototiga
                : '',
            keterangan_foto4:
              res.data.data.satpamkegiatanketeranganfotoempat !== 'null' && res.data.data.satpamkegiatanketeranganfotoempat !== '-'
                ? res.data.data.satpamkegiatanketeranganfotoempat
                : '',
            keterangan_foto5:
              res.data.data.satpamkegiatanketeranganfotolima !== 'null' && res.data.data.satpamkegiatanketeranganfotolima !== '-'
                ? res.data.data.satpamkegiatanketeranganfotolima
                : '',
            foto_kegiatan1:
              res.data.data.satpamkegiatanfotosatu.split('/data-kegiatan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.satpamkegiatanfotosatu,
            foto_kegiatan2:
              res.data.data.satpamkegiatanfotodua.split('/data-kegiatan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.satpamkegiatanfotodua,
            foto_kegiatan3:
              res.data.data.satpamkegiatanfototiga.split('/data-kegiatan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.satpamkegiatanfototiga,
            foto_kegiatan4:
              res.data.data.satpamkegiatanfotoempat.split('/data-kegiatan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.satpamkegiatanfotoempat,
            foto_kegiatan5:
              res.data.data.satpamkegiatanfotolima.split('/data-kegiatan/')[1].length > 0 && process.env.REACT_APP_API_URL_PHOTO + res.data.data.satpamkegiatanfotolima
          };
          setDefaultValues(defaultValue);
          setTanggalMelaporkan(new Date(res.data.data.satpamkegiatantanggalmelaporkan));
          setTanggalAktifitas(new Date(res.data.data.satpamkegiatantanggalaktifitas));
          reset(defaultValue);
          setSatpamKode(res.data.data.satpamkegiatansatpamkode);
        } else {
          setDefaultValues({});
        }
      });
    };
    getKegiatanHarianByKode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kode]);

  const [fileDataURL] = useImagePreview(watch('foto_kegiatan1'));
  const [fileDataURL2] = useImagePreview(watch('foto_kegiatan2'));
  const [fileDataURL3] = useImagePreview(watch('foto_kegiatan3'));
  const [fileDataURL4] = useImagePreview(watch('foto_kegiatan4'));
  const [fileDataURL5] = useImagePreview(watch('foto_kegiatan5'));

  useEffect(() => {
    const getData = async () => {
      await api.personilListByType('pecalang').then((res) => {
        res.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [...prev, { value: data.satpamkode, label: data.satpamnamalengkap }]);
        });
      });
    };
    const getDataKategori = async () => {
      await api.kegiatanHarianKategori().then((res) => {
        setKategoriDataOptions(res.data.data);
      });
    };
    getDataKategori();
    getData();
  }, [personilType]);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('satpamkegiatankode', kode);
    formData.append('perusahaankode', kodePerusahaan);
    formData.append('satpamkode', satpamKode);
    formData.append('tanggal', moment(tanggalMelaporkan).format('YYYY-MM-DDTHH:mm'));
    formData.append('tanggal_aktifitas', moment(tanggalAktifitas).format('YYYY-MM-DDTHH:mm'));

    if (data.foto_kegiatan1.length > 0 && typeof data.foto_kegiatan1 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan1[0], compressOptions);
      formData.append('foto_kegiatan1', compressedFile, compressedFile.name);
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    }

    if (data.foto_kegiatan2.length > 0 && typeof data.foto_kegiatan2 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan2[0], compressOptions);
      formData.append('foto_kegiatan2', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan3.length > 0 && typeof data.foto_kegiatan3 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan3[0], compressOptions);
      formData.append('foto_kegiatan3', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan4.length > 0 && typeof data.foto_kegiatan4 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan4[0], compressOptions);
      formData.append('foto_kegiatan4', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan5.length > 0 && typeof data.foto_kegiatan5 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan5[0], compressOptions);
      formData.append('foto_kegiatan5', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    await api
      .kegiatanUpdate(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        navigate('/kegiatan-harian');
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="flex items-center justify-center w-full py-3 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-gray-50 shadow-black/40">
          Edit Kegiatan Harian
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="flex flex-col w-full gap-5 mb-5">
          <div className="flex flex-wrap gap-5 text-sm lg:flex-nowrap">
            <div className="flex flex-col w-full gap-5">
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Personil</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={satpamDataOptions}
                      value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                      className="w-full"
                      placeholder="Pilih personil"
                      isSearchable={true}
                      defaultValue={satpamKode}
                      name="satpamkode"
                      required
                      onChange={(e) => setSatpamKode(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Kategori</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className={`w-full ${errors.satpamkegiatansatpamkegiatankategorikode ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamkegiatansatpamkegiatankategorikode')}>
                      <option value="">Pilih kategori</option>
                      {kategoriDataOptions.length > 0 &&
                        kategoriDataOptions.map((kategori) => (
                          <option key={kategori.satpamkegiatankategorikode} value={kategori.satpamkegiatankategorikode}>
                            {kategori.satpamkegiatankategorinama}
                          </option>
                        ))}
                    </select>
                    <p className="text-xs text-errorText">{errors.satpamkegiatansatpamkegiatankategorikode?.message}</p>
                  </div>
                </div>
                <div className="mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Uraian kegiatan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.uraian ? 'invalidForm' : 'inputForm'}`} rows={3} {...register('uraian')} />
                    <p className="text-xs text-errorText">{errors.uraian?.message}</p>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Keterangan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea className={`w-full ${errors.keterangan ? 'invalidForm' : 'inputForm'}`} rows={3} {...register('keterangan')} />
                    <p className="text-xs text-errorText">{errors.keterangan?.message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-5">
              <div className="p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Patokan lokasi</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className="w-full inputForm" {...register('patokan')} />
                  </div>
                </div>
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Alamat</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className={`w-full ${errors.alamat ? 'invalidForm' : 'inputForm'}`} {...register('alamat')} />
                    <p className="text-xs text-errorText">{errors.alamat?.message}</p>
                  </div>
                </div>
                <div className="items-center hidden md:flex">
                  <div className="hidden md:w-1/3">
                    <label className="hidden mb-1 font-semibold md:mb-0">Latitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input type="text" className="hidden w-full inputForm" {...register('latitude')} />
                  </div>
                </div>
                <div className="items-center hidden md:flex">
                  <div className="hidden md:w-1/3">
                    <label className="hidden mb-1 font-semibold md:mb-0">Longtitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input type="text" className="hidden w-full inputForm" {...register('longtitude')} />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-lg bg-gray-50 shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_aktifitas" className="block mb-1 font-semibold md:mb-0">
                      Tanggal kegiatan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalAktifitas}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalAktifitas(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                      Tanggal melaporkan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalMelaporkan}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalMelaporkan(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Status kegiatan</label>
                  </div>
                  <div className="md:w-2/3">
                    <select className={`w-full flex flex-col font-normal ${errors.status ? 'invalidForm' : 'inputForm'}`} {...register('status')}>
                      <option disabled value="">
                        Pilih status kegiatan
                      </option>
                      <option value={0}>Open</option>
                      <option value={1}>Close</option>
                    </select>
                    <p className="text-xs text-errorText">{errors.status?.message}</p>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamprosesketerangan" className="block mb-1 font-semibold md:mb-0">
                      Keterangan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full text-sm cursor-pointer ${errors.satpamprosesketerangan ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamprosesketerangan')}
                    />
                    <p className="text-xs text-errorText">{errors.satpamprosesketerangan?.message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-5 rounded-lg bg-gray-50 shadowAll">
            <div className="mx-auto">
              <div className="flex flex-wrap -m-1 md:-m-2">
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('foto_kegiatan1')} accept="image/*" />
                        </label>
                        <p className="text-xs text-errorText">{errors.foto_kegiatan1?.message}</p>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 1</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keterangan_foto1')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL2 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL2} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('foto_kegiatan2')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 2</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keterangan_foto2')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL3 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL3} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('foto_kegiatan3')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 3</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keterangan_foto3')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL4 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL4} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('foto_kegiatan4')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 4</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keterangan_foto4')} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full lg:w-1/5 md:w-1/3">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="flex flex-col items-center justify-center w-full h-40 p-2 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL5 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img src={fileDataURL5} className="object-contain w-full h-36" alt="preview" />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                            </div>
                          )}
                          <input type="file" className="opacity-0" {...register('foto_kegiatan5')} accept="image/*" />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 5</label>
                        <textarea className="w-full inputForm" rows={2} {...register('keterangan_foto5')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full px-10 py-5 mb-5 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <Link to="/kegiatan-harian">
              <button className="px-5 py-2 text-sm rounded-md btn text-gray-50 bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
