import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
// Import files
import * as api from '../../redux/api';
import BtnAction from '../../components/general/BtnAction';
import BtnAdd from '../../components/general/BtnAdd';
import Cards from '../../components/general/Cards';
import CardDashboard from '../../components/dashboard/CardDashboard';
import FilterDate from '../../components/general/FilterDate';
import FilterKategori from '../../components/general/FilterKategori';
import LoadingPage from '../../components/general/LoadingPage';
import ModalTemuanDetail from '../../components/temuan/ModalTemuanDetail';
import PhotoLaporan from '../../components/general/PhotoLaporan';
import ReadMore from '../../components/general/ReadMore';
import ShowModalImage from '../../components/general/ShowModalImage';
import ShowTable from '../../components/general/ShowTable';
import StatusLaporan from '../../components/general/StatusLaporan';

export default function Temuan({ isAdmin }) {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [imageData, setImageData] = useState(null);
  const [data, setData] = useState(['']);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [temuanDetailKode, setTemuanDetailKode] = useState('');
  const [kategori, setKategori] = useState('');
  const [kategoriCount, setKategoriCount] = useState(['']);
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const [tipeLaporan, setTipeLaporan] = useState('');
  const location = useLocation();
  const { perusahaanKode } = useSelector((state) => state.perusahaan);

  // Date
  const [date, setDate] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  const getData = async (formDate) => {
    await api.temuan(formDate).then((res) => {
      setData(res.data.data);
      setDatas(res.data);
      setLoading(false);
    });
  };

  const getCount = async (formData) => {
    await api.countTemuan(formData).then((res) => {
      setKategoriCount(res.data.data);
    });
  };

  useEffect(() => {
    if (location?.state?.date) {
      setDate(location?.state?.date);
      setKategori(location?.state?.kategori);
      setTipeLaporan(location?.state?.tipeLaporan);
    }
  }, [location?.state]);

  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    setLoading(true);
    getCount(formDate);
    getData(formDate);
  }, [date, kategori, perusahaanKode]);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(
          ({
            satpamtemuankode,
            satpamtemuantanggalmelaporkan,
            satpamtemuananalisis,
            satpamnamalengkap,
            satpamtemuanuraian,
            satpamtemuantindakan,
            satpamtemuanpatokan,
            satpamtemuanalamat
          }) => {
            if (
              debouncedQuery !== '' &&
              !`${satpamtemuankode} ${satpamtemuantanggalmelaporkan} ${satpamtemuananalisis} ${satpamnamalengkap} ${satpamtemuanuraian} ${satpamtemuantindakan} ${satpamtemuanpatokan} ${satpamtemuanalamat}`
                .toLowerCase()
                .includes(debouncedQuery.trim().toLowerCase())
            ) {
              return false;
            }
            return true;
          }
        )
      );
    } else setFilterData([]);
  }, [data, debouncedQuery]);

  const handleOpenModalDetail = (temuanKode) => {
    setTemuanDetailKode(temuanKode);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleDelete = (formData) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori
    };

    setShowPopover('');

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    const getData = async () => {
      await api
        .temuan(formDate)
        .then((res) => {
          setData(res.data.data);
          setDatas(res.data);
        })
        .catch((err) => console.log(err.message));
    };

    Swal.fire({
      text: 'Do you want to delete this temuan?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .temuanDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamtemuankode',
      width: 100,
      render: ({ satpamtemuankode }) => (
        <BtnAction
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          handleOpenModalDetail={handleOpenModalDetail}
          handleDelete={handleDelete}
          data={satpamtemuankode}
          id={{ satpamtemuankode }}
          page="/temuan"
        />
      )
    },
    {
      title: 'Foto Temuan',
      accessor: 'satpamtemuanfotosatu',
      width: 150,
      render: ({
        satpamtemuanfotosatu,
        satpamtemuanketeranganfotosatu,
        satpamtemuanfotodua,
        satpamtemuanketeranganfotodua,
        satpamtemuanfototiga,
        satpamtemuanketeranganfototiga,
        satpamtemuanfotoempat,
        satpamtemuanketeranganfotoempat,
        satpamtemuanfotolima,
        satpamtemuanketeranganfotolima
      }) => (
        <PhotoLaporan
          page="/data-temuan/"
          foto1={satpamtemuanfotosatu}
          foto2={satpamtemuanfotodua}
          foto3={satpamtemuanfototiga}
          foto4={satpamtemuanfotoempat}
          foto5={satpamtemuanfotolima}
          ket1={satpamtemuanketeranganfotosatu}
          ket2={satpamtemuanketeranganfotodua}
          ket3={satpamtemuanketeranganfototiga}
          ket4={satpamtemuanketeranganfotoempat}
          ket5={satpamtemuanketeranganfotolima}
          showImage={showImage}
          setShowImage={setShowImage}
          setImageData={setImageData}
        />
      )
    },
    {
      title: 'Tanggal, Kode, dan Status',
      accessor: 'satpamtemuantanggalmelaporkan',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamtemuankode, satpamtemuantanggalmelaporkan, satpamtemuanstatus }) => (
        <StatusLaporan kode={satpamtemuankode} tanggal={satpamtemuantanggalmelaporkan} status={satpamtemuanstatus} />
      )
    },
    {
      title: 'Kategori',
      accessor: 'satpamtemuankategorinama',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamtemuankategorinama }) => <div className="py-0 my-0">{satpamtemuankategorinama ? satpamtemuankategorinama : 'Belum ada kategori'}</div>
    },
    {
      title: 'Analisis',
      accessor: 'satpamtemuananalisis',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamtemuananalisis }) => (
        <div className="py-0 my-0">{satpamtemuananalisis && satpamtemuananalisis !== 'null' ? satpamtemuananalisis : 'Belum ada analisis'}</div>
      )
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Uraian Temuan',
      accessor: 'satpamtemuanuraian',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamtemuanuraian }) => <>{satpamtemuanuraian !== '' && <ReadMore>{satpamtemuanuraian}</ReadMore>}</>
    },
    {
      title: 'Uraian Tindakan',
      accessor: 'satpamtemuantindakan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamtemuantindakan }) => <>{satpamtemuantindakan !== '' && <ReadMore>{satpamtemuantindakan}</ReadMore>}</>
    },
    {
      title: 'Hasil Tindakan',
      accessor: 'satpamtemuanhasil',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamtemuanhasil }) => <>{satpamtemuanhasil !== '' && <ReadMore>{satpamtemuanhasil}</ReadMore>}</>
    },
    {
      title: 'Patokan lokasi',
      accessor: 'satpamtemuanalamatpatokan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Alamat',
      accessor: 'satpamtemuanalamat',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 300
    }
  ];

  if ((data && data[0] === '') || (kategoriCount && kategoriCount[0] === '')) return <LoadingPage />;

  return (
    <div className={`relative w-full min-h-screen p-5 ${isAdmin ? 'mt-1' : '-mt-10'}`} onClick={() => showPopover && setShowPopover('')}>
      {/* Cards */}
      <Cards
        title="Statistik Temuan"
        desc="Berikut ini adalah jumlah temuan yang ditemukan"
        card1={{ title: 'Open', value: datas?.Total_Open || 0 }}
        card2={{ title: 'Close', value: datas?.Total_Close || 0 }}
        card3={{ title: 'Total', value: datas?.Total_Data || 0 }}
        loading={loading}
      />

      {/* Cards */}
      <div className="flex flex-wrap gap-5 text-white">
        {kategoriCount.map((data) => (
          <CardDashboard data={data} loading={loading} />
        ))}
      </div>

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            {!isAdmin && <FilterDate date={date} setDate={setDate} tipeLaporan={tipeLaporan} />}
            <FilterKategori kategori={kategori} setKategori={setKategori} page="temuan" />
          </div>
          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-full">
              <BtnAdd path="/temuan/create" />

              <Tooltip label="Print laporan">
                <Link to={`/temuan-print`} state={{ data: data, date: date }}>
                  <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                    <HiOutlinePrinter size={24} />
                  </div>
                </Link>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* Show Table */}
        <ShowTable loading={loading} datas={datas} filterData={filterData} columnData={columnData} setQuery={setQuery} />
      </div>

      <div className="flex justify-center mt-5">
        <Link to="/">
          <button className="btnAksi bg-primary py-2 px-20">Dashboard</button>
        </Link>
      </div>

      {/* Modal Detail temuan */}
      <ModalTemuanDetail kode={temuanDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

      {/* Modal Image */}
      <ShowModalImage showImage={showImage} setShowImage={setShowImage} imageData={imageData} />
    </div>
  );
}
