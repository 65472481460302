import axios from 'axios';

const API = axios.create({ baseURL: `${process.env.REACT_APP_BASE_URL}/api` });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).access_token}`;
  }
  return req;
});

// Authentication
export const login = (formData) => API.post('/login', formData);
export const logout = (formData) => API.post('/auth/logout', formData);
export const register = (formData) => API.post('/auth/register', formData);

export const sendEmailLaporan = (formData) => API.post('/send-mail', formData);

// Hak akses
export const allMenu = () => API.get('/menu/all_menu');
// hak akses by petugas
export const mainMenu = () => API.get('/menu/main_menu');
export const childMenu = () => API.get('/menu/child_menu');

// Perusahaan
export const getPerusahaan = () => API.post('/satpam-perusahaan');
export const getAllPerusahaan = () => API.post('/satpam-perusahaan/all');
export const getDetailPerusahaan = (kodePerusahaan) => API.post('/satpam-perusahaan/detail', { satpamperusahaankode: kodePerusahaan });
export const createPerusahaan = (formData) =>
  API.post('/satpam-perusahaan/create', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const updatePerusahaan = (formData) =>
  API.post('/satpam-perusahaan/update', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const deletePerusahaan = (kodePerusahaan) => API.post('/satpam-perusahaan/delete', { satpamperusahaankode: kodePerusahaan });

// Petugas
export const getAllPetugas = () => API.post('/auth/all-profile');
export const getProfile = () => API.get('/auth/user-profile');
export const getAllPetugasByCompany = (kodePerusahaan) => API.post('/auth/all-profile', { adminpetugasperusahaankode: kodePerusahaan });
export const getDetailPetugas = (kodePetugas) => API.post('/auth/detail-profile', { adminpetugaskode: kodePetugas });
export const createPetugas = (formData) =>
  API.post('/auth/register', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const updatePetugas = (formData) =>
  API.post('/auth/update', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const changePasswordPetugas = (formData) => API.post('/auth/update_password', formData);
export const deletePetugas = (kodePetugas) => API.post('/auth/delete', { adminpetugaskode: kodePetugas });
export const generateAccountMobile = (formData) => API.post('/auth/generate-account-mobile', formData);

// Kegiatan harian
export const kegiatanHarian = (formDate) => API.post('/satpam-kegiatan', formDate);
export const kegiatanHarianByKode = (formData) => API.post(`/satpam-kegiatan/detail/`, formData);
export const kegiatanHarianDate = () => API.get('/satpam-kegiatan/kegiatan-per-date');
export const detailProses = (kodeKegiatan) => API.post('/satpam-proses', kodeKegiatan);
export const createKegiatan = (formData) =>
  API.post('/satpam-kegiatan/store', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const kegiatanUpdate = (formData) =>
  API.post('/satpam-kegiatan/update', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const kegiatanHarianDelete = (formData) => API.post('/satpam-kegiatan/delete', formData);
export const kegiatanHarianKategori = (formData) => API.post('/satpam-kegiatan-kategori', formData);
export const countHarian = (formData) => API.post('/satpam-kegiatan/count-by-kategori', formData);

// Kejadian
export const kejadian = (formDate) => API.post('/satpam-kejadian', formDate);
export const kejadianByKode = (formData) => API.post('/satpam-kejadian/detail', formData);
export const createKejadian = (formData) =>
  API.post('/satpam-kejadian/create', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const kejadianUpdate = (formData) =>
  API.post('/satpam-kejadian/update', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const kejadianDelete = (formData) => API.post('/satpam-kejadian/delete', formData);
export const kejadianKategori = () => API.get('/satpam-kejadian/kategori');
export const kejadianSubKategori = (formData) => API.post('/satpam-kejadian/kategori/detail', formData);
export const countDasabaya = (formData) => API.post('/satpam-kejadian/count-dasabaya', formData);

// Temuan
export const temuan = (formDate) => API.post('/satpam-temuan', formDate);
export const temuanByKode = (formData) => API.post('/satpam-temuan/detail', formData);
export const temuanDelete = (formData) => API.post('/satpam-temuan/delete', formData);
export const temuanKategori = () => API.get('/satpam-temuan/show/kategori');
export const createTemuan = (formData) => API.post('/satpam-temuan/create', formData);
export const temuanUpdate = (formData) =>
  API.post('/satpam-temuan/update', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const countTemuan = (formData) => API.post('/satpam-temuan/count-by-kategori', formData);

// Patroli
export const patroli = (formData) => API.post('/satpam-patroli/show', formData);
export const createPatroli = (formData) => API.post('/satpam-patroli/create', formData);
export const patroliDetail = (formData) => API.post('/satpam-patroli/detail', formData);
export const patroliDetailRute = (detailkode) => API.post('/satpam-patroli/detail-titik', { patroli_detail_kode: detailkode });
export const patroliDelete = (kode) => API.post('/satpam-patroli/delete', { satpampatrolikode: kode });
export const patroliGetPOS = (formData) => API.post('/satpampos', formData);
export const patroliGetRute = (poskode) => API.post('/satpamtitik/perpos', { satpamtitiksatpamposkode: poskode });
export const patroliSendNotif = (poskode) => API.post('/satpam-patroli/send-notifikasi', { satpampatrolisatpamkode: poskode });
export const patroliHarianDate = () => API.get('/satpam-patroli/per-date');

// Satpam
export const satpamTitik = () => API.post('/satpamtitik');
export const satpamMenuMaster = () => API.post('/satpam-menu-master');
export const satpamMenu = (satpamKode) => API.post('/satpam-menu', satpamKode);

// Personil
export const personilListByType = (type) => API.get(`/personil/list/${type}`);
export const satpamById = (id) => API.get(`/personil/detail/${id}`);
export const satpamDelete = (formData) => API.post('/personil/delete', formData);
export const satpamReset = (formData) => API.post('/personil/reset', formData);
export const satpamRoles = () => API.get('/personil/roles');
export const satpamVerify = (formData) => API.post('/personil/verify', formData);
export const satpamProfile = (id) => API.get(`/personil/profile/${id}`);
export const createSatpam = (formData) =>
  API.post('/personil/create', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const createWarga = (formData) =>
  API.post('/personil/create/ktt', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const updateWarga = (formData) =>
  API.post('/personil/update/ktt', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const satpamUpdate = (formData) =>
  API.post('/personil/update', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const imporSatpam = (formData) =>
  API.post('/personil/import', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const personilBanjarData = () => API.get('/personil/banjar/list');

// POS
export const detailPos = (kode) => API.post('/satpampos/detail', { satpamposkode: kode });
export const createPos = (formData) =>
  API.post('/satpampos/create', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const updatePos = (formData) =>
  API.post('/satpampos/update', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const posDelete = (poskode) => API.post('/satpampos/delete', { satpamposkode: poskode });

// Checkpoint
export const checkpointData = (formData) => API.post('/satpamtitik');
export const detailCheckpoint = (kode) => API.post('/satpamtitik/detail', { satpamtitikkode: kode });
export const createCheckpoint = (formData) =>
  API.post('/satpamtitik/create', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const updateCheckpoint = (formData) =>
  API.post('/satpamtitik/update', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const checkpointDelete = (poskode) => API.post('/satpamtitik/delete', { satpamtitikkode: poskode });

// Jadwal
export const jadwalData = (formData) => API.post('/satpam-jadwal', formData);
export const jadwalDataByPersonil = (formData) => API.post('/satpam-jadwal/show_bypersonil', formData);
export const timeSheet = (formData) => API.post('/satpam-jadwal/timesheet', formData);
export const jadwalDetail = (jadwalkode) => API.post('/satpam-jadwal/detail', { satpamjadwalkode: jadwalkode });
export const createJadwal = (formData) => API.post('/satpam-jadwal/create', formData);
export const updateJadwal = (formData) => API.post('/satpam-jadwal/update', formData);
export const jadwalDelete = (jadwalkode) => API.post('/satpam-jadwal/delete', { satpamjadwalkode: jadwalkode });
export const jadwalDeleteSelected = (formData) => API.post('/satpam-jadwal/delete_all', formData);
export const izinData = (formData) => API.post('/satpam-jadwal/show_izin', formData);
export const createIzin = (formData) => API.post('/satpam-jadwal/create_izin', formData);
export const updateIzin = (formData) => API.post('/satpam-jadwal/update_izin', formData);
export const izinDetail = (izinkode) => API.post('/satpam-jadwal/detail_izin', { satpamizinkode: izinkode });
export const izinDelete = (izinkode) => API.post('/satpam-jadwal/delete_izin', { satpamizinkode: izinkode });

// Kehadiran
export const kehadiranData = (formData) => API.post('/satpam-kehadiran/group', formData);
export const kehadiranDataByPersonil = (formData) => API.post('/satpam-kehadiran/persatpam', formData);
export const kehadiranDetail = (jadwalkode) => API.post('/satpam-kehadiran/detail', { satpamkehadiransatpamjadwalkode: jadwalkode });

// Analisis
export const getMonth = () => API.post('/satpam-kejadian/getmonth');
export const analisisKejadian = (formData) => API.post('/satpam-kejadian/analisis', formData);
export const analisisHarian = (formData) => API.post('/satpam-kegiatan/analisis', formData);
export const analisisTemuan = (formData) => API.post('/satpam-temuan/analisis', formData);
export const analisisKeluhan = (formData) => API.post('/satpam-keluhan/analisis', formData);
export const analisisPatroli = (formData) => API.post('/satpam-patroli/analisis', formData);
export const analisisSatpam = (formData) => API.post('/satpam-jadwal/analisis', formData);

// Informasi
export const informasiData = (formData) => API.post('/satpam-informasi', formData);
export const informasiDetail = (informasikode) => API.post('/satpam-informasi/detail', { satpaminformasikode: informasikode });
export const informasiDelete = (informasikode) => API.post('/satpam-informasi/delete', { satpaminformasikode: informasikode });
export const createInformasi = (formData) => API.post('/satpam-informasi/create', formData);
export const updateInformasi = (formData) => API.post('/satpam-informasi/update', formData);

// Notifikasi
export const notifikasiData = (kodepersonil) => API.post('/satpam-notifikasi', { satpamnotifikasisatpamkode: kodepersonil });
export const createNotifikasi = (formData) => API.post('/satpam-notifikasi/create', formData);
export const notifikasiDelete = (notifikasikode) => API.post('/satpam-notifikasi/delete', { satpamnotifikasikode: notifikasikode });

// Inbox
export const inboxData = (formData) => API.post('/satpam-inbox', formData);
export const inboxDetail = (inboxkode) => API.post('/satpam-inbox/detail', { satpaminboxkode: inboxkode });
export const inboxDelete = (inboxkode) => API.post('/satpam-inbox/delete', { satpaminboxkode: inboxkode });
export const createInbox = (formData) => API.post('/satpam-inbox/create', formData);
export const updateInbox = (formData) => API.post('/satpam-inbox/update', formData);

// Keadaan
export const keadaanData = (formData) => API.post('/satpam-keadaan', formData);
export const keadaanDetail = (keadaankode) => API.post('/satpam-keadaan/detail', { satpamkeadaankode: keadaankode });
export const keadaanDelete = (keadaankode) => API.post('/satpam-keadaan/delete', { satpamkeadaankode: keadaankode });
export const createKeadaan = (formData) => API.post('/satpam-keadaan/create', formData);
export const keadaanVerify = (formData) => API.post('/satpam-keadaan/verification', formData);

// Keluhan
export const keluhanData = (formData) => API.post('/satpam-keluhan', formData);
export const kategoriKeluhanData = (formData) => API.post('/satpam-keluhan/kategori', formData);
export const keluhanDetail = (keluhankode) => API.post('/satpam-keluhan/detail', { satpamkeluhankode: keluhankode });
export const keluhanDelete = (keluhankode) => API.post('/satpam-keluhan/delete', { satpamkeluhankode: keluhankode });
export const createKeluhan = (formData) => API.post('/satpam-keluhan/create', formData);
export const updateKeluhan = (formData) => API.post('/satpam-keluhan/update', formData);
export const assignKeluhan = (payload) => API.post('/satpam-keluhan/assign', payload);
export const updateStatusKeluhan = (payload) => API.post('/satpam-keluhan/update-status', payload);

// Banjar
export const getBanjarAll = () => API.get('/banjar');
export const getBanjarById = (id) => API.get(`/banjar/detail?id=${id}`);
export const createBanjar = (formData) => API.post('/banjar/create', formData);
export const deleteBanjar = (id) => API.delete(`/banjar/${id}/delete`);
export const updateBanjar = (id, formData) => API.post(`/banjar/${id}/update`, formData);

// Penduduk
export const getPendudukByBanjarId = (id) => API.get(`/banjar-penduduk?banjarId=${id}`);
export const getPendudukById = (id) => API.get(`/banjar-penduduk/detail?id=${id}`);
export const createPenduduk = (formData) => API.post('/banjar-penduduk/create', formData);
export const deletePenduduk = (id) => API.delete(`/banjar-penduduk/delete?id=${id}`);
export const updatePenduduk = (formData) => API.post('/banjar-penduduk/update', formData);

// Lalu Lintas
export const laluLintas = (formDate) => API.post('/satpam-lalulintas', formDate);
export const laluLintasByKode = (formData) => API.post(`/satpam-lalulintas/detail`, formData);
export const laluLintasDate = () => API.get('/satpam-lalulintas/kegiatan-per-date');
export const detailLaluLintas = (kodeKegiatan) => API.post('/satpam-proses', kodeKegiatan);
export const createLaluLintas = (formData) =>
  API.post('/satpam-lalulintas/create', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const laluLintasUpdate = (formData) =>
  API.post('/satpam-lalulintas/update', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const laluLintasDelete = (id) => API.delete(`/satpam-lalulintas/delete?id=${id}`);
export const laluLintasKategori = (formData) => API.post('/satpam-lalulintas-kategori', formData);

// Retribusi
export const getRetribusiAll = () => API.get('/retribusi');
export const getRetribusiById = (id) => API.get(`/retribusi/detail?id=${id}`);
export const createRetribusi = (formData) => API.post('/retribusi/create', formData);
export const deleteRetribusi = (id) => API.delete(`/retribusi/delete?id=${id}`);
export const updateRetribusi = (formData) => API.put('/retribusi/update', formData);

// Retribusi Id
export const getRetribusiDetailAll = (id) => API.get(`/retribusi-detail?retribusiId=${id}`);
export const getRetribusiDetailById = (id) => API.get(`/retribusi-detail/detail?id=${id}`);
export const createRetribusiDetail = (formData) => API.post('/retribusi-detail/create', formData);
export const deleteRetribusiDetail = (id) => API.delete(`/retribusi-detail/delete?id=${id}`);
export const updateRetribusiDetail = (formData) => API.post('/retribusi-detail/update', formData);

//Kontribusi
export const getKontribusi = (status, month) => API.get('/kontribusi', { params: { status: status, month: month } });
export const getDetailKontribusi = (id) => API.get(`/kontribusi/detail/${id}`);
export const deleteKontribusi = (id) => API.delete(`/kontribusi/detail/${id}/delete`);
export const createKontribusi = (formData) => API.post('/kontribusi/create', formData);
export const updateKontribusi = (id, formData) => API.post(`kontribusi/detail/${id}/update`, formData);
export const verifyKontribusi = (id) => API.get(`kontribusi/detail/${id}/verify`);
export const listWarga = () => API.get('/kontribusi/warga');

// Master data
export const getKota = (formData) => API.post('/kota', formData);
export const getKecamatan = (formData) => API.post('/kecamatan', formData);

// list-response
export const listKeadaanClient = () => API.get('/keadaan-client');
export const getDetailListClient = (id) => API.get(`/keadaan-client/${id}`);

//Tim Respon
export const GetListTim = () => API.get('/keadaan-client/tim');
export const CreateTimRespon = (payload) => API.post('/keadaan-client/create-tim', payload);
export const UpdateTimRespon = (payload) => API.put('/keadaan-client/update-tim', payload);
export const DeleteTimRespon = (idTim) => API.delete(`/keadaan-client/delete-tim?id=${idTim}`);

// promo
export const showPromo = () => API.get('/informasi-promo');
export const getKategoriPromo = () => API.get('informasi-promo/category');
export const createPromo = (payload) => API.post('/informasi-promo/create', payload);
export const updatePromo = (payload) => API.post(`/informasi-promo/update`, payload);
export const deletePromo = (id) => API.delete(`/informasi-promo/delete?id=${id}`);
export const detailPromo = (id) => API.get(`/informasi-promo/${id}`);

//  Krama Tama Usaha

export const getKTU = () => API.get('/personil/ktu');
export const getPaketKTU = () => API.get('/personil/ktu/package');
export const getDetailKTU = (id) => API.get(`/personil/ktu/${id}`);
export const createKTU = (payload) => API.post('/personil/create/ktu', payload);
export const updateKTU = (payload) => API.post('/personil/update/ktu', payload);
