import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useImagePreview from '../../hooks/useImagePreview';
import { informasiUpdateSchema } from '../../utils/schemaValidation';
import imageCompression from 'browser-image-compression';
import id from 'date-fns/locale/id';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment/moment';
import { formats, modules } from '../../utils/reactQuillOptions';
import { Tooltip } from '@mantine/core';
import { HiOutlineInformationCircle } from 'react-icons/hi';
const dataOpt = [
  {
    id: '1',
    value: 'informasi',
    name: 'Informasi'
  },
  {
    id: '2',
    value: 'lainnya',
    name: 'Lainnya'
  }
];

export default function InformasiUpdate() {
  const [loading, setLoading] = useState(false);
  const [tanggal, setTanggal] = useState('');
  const [isi, setIsi] = useState('');

  const navigate = useNavigate();
  const { kode } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(informasiUpdateSchema)
  });

  const [fileDataURL] = useImagePreview(watch('satpaminformasigambar'));

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getInformasiByKode = async () => {
      await api.informasiDetail(kode).then((res) => {
        if (res.data.data.length > 0) {
          res.data.data[0].satpaminformasiisi !== null && setIsi(res.data.data[0].satpaminformasiisi);
          res.data.data[0].satpaminformasitanggal !== null && setTanggal(new Date(res.data.data[0].satpaminformasitanggal));

          const defaultValue = {
            satpaminformasigrup: res.data.data[0].satpaminformasigrup !== null ? res.data.data[0].satpaminformasigrup : '',
            satpaminformasiheader: res.data.data[0].satpaminformasiheader !== null ? res.data.data[0].satpaminformasiheader : '',
            satpaminformasiheaderdetail: res.data.data[0].satpaminformasiheaderdetail !== null ? res.data.data[0].satpaminformasiheaderdetail : '',
            satpaminformasijudul: res.data.data[0].satpaminformasijudul !== null ? res.data.data[0].satpaminformasijudul : '',
            satpaminformasilink: res.data.data[0].satpaminformasilink !== null ? res.data.data[0].satpaminformasilink : '',
            satpaminformasistatus: res.data.data[0].satpaminformasistatus !== null ? res.data.data[0].satpaminformasistatus : '',
            satpaminformasigambar: res.data.data[0].satpaminformasigambar.split('/data-informasi/')[1].length > 0 && res.data.data[0].satpaminformasigambar,
            satpaminformasiopen: res.data.data[0].satpaminformasiopen
          };
          reset(defaultValue);
        }
      });
    };
    getInformasiByKode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kode]);

  const onSubmit = async (data) => {
    setLoading(true);
    // if (typeof data.satpaminformasigambar === 'string') delete data.satpaminformasigambar;

    data.satpaminformasikode = kode;
    data.satpaminformasitanggal = moment(tanggal).format('YYYY-MM-DDTHH:mm');

    // Change tag <p></p> to tag <br>
    let newIsi = isi;
    const removeTagP = newIsi.replace(/<p>/g, '');
    const changeTagPtoBr = removeTagP.replace(/<\/p>/g, '<br>');

    data.satpaminformasiisi = changeTagPtoBr;
    const formData = new FormData();

    if (data.satpaminformasigambar.length > 0 && typeof data.satpaminformasigambar !== 'string') {
      const compressedFile = await imageCompression(data.satpaminformasigambar[0], compressOptions);
      formData.append('satpaminformasigambar', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key !== 'satpaminformasigambar') {
        if (key === 'field') {
          formData.append(key, data[key][1]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    setLoading(true);

    await api
      .updateInformasi(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        navigate('/informasi');
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="flex items-center justify-center py-3 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-gray-50 shadow-black/40">Update Informasi</div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="flex flex-col w-full gap-5 mb-5">
          <div className="flex flex-col w-full gap-5">
            <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
              <div className="items-center mb-3 md:flex">
                <div className="md:w-1/4">
                  <label className="block mb-1 font-semibold md:mb-0">Tanggal Informasi</label>
                </div>
                <div className="md:w-3/4">
                  <div className="relative block">
                    <DatePicker
                      selected={tanggal}
                      className={`w-full text-sm cursor-pointer inputForm`}
                      onChange={(date) => setTanggal(date)}
                      locale={id}
                      showTimeInput
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeFormat="HH:mm"
                      placeholderText="dd/MM/yyyy HH:mm"
                      required
                    />
                    <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                      <i className="far fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="items-center mb-3 md:flex">
                <div className="md:w-1/4">
                  <label className="block mb-1 font-semibold md:mb-0">Grup</label>
                </div>
                <div className="md:w-3/4">
                  <select className={`w-full ${errors.satpaminformasigrup ? 'invalidForm' : 'inputForm'}`} {...register('satpaminformasigrup')}>
                    <option value="">Silahkan Pilih</option>
                    {dataOpt.map((val) => (
                      <option value={val.value} key={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  <p className="text-xs text-errorText">{errors.satpaminformasigrup?.message}</p>
                </div>
              </div>
              <div className="items-center md:flex">
                <div className="md:w-1/4">
                  <label className="block mb-1 font-semibold md:mb-0">Status</label>
                </div>
                <div className="md:w-3/4">
                  <select className={`w-full ${errors.satpaminformasistatus ? 'invalidForm' : 'inputForm'}`} {...register('satpaminformasistatus')}>
                    <option value="">Pilih status</option>
                    <option value="Aktif">Aktif</option>
                    <option value="Tidak Aktif">Tidak Aktif</option>
                  </select>
                  <p className="text-xs text-errorText">{errors.satpaminformasistatus?.message}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
              <div className="mb-3 md:flex">
                <div className="md:w-1/4">
                  <label className="block mb-1 font-semibold md:mb-0">Header Informasi</label>
                </div>
                <div className="md:w-3/4">
                  <textarea className={`w-full ${errors.satpaminformasiheader ? 'invalidForm' : 'inputForm'}`} rows={2} {...register('satpaminformasiheader')} />
                  <p className="text-xs text-errorText">{errors.satpaminformasiheader?.message}</p>
                </div>
              </div>
              <div className="md:flex">
                <div className="md:w-1/4">
                  <label className="block mb-1 font-semibold md:mb-0">Header Detail Informasi</label>
                </div>
                <div className="md:w-3/4">
                  <textarea rows={3} className={`w-full ${errors.satpaminformasiheaderdetail ? 'invalidForm' : 'inputForm'}`} {...register('satpaminformasiheaderdetail')} />
                  <p className="text-xs text-errorText">{errors.satpaminformasiheaderdetail?.message}</p>
                </div>
              </div>
            </div>

            <div className="p-5 rounded-lg bg-gray-50 shadowAll">
              <div className="mb-3 md:flex">
                <div className="md:w-1/4">
                  <label htmlFor="satpamfoto" className="block mb-1 font-semibold md:mb-0">
                    Gambar Informasi
                  </label>
                </div>
                <div className="md:w-3/4">
                  <div className="flex w-full">
                    <div className="w-full">
                      <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                        <div className="flex flex-col w-full">
                          <label className="flex flex-col items-center justify-center w-full p-2 border-4 border-dashed h-36 hover:bg-gray-100 hover:border-gray-300">
                            {fileDataURL ? (
                              <div className="flex flex-col items-center justify-center">
                                <img src={fileDataURL} className="object-contain w-full h-32" alt="preview" />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                              </div>
                            )}
                            <input type="file" className="opacity-0" {...register('satpaminformasigambar')} accept="image/*" />
                          </label>
                        </div>
                        <p className="text-xs text-errorText">{errors.satpaminformasigambar?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-5 rounded-lg bg-gray-50 shadowAll">
              <div className="items-center mb-3 md:flex">
                <div className="md:w-1/4">
                  <label htmlFor="tanggal_aktifitas" className="block mb-1 font-semibold md:mb-0">
                    Judul
                  </label>
                </div>
                <div className="md:w-3/4">
                  <input type="text" className={`w-full ${errors.satpaminformasijudul ? 'invalidForm' : 'inputForm'}`} {...register('satpaminformasijudul')} />
                  <p className="text-xs text-errorText">{errors.satpaminformasijudul?.message}</p>
                </div>
              </div>
              <div className="mb-3 md:flex">
                <div className="md:w-1/4">
                  <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                    Isi
                  </label>
                </div>
                <div className="md:w-3/4">
                  <ReactQuill theme="snow" modules={modules} formats={formats} value={isi} onChange={setIsi} />
                </div>
              </div>
              <div className="items-center mb-3 md:flex">
                <div className="md:w-1/4 ">
                  <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                    Link
                  </label>
                </div>
                <div className="mb-3 md:w-3/4">
                  <input type="text" className={`w-full ${errors.satpaminformasilink ? 'invalidForm' : 'inputForm'}`} {...register('satpaminformasilink')} />
                  <p className="text-xs text-errorText">{errors.satpaminformasilink?.message}</p>
                </div>
              </div>
              <div className="items-center md:flex">
                <div className="flex gap-2 md:w-1/4">
                  <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                    Sebarkan Informasi
                  </label>
                  <Tooltip
                    multiline
                    w={120}
                    withArrow
                    transitionProps={{ duration: 200 }}
                    label="Informasi penutupan lalu lintas yang bersifat publik  akan dibagikan kepada masyarakat dan Hotel yang berada di sekitar desa adat">
                    <p className="my-auto">
                      <HiOutlineInformationCircle size={16} />
                    </p>
                  </Tooltip>
                </div>
                <div className="md:w-3/4">
                  <select className={`w-full flex flex-col font-normal ${errors.satpaminformasiopen ? 'invalidForm' : 'inputForm'}`} {...register('satpaminformasiopen')}>
                    <option disabled value="">
                      Belum Ditentukan
                    </option>
                    <option value={0}>Private</option>
                    <option value={1}>Publik</option>
                  </select>
                  <p className="text-xs text-errorText">{errors.satpaminformasiopen?.message}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full px-10 py-5 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <Link to="/informasi">
              <button className="px-5 py-2 text-sm rounded-md btn text-gray-50 bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
