import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api'; // Replace with your actual API module
import { Modal } from '@mantine/core';
import { ScaleLoader } from 'react-spinners';

export default function ModalKontribusiDetails({ setOpenModalDetail, id, openModalDetail }) {
  const [dataKontribusi, setDataKontribusi] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getDetailKontribusi(id.id);

        setDataKontribusi(response.data.data); // Assuming the data you want is in response.data
      } catch (error) {
        // Handle error if needed
        console.error('Error fetching detail:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
      {isLoading ? (
        <div className="text-center py-8">
          <ScaleLoader color="#0077B6" loading={true} size={150} />
        </div>
      ) : (
        <>
          <div className="text-center text-lg font-bold mb-5">Detail Kontribusi</div>
          <div>
            <div className="mx-auto">
              <div className="grid md:grid-cols-2 grid-cols-1 gap-0 mx-auto mb-2">
                <div className="w-full font-bold">Code</div>
                <div className="w-full">{dataKontribusi?.code || '-'}</div>
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-0 mx-auto mb-2">
                <div className="w-full font-bold">Petugas</div>
                <div className="w-full">{dataKontribusi?.petugas || '-'}</div>
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-0 mx-auto mb-2">
                <div className="w-full font-bold">Date</div>
                <div className="w-full">{dataKontribusi?.date || '-'}</div>
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-0 mx-auto mb-2">
                <div className="w-full font-bold">Status</div>
                <div className="w-full">
                  {dataKontribusi?.status === 'UNPAID' && 'Belum Lunas'}
                  {dataKontribusi?.status === 'PAID' && 'Lunas'}
                  {dataKontribusi?.status === 'PROGRESS' && 'Sedang Diproses'}
                </div>
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-0 mx-auto mb-2">
                <div className="w-full font-bold">Nominal</div>
                <div className="w-full">{dataKontribusi?.price || '-'}</div>
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-0 mx-auto mb-2">
                <div className="w-full font-bold">Paid At</div>
                <div className="w-full">{dataKontribusi?.paid_at || '-'}</div>
              </div>
            </div>

            <div className=" w-full mt-5 gap-2 mx-auto text-center ">
              <div className="w-full font-bold text-center">Bukti Pembayaran</div>
              <div className="w-full">
                {dataKontribusi?.status === 'UNPAID' ? (
                  'Menunggu Proses Pembayaran...'
                ) : (
                  <img src={dataKontribusi?.bukti_pembayaran} alt="bukti" className="mx-auto mt-4 rounded-md shadow-sm" />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}
