import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';

export default function ModalPetugasDetail({ kode, openModalDetail, setOpenModalDetail }) {
  const [dataPetugas, setDataPetugas] = useState({});
  const [hakAksesMenusId, setHakAksesMenusId] = useState([]);
  const [allMenuData, setAllMenuData] = useState([]);

  useEffect(() => {
    const getAllMenu = async () => {
      await api.allMenu().then((res) => {
        setAllMenuData(res.data.data);
      });
    };
    getAllMenu();
  }, []);

  useEffect(() => {
    if (kode !== '') {
      const getPetugasByKode = async () => {
        await api.getDetailPetugas(kode).then((res) => {
          setDataPetugas(res.data.data[0]);
          if (res.data.hak_akses.length > 0) {
            res.data.hak_akses.map((hakakses) => {
              return setHakAksesMenusId((prev) => [
                ...prev,
                { adminmenuid: hakakses.adminhakaksesadminmenuid.toString() }
              ]);
            });
          } else {
            setHakAksesMenusId([]);
          }
        });
      };

      getPetugasByKode();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Petugas</div>
      <div className="flex justify-center">
        {dataPetugas.adminpetugasfoto?.split('/data-petugas/')[1]?.length > 0 ? (
          <img
            src={dataPetugas.adminpetugasfoto}
            alt=""
            className="w-[150px] h-[150px] object-cover mb-5 rounded-xl"
          />
        ) : (
          <img
            src={require('../../images/satpam.jpg')}
            alt=""
            className="w-[150px] h-[150px] object-cover mb-5 rounded-xl"
          />
        )}
      </div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Petugas</div>
            <div className="w-full">{dataPetugas?.adminpetugaskode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Petugas</div>
            <div className="w-full">{dataPetugas?.adminpetugasnamalengkap}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Perusahaan</div>
            <div className="w-full">{dataPetugas?.adminpetugasperusahaannama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Jabatan</div>
            <div className="w-full">{dataPetugas?.adminpetugasjabatan}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tipe</div>
            <div className="w-full">{dataPetugas?.adminpetugastipe}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Username</div>
            <div className="w-full">{dataPetugas?.adminpetugasusername}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Hak Akses</div>
            <div className="w-full">
              {hakAksesMenusId.length > 0
                ? allMenuData
                    ?.filter(
                      (item) =>
                        item?.adminmenugrup === item.adminmenugrup &&
                        item?.adminmenuclass === 'Main'
                    )
                    ?.map((mainmenu) => (
                      <div key={mainmenu.adminmenuid} className="flex flex-col w-full text-sm">
                        <div className="bg-customGray rounded p-1.5 flex gap-2 pr-2">
                          <input
                            type="checkbox"
                            id={mainmenu.adminmenuid}
                            defaultValue={mainmenu.adminmenuid}
                            checked={
                              hakAksesMenusId.filter(
                                (menu) => menu.adminmenuid === mainmenu.adminmenuid.toString()
                              ).length > 0
                                ? true
                                : false
                            }
                            readOnly
                          />
                          <label htmlFor={mainmenu.adminmenuid}>{mainmenu.adminmenugrup}</label>
                        </div>
                        <div className="flex flex-wrap p-1.5">
                          {allMenuData
                            ?.filter(
                              (item) =>
                                item?.adminmenugrup === mainmenu.adminmenugrup &&
                                item?.adminmenuclass === 'Child'
                            )
                            .map((childmenu) => (
                              <div key={childmenu.adminmenuid} className="flex gap-2 pr-2">
                                <input
                                  type="checkbox"
                                  id={childmenu.adminmenuid}
                                  defaultValue={childmenu.adminmenuid}
                                  checked={
                                    hakAksesMenusId.filter(
                                      (menu) =>
                                        menu.adminmenuid === childmenu.adminmenuid.toString()
                                    ).length > 0
                                      ? true
                                      : false
                                  }
                                  readOnly
                                />
                                <label htmlFor={childmenu.adminmenuid}>
                                  {childmenu.adminmenuutama}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))
                : allMenuData
                    ?.filter(
                      (item) =>
                        item?.adminmenugrup === item.adminmenugrup &&
                        item?.adminmenuclass === 'Main'
                    )
                    .map((mainmenu) => (
                      <div key={mainmenu.adminmenuid} className="flex flex-col w-full text-sm">
                        <div className="bg-customGray rounded p-1.5 flex gap-2 pr-2">
                          <input type="checkbox" id={mainmenu.adminmenuid} readOnly />
                          <label htmlFor={mainmenu.adminmenuid}>{mainmenu.adminmenugrup}</label>
                        </div>
                        <div className="flex flex-wrap p-1.5">
                          {allMenuData
                            ?.filter(
                              (item) =>
                                item?.adminmenugrup === item.adminmenugrup &&
                                item?.adminmenuclass === 'Child'
                            )
                            ?.map((childmenu) => (
                              <div key={childmenu.adminmenuid} className="flex gap-2 pr-2">
                                <input type="checkbox" id={childmenu.adminmenuid} readOnly />
                                <label htmlFor={childmenu.adminmenuid}>
                                  {childmenu.adminmenuutama}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
