import React, { useEffect, useState } from 'react';
import { DataTable } from 'mantine-datatable';

export default function Table({ columnData, data, setQuery, id, verify, setStatus, banjarOptions, onBanjarFilterChange }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [records, setRecords] = useState(data?.slice(0, pageSize));

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecords(data?.slice(from, to));
  }, [data, page, pageSize]);

  return (
    <>
      {/* Show data and search */}
      <div className="flex flex-col justify-between w-full mb-2 md:flex-row md:items-center">
        <div className="flex flex-col w-full gap-3 mb-2 sm:flex-row md:mb-0">
          <div className="flex flex-col w-full text-xs font-semibold md:w-fit">
            <span>Show &nbsp;</span>
            <select className="w-full inputForm" onChange={(e) => setPageSize(e.target.value)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {verify && (
            <div className="flex flex-col w-full text-xs md:w-fit">
              <span>Filter Status</span>
              <select className="w-full inputForm" onChange={(e) => setStatus(e.target.value)}>
                <option value="all">Semua</option>
                <option value="verify">Terverifikasi</option>
                <option value="unverify">Belum Verifikasi</option>
              </select>
            </div>
          )}
          {banjarOptions != undefined && banjarOptions.length > 0 && (
            <div className="flex flex-col w-full text-xs md:w-fit">
              <span>Filter Banjar</span>
              <select className="w-full inputForm" onChange={(e) => onBanjarFilterChange(e.target.value)}>
                <option value="all">Semua</option>
                {banjarOptions.map((item) => (
                  <option key={item.code} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {/* {role && (
            <div className="flex flex-col w-full text-xs md:w-fit">
              <span>Filter Role</span>
              <select className="w-full inputForm" onChange={(e) => setRole(e.target.value)}>
                <option value="all">Semua</option>
                <option value="Admin">Admin</option>
                <option value="Petugas">Petugas</option>
                <option value="Krama Tamiu & Tamiu">Krama Tamiu & Tamiu</option>
              </select>
            </div>
          )} */}
        </div>
        <div className="w-full text-xs md:w-1/4">
          <span className="font-semibold">Search: &nbsp;</span>
          <input className="w-full inputForm" type="search" placeholder="search here..." onChange={(e) => setQuery(e.target.value)} />
        </div>
      </div>
      {/* Table */}
      <div className="w-full h-[80vh] overflow-y-auto overflow-x-auto ">
        <DataTable
          styles={{
            header: {
              wordWrap: 'normal'
            }
          }}
          shadow="xs"
          withColumnBorders
          withBorder
          striped
          paginationColor="red"
          highlightOnHover
          verticalSpacing="xs"
          columns={columnData.map((value) => {
            return {
              ...value,
              width: 150,
              cellsStyle: {
                verticalAlign: 'top'
              },
              textAlignment: 'center',
              ellipsis: true,
              noWrap: true
            };
          })}
          records={records}
          totalRecords={data?.length}
          recordsPerPage={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          idAccessor={id}
        />
      </div>
    </>
  );
}
