import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';

export default function ModalPerusahaanDetail({ kode, openModalDetail, setOpenModalDetail }) {
  const [dataPerusahaan, setDataPerusahaan] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getPerusahaanByKode = async () => {
        await api.getDetailPerusahaan(kode).then((res) => {
          setDataPerusahaan(res.data.data[0]);
        });
      };

      getPerusahaanByKode();
    }
  }, [kode]);

  return (
    <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Desa Adat</div>
      <div className="flex justify-center">
        {dataPerusahaan?.satpamperusahaanfoto?.split('/data-perusahaan/')[1].length > 0 ? (
          <img src={process.env.REACT_APP_API_URL_PHOTO + dataPerusahaan.satpamperusahaanfoto} alt="" className="w-[150px] h-[150px] object-cover mb-5 rounded-xl" />
        ) : (
          <img src={require('../../images/logo.png')} alt="" className="w-[150px] h-[150px] object-cover mb-5 rounded-xl" />
        )}
      </div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Desa Adat</div>
            <div className="w-full">{dataPerusahaan?.satpamperusahaankode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Desa Adat</div>
            <div className="w-full">{dataPerusahaan?.satpamperusahaannama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Radius</div>
            <div className="w-full">{dataPerusahaan?.satpamperusahaanradius}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Latitude</div>
            <div className="w-full">{dataPerusahaan?.satpamperusahaanlatitude}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Longitude</div>
            <div className="w-full">{dataPerusahaan?.satpamperusahaanlongitude}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Status</div>
            <div className="w-full">{dataPerusahaan?.satpamperusahaanstatus}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Alamat</div>
            <div className="w-full">{dataPerusahaan?.satpamperusahaanalamat}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
