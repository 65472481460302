import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { MdVerified } from 'react-icons/md';
import { Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useState, useEffect } from 'react';
// Import files
import * as api from '../../redux/api';
import BtnAction from '../../components/general/BtnAction';
import Cards from '../../components/general/Cards';
import LoadingPage from '../../components/general/LoadingPage';
import ModalImportSatpam from '../../components/satpam/ModalImportSatpam';
import ModalSatpamCetak from '../../components/satpam/ModalSatpamCetak';
import ModalSatpamDetail from '../../components/satpam/ModalSatpamDetail';
import ModalSatpamVerifikasi from '../../components/satpam/ModalSatpamVerifikasi';
import Table from '../../components/general/Table';

export default function SatpamData({ personilType }) {
  const [data, setData] = useState(['']);
  const [datas, setDatas] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalVerifikasi, setOpenModalVerifikasi] = useState(false);
  const [satpamDetailId, setSatpamDetailId] = useState('');
  const [showPopover, setShowPopover] = useState('');
  const [status, setStatus] = useState('all');

  // const [role, setRole] = useState('all');

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  useEffect(() => {
    const getData = async () => {
      await api.personilListByType(personilType).then((response) => {
        setData(response.data.data);
        setDatas(response.data);
      });
    };
    getData();
  }, [personilType]);

  useEffect(() => {
    setFilterData(
      data.filter(({ satpamkode, satpamstatus, satpamnamalengkap, satpamtipe, satpamjabatan, satpamnohp }) => {
        if (
          debouncedQuery !== '' &&
          !`${satpamkode} ${satpamstatus} ${satpamnamalengkap} ${satpamtipe} ${satpamjabatan} ${satpamnohp}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  useEffect(() => {
    if (status === 'all') setFilterData(data);
    else if (status === 'verify') setFilterData(data.filter((data) => data.is_verified === 1));
    else setFilterData(data.filter((data) => data.is_verified !== 1));
  }, [data, status]);

  // useEffect(() => {
  //   if (status === 'all' && role === 'all') setFilterData(data);
  //   else if (status === 'all') {
  //     if (role === 'Admin') setFilterData(data.filter((data) => data.role === 'Admin'));
  //     if (role === 'Petugas') setFilterData(data.filter((data) => data.role === 'Petugas'));
  //     if (role === 'Krama Tamiu & Tamiu') setFilterData(data.filter((data) => data.role === 'Krama Tamiu & Tamiu'));
  //   } else if (status === 'unverify') {
  //     if (role === 'all') setFilterData(data.filter((data) => data.is_verified !== 1));
  //     if (role === 'Admin') setFilterData(data.filter((data) => data.is_verified !== 1).filter((data) => data.role === 'Admin'));
  //     if (role === 'Petugas') setFilterData(data.filter((data) => data.is_verified !== 1).filter((data) => data.role === 'Petugas'));
  //     if (role === 'Krama Tamiu & Tamiu') setFilterData(data.filter((data) => data.is_verified !== 1).filter((data) => data.role === 'Krama Tamiu & Tamiu'));
  //   } else {
  //     if (role === 'all') setFilterData(data.filter((data) => data.is_verified !== 0));
  //     if (role === 'Admin') setFilterData(data.filter((data) => data.is_verified !== 0).filter((data) => data.role === 'Admin'));
  //     if (role === 'Petugas') setFilterData(data.filter((data) => data.is_verified !== 0).filter((data) => data.role === 'Petugas'));
  //     if (role === 'Krama Tamiu & Tamiu') setFilterData(data.filter((data) => data.is_verified !== 0).filter((data) => data.role === 'Krama Tamiu & Tamiu'));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, status, role]);

  const getData = async () => {
    await api.personilListByType(personilType).then((response) => {
      setData(response.data.data);
      setDatas(response.data);
    });
  };

  const handleDelete = (data) => {
    setShowPopover('');
    Swal.fire({
      text: `Do you want to delete "${data.satpamnamalengkap}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .satpamDelete({ satpamid: data.satpamid })
            .then((res) => {
              if (res.data.status === false) Swal.fire({ icon: 'error', title: 'Oops...', text: res.data.Message });
              else Swal.fire('Good job!', res.data.Message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  const handleReset = (data) => {
    Swal.fire({
      text: `Do you want to reset "${data.satpamnamalengkap}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const resetData = async () => {
          const res = await api.satpamReset({ satpamid: data.satpamid });
          if (res.data.status) {
            Swal.fire('Good job!', res.data.message, 'success');
          } else
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.data.message
            });
        };
        resetData();
      }
    });
  };

  const handleVerification = (data) => {
    Swal.fire({
      text: `Apakah anda ingin memverifikasi "${data.satpamnamalengkap}"?`,
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const verifikasiData = async () => {
          const res = await api.satpamVerify({ satpamid: data.satpamid });
          if (res.data.status) {
            Swal.fire('Good job!', res.data.message, 'success');
            getData();
          } else
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.data.message
            });
        };
        verifikasiData();
        setOpenModalVerifikasi(false);
      }
    });
  };

  const handleOpenModalDetail = (id) => {
    setSatpamDetailId(id);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleOpenModalVerifikasi = (id) => {
    setSatpamDetailId(id);
    setOpenModalVerifikasi(true);
    setShowPopover('');
  };

  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamid',
      width: 90,
      render: (data) => (
        <BtnAction
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          handleOpenModalDetail={handleOpenModalDetail}
          HideDelete={true}
          data={data.satpamid}
          id={data}
          page="/personil"
          noPrint={true}
          reset={true}
          handleReset={handleReset}
          verifikasi={true}
          handleVerification={handleVerification}
          handleOpenModalVerifikasi={handleOpenModalVerifikasi}
        />
      )
    },
    {
      title: 'Status',
      accessor: 'is_verified',
      width: 120,
      render: ({ is_verified }) => (
        <div>
          {is_verified === 1 ? (
            <span className="flex items-center gap-1">
              <MdVerified size={20} className="text-primary" /> Terverifikasi
            </span>
          ) : (
            <span className="text-red-700">Belum verifikasi</span>
          )}
        </div>
      )
    },
    {
      title: 'Role',
      accessor: 'role',
      width: 125
    },
    {
      title: 'Foto Personil',
      accessor: 'satpamfoto',
      textAlignment: 'center',
      width: 175,
      render: ({ satpamfoto }) => (
        <div className="flex justify-center">
          {satpamfoto && satpamfoto.split('/data-satpam/')[1] ? (
            <img src={`${process.env.REACT_APP_API_URL_PHOTO}${satpamfoto}`} alt="" className="h-[125px] w-[125px] object-cover my-2 rounded-lg" />
          ) : (
            <img src={require('../../images/satpam.jpg')} alt="" className="h-[125px] w-[125px] object-cover my-2 rounded-lg" />
          )}
        </div>
      )
    },
    {
      title: 'Nama Lengkap',
      accessor: 'satpamnamalengkap',
      width: 200
    },
    // { title: 'Tipe', accessor: 'satpamtipe', width: 125, textAlignment: 'center' },
    { title: 'No. Hp', accessor: 'satpamnohp', width: 150 },
    {
      title: 'Jabatan',
      accessor: 'satpamjabatan',
      width: 150
    }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8" onClick={() => showPopover && setShowPopover('')}>
      <div className="w-full">
        <Cards
          title="Statistik Data Personil"
          desc="Berikut ini adalah jumlah personil yang terdaftar"
          card1={{ title: 'Utama', value: datas?.satpam_utama || 0 }}
          card2={{ title: 'Cadangan', value: datas?.satpam_cadangan || 0 }}
          card3={{ title: 'Personil', value: datas?.total_satpam || 0 }}
        />

        {/* Table Component */}
        <div className="my-10 w-full bg-light p-5 rounded-lg shadowAll">
          <div className="flex justify-end mb-3">
            <div className="flex gap-4 w-fit">
              <ModalSatpamCetak />
              {adminType === 'Admin Project' && (
                <>
                  <ModalImportSatpam getData={getData} />
                  <Tooltip label="Tambah data">
                    <Link to="/personil/registrasi">
                      <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                        <AiOutlineFileAdd size={24} />
                      </div>
                    </Link>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          {data.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} id="satpamid" verify={true} setStatus={setStatus} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data personil.</div>
            </div>
          )}
        </div>
        <ModalSatpamDetail id={satpamDetailId} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />
        <ModalSatpamVerifikasi
          id={satpamDetailId}
          openModalVerifikasi={openModalVerifikasi}
          setOpenModalVerifikasi={setOpenModalVerifikasi}
          handleVerification={handleVerification}
          data={data.filter((item) => item.satpamid === satpamDetailId)[0]}
        />
      </div>
    </div>
  );
}
