import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import moment from 'moment';

const parse = require('html-react-parser');

export default function ModalPromoDetail({ id, openModalDetail, setOpenModalDetail }) {
  const [dataDetail, setData] = useState({});

  useEffect(() => {
    if (id !== '') {
      const getDetails = async () => {
        await api.detailPromo(id).then((res) => {
          setData(res.data.data);
        });
      };

      getDetails();
    }
  }, [id]);

  const date = dataDetail?.date;

  return (
    <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Informasi</div>
      <div className="flex justify-center">
        {dataDetail.image?.length > 0 ? (
          <img src={dataDetail.image} alt="" className="w-full h-56 object-contain mb-5 rounded-xl" />
        ) : (
          <img src={require('../../images/logo.png')} alt="" className="w-full h-56 object-contain mb-5 rounded-xl" />
        )}
      </div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Dibuat</div>
            <div className="w-full">{moment(date).format('DD MMM YYYY')}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kategori</div>
            <div className="w-full">{dataDetail?.kategori_name || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Perusahaan</div>
            <div className="w-full">{dataDetail?.perusahaan_name || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Klien</div>
            <div className="w-full">{dataDetail?.client_name || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Judul</div>
            <div className="w-full">{dataDetail?.title || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Isi</div>
            <div className="w-full">{dataDetail?.content !== '' && dataDetail?.content !== null && parse(dataDetail.content || '-')}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tautan</div>
            <div className="w-full">{dataDetail?.link || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Status</div>
            <div className="w-full">{dataDetail?.status === 1 ? 'Aktif' : 'Tidak Aktif'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Sebarkan Informasi</div>
            <div className="w-full">{dataDetail?.open === 1 ? 'Terbuka' : 'Tertutup'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Jumlah Views</div>
            <div className="w-full">{dataDetail?.detail?.count}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
