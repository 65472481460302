import React from 'react';
import { parseToNewLine } from '../../utils/parseToNewLine';
import HeaderlaporanMail from '../general/HeaderLaporanMail';
import TimelineSatpamProses from '../general/TimelineSatpamProses';
import moment from 'moment';
import PhotoMail from '../general/PhotoMail';

const TableCetakKegiatanMail = ({ data }) => {
  return (
    <div style={{ width: '100%' }}>
      {data.map((item) => (
        <div key={item.satpamkegiatanid}>
          <div style={{ display: 'flex', width: '100%', padding: '0.5rem 0' }}>
            <HeaderlaporanMail />
            <div
              style={{
                width: ' 100%',
                padding: '0.7rem',
                textAlign: 'right',
                backgroundColor: '#0f172a',
                textColor: 'white'
              }}>
              <div
                style={{
                  display: 'block',
                  fontSize: '18px',
                  fontWeight: '700',
                  color: 'rgb(243, 244, 246)'
                }}>
                LAPORAN KEGIATAN HARIAN
              </div>
              <div
                style={{
                  display: 'block',
                  fontSize: '15px',
                  fontWeight: '700',
                  color: 'rgb(243, 244, 246)'
                }}>
                {item.satpamperusahaannama}
              </div>
            </div>
          </div>
          <div style={{ margin: '1.25rem' }}>
            <table style={{ width: '100%', borderColor: 'rgb(249, 250, 251)' }} id="kegiatanHarianPrint">
              <thead
                style={{
                  backgroundColor: 'rgb(5, 45, 90)',
                  fontSize: '12px',
                  fontWeight: '700',
                  color: 'rgb(255, 255, 255)'
                }}>
                <tr>
                  <th
                    colSpan="4"
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      textAlign: 'left'
                    }}>
                    <div style={{ padding: '0.5rem', fontSize: '18px', fontWeight: '600' }}>#{item?.satpamkegiatankode}</div>
                  </th>
                </tr>
              </thead>
              <tbody style={{ color: 'rgb(0, 0, 0)' }}>
                <tr>
                  <td
                    style={{
                      width: '30%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      fontWeight: '600'
                    }}>
                    {moment(item?.satpamkegiatantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
                  </td>
                  <td
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left'
                    }}>
                    <span style={{ fontWeight: '700' }}>KATEGORI</span> : {item?.satpamkegiatankategorinama}
                  </td>
                  <td
                    rowSpan="3"
                    style={{
                      width: '20%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <h4 style={{ marginBottom: '0.5rem', fontWeight: '600' }}>DETAIL PROSES</h4>
                    <TimelineSatpamProses data={item.satpam_proses ? item.satpam_proses : ''} />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    PATOKAN LOKASI : {item?.satpamkegiatanalamatpatokan}
                    <br />
                    <br />
                    <span style={{ fontWeight: '600' }}>ALAMAT LENGKAP</span> : {item?.satpamkegiatanalamat}
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <h4 style={{ marginBottom: '0.5rem', fontWeight: '600' }}>URAIAN KEGIATAN</h4>
                    <p style={{ marginBottom: '0.75rem' }}>{parseToNewLine(item?.satpamkegiatanuraian)}</p>
                    <h4 style={{ marginBottom: '0.5rem', fontWeight: '600' }}>KETERANGAN</h4>
                    {parseToNewLine(item?.satpamkegiatanketerangan)}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left'
                    }}>
                    <h4 style={{ fontWeight: '700' }}>
                      PELAPOR <br />
                      {item?.satpamnamalengkap}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    rowSpan="2"
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '1.25rem'
                    }}>
                    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <div
                        style={{
                          margin: '-0.25rem',
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}>
                        <PhotoMail
                          data="/data-kegiatan/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkegiatanfotosatu}
                          keterangan={item?.satpamkegiatanketeranganfotosatu}
                        />
                        <PhotoMail
                          data="/data-kegiatan/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkegiatanfotodua}
                          keterangan={item?.satpamkegiatanketeranganfotodua}
                        />
                        <PhotoMail
                          data="/data-kegiatan/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkegiatanfototiga}
                          keterangan={item?.satpamkegiatanketeranganfototiga}
                        />
                        <PhotoMail
                          data="/data-kegiatan/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkegiatanfotoempat}
                          keterangan={item?.satpamkegiatanketeranganfotoempat}
                        />
                        <PhotoMail
                          data="/data-kegiatan/"
                          photo={process.env.REACT_APP_API_URL_PHOTO + item?.satpamkegiatanfotolima}
                          keterangan={item?.satpamkegiatanketeranganfotolima}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '20%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'center',
                      verticalAlign: 'middle'
                    }}>
                    <div>Tanda Tangan</div>
                    <div
                      style={{
                        marginLeft: '1.25rem',
                        marginRight: '1.25rem',
                        marginTop: '7rem',
                        marginBottom: '2.5rem',
                        border: '1px solid #c0c0c0'
                      }}></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              marginTop: '0.5rem',
              marginBottom: '0.5rem',
              width: '100%',
              textAlign: 'center',
              color: 'rgb(0, 0, 0)'
            }}>
            <b>Copyright&copy;{process.env.REACT_APP_YEAR}</b>
            <br />
            <b>{process.env.REACT_APP_COMPANY}</b>
          </div>
          <div className="page-break"></div>
        </div>
      ))}
    </div>
  );
};

export default TableCetakKegiatanMail;
