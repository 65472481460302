import { useEffect, useState } from 'react';

export default function useImagePreview(file) {
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    if (typeof file === 'string') {
      setImgSrc(file);
    } else if (file && file[0]) {
      const newUrl = URL.createObjectURL(file[0]);

      if (newUrl !== imgSrc) {
        setImgSrc(newUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return [imgSrc, setImgSrc];
}
