import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
// Import files
import * as api from '../../redux/api';

export default function PatroliCreate({ personilType }) {
  const [loading, setLoading] = useState(false);
  const [posOptions, setPosOptions] = useState([]);
  const [ruteOptions, setRuteOptions] = useState([]);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [satpamKode, setSatpamKode] = useState('');
  const [tanggalAkhir, setTanggalAkhir] = useState(new Date());
  const [tanggalMulai, setTanggalMulai] = useState(new Date());
  const [openApiPatroli, setOenApiPatroli] = useState(false);

  // Tabel checkpoin yang dipilih
  const [ruteFields, setRuteFields] = useState([]);

  const initialFormValue = {
    satpampatrolisatpamkode: '',
    satpampatrolijenis: '',
    satpampatrolinama: '',
    satpampatrolisatpamposkode: '',
    satpampatrolimulaitanggal: '',
    satpampatroliselesaitanggal: '',
    satpampatrolirute: '',
    satpampatroliopen: openApiPatroli === false ? 1 : 0
  };

  const [formValue, setFormValue] = useState(initialFormValue);

  const getData = async () => {
    await api.personilListByType('pecalang').then((res) => {
      res.data.data.map((data) => {
        return setSatpamDataOptions((prev) => [...prev, { value: data.satpamkode, label: data.satpamnamalengkap }]);
      });
    });
  };
  const getDataPOS = async () => {
    await api.patroliGetPOS().then((res) => {
      res.data.data.map((data) => {
        return setPosOptions((prev) => [...prev, { value: data.satpamposkode, label: data.satpamposnama }]);
      });
    });
  };

  useEffect(() => {
    getDataPOS();
    getData();
  }, [personilType]);

  useEffect(() => {
    setFormValue({ ...formValue, satpampatrolisatpamkode: satpamKode });
  }, [satpamKode]);

  useEffect(() => {
    setFormValue((prev) => ({
      ...prev,
      satpampatrolimulaitanggal: moment(tanggalMulai).format('YYYY-MM-DDTHH:mm'),
      satpampatroliselesaitanggal: moment(tanggalAkhir).format('YYYY-MM-DDTHH:mm'),
      satpampatrolirute: ruteFields
    }));
  }, [tanggalMulai, tanggalAkhir, ruteFields]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValue({ ...formValue, [name]: value });

    if (name === 'satpampatrolisatpamposkode') {
      setRuteOptions([]);
      setRuteFields([]);
      if (value !== '') {
        const getDataRute = async () => {
          await api.patroliGetRute(value).then((res) => {
            res.data.data.map((data) => {
              // setTempRuteOptions((prev) => [
              //   ...prev,
              //   { value: data.satpamtitikkode, label: data.satpamtitiknama }
              // ]);
              setRuteFields((prev) => [...prev, { rute: data.satpamtitikkode, target: 1 }]);
              return setRuteOptions((prev) => [...prev, { rute: data.satpamtitikkode, label: data.satpamtitiknama, target: 1 }]);
            });
          });
        };
        getDataRute();
      }
    }
  };

  const handleRute = (e) => {
    if (e.target.name === 'koderute') {
      if (e.target.checked) {
        setRuteFields([...ruteFields, { rute: e.target.value, target: 1 }]);
      } else {
        setRuteFields(ruteFields.filter((rute) => rute.rute !== e.target.value));
      }
    } else {
      let newRuteFields = [...ruteFields];
      const indexRute = ruteFields.findIndex((field) => field.rute === e.target.name);
      newRuteFields[indexRute] = { ...newRuteFields[indexRute], target: parseInt(e.target.value) };

      setRuteFields(newRuteFields);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formValue);
    setLoading(true);

    await api
      .createPatroli(formValue)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        setRuteFields([]);
        setSatpamKode('');
        setFormValue(initialFormValue);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 overflow-x-hidden md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="flex items-center justify-center w-full py-3 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-light shadow-black/40">
          Tambah Patroli
        </div>
        <form onSubmit={handleSubmit} encType="multipart/form-data" className="flex flex-col w-full gap-5">
          <div className="flex flex-wrap gap-5 text-sm lg:flex-nowrap">
            <div className="flex flex-col w-full gap-5">
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-light shadowAll">
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Personil</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={satpamDataOptions}
                      className="w-full"
                      placeholder="Pilih personil"
                      isSearchable={true}
                      value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                      defaultValue={satpamKode}
                      name="satpampatrolisatpamkode"
                      required
                      onChange={(e) => setSatpamKode(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-light shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Jenis Patroli</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className="w-full inputForm"
                      name="satpampatrolijenis"
                      defaultValue={formValue.satpampatrolijenis || ''}
                      value={formValue.satpampatrolijenis}
                      onChange={handleInputChange}>
                      <option value="">Pilih jenis patroli</option>
                      <option value="Patroli Jalan Kaki">Patroli Jalan Kaki</option>
                      <option value="Patroli Berkendara">Patroli Berkendara</option>
                    </select>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Nama Patroli</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full inputForm`}
                      name="satpampatrolinama"
                      defaultValue={formValue.satpampatrolinama || ''}
                      value={formValue.satpampatrolinama}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="items-center md:flex">
                  <div className="md:w-1/3">
                    <label className="block mb-1 font-semibold md:mb-0">Pilih POS</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className="w-full inputForm"
                      name="satpampatrolisatpamposkode"
                      defaultValue={formValue.satpampatrolisatpamposkode || ''}
                      value={formValue.satpampatrolisatpamposkode}
                      onChange={handleInputChange}>
                      <option value="">Pilih POS patroli</option>
                      {posOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="p-5 rounded-lg bg-light shadowAll">
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="satpampatrolimulaitanggal" className="block mb-1 font-semibold md:mb-0">
                      Tanggal mulai
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalMulai}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalMulai(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center mb-3 md:flex">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block mb-1 font-semibold md:mb-0">
                      Tanggal selesai
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalAkhir}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalAkhir(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        required
                      />
                      <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-around p-5 rounded-lg  bg-gray-50 shadowAll">
                <div className="mt-3 flex gap-2">
                  <input type="checkbox" checked={openApiPatroli} onChange={(event) => setOenApiPatroli(event.target.checked)} />
                  <p className="text-xs md:text-sm">Silahkan centang jika informasi bersifat publik</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full gap-5">
              <div className="p-5 rounded-lg bg-light shadowAll">
                <div className="w-full md:flex md:flex-col">
                  <div className="px-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                    <div className="inline-block min-w-full overflow-hidden rounded-lg shadow-md">
                      <table className="min-w-full leading-normal">
                        <thead>
                          <tr>
                            <th className="w-[10%] px-2 py-1 border-b-2 border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">Aksi</th>
                            <th className="w-[5%] px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">No</th>
                            <th className="px-2 py-1 text-sm font-semibold tracking-wider text-center align-middle bg-gray-200 border-b-2 border-gray-300">Rute</th>
                            <th className="w-[15%] px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">Target</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ruteOptions.length > 0 ? (
                            ruteOptions.map((option, index) => (
                              <tr key={option.rute}>
                                <td className="px-2 py-1 text-sm tracking-wider text-center border-b-2 border-gray-200">
                                  <input
                                    type="checkbox"
                                    name="koderute"
                                    id={option.rute}
                                    defaultChecked={ruteFields.filter((field) => field.rute === option.rute).length > 0 ? true : false}
                                    value={option.rute}
                                    onChange={handleRute}
                                  />
                                </td>
                                <td className="px-2 py-1 text-sm tracking-wider text-center align-middle border-b-2 border-gray-200">{index + 1}</td>
                                <td className="px-2 py-1 text-sm tracking-wider text-left align-middle border-b-2 border-gray-200">{option.label}</td>
                                <td className="px-2 py-1 text-sm tracking-wider text-center align-middle border-b-2 border-gray-200">
                                  <input
                                    type="number"
                                    className={`w-full py-1 px-2 text-center flex flex-col inputForm`}
                                    name={option.rute}
                                    value={ruteFields.filter((field) => field.rute === option.rute).map((value) => value.target)}
                                    onChange={handleRute}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" className="px-2 py-1 text-sm tracking-wider text-center border-b-2 border-gray-200">
                                Pilih pos terlebih dahulu
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full px-10 py-5 bg-light rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-light bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <Link to="/patroli">
              <button className="px-5 py-2 text-sm rounded-md btn text-light bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
