// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Link } from 'react-router-dom';
import { Modal, Tooltip } from '@mantine/core';
import { Popover } from '@mantine/core';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import ModalInboxDetail from '../../components/inbox/ModalInboxDetail';
import { useDebouncedValue } from '@mantine/hooks';
const parse = require('html-react-parser');

export default function InboxData() {
  const [showImage, setShowImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [data, setData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [inboxDetailKode, setInboxDetailKode] = useState('');

  const [filterData, setFilterData] = useState([]);

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.inboxData().then((res) => {
      setData(res.data.Data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(
          ({
            satpaminboxtanggal,
            satpaminboxtipe,
            satpaminboxjudul,
            satpaminboxjuduldetail,
            satpaminboxdeskripsi,
            satpaminboxberlaku,
            satpaminboxaksitext
          }) => {
            if (
              debouncedQuery !== '' &&
              !`${satpaminboxtanggal} ${satpaminboxtipe} ${satpaminboxjudul} ${satpaminboxjuduldetail} ${satpaminboxdeskripsi} ${satpaminboxberlaku} ${satpaminboxaksitext}`
                .toLowerCase()
                .includes(debouncedQuery.trim().toLowerCase())
            ) {
              return false;
            }
            return true;
          }
        )
      );
    }
  }, [data, debouncedQuery]);

  const handleImageData = ({ satpaminboxgambar }) => {
    setImageData(
      satpaminboxgambar?.split('/data-inbox/')[1]
        ? satpaminboxgambar
        : require('../../images/logo.png')
    );
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (kode) => {
    setInboxDetailKode(kode);
    setOpenModalDetail(true);
  };

  const handleDelete = (kode) => {
    Swal.fire({
      text: 'Do you want to delete this inbox?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .inboxDelete(kode)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpaminboxkode',
      width: 100,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpaminboxkode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div
                size="sm"
                className="popOverItem"
                onClick={() => handleOpenModalDetail(satpaminboxkode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/inbox/update/${satpaminboxkode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Edit
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleDelete(satpaminboxkode)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Gambar / Thumbnail',
      accessor: 'satpaminboxgambar',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpaminboxgambar, satpaminboxtipe, satpaminboxvideo }) => (
        <div className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80">
          {satpaminboxtipe === 'Gambar' ? (
            satpaminboxgambar.split('/data-inbox/')[1] ? (
              <img
                src={satpaminboxgambar}
                alt=""
                className="h-full object-contain rounded"
                onClick={() => handleImageData({ satpaminboxgambar })}
              />
            ) : (
              <img
                src={require('../../images/logo.png')}
                alt=""
                className="h-full object-contain rounded"
              />
            )
          ) : (
            satpaminboxtipe === 'Video' && (
              <a href={`https://youtu.be/${satpaminboxvideo}`} target="_blank" rel="noreferrer">
                <img src={satpaminboxgambar} alt="" className="h-full object-contain rounded" />
              </a>
            )
          )}
        </div>
      )
    },
    {
      title: 'Tanggal, Status',
      accessor: 'satpaminboxtanggal',
      width: 180,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpaminboxtanggal, satpaminboxstatus }) => (
        <div>
          <div className="flex justify-center text-center h-auto w-full py-0 my-0 mb-1 cursor-pointer">
            {moment(satpaminboxtanggal).format('DD MMM YYYY HH:mm')}
          </div>
          {satpaminboxstatus === 'Aktif' ? (
            <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">
              Aktif
            </div>
          ) : (
            <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">
              Tidak Aktif
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Tipe',
      accessor: 'satpaminboxtipe',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 120
    },
    {
      title: 'Judul',
      accessor: 'satpaminboxjudul',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Detail Judul',
      accessor: 'satpaminboxjuduldetail',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 300
    },
    {
      title: 'Deskripsi',
      accessor: 'satpaminboxdeskripsi',
      width: 300,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpaminboxdeskripsi }) => (
        <div>
          {satpaminboxdeskripsi !== null &&
            satpaminboxdeskripsi !== '' &&
            parse(satpaminboxdeskripsi)}
        </div>
      )
    },
    {
      title: 'Berlaku',
      accessor: 'satpaminboxberlaku',
      width: 100,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpaminboxberlaku }) => <div>{satpaminboxberlaku} Hari</div>
    },
    {
      title: 'Inbox Aksi',
      accessor: 'satpaminboxaksitext',
      width: 250,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpaminboxaksi, satpaminboxaksitext }) => (
        <>
          {satpaminboxaksi !== null ? (
            <div className="mt-2">
              <a
                href={satpaminboxaksi}
                className="border-primary border-2 text-sm text-primary btnStatus px-3 w-full rounded text-center mb-2"
                target="_blank"
                rel="noreferrer">
                {satpaminboxaksitext}
              </a>
            </div>
          ) : (
            satpaminboxaksitext
          )}
        </>
      )
    }
  ];

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full"></div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <Tooltip label="Tambah Inbox">
                  <Link to="/inbox/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data inbox.</div>
            </div>
          )}
        </div>
        {/* Modal Detail Perusahaan */}
        <ModalInboxDetail
          kode={inboxDetailKode}
          openModalDetail={openModalDetail}
          setOpenModalDetail={setOpenModalDetail}
        />

        {/* Modal Image */}
        <Modal
          centered
          size="md"
          opened={showImage}
          onClose={() => setShowImage(false)}
          className="overflow-auto bg-transparent -mt-10">
          {/* Content image */}
          {imageData && (
            <img
              src={imageData}
              alt=""
              className="w-full object-contain rounded-t-lg flex items-center justify-center"
            />
          )}
        </Modal>
      </div>
    </div>
  );
}
