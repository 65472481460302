import React, { useEffect } from 'react';
import { Modal } from '@mantine/core';
import { useControls, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { AiOutlineZoomIn, AiOutlineZoomOut, AiOutlineRedo } from 'react-icons/ai';

const ModalPatroliImage = ({ modalOpen, setOpenModal, imgData }) => {
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <div className="gap-y-2 flex flex-col">
        <button className="rounded-md p-2 bg-slate-50 drop-shadow-md border" onClick={() => zoomIn()}>
          <AiOutlineZoomIn size={24} />
        </button>
        <button className="rounded-md p-2 bg-slate-50 drop-shadow-md border" onClick={() => zoomOut()}>
          <AiOutlineZoomOut size={24} />
        </button>
        <button className="rounded-md p-2 bg-slate-50 drop-shadow-md border" onClick={() => resetTransform()}>
          <AiOutlineRedo size={24} />
        </button>
      </div>
    );
  };
  return (
    <Modal opened={modalOpen} onClose={() => setOpenModal(false)} size="xl" closeOnClickOutside>
      <div className="w-full h-auto">
        <TransformWrapper>
          <div className="w-full h-full relative flex justify-center items-center">
            <div className="absolute bottom-2 right-5 z-50">
              <Controls />
            </div>
            <TransformComponent>
              <img src={imgData} alt="" className="w-full  max-h-[600px] object-cover" />
            </TransformComponent>
          </div>
        </TransformWrapper>
      </div>
    </Modal>
  );
};

export default ModalPatroliImage;
