import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import moment from 'moment';

const parse = require('html-react-parser');

export default function ModalInboxDetail({ kode, openModalDetail, setOpenModalDetail }) {
  const [dataInbox, setDataInbox] = useState({});
  const [dataSatpam, setDataSatpam] = useState([]);

  useEffect(() => {
    if (kode !== '') {
      const getInboxByKode = async () => {
        await api.inboxDetail(kode).then((res) => {
          setDataInbox(res.data.inbox[0]);
          setDataSatpam(res.data.satpam);
        });
      };

      getInboxByKode();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Inbox</div>
      <div className="flex justify-center">
        {dataInbox?.satpaminboxtipe === 'Gambar' ? (
          dataInbox?.satpaminboxgambar.split('/data-inbox/')[1] ? (
            <img
              src={dataInbox?.satpaminboxgambar}
              alt=""
              className="h-full object-contain rounded"
            />
          ) : (
            <img
              src={require('../../images/logo.png')}
              alt=""
              className="h-full object-contain rounded"
            />
          )
        ) : (
          dataInbox?.satpaminboxtipe === 'Video' && (
            <a
              href={`https://youtu.be/${dataInbox?.satpaminboxvideo}`}
              target="_blank"
              rel="noreferrer">
              <img
                src={dataInbox?.satpaminboxgambar}
                alt=""
                className="h-full object-contain rounded"
              />
            </a>
          )
        )}
      </div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal</div>
            <div className="w-full">
              {dataInbox?.satpaminboxtangal !== '' &&
                dataInbox?.satpaminboxtangal !== null &&
                moment(dataInbox?.satpaminboxtangal).format('DD MMM YYYY HH:mm')}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tipe</div>
            <div className="w-full">{dataInbox?.satpaminboxtipe}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Judul</div>
            <div className="w-full">{dataInbox?.satpaminboxjudul}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Detail Judul</div>
            <div className="w-full">{dataInbox?.satpaminboxjuduldetail}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Deskripsi</div>
            <div className="w-full">
              {dataInbox?.satpaminboxdeskripsi !== '' &&
                dataInbox?.satpaminboxdeskripsi !== null &&
                parse(dataInbox?.satpaminboxdeskripsi || '-')}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Aksi</div>
            <div className="w-full">
              <a
                href={dataInbox?.satpaminboxaksi || '#'}
                className="border-primary border-2 text-sm px-4 text-primary btnStatus w-full rounded text-center mb-2">
                {dataInbox?.satpaminboxaksitext || '-'}
              </a>
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Status</div>
            <div className="w-full">{dataInbox?.satpaminboxstatus}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Dikirim ke</div>
            <div className="w-full">
              <div className="flex flex-col">
                {dataSatpam?.length > 0 &&
                  dataSatpam.map((personil) => (
                    <div key={personil.satpamkode}>- {personil.satpamnamalengkap}</div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
