import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// Import files
import * as api from '../../redux/api';
import CardDashboard from '../../components/dashboard/CardDashboard';
import MapBox from '../../components/dashboard/MapBox';
import FilterDateNew from '../../components/general/FilterDateNew';
import LoadingPage from '../../components/general/LoadingPage';

export default function Dashboard({ satpamData }) {
  const [laporan, setLaporan] = useState('/kegiatan-harian');
  const [tipeLaporan, setTipeLaporan] = useState('this week');
  const [data, setData] = useState([]);
  const [date, setDate] = useState({
    startDate: moment().subtract().add(-6, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [dateDasabaya, setDateDasabaya] = useState({
    startdate: moment().subtract().add(-6, 'days').format('YYYY-MM-DD'),
    enddate: moment().format('YYYY-MM-DD')
  });
  const [kategoriOptions, setKategoriOptions] = useState([]);
  const [kategori, setKategori] = useState('');
  const [dasabaya, setDasabaya] = useState([]);
  const [errorLaporan, setErrorLaporan] = useState(false);
  const [isShowFieldCategory, setShowLaporan] = useState(true);
  const [isShowFieldDate, setShowFieldDate] = useState(true);

  // Count dasa baya
  useEffect(() => {
    const getData = async () => {
      await api.countDasabaya(dateDasabaya).then((res) => {
        setDasabaya(res.data.data);
      });
    };
    getData();
  }, [dateDasabaya]);

  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    name === 'startdate' &&
      setDateDasabaya({
        startdate: moment(value).format('YYYY-MM-DD'),
        enddate: moment(value).endOf('month').format('YYYY-MM-DD')
      });

    name === 'enddate' && setDateDasabaya((prev) => ({ ...prev, enddate: moment(value).format('YYYY-MM-DD') }));
  };

  const handleChangeLaporan = (e) => {
    // value of laporan
    const laporanValue = e.target.value;

    // update field value
    setLaporan(laporanValue);

    // update the view of field category if laporan value is changed
    const excludeShowCategory = ['/lalu-lintas', '/keadaan-darurat', '/laporan-bulanan'];
    const actionLaporan = !excludeShowCategory.includes(laporanValue);
    setShowLaporan(actionLaporan);
    if (!actionLaporan) {
      setKategori('');
    }

    // update the view of field date
    const actionDate = laporanValue !== '/keadaan-darurat' && laporanValue !== '/laporan-bulanan';
    setShowFieldDate(actionDate);
    if (!actionDate) {
      setDate({
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      });
    }
  };

  // Kategori laporan
  useEffect(() => {
    const getData = async () => {
      if (laporan === '/kegiatan-harian') {
        await api.kegiatanHarianKategori().then((res) => {
          if (res.data.data) setKategoriOptions([]);
          res.data.data.map((data) => {
            return setKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamkegiatankategorikode,
                label: data.satpamkegiatankategorinama
              }
            ]);
          });
        });
      }

      if (laporan === '/kejadian') {
        await api.kejadianKategori().then((res) => {
          if (res.data.data) setKategoriOptions([]);
          res.data.data.map((data) => {
            return setKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamkejadiankategorikode,
                label: data.satpamkejadiankategorinama
              }
            ]);
          });
        });
      }

      if (laporan === '/temuan') {
        await api.temuanKategori().then((res) => {
          if (res.data.data) setKategoriOptions([]);
          res.data.data.map((data) => {
            return setKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamtemuankategorikode,
                label: data.satpamtemuankategorinama
              }
            ]);
          });
        });
      }
    };
    getData();
  }, [laporan]);

  // Data laporan
  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpamkegiatansatpamkegiatankategorikode: kategori
    };

    const getData = async () => {
      if (laporan) {
        if (laporan === '/kegiatan-harian') {
          await api.kegiatanHarian(formDate).then((res) => {
            setData(res.data.data);
          });
        }

        laporan === '/kejadian' &&
          (await api.kejadian(formDate).then((res) => {
            setData(res.data.data);
          }));

        laporan === '/lalu-lintas' &&
          (await api.laluLintas(formDate).then((res) => {
            setData(res.data.data);
          }));

        if (laporan === '/temuan') {
          await api.temuan(formDate).then((res) => {
            setData(res.data.data);
          });
        }
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, laporan, tipeLaporan]);

  const handleErrorLaporan = () => {
    !laporan ? setErrorLaporan(true) : setErrorLaporan(false);
  };

  if (dasabaya.length === 0) {
    return <LoadingPage />;
  }

  return (
    <>
      {/* Map */}
      <div className="w-full mx-auto">
        <MapBox height="275px" data={satpamData} />
      </div>
      <div className="px-4 md:px-10 mx-auto w-full -mt-4 mb-16">
        <div className="mt-4 grid grid-cols-1 gap-4 my-4 justify-between w-full">
          {/* Peta */}
          {/* <div className="w-full h-auto -mt-3 bg-gray-50 rounded-lg shadow-black/20 shadow-lg"> */}
          {/* </div> */}

          <div className="flex flex-col md:flex-row justify-between w-full md:items-center mt-8 -mb-2">
            <div className="text-2xl font-bold">Laporan Dasa Baya</div>
            <div className="flex md:flex-row flex-col md:justify-center items-center md:mt-0 mt-2 text-xs">
              <div className="font-bold w-full md:mr-2 text-sm mb-1 md:mb-0">Filter Dasabaya:</div>
              <div className="w-full flex flex-col justify-center items-center">
                <input type="date" id="startdate" className="inputForm py-1 w-full" name="startdate" value={dateDasabaya.startdate} onChange={handleChangeDate} />
              </div>
              <div className="mx-2 my-2 md:my-0 text-center">sampai</div>
              <div className="w-full flex flex-col justify-center items-center">
                <input
                  type="date"
                  id="enddate"
                  className="cursor-pointer inputForm py-1 w-full"
                  name="enddate"
                  value={dateDasabaya.enddate}
                  min={dateDasabaya.startdate}
                  // max={moment(dateDasabaya.startdate).add(30, 'day').format('YYYY-MM-DD')}
                  onChange={handleChangeDate}
                />
              </div>
            </div>
          </div>

          {/* Cards */}
          <div className="flex flex-col sm:flex-row flex-wrap gap-5 text-white">
            {dasabaya.map((data, i) => (
              <CardDashboard key={i} data={data} />
            ))}
          </div>

          {/* Filter Laporan */}
          <div className="sm:mx-[15%]">
            <div className="font-bold text-xl mt-10 mb-5">Filter Laporan</div>
            <div className="flex gap-5">
              <div className="flex flex-col gap-8 w-[30%]">
                <label className="font-semibold mt-2">Jenis Laporan</label>
                {laporan !== '/lalu-lintas' && laporan !== '/laporan-bulanan' && laporan !== '/keadaan-darurat' && <label className="font-semibold">Kategori</label>}
                {laporan !== '/keadaan-darurat' && laporan !== '/laporan-bulanan' && <label className="font-semibold">Waktu Pelaporan</label>}
              </div>
              <div className="flex flex-col gap-5 w-[70%]">
                <select defaultValue="/kegiatan-harian" className="inputForm w-full" onChange={handleChangeLaporan}>
                  <option disabled value="" className="text-white bg-gray-500">
                    Pilih jenis laporan
                  </option>
                  <option value="/kegiatan-harian">Laporan Harian</option>
                  <option value="/kejadian">Laporan Kejadian</option>
                  <option value="/temuan">Laporan Temuan</option>
                  <option value="/lalu-lintas">Laporan Lalu Lintas</option>
                  <option value="/keadaan-darurat">Laporan Keadaan Darurat</option>
                  <option value="/laporan-bulanan">Laporan Bulanan</option>
                </select>
                <select defaultValue="" className={isShowFieldCategory ? 'inputForm w-full' : 'inputForm w-full hidden'} onChange={(e) => setKategori(e.target.value)}>
                  <option value="">Semua kategori</option>
                  {kategoriOptions.map((data, i) => (
                    <option value={data.value} key={i}>
                      {data.label}
                    </option>
                  ))}
                </select>
                <FilterDateNew date={date} setDate={setDate} setTipeLaporan={setTipeLaporan} isShow={isShowFieldDate} />
              </div>
            </div>
            <div className="flex justify-center gap-5 sm:gap-10 mt-10">
              <Link to={laporan} state={{ date, tipeLaporan, kategori }}>
                <button className="bg-primary rounded-md text-white px-5 py-2" onClick={handleErrorLaporan}>
                  Lihat Laporan
                </button>
              </Link>
              {laporan !== '/keadaan-darurat' && laporan !== '/laporan-bulanan' && (
                <Link to={`${laporan}-print`} state={{ data, date }}>
                  <button className="bg-primary rounded-md text-white px-5 py-2" onClick={handleErrorLaporan}>
                    Simpan PDF
                  </button>
                </Link>
              )}
            </div>
            {errorLaporan && !laporan && <div className="text-red-700 mt-2 text-md">Pilih jenis laporan terlebih dahulu!</div>}
          </div>
        </div>
      </div>
    </>
  );
}
