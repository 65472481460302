import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <div className="w-full justify-center bg-gray-50">
      <div className="mx-auto text-center mt-8">
        <h2 className="mb-2 text-[50px] font-bold leading-none sm:text-[80px] md:text-[100px]">
          404
        </h2>
        <h4 className="mb-3 text-[22px] font-semibold leading-tight">
          Oops! That page can’t be found
        </h4>
        <p className="mb-8 text-lg">The page you are looking it maybe deleted</p>
        <Link
          to="/"
          className="inline-block rounded-lg border text-primary border-primary px-8 py-3 text-center text-base font-semibold transition hover:text-white hover:bg-primary">
          Go To Home
        </Link>
      </div>
    </div>
  );
}
