import React from 'react';

const SectionDataDiriUsaha = ({ register, watch, errors, banjarOptions }) => {
  return (
    <div className="flex flex-col w-full p-5 mb-5 rounded-lg bg-light shadowAll lg:mb-0">
      {/* Banjar */}
      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="banjar_code" className="block mb-1 font-semibold md:mb-0">
            Banjar
          </label>
        </div>
        <div className="md:w-2/3">
          <select defaultValue="" className={`w-full text-sm cursor-pointer ${errors.banjar_code ? 'invalidForm' : 'inputForm'}`} {...register('banjar_code')}>
            <option value="" disabled>
              Pilih Banjar
            </option>
            {banjarOptions?.map((item, i) => (
              <option key={i} value={item.code} selected={item.code === watch('banjar_code')}>
                {item.name}
              </option>
            ))}
          </select>
          <p className="text-xs text-primary">{errors.banjar_code?.message}</p>
        </div>
      </div>

      {/* Nama Lengkap */}
      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="satpamnamalengkap" className="block mb-1 font-semibold md:mb-0">
            Nama Lengkap
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            id="satpamnamalengkap"
            type="text"
            placeholder="Nama lengkap satpam"
            className={`w-full text-sm ${errors.satpamnamalengkap ? 'invalidForm' : 'inputForm'}`}
            {...register('satpamnamalengkap')}
          />
          <p className="text-xs text-red-700">{errors.satpamnamalengkap?.message}</p>
        </div>
      </div>

      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="jenis_kewarganegaraan" className="block mb-1 font-semibold md:mb-0">
            Kewarganegaraan
          </label>
        </div>

        <div className="md:w-2/3">
          <select
            id="jenis_kewarganegaraan"
            defaultValue=""
            className={`w-full text-sm cursor-pointer ${errors.jenis_kewarganegaraan ? 'invalidForm' : 'inputForm'}`}
            {...register('jenis_kewarganegaraan')}>
            <option value="" disabled>
              Pilih kewarganegaraan
            </option>
            <option value="WNI">WNI</option>
            <option value="WNA">WNA</option>
          </select>
          <p className="text-xs text-red-700">{errors.jenis_kewarganegaraan?.message}</p>
        </div>
      </div>

      {watch('jenis_kewarganegaraan') === 'WNA' && (
        <>
          <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
            <div className="md:w-1/3">
              <label htmlFor="nomor_identitas" className="block mb-1 font-semibold md:mb-0">
                Nomor Paspor
              </label>
            </div>

            <div className="md:w-2/3">
              <input
                id="paspor"
                type="number"
                placeholder="Contoh: 1405010114060008"
                className={`w-full text-sm ${errors.paspor ? 'invalidForm' : 'inputForm'}`}
                {...register('paspor')}
              />
              <p className="text-xs text-red-700">{errors.paspor?.message}</p>
            </div>
          </div>

          <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
            <div className="md:w-1/3">
              <label htmlFor="date_expired_paspor" className="block mb-1 font-semibold md:mb-0">
                Tanggal Kadarluarsa Paspor
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                id="date_expired_paspor"
                type="date"
                className={`w-full text-sm ${errors.date_expired_paspor ? 'invalidForm' : 'inputForm'}`}
                {...register('date_expired_paspor')}
              />
              <p className="text-xs text-red-700">{errors.date_expired_paspor?.message}</p>
            </div>
          </div>
        </>
      )}

      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="nomor_identitas" className="block mb-1 font-semibold md:mb-0">
            Nomor Identitas
          </label>
        </div>

        <div className="md:w-2/3">
          <input
            id="nomor_identitas"
            type="number"
            placeholder="Contoh: 1405010114060008"
            className={`w-full text-sm ${errors.nomor_identitas ? 'invalidForm' : 'inputForm'}`}
            {...register('nomor_identitas')}
          />
          <p className="text-xs text-red-700">{errors.nomor_identitas?.message}</p>
        </div>
      </div>

      {/* Nomor HP */}
      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="satpamnohp" className="block mb-1 font-semibold md:mb-0">
            Nomor HP
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            type="number"
            placeholder="Contoh: 082376525674"
            className={`w-full text-sm ${errors.satpamnohp ? 'invalidForm' : 'inputForm'}`}
            {...register('satpamnohp')}
          />
          <p className="text-xs text-red-700">{errors.satpamnohp?.message}</p>
        </div>
      </div>

      {/* Email */}
      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="satpamemail" className="block mb-1 font-semibold md:mb-0">
            Email
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            id="satpamemail"
            type="email"
            placeholder="Contoh: satpam@gmail.com"
            className={`w-full text-sm ${errors.satpamemail ? 'invalidForm' : 'inputForm'}`}
            {...register('satpamemail')}
          />
          <p className="text-xs text-red-700">{errors.satpamemail?.message}</p>
        </div>
      </div>

      {/* Jenis Kelamin */}
      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="satpamjeniskelamin" className="block mb-1 font-semibold md:mb-0">
            Jenis Kelamin
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            id="satpamjeniskelamin"
            defaultValue=""
            className={`w-full text-sm ${errors.satpamjeniskelamin ? 'invalidForm' : 'inputForm'}`}
            {...register('satpamjeniskelamin')}>
            <option value="" disabled>
              Pilih jenis kelamin
            </option>
            <option value="Laki-Laki">Laki-laki</option>
            <option value="Perempuan">Perempuan</option>
          </select>
          <p className="text-xs text-red-700">{errors.satpamjeniskelamin?.message}</p>
        </div>
      </div>

      {/* Tempat Lahir */}
      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="tempat_lahir" className="block mb-1 font-semibold md:mb-0">
            Tempat Lahir
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            id="tempat_lahir"
            type="text"
            placeholder="Tempat lahir berdasarkan kartu id"
            className={`w-full text-sm ${errors.tempat_lahir ? 'invalidForm' : 'inputForm'}`}
            {...register('tempat_lahir')}
          />
          <p className="text-xs text-red-700">{errors.tempat_lahir?.message}</p>
        </div>
      </div>

      {/* Tanggal Lahir */}
      <div className="items-center gap-3 px-2 mb-3 md:flex justify-arround md:px-5">
        <div className="md:w-1/3">
          <label htmlFor="tanggal_lahir" className="block mb-1 font-semibold md:mb-0">
            Tanggal Lahir
          </label>
        </div>
        <div className="md:w-2/3">
          <input id="tanggal_lahir" type="date" className={`w-full text-sm ${errors.tanggal_lahir ? 'invalidForm' : 'inputForm'}`} {...register('tanggal_lahir')} />
          <p className="text-xs text-red-700">{errors.tanggal_lahir?.message}</p>
        </div>
      </div>
    </div>
  );
};

export default SectionDataDiriUsaha;
