import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { posUpdateSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';
import imageCompression from 'browser-image-compression';
import { validateLatitude, validateLongitude } from '../../utils/latLongValidation';

export default function POSUpdate() {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [latitudeValidation, setLatitudeValidation] = useState({ error: { status: false } });
  const [longitudeValidation, setLongitudeValidation] = useState({ error: { status: false } });

  const navigate = useNavigate();
  const { kode } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(posUpdateSchema),
    defaultValues: defaultValues
  });

  const [fotoPOSUpload] = useImagePreview(watch('satpamposfoto'));

  useEffect(() => {
    const getPosByKode = async () => {
      await api.detailPos(kode).then((res) => {
        if (res.data.data.length > 0) {
          const defaultValue = {
            satpamposkode: res.data.data[0].satpamposkode,
            satpamposstatus: res.data.data[0].satpamposstatus,
            satpamposnama: res.data.data[0].satpamposnama,
            satpamposradius: res.data.data[0].satpamposradius,
            satpamposlatitude: res.data.data[0].satpamposlatitude,
            satpamposlongitude: res.data.data[0].satpamposlongitude,
            satpamposketerangan:
              res.data.data[0].satpamposketerangan !== 'null'
                ? res.data.data[0].satpamposketerangan
                : '-',
            satpamposfoto:
              res.data.data[0].satpamposfoto.split('/data-pos/')[1].length > 0 &&
              res.data.data[0].satpamposfoto
          };
          setDefaultValues(defaultValue);
          reset(defaultValue);
        } else {
          setDefaultValues({});
        }
      });
    };
    getPosByKode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kode]);

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('satpamposkode', kode);

    if (data.satpamposfoto.length > 0 && typeof data.satpamposfoto !== 'string') {
      const compressedFile = await imageCompression(data.satpamposfoto[0], compressOptions);
      formData.append('satpamposfoto', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }
    if (!latitudeValidation.error.status && !longitudeValidation.error.status) {
      await api
        .updatePos(formData)
        .then((res) => {
          Swal.fire('Good job!', 'You successfully submit the form!', 'success');
          setLoading(false);
          navigate('/pos');
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Latitude dan/atau Longitude tidak valid'
      });
    }
  };

  const handleValidateLatLong = (e) => {
    const { name, value } = e.target;
    if (name === 'satpamposlatitude') {
      setLatitudeValidation(validateLatitude(value));
    }
    if (name === 'satpamposlongitude') {
      setLongitudeValidation(validateLongitude(value));
    }
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-10 text-center text-lg font-semibold">
          Update POS
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap lg:flex-nowrap text-sm gap-5">
            <div className="w-full flex flex-col gap-5">
              <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Nama Pos</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full ${errors.satpamposnama ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamposnama')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamposnama?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Keterangan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea
                      rows={4}
                      className={`w-full ${
                        errors.satpamposketerangan ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamposketerangan')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamposketerangan?.message}</p>
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Status Pos</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className={`w-full ${errors.satpamposstatus ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamposstatus')}>
                      <option value="">Pilih status</option>
                      <option value="Aktif">Aktif</option>
                      <option value="Tidak Aktif">Tidak Aktif</option>
                    </select>
                    <p className="text-errorText text-xs">{errors.satpamposstatus?.message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
              <div className="md:flex mb-3">
                <div className="md:w-1/3">
                  <label className="block font-semibold mb-1 md:mb-0">Foto POS</label>
                </div>
                <div className="md:w-2/3">
                  <div className="flex w-full">
                    <div className="w-full">
                      <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                        <div className="flex flex-col w-full">
                          <div className="text-sm">Pilih foto</div>
                          <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            {fotoPOSUpload ? (
                              <div className="flex flex-col items-center justify-center">
                                <img
                                  src={fotoPOSUpload}
                                  className="h-36 w-full object-contain"
                                  alt="preview"
                                />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-7">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor">
                                  <path
                                    fillRule="evenodd"
                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  Select a photo
                                </p>
                              </div>
                            )}
                            <input
                              type="file"
                              className="opacity-0"
                              {...register('satpamposfoto')}
                              accept="image/*"
                            />
                          </label>
                          <p className="text-errorText text-xs">{errors.satpamposfoto?.message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex mb-3 items-center">
                <div className="md:w-1/3">
                  <label className="block font-semibold mb-1 md:mb-0">Radius</label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    className={`w-full ${errors.satpamposradius ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamposradius')}
                  />
                  <p className="text-errorText text-xs">{errors.satpamposradius?.message}</p>
                </div>
              </div>

              <div className="md:flex mb-3 items-center">
                <div className="md:w-1/3">
                  <label className="block font-semibold mb-1 md:mb-0">Latitude</label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    className={`w-full ${errors.satpamposlatitude ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamposlatitude')}
                    onKeyUp={handleValidateLatLong}
                  />
                  <p className="text-errorText text-xs">{errors.satpamposlatitude?.message}</p>
                  <p className="text-errorText text-xs">{latitudeValidation?.error?.message}</p>
                </div>
              </div>
              <div className="md:flex items-center">
                <div className="md:w-1/3">
                  <label className="block font-semibold mb-1 md:mb-0">Longtitude</label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    className={`w-full ${errors.satpamposlongitude ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamposlongitude')}
                    onKeyUp={handleValidateLatLong}
                  />
                  <p className="text-errorText text-xs">{errors.satpamposlongitude?.message}</p>
                  <p className="text-errorText text-xs">{longitudeValidation?.error?.message}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <Link to="/pos">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
