import moment from 'moment';
import React from 'react';

export default function StatusLaporan({ kode, tanggal, status }) {
  return (
    <div>
      <div className="py-0 my-0">
        <div className="py-0 my-0">{typeof tanggal !== 'undefined' && moment(tanggal).format('DD MMM YYYY HH:mm')}</div>
        <div className="py-0 mt-0 mb-5">{kode} &nbsp;</div>
        {Number(status) === 0 ? (
          <div className="border-green-700 border-2 text-sm text-green-700 btnStatus block w-full rounded-full text-center mb-2">Open</div>
        ) : (
          <div className="border-red-700 text-sm border-2 text-red-700 btnStatus block w-full rounded-full text-center mb-2">Closed</div>
        )}
      </div>
    </div>
  );
}
